import React from 'react'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import CommonStore from '../../../store'
import { useLocalStore } from 'mobx-react'

const IMG_LIST = [
	'https://cdn.kangarooread.com/resource/event/event_2023021601.jpg',
	'https://cdn.kangarooread.com/resource/event/event_2023021602.jpg',
	'https://cdn.kangarooread.com/resource/event/event_2023021603.jpg',
	'https://cdn.kangarooread.com/resource/event/event_2023021604.jpg',
	'https://cdn.kangarooread.com/resource/event/event_2023021605.jpg'
]

export default () => {
	const store = useLocalStore(() => CommonStore)
	const { env: runtimeEnv } = store
	document.title = '开学季'

	const toSub = () => {
		if (runtimeEnv === 'app') {
			bridge.appNavigate({ id: 1 })
		} else if (runtimeEnv === 'mp') {
			wx.miniProgram.navigateTo({
				url: '/subPkg/main/pages/new-subscribe-detail/index'
			})
		}
	}

	return (
		<div
			style={{
				position: 'relative',
				zIndex: 1,
				paddingBottom: 100
			}}>
			{IMG_LIST.map((img) => (
				<img
					style={{
						width: '100%',
						display: 'block'
					}}
					src={img}
				/>
			))}
			<div
				style={{
					position: 'fixed',
					zIndex: 2,
					left: '50%',
					bottom: 20,
					transform: 'translateX(-50%)',
					width: '80%',
					height: '45px',
					lineHeight: '45px',
					textAlign: 'center',
					color: 'white',
					fontSize: 16,
					borderRadius: 25,
					background: '#FFCE40'
				}}
				onClick={toSub}>
				加入好读家庭
			</div>
		</div>
	)
}

import React from 'react'
import './page_course.less'

const Page_course = (props) => {
	const { onSelect } = props || {}

	const selectCourse = (type) => {
		onSelect && onSelect(type)
	}

	return (
		<>
			<picture className='yr-page-course-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_course_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_course_bg.png'
					className='yr-page-course-bg-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-course-text'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_course_text.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_course_text.png'
					className='yr-page-course-text-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-course-img'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_course_img.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_course_img.png'
					className='yr-page-course-img-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-course-btn-wrapper'>
				<picture
					className='yr-page-course-btn-1'
					onClick={() => selectCourse(1)}>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_course_btn_1.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_page_course_btn_1.png'
						className='yr-page-course-btn-1-img'
						alt=''
					/>
				</picture>
				<picture
					className='yr-page-course-btn-2'
					onClick={() => selectCourse(2)}>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_course_btn_2.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_page_course_btn_2.png'
						className='yr-page-course-btn-2-img'
						alt=''
					/>
				</picture>
			</div>
		</>
	)
}

export default Page_course

import React from 'react'

export default function EventItem(props) {
  const { data, toDetail } = props
  const { id, city, image, activityFinish, title, timeStr, distance, enrollFinish, payAmount } = data || {}
  return (
    <div className="event-item" onClick={() => toDetail(id)}>
      <div className="img-wrapper">
        {city ? <div className="tag">{city}</div> : null}
        <img src={image} className="img" alt=""/>
      </div>
      <div className="info-wrapper">
        <div className="name">{title || ''}</div>
        <div className="desc">
          <span className="icon icon-1"/>
          {timeStr || ''}</div>
        <div className="desc">
          <span className="icon icon-2"/>
          距我{distance || 0}</div>
        {activityFinish
          ? <div className="price" style={{ fontWeight: 400, color: '#999' }}>活动已结束</div>
          : enrollFinish
            ? <div className="price" style={{ fontWeight: 400, color: '#999' }}>报名已结束</div>
            : payAmount > 0
              ? <div className="price">¥{payAmount}</div>
              : <div className="price" style={{ fontWeight: 400 }}>免费</div>
        }
        {!activityFinish && !enrollFinish
          ? <div className="buy-btn">去报名</div>
          : null
        }
      </div>
    </div>
  )
}

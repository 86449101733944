import React, { Component } from 'react'
import './index.less'
import { env } from '../../lib/conf'
import serv from '../../lib/api'
import PrizeModal from '../../views/Event/EventLottery/components/PrizeModal'
import { inject } from 'mobx-react'
import html2canvas from 'html2canvas'
import { bridge } from '../../lib/bridge'
import wx from 'weixin-js-sdk'

const { IMG } = env

@inject('store')
class Lottery extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startRadian: -90 * Math.PI / 180,//大转盘的开始弧度(canvas绘制圆从水平方向开始，所以这里调整为垂直方向) 弧度计算公式：角度*Math.PI/180
			canBeClick: true, // 判断抽奖有没有结束
			canvas: '',
			visible: false,
			prize: {},
			shareImgVisible: false
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.onLoadPage(this.props.awards)
		}, 300)
	}

	onLoadPage(awards) {
		let { startRadian } = this.state
		let canvas = document.getElementById('wheel-canvas')
		// 获取canvas的上下文,context含有各种api用来操作canvas
		let context = canvas.getContext('2d')
		this.setState({ canvas: canvas, context: context })
		context.save()
		// 新建一个路径,画笔的位置回到默认的坐标(0,0)的位置
		// 保证了当前的绘制不会影响到之前的绘制
		context.beginPath()
		// 设置填充转盘用的颜色,fill是填充而不是绘制
		context.fillStyle = '#fff'
		// 绘制一个圆,有六个参数,分别表示:圆心的x坐标,圆心的y坐标,圆的半径,开始绘制的角度,结束的角度,绘制方向(false表示顺时针)
		context.arc(344, 344, 344, startRadian, Math.PI * 2 + startRadian, false)
		// 将设置的颜色填充到圆中,这里不用closePath是因为closePath对fill无效.
		context.fill()

		// 将画布的状态恢复到上一次save()时的状态
		context.restore()
		// 第一个奖品色块开始绘制时开始的弧度及结束的弧度
		let RadianGap = Math.PI * 2 / awards.length, endRadian = startRadian + RadianGap
		for (let i = 0; i < awards.length; i++) {
			context.save()
			context.beginPath()
			// 为了区分不同的色块,使用随机生成的颜色作为色块的填充色
			context.fillStyle = awards[i].color
			// 这里需要使用moveTo方法将初始位置定位在圆点处,这样绘制的圆弧都会以圆点作为闭合点
			context.moveTo(344, 344)
			// 画圆弧时,每次都会自动调用moveTo,将画笔移动到圆弧的起点,半径设置的比转盘稍小一点
			context.arc(344, 344, 344, startRadian, endRadian, false)
			context.fill()
			context.restore()
			// 开始绘制文字
			context.save()
			//设置文字颜色
			context.fillStyle = '#701818'
			//设置文字样式
			context.font = '30px PingFang SC'
			// 改变canvas原点的位置,简单来说,translate到哪个坐标点,那么那个坐标点就将变为坐标(0, 0)
			context.translate(
				344 + Math.cos(startRadian + RadianGap / 2) * 311,
				344 + Math.sin(startRadian + RadianGap / 2) * 311
			)

			// 旋转角度,这个旋转是相对于原点进行旋转的.
			context.rotate(startRadian + RadianGap / 2 + Math.PI / 2)
			// 这里就是根据获取的各行的文字进行绘制,maxLineWidth取90,相当与一行最多展示4个文字
			this.getLineTextList(context, awards[i].lotteryName, 90).forEach((line, index) => {
				// 绘制文字的方法,三个参数分别带:要绘制的文字,开始绘制的x坐标,开始绘制的y坐标
				context.fillText(line, -context.measureText(line).width / 2, ++index * 25)
			})

			const img = document.getElementById(`img-${i}`)
			context.drawImage(img, -40, 80, 80, 80)
			context.closePath()

			context.restore()
			// 每个奖品色块绘制完后,下个奖品的弧度会递增
			startRadian += RadianGap
			endRadian += RadianGap
		}
		//下面是画中间的小圆
		context.save()
		// 新建一个路径,画笔的位置回到默认的坐标(0,0)的位置
		// 保证了当前的绘制不会影响到之前的绘制
		context.beginPath()
		// 设置填充转盘用的颜色,fill是填充而不是绘制
		context.fillStyle = '#fff'
		// 绘制一个圆,有六个参数,分别表示:圆心的x坐标,圆心的y坐标,圆的半径,开始绘制的角度,结束的角度,绘制方向(false表示顺时针)
		context.arc(344, 344, 30, startRadian, Math.PI * 2 + startRadian, false)
		// 将设置的颜色填充到圆中,这里不用closePath是因为closePath对fill无效.
		context.fill()
		// 将画布的状态恢复到上一次save()时的状态
		context.restore()
	}

	//绘制文字，文字过长进行换行，防止文字溢出
	getLineTextList(context, text, maxLineWidth) {
		let wordList = text.split(''), tempLine = '', lineList = []
		for (let i = 0; i < wordList.length; i++) {
			if (context.measureText(tempLine).width >= maxLineWidth) {
				lineList.push(tempLine)
				maxLineWidth -= context.measureText(text[0]).width
				tempLine = ''
			}
			tempLine += wordList[i]
		}
		lineList.push(tempLine)
		return lineList
	}

	// 这个方法是为了将canvas再window中的坐标点转化为canvas中的坐标点
	windowToCanvas(canvas, e) {
		// getBoundingClientRect这个方法返回html元素的大小及其相对于视口的位置
		const canvasPosition = canvas.getBoundingClientRect(), x = e.clientX, y = e.clientY
		return {
			x: x - canvasPosition.left,
			y: y - canvasPosition.top
		}
	};

	//点击抽奖让转盘转起来
	draw = async (e) => {
		const { showToast } = this.props.store
		const { leftCount } = this.props
		if (leftCount <= 0) {
			showToast('抽奖次数用完啦')
			return
		}
		// 只要抽奖没有结束，就不让再次抽奖
		if (!this.state.canBeClick) return
		this.setState({
			canBeClick: false,
			startRadian: 0 // 每次点击抽奖，都将初始化角度重置
		})
		try {
			const res = await serv.joinLotteryActive()
			if (res) {
				const { awards } = this.props
				const { data } = res
				const { lotteryType, productId, orderId } = data || {}
				const idx = awards.findIndex(it => it.lotteryType === lotteryType)
				if (idx > -1) {
					this.showPrize(productId, orderId || '')
					const distance = this.distanceToStop(idx)
					this.rotatePanel(distance) // 调用处理旋转的方法
				}
			} else {
				this.setState({
					canBeClick: true
				})
			}
		} catch (err) {
			console.log(err)
			this.setState({
				canBeClick: true
			})
		}
	}

	// 处理旋转的关键方法
	rotatePanel(distance) {
		const { startRadian } = this.state
		const { awards } = this.props
		// 这里用一个很简单的缓动函数来计算每次绘制需要改变的角度，这样可以达到一个转盘从块到慢的渐变的过程
		let changeRadian = (distance - startRadian) / 20
		this.setState({
			startRadian: startRadian + changeRadian
		})
		// 当最后的目标距离与startRadian之间的差距低于0.0001时，就默认奖品抽完了，可以继续抽下一个了。
		if (distance - startRadian <= 0.001) {
			this.setState({
				canBeClick: true
			})
			return
		}
		// 初始角度改变后，需要重新绘制
		this.onLoadPage(awards)
		// 循环调用rotatePanel函数，使得转盘的绘制连续，造成旋转的视觉效果
		window.requestAnimationFrame(this.rotatePanel.bind(this, distance))
	}

	distanceToStop(currentPrizeIndex) {
		const { awards } = this.props
		// middleDegrees为奖品块的中间角度（最终停留都是以中间角度进行计算的）距离初始的startRadian的距离，distance就是当前奖品跑到指针位置要转动的距离。
		let middleDegrees = 0, distance = 0
		// 映射出每个奖品的middleDegrees
		let awardsToDegreesList = awards.map((data, index) => {
			let awardRadian = (Math.PI * 2) / awards.length
			return awardRadian * index + (awardRadian * (index + 1) - awardRadian * index) / 2
		})

		// 随机生成一个索引值，来表示此次抽奖应该中的奖品
		console.log('当前奖品应该中的奖品是：' + awards[currentPrizeIndex].lotteryName)
		middleDegrees = awardsToDegreesList[currentPrizeIndex]
		// 因为指针是垂直向上的，相当坐标系的Math.PI/2,所以这里要进行判断来移动角度
		distance = Math.PI * 3 / 2 - middleDegrees
		distance = distance > 0 ? distance : Math.PI * 2 + distance
		// 这里额外加上后面的值，是为了让转盘多转动几圈，看上去更像是在抽奖
		return distance + Math.PI * 10
	}

	showPrize = (productId, orderId) => {
		const { prizeList } = this.props
		const product = prizeList.find(it => it.productId === productId)
		this.setState({
			prize: { ...product, orderId }
		})
		setTimeout(() => {
			this.setState({
				visible: true
			})
		}, 2000)
	}

	closeModal = (orderId) => {
		this.setState({
			visible: false
		})
		const { finish } = this.props
		finish && finish(orderId || '')
	}

	showShareImgModal = () => {
		const { productId, pic, productName } = this.state.prize
		const { env: runtimeEnv, showLoading, hideLoading } = this.props.store
		this.setState({
			shareImgVisible: true
		})
		if (runtimeEnv === 'app') {
			showLoading('加载中...')
			const node = document.getElementById(`prize-${productId}`)
			html2canvas(node, {
				allowTaint: true,
				useCORS: true,
				scrollY: 0
			})
				.then((canvas) => {
					if (canvas) {
						const src = canvas.toDataURL('image/jpg')
						bridge.appShareBase64(src)
					}
					hideLoading()
				})
				.catch((err) => {
					console.log(err)
					hideLoading()
				})
		} else {
			wx.miniProgram.navigateTo({
				url: `/subPkg/extend/pages/img-preview-page/index?type=lotteryPrize&pImg=${encodeURIComponent(pic)}&pName=${encodeURIComponent(productName)}`
			})
		}
	}

	closeShareImgModal = () => {
		this.setState({
			shareImgVisible: false
		})
	}

	render() {
		const { awards, userInfo } = this.props
		const { visible, prize, shareImgVisible } = this.state
		const { productName, pic, orderId, productId } = prize
		const { accountVo } = userInfo || {}
		const { nickname } = accountVo || {}

		return <div className="wheel-container">
			<div className="wheel-main">
				<div className="wheel">
					<img className="arrow-icon" src={`${IMG}event-lottery/event-lottery-arrow.png`} alt=""/>
					<img className="wheel-circle" src={`${IMG}event-lottery/202206_lottery_border.png`} alt=""/>
					<canvas className="item" id="wheel-canvas" height={688} width={688}/>
					<img onClick={() => this.draw()} className="pointer"
					     src={`${IMG}event-lottery/202106_lottery_point.png`} alt=""/>
				</div>
			</div>
			{awards.map((i, idx) => <img src={i.productPic} id={`img-${idx}`} key={idx} style={{ display: 'none' }} alt=""/>)}

			{/*奖品modal*/}
			<PrizeModal visible={visible} title="恭喜你，中奖啦！" size="small"
			            onClose={() => this.closeModal()}>
				<div className="prize-info">
					<img src={pic} alt="" className="prize-img"/>
					<div className="prize-name">{productName || ''}</div>
					<div className="btn-wrapper">
						{orderId ? <div className="modal-close-btn" onClick={() => this.closeModal(orderId)}>填写地址</div> :
							<div className="modal-close-btn" onClick={() => this.closeModal()}>再抽一次</div>}
						<div className="share-btn" onClick={() => this.showShareImgModal()}>分享</div>
					</div>
				</div>
			</PrizeModal>

			{/*{shareImgVisible && <div className="prize-share-img-wrapper" onClick={() => this.closeShareImgModal()}>*/}
			{/*	<div className="prize-share-img-modal">*/}
			{/*		<img src="https://cdn.kangarooread.com/mp-assets/event-lottery/prize_share_img_bg.jpg"*/}
			{/*		     className="prize-share-img" alt=""/>*/}
			{/*		<img src={pic} className="prize-img" alt=""/>*/}
			{/*		<div className="nickname-wrapper">恭喜 <span className="nickname">{nickname || ''}</span> 获得</div>*/}
			{/*		<div className="prize-name">{productName || '奖品名称'}</div>*/}
			{/*	</div>*/}
			{/*</div>}*/}

			<div style={{
				position: 'absolute',
				left: -9999,
				top: -9999
			}}>
				<div className="prize-share-img-modal" id={`prize-${productId}`}>
					<img src={`https://cdn.kangarooread.com/mp-assets/event-lottery/prize_share_img_bg.jpg?timeStamp=${new Date().getTime()}`}
					     className="prize-share-img" alt="" crossOrigin="anonymous"/>
					<img src={`${pic}?timeStamp=${new Date().getTime()}`} className="prize-img" alt="" crossOrigin="anonymous"/>
					<div className="nickname-wrapper">恭喜 <span className="nickname">{nickname || ''}</span> 获得</div>
					<div className="prize-name">{productName || ''}</div>
				</div>
			</div>

		</div>
	}
}

export default Lottery

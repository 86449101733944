import React, { useEffect, useState } from 'react'
import { Observer, useLocalStore } from 'mobx-react'
import CommonStore from '../../store'
import { bridge } from '../../lib/bridge'
import { Util } from '../../lib/util'
import { storage } from '../../lib/conf'
import wx from 'weixin-js-sdk'
import serv from '../../lib/api'
import './index.less'
import Blank from '../../components/Blank'

/**
 * @description 表单1的输入框组件
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Input(props) {
  const [value, setValue] = useState(props.initialValue || '')
  const { onChange, type, label, disabled, placeholder, exNode, initialValue } = props

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  const handleChange = (e) => {
    const { value } = e.target
    setValue(value)
    onChange && onChange(value)
  }

  return <div className="input-item">
    <div className="label">{label}</div>
    <input className="input" type={type || 'text'} disabled={disabled} value={value} onChange={handleChange}
           placeholder={placeholder || ''}/>
    <div className="sp-line"/>
    {exNode}
  </div>
}

function InputValid(props) {
  const [value, setValue] = useState(props.initialValue)
  const [count, setCount] = useState(0)
  const { onChange, type, disabled, placeholder, showSendCode } = props

  useEffect(() => {
    let timer = null
    timer = setTimeout(() => {
      if (count > 0) {
        setCount(count => count - 1)
      }
    }, 999)
    if (count === 0) {
      return () => {
        timer = null
        clearTimeout(timer)
      }
    }
  }, [count])

  const startCountDown = () => {
    setCount(59)
  }

  const handleChange = (e) => {
    const { value } = e.target
    setValue(value)
    onChange && onChange(value)
  }

  return <div className="input-item">
    <input type={type} className="input" disabled={disabled} value={value} onChange={handleChange}
           placeholder={placeholder}/>
    {showSendCode ? <div className={`send-code-btn ${count < 60 && count > 0 ? 'disabled' : ''}`}
                         onClick={startCountDown}>{count === 0 ? '获取验证码' : `${count}秒后重发`}</div> : null}
  </div>
}

/**
 * @description 审核中的状态组件
 * @returns {JSX.Element}
 * @constructor
 */
function Auditing() {
  return <div className="auditing">
    <img src="https://cdn.kangarooread.com/mp-assets/gongmao/gm-sign-img-1.png" className="gm-sign-img-1"
         alt=""/>
    <h1 className="desc">资料审核中，请耐心等待</h1>
  </div>
}

/**
 * @description 审核驳回的状态组件
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Reject(props) {
  const { reSubmit } = props

  return <div className="reject">
    <img src="https://cdn.kangarooread.com/mp-assets/gongmao/gm-sign-img-2.png" className="gm-sign-img-2"
         alt=""/>
    <div className="reject-info">
      <div className="title">审核驳回</div>
      {/*<div className="content">不在天猫店铺选择范围，不符合天猫入驻标准，不符合各项标准</div>*/}
    </div>
    <div className="re-submit" onClick={reSubmit}>重新提交</div>
  </div>
}

export default function GmSign(props) {
  const store = useLocalStore(() => CommonStore)
  const [params, setParams] = useState({})
  const [code, setCode] = useState('')
  const [step, setStep] = useState(1)
  const [select, setSelect] = useState(false)
  const [status, setStatus] = useState(1)
  const [userInfo, setUserInfo] = useState({})
	const [contractId, setContractId] = useState('')
  const { showLoading, hideLoading, showToast, env } = store

  useEffect(() => {
    document.title = '提现签约'
    showLoading('加载中...')
    initData().then(_ => {
      hideLoading()
    })
  }, [])

  /**
   * @description 初始化数据
   * @returns {Promise<void>}
   */
  const initData = async () => {
    await checkUser()
    await checkContractStatus()
  }

  /**
   * @description 检查签约状态
   * @returns {Promise<void>}
   */
  const checkContractStatus = async () => {
    showLoading('加载中...')
    try {
      const res = await serv.checkContractStatus()
      if (res) {
        const { data } = res
        const { signedContract, needRealName, mobile, name, identity, bankAccount, bankName } = data || {}
        if (needRealName) {
          editRealInfo()
        }
        if (signedContract) {
          setStatus(signedContract || 1)
          if (signedContract === 4) {
            setParams({
              mobile: mobile || '',
              name: name || '',
              idNumber: identity || '',
              bankNum: bankAccount || '',
              bankName: bankName || ''
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
	    hideLoading()
    }
  }

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  const checkUser = async () => {
    if (env === 'app') {
      bridge.getUserInfoFromApp().then((res) => {
        if (res) {
          const { identity, realName } = res
          setParams({ ...params, idNumber: identity, name: realName })
          setUserInfo(res || {})
          Util.setLocalStorage(storage.userInfo, JSON.stringify(res || {}))
        } else {
          bridge.loginFromApp().then((userInfo) => {
            if (!userInfo) {
              showToast('请先登录')
            }
          })
        }
      })
    } else {
      try {
        const token = Util.getParams('token')
        Util.setLocalStorage(
          storage.userInfo,
          JSON.stringify({ accountVo: { token: token || '' } })
        )
        if (token) {
          await fetchUser()
        } else {
          wx.miniProgram.navigateTo({
            url: '/pages/login/index'
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  /**
   * @description 获取用户信息（小程序及H5使用）
   */
  const fetchUser = async () => {
    try {
	    const res = await serv.getUserInfo()
      if (res) {
        const { data } = res
        const { identity, realName } = data || {}
        setParams({ ...params, idNumber: identity, name: realName })
        setUserInfo(data || {})
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 输入回调
   * @param value
   * @param key
   */
  const handleInput = (value, key) => {
    params[key] = value.trim()
    setParams(params)
  }

  /**
   * @description 输入验证码
   * @param value
   */
  const handleInputSendCode = (value) => {
    setCode(value.trim())
  }

  /**
   * @description 验证实名及银行卡信息
   */
  const handleSubmit = async () => {
    const { idNumber, name, bankNum, mobile, bankName } = params
    if (!idNumber) {
      showToast('请填写身份证信息')
      return
    }
    if (!name) {
      showToast('请填写真实姓名')
      return
    }
    if (!bankName) {
      showToast('请填写开户银行')
      return
    }
    if (!bankNum) {
      showToast('请填写银行卡号')
      return
    }
    if (!mobile) {
      showToast('请填写手机号')
      return
    }
    params.certificateType = 1
    showLoading('提交中...')
    try {
      const res = await serv.syncEmployeeInfo(params)
      if (res) {
        const { data } = res
        if (data) {
          setStep(2)
	        setContractId(data)
        } else {
          showToast('提交失败')
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
	    hideLoading()
    }
  }

  /**
   * @description 提交验证码
   */
  const handleValidCode = async () => {
    if (!select) {
      showToast('请查看并勾选服务协议')
      return
    }
    if (!code) {
      showToast('请填写验证码')
      return
    }
		if (!contractId) {
			showToast('签约ID错误')
			return
		}
    showLoading('提交中...')
	  const params = {
		  contractId,
		  authCode: code
	  }
    try {
      const res = await serv.signContract(params)
      if (res) {
        const { data } = res
        if (data) {
          showToast('提交成功，已开始审核')
          setTimeout(() => {
            if (env === 'app') {
              bridge.appClose().then(_ => {
                console.log('关闭webview')
              })
            } else {
              wx.miniProgram.navigateBack()
            }
          }, 1000)
        } else {
          showToast('提交失败')
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
	    hideLoading()
    }
  }

  /**
   * @description 查看协议详情
   */
  const toProtocol = () => {
    const { push } = props.history
    push('/protocol/gm')
  }

  /**
   * @description 编辑实名信息
   */
  const editRealInfo = () => {
    if (env === 'app') {
      bridge.appNavigate({ id: 108, params: `source=gongmao` })
    } else {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/authentication-photo/index?source=gongmao&type=edit'
      })
    }
  }

  /**
   * @description 重新填写
   */
  const reSubmit = () => {
    setStatus(1)
  }

  return (
    <Observer>
      {() => (
        <div className="sign-wrapper">
          {/*未签约*/}
          {status === 1 || status === 4 ? <>
            {step === 1 ? <>
              <Blank height={16}/>
              <div className="form-wrapper">
                <Input label="证件类型" disabled initialValue="中华人民共和国居民身份证"/>
                <Input label="证件号码" initialValue={params.idNumber || ''}
                       onChange={(value) => handleInput(value, 'idNumber')} placeholder="请填写身份证号"/>
                <Input label="姓名" initialValue={params.name || ''} onChange={(value) => handleInput(value, 'name')}
                       placeholder="请填写真实姓名" exNode={
                  <div className="link-btn" onClick={editRealInfo}>修改实名资料</div>
                }/>
              </div>
              <div className="form-wrapper">
                <div className="top-tip">以上是您的实名资料，请准确填写您本人的账户信息</div>
                <Input label="开户银行" initialValue={params.bankName} onChange={(value) => handleInput(value, 'bankName')}
                       placeholder="请填写开户银行"/>
                <Input label="银行卡号" initialValue={params.bankNum} onChange={(value) => handleInput(value, 'bankNum')}
                       placeholder="请填写提现银行卡号"/>
                <Input label="手机号" initialValue={params.mobile} onChange={(value) => handleInput(value, 'mobile')}
                       placeholder="请填写此银行卡绑定手机号"/>
              </div>
              <button className="bottom-btn" onClick={handleSubmit}>提交</button>
            </> : <>
              <div className="top-tip">请填写验证码，查看并同意服务协议</div>
              <div className="valid-phone-wrapper">
                <InputValid initialValue={`验证码已发送至 ${params.mobile}`} disabled/>
                <InputValid onChange={handleInputSendCode} placeholder="输入短信验证码"/>
              </div>
              <div className="gm-sign-agreement-wrapper">
                <span className={`icon ${select ? 'selected' : ''}`} onClick={() => setSelect(!select)}/>
                本人自愿同意签署 <a className="link" onClick={toProtocol}>《服务协议》</a>
              </div>
              <button className="bottom-btn no-fix" onClick={handleValidCode}>提交</button>
            </>}
          </> : null}
          {/*审核中*/}
          {status === 2 ? <Auditing/> : null}
          {/*驳回的情况*/}
          {/*{status === 3 ? <Reject reSubmit={reSubmit}/> : null}*/}
        </div>
      )}
    </Observer>
  )
}


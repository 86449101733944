import React, { Component } from 'react'
import './index.less'

class Agreement extends Component {
  componentDidMount() {
    document.title = '微信支付分委托扣款授权书'
  }

  render() {
    return (
      <div className="agreement-wrapper">
        <div className="desc">
          本授权书由您向杭州好奇谷信息科技有限公司（下称“好奇谷”）出具，具有授权之法律效力。请您务必审慎阅读、充分理解本授权书各条款内容，特别是免除或者限制责任的条款，前述条款可能以加粗字体显示，您应重点阅读。除非您已阅读并接受本授权书所有条款，否则您无权使用微信支付的自动续费、自动缴费、自动扣款等服务。您同意本授权书即视为您已授权好奇谷代理您向财付通支付科技有限公司（下称“财付通”）申请开通微信支付自动续费和免密支付功能，并自愿承担由此导致的一切法律后果。
        </div>
        <div className="desc bold">
          您确认并不可撤销地授权好奇谷向财付通发出扣款指令，财付通即可在不验证您的支付密码、短信动态码等信息的情况下直接从您的银行账户或微信支付账户中扣划好奇谷指定的款项至好奇谷指定账户。
        </div>
        <div className="desc  bold">
          在任何情况下，只要好奇谷向财付通发出支付指令，财付通就可按照该指令进行资金扣划，财付通对好奇谷的支付指令的正确性、合法性、完整性、真实性不承担任何法律责任，相关法律责任由您和好奇谷自行承担。
        </div>
        <div className="desc">
          您在扣款账户内必须预留有足够的资金余额，否则因账户余额不足导致无法及时扣款或扣款错误、失败的，一切责任由您自行承担。因不可归责于财付通的事由，导致的不能及时划付款项、划账错误等责任与财付通无关。
        </div>
        <div className="desc">
          您确认，因好奇谷的原因导致您遭受经济损失的，由您与好奇谷协商解决，与财付通无关。
        </div>
      </div>
    )
  }
}

export default Agreement

import React, { useEffect, useState } from 'react'
import './index.less'
import ActionSheet from '../../components/ActionSheet'
import { Util } from '../../lib/util'
import wx from 'weixin-js-sdk'
import { bridge } from '../../lib/bridge'
import { storage } from '../../lib/conf'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'
import serv from '../../lib/api'
import SwipeableViews from 'react-swipeable-views'
import html2canvas from 'html2canvas'

export default function ReadInvite(props) {
	const store = useLocalStore(() => CommonStore)
	const {
		userInfo,
		env: runtimeEnv,
		showToast,
		updateUserInfo,
		showLoading,
		hideLoading,
		showPageLoading,
		hidePageLoading,
		showModal,
		closeModal
	} = store

	const [detail, setDetail] = useState(null)
	const [ruleVisible, setRuleVisible] = useState(false)
	const [shareVisible, setShareVisible] = useState(false)
	const [qrCode, setQrCode] = useState('')
	const [currentImg, setCurrentImg] = useState(0)
	const [showAni, setShowAni] = useState(false)

	const initData = () => {
		Util.checkAuth().then(async (hasLogin) => {
			if (hasLogin) {
				initShareInfo()
				showPageLoading()
				initLevelTipModal()
				await getDetail()
				await getQrcode()
				hidePageLoading()
			} else {
				if (runtimeEnv === 'app') {
					loginInApp()
				} else if (runtimeEnv === 'mp') {
					wx.miniProgram.navigateTo({
						url: '/pages/login/index'
					})
				} else {
					showToast('请先登录')
				}
			}
		})
	}

	useEffect(() => {
		initData()
		setTimeout(() => {
			setShowAni(true)
		}, 2000)
	}, [])

	const initLevelTipModal = () => {
		const { level } = userInfo
		if (level > 0) {
			showModal({
				content:
					'「共读有礼」为会员活动，领读者邀请会员订阅双方均不享受活动奖励。',
				okBtnText: '知道了',
				showCancelBtn: false,
				gravity: 1,
				onConfirm: () => {
					closeModal()
					Util.setLocalStorage('levelTipModalCount', 1)
				}
			})
		}
	}

	/**
	 * @description 初始化分享信息
	 */
	const initShareInfo = () => {
		const { id: userId } = userInfo || {}
		// 本页面需要转发
		bridge.jsShowShareWechat(true)
		bridge.jsShareWechat({
			title: '邀请你加入「好读家庭」儿童订阅计划',
			thumb: `https://cdn.kangarooread.com/mp-assets/invite/share_card_img_level_1.png`,
			path: `subPkg/main/pages/new-subscribe-detail/index?shareId=${userId}`
		})
		wx.miniProgram.postMessage({
			data: {
				title: '邀请你加入「好读家庭」儿童订阅计划',
				thumb: `https://cdn.kangarooread.com/mp-assets/invite/share_card_img_level_1.png`,
				path: `/subPkg/main/pages/new-subscribe-detail/index?shareId=${userId}`
			}
		})
	}

	/**
	 * @description APP 登录事件通知
	 */
	const loginInApp = () => {
		bridge.loginFromApp().then((res) => {
			if (res) {
				updateUserInfo(res)
			} else {
				Util.removeLocalStorage(storage.userInfo)
			}
		})
	}

	const getDetail = async () => {
		try {
			const res = await serv.inviteActivityDetail()
			if (res) {
				const { data } = res
				const { rules } = data || {}
				const tempRules = rules && rules.filter((_, idx) => idx < 2)
				const detail = {
					...data,
					tempRules: tempRules || []
				}
				setDetail(detail)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getQrcode = async () => {
		const { id: userId } = userInfo
		const path = 'subPkg/main/pages/new-subscribe-detail/index'
		const scene = `m;;;${userId || ''};1;100`
		const type = 23
		const params = {
			path,
			scene,
			type
		}
		try {
			const res = await serv.getQrCode(params)
			if (res) {
				const { cdnUrl } = res.data || {}
				setQrCode(cdnUrl)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const showRuleActionSheet = () => {
		setRuleVisible(true)
	}

	const closeRuleActionSheet = () => {
		setRuleVisible(false)
	}

	const showShareVisible = () => {
		const { serviceId, cardStatus, level } = userInfo
		if ((!serviceId || cardStatus === 6) && level === 0) {
			if (runtimeEnv === 'app') {
				bridge.appNavigate({ id: 1 })
			} else if (runtimeEnv === 'mp') {
				wx.miniProgram.navigateTo({
					url: '/subPkg/main/pages/new-subscribe-detail/index'
				})
			}
		} else {
			if (runtimeEnv === 'mp') {
				showModal({
					content: '请点击右上角"..."进行转发',
					okBtnText: '知道了',
					showCancelBtn: false,
					gravity: 1,
					onConfirm: () => {
						closeModal()
					}
				})
			} else {
				setShareVisible(true)
			}
		}
	}

	const closeShareVisible = () => {
		setShareVisible(false)
	}

	const changeList = (currentImg) => {
		setCurrentImg(currentImg)
	}

	const toRecord = () => {
		props.history.push('/read-invite-record-detail')
	}

	const shareWechat = () => {
		const { id: userId } = userInfo
		bridge.appShareWechat({
			title: '邀请你加入「好读家庭」儿童订阅计划',
			thumb: `https://cdn.kangarooread.com/mp-assets/invite/share_card_img_level_1.png`,
			path: `subPkg/main/pages/new-subscribe-detail/index?shareId=${userId}`
		})
	}

	const shareImg = () => {
		const node = document.getElementById(`poster-wrapper-${currentImg}`)
		closeShareVisible()
		showLoading('加载中...')
		html2canvas(node, {
			allowTaint: true,
			useCORS: true,
			scrollY: 0
		})
			.then((canvas) => {
				if (canvas) {
					const src = canvas.toDataURL('image/jpg')
					if (runtimeEnv === 'app') {
						bridge.appShareBase64(src)
					}
				}
				hideLoading()
			})
			.catch((err) => {
				console.log(err)
				hideLoading()
			})
	}

	const { btnLabel, rules, shareImgList } = detail || {}

	const { accountVo, level } = userInfo || {}
	const { nickname: n, avatar: a } = accountVo || {}

	return (
		<>
			<div className='read-invite-wrapper bg-normal'>
				<img
					src='https://cdn.kangarooread.com/mp-assets/invite/invite_record_btn_v3.png'
					className='invite-record-btn'
					alt=''
					onClick={toRecord}
				/>

				<div className='head-img-wrapper'>
					<img
						src='https://cdn.kangarooread.com/mp-assets/invite/head_img_v2.png'
						className='head-img'
						alt=''
					/>
				</div>
				<div
					className={`invite-btn ${showAni && 'show-ani'}`}
					style={{ marginTop: 28, marginBottom: 28 }}
					onClick={showShareVisible}>
					<img
						src='https://cdn.kangarooread.com/mp-assets/invite/logo.png'
						className='logo'
						alt=''
					/>
					<span className='text'>{btnLabel || ''}</span>
				</div>

				{level && level > 0 ? (
					<div className='level-info'>
						亲爱的领读者：共读有礼活动仅为会员转介绍场景的福利；领读者直接邀请的会员福利，根据每月活动政策制定。
					</div>
				) : null}

				<div className='rule-card-wrapper'>
					<div className='label'>· 活动规则 ·</div>
					<div className='rule-list'>
						{rules &&
							rules.map((it, idx) => (
								<div className='rule-item' key={idx}>
									{it}
								</div>
							))}
					</div>
				</div>
			</div>

			<ActionSheet
				visible={ruleVisible}
				showCloseIcon={false}
				onClose={closeRuleActionSheet}>
				<div className='rule-title'>活动规则</div>
				<div className='rule-list'>
					{rules &&
						rules.map((it, idx) => (
							<div className='rule-item' key={idx}>
								{it}
							</div>
						))}
				</div>
				<div
					className='close-btn'
					style={{ marginTop: 20 }}
					onClick={closeRuleActionSheet}>
					关闭
				</div>
			</ActionSheet>

			<ActionSheet
				visible={shareVisible}
				bg='#F6F6F6'
				showCloseIcon={false}
				onClose={closeShareVisible}>
				<div className='op-wrapper'>
					<div className='item' onClick={shareWechat}>
						<img
							src='https://cdn.kangarooread.com/mp-assets/common/wechat_icon.png'
							alt=''
							className='icon'
						/>
						<div className='label'>微信</div>
					</div>
					<div className='item' onClick={shareImg}>
						<img
							src='https://cdn.kangarooread.com/mp-assets/common/pic_icon.png'
							alt=''
							className='icon'
						/>
						<div className='label'>保存图片</div>
					</div>
				</div>
				<div className='share-close-btn' onClick={closeShareVisible}>
					取消
				</div>
			</ActionSheet>

			{shareVisible && (
				<div className='img-list-wrapper'>
					<div className='dots'>
						{shareImgList.map((_, idx) => (
							<span
								className={`dot ${idx === currentImg && 'active'}`}
								key={idx}
							/>
						))}
					</div>
					<SwipeableViews
						className='img-swiper'
						slideClassName='slide-item'
						index={currentImg}
						onChangeIndex={changeList}>
						{shareImgList.map((it, idx) => (
							<div
								className={`share-img-item ${
									window.devicePixelRatio % 2 > 0 && 'less-width'
								}`}
								key={idx}>
								<img src={it} className='img' alt='' />
								<div className='info-wrapper'>
									<img src={a} className='avatar' alt='' />
									<div className='text-wrapper'>
										<div className='name-wrapper'>
											<span className='name'>{n}</span>
											<span className='text-1'>邀请你</span>
										</div>
										<div className='text-2'>一起亲子阅读，陪伴点亮成长</div>
									</div>
									<img src={qrCode} className='qr-code' alt='' />
								</div>
							</div>
						))}
					</SwipeableViews>
				</div>
			)}

			{shareImgList && (
				<div className='img-list-wrapper' style={{ left: -1000, top: -99999 }}>
					<SwipeableViews
						className='img-swiper'
						slideClassName='slide-item'
						index={currentImg}
						onChangeIndex={changeList}>
						{shareImgList.map((it, idx) => (
							<div
								className={`share-img-item ${
									window.devicePixelRatio % 2 > 0 && 'less-width'
								}`}
								id={`poster-wrapper-${idx}`}
								key={idx}>
								<img
									src={`${it}?timeStamp=${new Date().getTime()}`}
									className='img'
									alt=''
									crossOrigin='anonymous'
								/>
								<div className='info-wrapper'>
									<img
										src={`${a}?timeStamp=${new Date().getTime()}`}
										className='avatar'
										alt=''
										crossOrigin='anonymous'
									/>
									<div className='text-wrapper'>
										<div className='name-wrapper'>
											<span className='name'>{n}</span>
											<span className='text-1'>邀请你</span>
										</div>
										<div className='text-2'>一起亲子阅读，陪伴点亮成长</div>
									</div>
									<img
										src={`${qrCode}?timeStamp=${new Date().getTime()}`}
										className='qr-code'
										alt=''
										crossOrigin='anonymous'
									/>
								</div>
							</div>
						))}
					</SwipeableViews>
				</div>
			)}
		</>
	)
}

import React, { useEffect, useState } from 'react'
import './index.less'
import { bridge } from '../../lib/bridge'
import gcoord from 'gcoord'
import { deBounce } from '../../lib/util'

export default function MapSearch() {
  const [map, setMap] = useState(null)
  const [list, setList] = useState([])
  const [location, setLocation] = useState({})

  useEffect(() => {
    initScript().then(async _ => {
      const locationInfo = await bridge.appGetLocationInfo()
      console.log(`appGetLocation => ${JSON.stringify(locationInfo)}`)
      locationInfo && setLocation(locationInfo)
      const { longitude, latitude } = locationInfo || {}
      const [lng, lat] = gcoord.transform(
        [longitude || 120.209947, latitude || 30.245853],    // 经纬度坐标
        gcoord.GCJ02,
        gcoord.WGS84
      )
      initMap({ longitude: lng, latitude: lat })
    })

    return () => {
      setMap(null)
    }
  }, [])

  const initScript = () => {
    return new Promise(resolve => {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://api.tianditu.gov.cn/api?v=4.0&tk=71ef6f10d6b4554ab17641ac20b67d98'
      document.getElementsByTagName('head')[0].appendChild(script)
      setTimeout(() => {
        resolve()
      }, 1000)
    })
  }

  const initMap = ({ longitude: lng, latitude: lat }) => {
    const { T } = window
    const map = T && new T.Map('tdmap')
    setMap(map)
    map && map.centerAndZoom(new T.LngLat(lng, lat), 12)
    const geocode = T && new T.Geocoder()
    geocode && geocode.getLocation(new T.LngLat(lng, lat), (result) => getLocationInfo(result, map))
  }

  const getLocationInfo = (result, map) => {
    const { addressComponent, location } = result || {}
    const { poi } = addressComponent || {}
    const { lon, lat } = location || {}
    const config = {
      pageCapacity: 10,	//每页显示的数量
      onSearchComplete: localSearchResult	//接收数据的回调函数
    }
    //创建搜索对象
    const localSearch = new T.LocalSearch(map, config)
    localSearch.searchNearby(poi, new T.LngLat(lon, lat), 5000)
  }

  const localSearchResult = (result) => {
    const { pois } = result || {}
    setList(pois || [])
  }

  const handleInput = e => {
    delayInput(e.target.value)
  }

  const delayInput = deBounce(v => handleSearch(v), 500)

  const handleSearch = (value) => {
    const config = {
      pageCapacity: 10,	//每页显示的数量
      onSearchComplete: localSearchResult	//接收数据的回调函数
    }
    //创建搜索对象
    const localSearch = new T.LocalSearch(map, config)
    value && localSearch.search(value)
  }

  const handleReport = (item) => {
    const { lonlat, address, name } = item || {}
    if (lonlat) {
      const [lng, lat] = lonlat.split(' ')
      const reportInfo = {
        ...location,
        longitude: lng || '',
        latitude: lat || '',
        organizationTitle: name || '',
        address: address || ''
      }
      console.log(`reportInfo => ${JSON.stringify(reportInfo)}`)
      bridge.appSetLocation(reportInfo)
      .then((res) => {
        if (res) {
          bridge.appClose()
        }
      })
    }
  }

  return <div className="td-map-wrapper">
    <div id="tdmap" className="td-map"/>
    <div className="search-input">
      <input type="text" className="input" placeholder="输入搜索地点" onInput={handleInput}/>
    </div>
    <div className="poi-list">
      {list.map((item, index) => (
        <div className="poi-item" key={index} onClick={() => handleReport(item)}>
          <div className="label">{item.name}</div>
          <div className="address">{item.address}</div>
        </div>))}
    </div>
  </div>
}

import React from 'react'
import './page_1.less'

const Page_1 = (props) => {
	const { condition, registerDay, subStartMonth, subEndMonth, year, onSelect } =
		props || {}

	const selectStep = (type) => {
		onSelect && onSelect(type)
	}

	return (
		<>
			<picture className='yr-page-1-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_1_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_1_bg.png'
					className='yr-page-1-bg-img'
					alt=''
				/>
			</picture>

			<div className='yr-page-1-text-wrapper'>
				{condition === 1 && (
					<>
						<span className='data-text'>{year || ''}</span>
						<br />
						你在&nbsp;<span className='data-text'>{subStartMonth || ''}</span>
						&nbsp;月至&nbsp;
						<span className='data-text'>{subEndMonth || ''}</span>&nbsp;月
						<br />
						为孩子准备了丰富的精神食粮，
						<br />
						为什么没有继续呢？
						<br />
						绘本是最适合婴幼儿的读物，
						<br />
						蕴含&nbsp;
						<span className='data-text'>文学、艺术、哲学、科学</span>
						&nbsp;等知识，
						<br />
						阅读是可以陪伴孩子一辈子最长情的良师益友，
						<br />
						不要犹豫，
						<br />
						孩子值得持续拥有这份最珍贵的礼物！
						<picture className='yr-page-1-btn-1' onClick={() => selectStep(1)}>
							<source
								srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_1_btn_1.webp'
								type='image/webp'
							/>
							<img
								src='https://cdn.kangarooread.com/resource/year_report/yr_page_1_btn_1.png'
								className='yr-page-1-btn-1-img'
								alt=''
							/>
						</picture>
						<picture className='yr-page-1-btn-2' onClick={() => selectStep(2)}>
							<source
								srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_1_btn_2.webp'
								type='image/webp'
							/>
							<img
								src='https://cdn.kangarooread.com/resource/year_report/yr_page_1_btn_2.png'
								className='yr-page-1-btn-2-img'
								alt=''
							/>
						</picture>
					</>
				)}
				{condition === 3 && (
					<>
						哎呀呀，
						<br />
						{year || '2022'}，你和孩子在好奇说
						<br />
						没有留下任何阅读印记哦......
						<br />
						<br />
						阅读是可以陪伴孩子
						<br />
						一辈子最长情的良师益友，
						<br />
						让我们把这份最珍贵的礼物送给孩子！
						<picture
							className='yr-page-1-course-btn'
							onClick={() => selectStep(3)}>
							<source
								srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_1_course_btn.webp'
								type='image/webp'
							/>
							<img
								src='https://cdn.kangarooread.com/resource/year_report/yr_page_1_course_btn.png'
								className='yr-page-1-course-btn-img'
								alt=''
							/>
						</picture>
					</>
				)}
				{condition === 4 && (
					<>
						你来好奇说已经&nbsp;
						<span className='data-text'>{registerDay || ''}</span>
						&nbsp;天了，
						<br />
						是否因为各种原因，
						<br />
						迟迟没有开启亲子阅读呢？
						<br />
						绘本是最适合婴幼儿的读物，
						<br />
						蕴含&nbsp;
						<span className='data-text'>文学、艺术、哲学、科学</span>
						&nbsp;等知识，
						<br />
						阅读是可以陪伴孩子一辈子
						<br />
						最长情的良师益友，
						<br />
						让我们一起行动起来，
						<br />
						把这份最珍贵的礼物送给孩子！
						<picture
							className='yr-page-1-course-btn'
							onClick={() => selectStep(3)}>
							<source
								srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_1_course_btn.webp'
								type='image/webp'
							/>
							<img
								src='https://cdn.kangarooread.com/resource/year_report/yr_page_1_course_btn.png'
								className='yr-page-1-course-btn-img'
								alt=''
							/>
						</picture>
					</>
				)}
			</div>

			{condition === 3 ? (
				<picture className='yr-gift-box-b'>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_gift_box_b.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_gift_box_b.png'
						className='yr-gift-box-b-img'
						alt=''
					/>
				</picture>
			) : (
				<picture className='yr-gift-box-s'>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_gift_box_s.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_gift_box_s.png'
						className='yr-gift-box-s-img'
						alt=''
					/>
				</picture>
			)}
		</>
	)
}

export default Page_1

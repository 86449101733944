import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable, toJS } from 'mobx'
import { env, storage, isProd } from '../../../lib/conf'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import Lottery from '../../../components/Lottery'
import Title from './components/Title'
import Card from './components/Card'
import CardNormal from './components/CardNormal'
import PrizeModal from './components/PrizeModal'
import Blank from '../../../components/Blank'
import { Util } from '../../../lib/util'
import serv from '../../../lib/api'
import moment from 'moment'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import './index.less'
import NoData from '../../../components/NoData'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const { IMG } = env

@inject('store')
@observer
class EventLottery extends Component {
  @observable loaded = false
  @observable env = ''
  @observable userInfo = {}
  @observable currentCampId = ''
  @observable detail = {}
  @observable prizeList = []
  @observable recordModal = {
    visible: false,
    list: []
  }
  @observable addressList = []
  @observable addressVisible = false
  @observable currentAddressId = ''
  @observable currentOrderId = ''
  @observable rollList = []

  ruleRef = React.createRef()
  prizeRef = React.createRef()

  componentDidMount() {
    const { showPageLoading, hidePageLoading } = this.props.store
    showPageLoading()
    this.getEnv()
    this.checkUser().then(_ => {
      // this.initShareInfo()
      Promise.race([
        this.getDetail(),
        this.getLotteryRollList(),
        this.getUserAddressList()
      ])
      .then(_ => {
        setTimeout(() => {
          this.loaded = true
          hidePageLoading()
        }, 2000)
      })
    })
    .catch(err => {
      console.log(err)
      this.loaded = true
      hidePageLoading()
    })
  }

  /**
   * @description 获取获奖人员名单
   */
  getLotteryRollList = async () => {
    try {
      const res = await serv.getLotteryRollList()
      if (res) {
        const { data } = res
        this.rollList = [...(data || [])]
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取用户地址列表，用户收货
   * @returns {Promise<void>}
   */
  getUserAddressList = async () => {
    try {
      const res = await serv.getUserAddressList()
      if (res) {
        const { data } = res
        this.addressList = [...(data || [])]
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取抽奖详情
   * @returns {Promise<void>}
   */
  getDetail = async () => {
    try {
      const res = await serv.getLotteryInfo()
      if (res) {
        const { data } = res
        data && data.lotteryActiveTypeVos && data.lotteryActiveTypeVos.forEach((it, idx) => {
          if ((idx + 1) % 2 === 0) {
            it.color = '#FFE9E5'
          } else {
            it.color = '#ffffff'
          }
        })
        this.detail = { ...(data || {}) }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取当前环境
   */
  @action
  getEnv = () => {
    const { env } = this.props.store
    this.env = env
  }

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  checkUser = () => {
    return new Promise((resolve, reject) => {
      const { showToast } = this.props.store
      if (this.env === 'app') {
        bridge.getUserInfoFromApp().then((res) => {
          if (res) {
            this.userInfo = { ...res }
            resolve()
          } else {
            bridge.loginFromApp().then((userInfo) => {
              if (userInfo) {
                this.userInfo = { ...userInfo }
                resolve()
              } else {
                showToast('请先登录')
                reject()
              }
            })
          }
        })
      } else {
        try {
          const token = Util.getParams('token')
          Util.setLocalStorage(
            storage.userInfo,
            JSON.stringify({ accountVo: { token: token || '' } })
          )
          if (token) {
            this.fetchUser().then(_ => {
              resolve()
            })
          } else {
            wx.miniProgram.navigateTo({
              url: '/pages/login/index',
              success: () => {
                reject()
              }
            })
          }
        } catch (err) {
          console.log(err)
          reject()
        }
      }
    })
  }

  /**
   * @description 获取用户信息（小程序及H5使用）
   */
  fetchUser = async () => {
    try {
      const res = await serv.getUserInfo()
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 跳转到规则
   */
  handleScrollToRule = () => {
    window.scrollTo({
      top: this.ruleRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  /**
   * @description 跳转到奖池
   */
  handleScrollToPrize = () => {
    window.scrollTo({
      top: this.prizeRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  /**
   * @description 初始化分享信息
   */
  initShareInfo = () => {
    const { id: userId } = this.userInfo
    // 本页面需要转发
    bridge.jsShowShareWechat(true)
    bridge.jsShareWechat({
      title: '好奇说2周年岁啦！好礼抽不停，幸运转盘GoGoGo！',
      thumb: `${IMG}event-lottery/202106_lottery_share_img.png`,
      path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-lottery&shareType=open&shareId=${userId}`
    })
    wx.miniProgram.postMessage({
      data: {
        title: '好奇说2周年岁啦！好礼抽不停，幸运转盘GoGoGo！',
        imageUrl: `${IMG}event-lottery/202106_lottery_share_img.png`,
        path: `/subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-lottery&shareType=open&shareId=${userId}`
      }
    })
  }

  /**
   * @description 打开获奖记录
   */
  showRecordModal = async () => {
    try {
      const res = await serv.getLotteryOrderList()
      if (res) {
        const { data } = res
        this.recordModal.list = [...(data || [])]
        this.recordModal.visible = true
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 抽奖获得奖品
   * @param orderId
   */
  @action
  handleFinishRotate = (orderId) => {
    // this.getDetail()
    this.detail.leftCount -= 1
    if (orderId) {
      this.toShowAddressAction(orderId)
    }
  }

  /**
   * @description 跳转任务
   * @param item
   */
  toMission = (item) => {
    const { redirectType, url: redirectUrl, btnShow, type, count } = item
    if (!redirectType && !btnShow) return
    if (redirectType) {
      let url
      switch (redirectType) {
	      case 1:
		      url = '/subPkg/main/pages/new-subscribe-detail/index'
		      break
        case 27:
          url = '/subPkg/extend/pages/keep-detail/index'
          break
        case 107:
          url = `/subPkg/main/pages/price-get-detail/index`
          break
	      case 146:
					url = '/subPkg/main/pages/buy-gdd/index'
		      break
        default:
          break
      }
      if (!url) return

      if (this.env === 'app') {
        bridge.appNavigate({ id: redirectType, params: redirectUrl })
      } else {
        url = `${url}?${redirectUrl}`
        wx.miniProgram.navigateTo({
          url,
          success: () => {
            wx.miniProgram.switchTab({
              url
            })
          }
        })
      }
    } else {
      type && this.fetchCount(type, count)
    }
  }

  fetchCount = async (type, count) => {
    const { showToast } = this.props.store
    try {
      const res = await serv.fetchLotteryCount(type)
      if (res) {
        const { data } = res
        if (data) {
          showToast(`抽奖次数+${count}次`)
          this.getDetail()
        } else {
          showToast('领取失败')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  showDownloadTip = () => {
    if (this.env === 'app') return
    const { showModal, closeModal, showToast } = this.props.store
    const options = {
      title: '温馨提示',
      content: '请下载APP后登录领取抽奖次数',
      okBtnText: '复制下载链接',
      showCancelBtn: false,
      gravity: 1,
      onConfirm: async () => {
        const input = document.createElement('input')
        input.setAttribute('readonly', 'readonly')
        input.setAttribute('value', 'https://web.kangarooread.com/#/app-download')
        document.body.appendChild(input)
        input.setSelectionRange(0, 9999)
        if (document.execCommand) {
          document.execCommand('copy')
          showToast('已复制下载链接至剪贴板')
        }
        document.body.removeChild(input)
        closeModal()
      },
      onClose: () => {
        closeModal()
      }
    }
    showModal(options)
  }

  /**
   * @description 是否确认用积分换次数
   */
  showExchangePointModal = () => {
    const { showModal, closeModal } = this.props.store
    const options = {
      title: '确认使用20积分兑换？',
      content: '',
      okBtnText: '确定',
      cancelBtnText: '取消',
      showCancelBtn: true,
      gravity: 2,
      onConfirm: async () => {
        await this.exchangePoint()
        closeModal()
      },
      onClose: () => {
        closeModal()
      }
    }
    showModal(options)
  }

  /**
   * @description 积分兑换抽奖次数
   * @returns {Promise<void>}
   */
  exchangePoint = async () => {
    const { showToast } = this.props.store
    try {
      const res = await serv.exchangeLotteryActiveCount()
      if (res) {
        const { data } = res
        if (data) {
          showToast('兑换成功')
          await this.getDetail()
        } else {
          showToast('兑换失败')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 单选地址
   * @param id
   */
  selectAddress = (id) => {
    this.currentAddressId = id
  }

  /**
   * @description 提交收货地址
   * @returns {Promise<void>}
   */
  commitAddress = async () => {
    const { showToast } = this.props.store
    if (!this.currentAddressId) {
      showToast('请选择收货地址')
      return
    }
    if (!this.currentOrderId) {
      showToast('请先选择订单')
      return
    }
    const params = {
      orderId: this.currentOrderId,
      addressId: this.currentAddressId
    }
    try {
      const res = await serv.commitUserAddress(params)
      if (res) {
        const { data } = res
        if (data) {
          showToast('操作成功')
        } else {
          showToast('操作失败')
        }
      } else {
        showToast('操作异常')
      }
    } catch (err) {
      console.log(err)
      showToast('操作异常')
    }
    this.addressVisible = false
    this.currentOrderId = ''
  }

  /**
   * @description 跳转订单详情
   * @param orderId
   */
  toOrderDetail = (orderId) => {
    if (this.env === 'app') {
      bridge.appNavigate({ id: 104, params: `type=14&id=${orderId}` })
    } else {
      wx.miniProgram.navigateTo({
        url: `/subPkg/main/pages/order-confirm/index?id=${orderId}&type=14`
      })
    }
  }

  /**
   * @description 打开填写地址弹框
   * @param orderId
   */
  @action
  toShowAddressAction = (orderId) => {
    this.addressVisible = true
    this.currentOrderId = orderId
    this.recordModal.visible = false
  }

  render() {
    const {
      lotteryActiveTypeVos,
      lotteryActiveVos,
      missionList,
      leftCount
    } = this.detail

    const {
      visible: recordVisible,
      list: recordList
    } = this.recordModal

    return (
      <div className="event-lottery">
        {this.rollList.length > 0
          ? <AutoPlaySwipeableViews
            className="swiper"
            interval={2000}
          >
            {this.rollList.map(it => (
              <div className="swiper-item" key={it.id}>
                <div className="roll-item">
                  <img src={it.avator} className="avatar" alt=""/>
                  <div className="text">{it.userName && it.userName.replace(
                    /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, '')} 抽到了 {it.productName || ''}</div>
                </div>
              </div>
            ))}
          </AutoPlaySwipeableViews>
          : null}
        <img src={`${IMG}event-lottery/202311_lottery_top_title.png`} className="lottery-title" alt=""/>
        {this.loaded
          ? <div className="lottery-wrapper">
            <Lottery
              awards={toJS(lotteryActiveTypeVos || [])}
              prizeList={toJS(lotteryActiveVos || [])}
              finish={this.handleFinishRotate}
              userInfo={this.userInfo}
              leftCount={leftCount || 0}
            />
          </div>
          : null
        }
        <img src={`${IMG}event-lottery/202206_lottery_bg_1.png`} className="lottery-bg-1" alt=""/>
        <img src={`${IMG}event-lottery/202206_lottery_bg_2.png`} className="lottery-bg-2" alt=""/>
        {this.loaded
          ? <div className="lottery-bottom">
            <div className="label">剩余抽奖次数</div>
            <div className="lottery-count">{leftCount || 0}</div>
            <div className="label">次</div>
            <div className="refresh-btn" onClick={() => this.getDetail()}/>
          </div>
          : null}
        <div className="rule-button-wrapper">
          <div className="rule-button" onClick={() => this.handleScrollToRule()}>活动规则</div>
          <div className="record-button" onClick={() => this.showRecordModal()}>中奖记录</div>
        </div>
        {/*<div className="prize-button" onClick={() => this.handleScrollToPrize()}>查<br/>看<br/>奖<br/>品<br/>池</div>*/}
        {/*领取抽奖次数*/}

        {/*活动过期后取消注释*/}
        {/*<div className="event-lottery-is-over"/>*/}

        {/* <CardNormal bg="#FFFAF3">
          <div className="card-content">
            {missionList && missionList.length > 0 ? <>
              <Title title="获得我的抽奖机会"/>
              {missionList.map((it, idx) => <div className="mission-item" key={idx}>
                <div className="m-title-info">
                  <div className="m-title">{it.title || ''}</div>
                  <div className="m-desc">{it.dec || ''}</div>
                  {/*<div className="progress-wrapper">*!/*/}
                  {/*        <div className="progress">*/}
                  {/*          <div className="current" style={{ width: `${(clockSevenProcess || 0) / 7 * 100}%` }}/>*/}
                  {/*        </div>*/}
                  {/*        <div className="text">{clockSevenProcess || 0}天/7天</div>*/}
                  {/*      </div>
                </div>
                <div className="m-btn-wrapper">
                  <div className={`m-btn ${(it.btnShow || it.redirectType) ? '' : 'disabled'}`}
                       onClick={() => this.toMission(it)}>
                    {it.redirectType ? <span>
                      {it.type === 15 ? '去报名' : it.type === 16 ? '去邀请' : '去完成'}
                    </span> : <span>
                      {it.btnShow ? `领取+${it.count || 1}次` : '已领取'}
                    </span>}
                  </div>
                  {it.fetchedCount > 0 && <div className="cut-count">已领{it.fetchedCount || 0}次</div>}
                </div>
                {idx < missionList.length - 1 && <div className="s-line"/>}
              </div>)}
              {/*登录APP
            </> : <>
              <Title title="温馨提示"/>
              <div className="no-auth-info">订阅会员才能参加活动哦！</div>
            </>}
          </div>
        </CardNormal> */}

        {/*奖品列表*/}
        <div ref={this.prizeRef}>
          <CardNormal bg="#FFFAF3">
            <div className="card-content">
              <Title title="惊喜奖品"/>
              <div className="prize-list">
                {lotteryActiveVos && lotteryActiveVos.map(it => (
                  <div className="prize-item" key={it.id}>
                    <img src={it.pic} className="prize-pic" alt=""/>
                    <div className="prize-name">{it.productName}</div>
                  </div>
                ))}
              </div>
            </div>
          </CardNormal>
        </div>

        {/*规则*/}
        <div ref={this.ruleRef}>
          <CardNormal bg="#FFFAF3">
            <div className="card-content">
              <Title title="活动规则"/>
              <div className="rule">
                  <div>1. 活动时间：2023年11月1日00:00-11月11日 23:59。</div>
                  <div>2. 抽奖时间：2023年11月13日10:00-11月15日 23:59。</div>
                  <div>3. 抽奖奖品发放结果可登录个人积分或订单中心查看。</div>
                  <div>4. 活动结束后，未使用和未领取的抽奖次数作废。</div>
                  <div>5. 2023年11月30日23:59 前未填写收件地址的中奖奖品作废。</div>
                  <div>6. 本次活动与苹果公司无关。</div>
                  <div>7. 最终解释权归好奇说绘本所有。</div>
              </div>
            </div>
          </CardNormal>
        </div>

        {/*记录modal*/}
        <PrizeModal visible={recordVisible} title="中奖记录" onClose={() => this.recordModal.visible = false}>
          {recordList && recordList.length > 0 ? <div className="record-list">
            {recordList.map(it => (
              <div className="record-item" key={it.id}>
                <div className="left">
                  <div className="record-title">{it.desc || ''}</div>
                  <div className="record-time">{moment(it.createTime).format('YYYY/MM/DD HH:mm')}</div>
                </div>
                <div className="right">
                  {it.status === 1 ?
                    <div className="address-btn" onClick={() => this.toShowAddressAction(it.id)}>填写地址</div> : null}
                  {it.status === 2 ?
                    <div className="border-btn" onClick={() => this.toOrderDetail(it.id)}>查看订单</div> : null}
                  {it.status === 3 ? <div className="desc">已发放</div> : null}
                </div>
                <div className="line"/>
              </div>
            ))}
          </div> : <div className="empty-wrapper">
            <div className="empty-text">没有中奖记录哦～</div>
            <div className="empty-icon"/>
          </div>}
        </PrizeModal>

        <div className={`action-modal ${this.addressVisible ? 'show' : ''}`}>
          <div className="action-modal-title">地址列表<span className="close-icon-btn"
                                                        onClick={() => this.addressVisible = false}>关闭</span></div>
          <div className="address-list">
            {this.addressList && this.addressList.map((it, idx) => {
              const { id, name, phone, province, city, county, address } = it
              return <div className="item" key={id} onClick={() => this.selectAddress(id)}>
                <div className={`radio ${this.currentAddressId === id ? 'selected' : ''}`}/>
                <div className="info">
                  {name || ''} / {phone || ''}
                  <br/>
                  {province || ''}{city || ''}{county || ''}{address || ''}
                </div>
              </div>
            })}
            {this.addressList.length === 0 ?
              <div style={{ padding: 30, color: '#666', textAlign: 'center' }}>请至地址簿添加收货地址</div> : ''}
          </div>
          <div className="bottom-wrapper">
            <div className="confirm-btn" onClick={() => this.commitAddress()}>确认</div>
          </div>
        </div>
      </div>
    )
  }
}

export default EventLottery

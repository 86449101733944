import accounting from 'accounting'
import md5 from 'js-md5'
import { createHashHistory } from 'history'
import dsBridge from 'dsbridge'
import wx from 'weixin-js-sdk'
import { storage } from './conf'

const HISTORY = createHashHistory()

const formatNumber = (n) => {
  const s = n.toString()
  return s[1] ? s : `0${s}`
}

/* eslint-disable */
function typeOf(obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

// deepCopy
function deepCopy(data) {
  const t = typeOf(data)
  let o

  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }

  if (t === 'array') {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]))
    }
  } else if (t === 'object') {
    for (let i in data) {
      o[i] = deepCopy(data[i])
    }
  }
  return o
}

/* eslint-enable */

// 防抖
function deBounce(fn, wait) {
  let timeOut = null
  return function (...args) {
    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      fn(...args)
    }, wait)
  }}


// 节流
function throttle(fn, interval = 200) {
  let last
  const time = interval
  let timer = null
  return function (...args) {
    // eslint-disable-line
    const th = this
    const now = +new Date()
    if (last && now - last < time) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(th, args)
      }, time)
    } else {
      last = now
      fn.apply(th, args)
    }
  }
}

const Util = {
  getQueryString(url, name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = url.slice(url.search(/\?/)).substr(1).match(reg)
    if (r != null) {
      return unescape(r[2])
    }
    return null
  },

  getDeviceId() {
    let agent = navigator.userAgent
    agent = agent.substr(2, 12)
    return `${agent}${(Math.random() * 10000).toFixed(0)}`
  },
  /**
   * md5加密
   */
  setMD5(str) {
    return md5(str)
  },

  /**
   * 格式化金额
   */
  formatMoney: (m, prev = '', precision = 2, symbol = '') =>
    accounting.formatMoney(m, prev, precision, ',', '.', { symbol }),

  /**
   * 分 => 元
   */
  fenToYuan(m, prev = '', symbol = '', precision = 2) {
    const _precision = (m || 0) % 100 === 0 ? 0 : precision
    const o = m ? this.numberCal.div(m, 100) : 0
    return o === 0
      ? `${prev}0${symbol}`
      : accounting.formatMoney(o, prev, _precision, '', '.', { symbol }) * 1
  },

  /**
   * 格式化日期
   */
  formatTime: (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return `${[year, month, day].map(formatNumber).join('-')} ${[
      hour,
      minute,
      second
    ]
    .map(formatNumber)
    .join(':')}`
  },

  /**
   * 浮点数计算； 规避浮点数的精度丢失；
   * eg: 32.80*100 // 3279.9999999999995;
   */
  numberCal: {
    add(a, b) {
      let c
      let d
      try {
        c = a.toString().split('.')[1].length
      } catch (f) {
        c = 0
      } // eslint-disable-line
      try {
        d = b.toString().split('.')[1].length
      } catch (f) {
        d = 0
      } // eslint-disable-line
      const e = 10 ** Math.max(c, d)
      return (this.mul(a, e) + this.mul(b, e)) / e
    },
    sub(a, b) {
      let c
      let d
      try {
        c = a.toString().split('.')[1].length
      } catch (f) {
        c = 0
      } // eslint-disable-line
      try {
        d = b.toString().split('.')[1].length
      } catch (f) {
        d = 0
      } // eslint-disable-line
      const e = 10 ** Math.max(c, d)
      return (this.mul(a, e) - this.mul(b, e)) / e
    },
    mul(a, b) {
      let c = 0
      const d = a.toString()
      const e = b.toString()
      try {
        c += d.split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      try {
        c += e.split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      return (Number(d.replace('.', '')) * Number(e.replace('.', ''))) / 10 ** c
    },
    div(a, b) {
      let e = 0
      let f = 0
      try {
        e = a.toString().split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      try {
        f = b.toString().split('.')[1].length
      } catch (f) {
      } // eslint-disable-line
      const c = Number(a.toString().replace('.', ''))
      const d = Number(b.toString().replace('.', ''))
      return this.mul(c / d, 10 ** (f - e))
    }
  },

  /**
   @description localStorage, sessionStorage 删除、更新、查看;
   * @param k
   * */
  getLocalStorage(k) {
    return window.localStorage.getItem(k) || ''
  },
  getSessionStorage(k) {
    return window.sessionStorage.getItem(k) || ''
  },

  setLocalStorage(k, value) {
    window.localStorage.setItem(k, value)
  },
  setSessionStorage(k, value) {
    window.sessionStorage.setItem(k, value)
  },

  removeLocalStorage(k) {
    window.localStorage.removeItem(k)
  },
  removeSessionStorage(k) {
    window.sessionStorage.removeItem(k)
  },

  // 打开新窗口
  newWindow(url) {
    if (!url) return
    const { location } = window
    const { origin } = location
    window.open(`${origin}#${url}`)
  },

  // 路由 => push
  pushRouter(url) {
    if (!url) return
    HISTORY.push(url)
  },

  // 路由 => replace
  replaceRouter(url) {
    if (!url) return
    HISTORY.replace(url)
  },

  // 截取url上面的字段
  getParams(name = '') {
    if (!name) return ''
    try {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const params = window.location.href.replace('?', '&')
      const r = params.match(reg)
      if (r !== null) {
        const param = decodeURIComponent(r[2])
        if (param !== null && param.toString().length >= 1) {
          return param
        }
        return ''
      }
      return ''
    } catch (err) {
      console.log(err)
	    return ''
    }
  },

  isJSON(str = '') {
    if (typeof str == 'string') {
      try {
        var obj = JSON.parse(str)
        if (typeof obj == 'object' && obj) {
          return true
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    }
  },

  checkAuth() {
    return new Promise((resolve, reject) => {
      let tk = window.localStorage.getItem(storage.userInfo)
      if (!tk || tk === '{}') {
        resolve()
      } else {
        tk = JSON.parse(tk || '{}')
        const token = (tk.accountVo && tk.accountVo.token) || ''
        resolve(token)
      }
    })
  },

  /**
   * 富文本添加class
   */
  formatContext(context = '') {
    if (!context) return ''
    let c = context
    c = c.replace(/width\s*:\s*[0-9]+px/g, 'width:100%')
    c = c.replace(/<([/]?)(center)((:?\s*)(:?[^>]*)(:?\s*))>/g, '<$1div$3>') // 替换center标签
    c = c.replace(/<img/gi, '<img class="preview-img" style="display:block;width:100% !important;height: auto !important;" ') // 正则给img标签增加class
    c = c.replace(/<p/gi, '<p class="rich-p" ') // 正则给p标签增加class
    c = c.replace(/<span/gi, '<span class="rich-span" ')
    return c
  },

  /**
   * @description 检查版本号（app用）
   * @param v1
   * @param v2
   * @returns {number}
   */
  compareVersion(v1, v2) {
    v1 = v1.split('.')
    v2 = v2.split('.')
    const len = Math.max(v1.length, v2.length)

    while (v1.length < len) {
      v1.push('0')
    }
    while (v2.length < len) {
      v2.push('0')
    }

    for (let i = 0; i < len; i++) {
      const num1 = parseInt(v1[i])
      const num2 = parseInt(v2[i])

      if (num1 > num2) {
        return 1
      } else if (num1 < num2) {
        return -1
      }
    }

    return 0
  },

  /**
   * @description 判断app环境
   * @returns {string}
   */
  appEnv(){
    const ua = navigator.userAgent
    if (ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1) {
      return 'android'
    } else if (ua.indexOf('iPhone') > -1) {
      return 'ios'
    } else {
      return 'other'
    }
  }
}

const Validate = {
  isMobile: (v) => {
    if (!v) return false
    return /^1[3|4|5|6|7|8][0-9]\d{8}$/.test(v)
  },
  isPhone: () => {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  }
}



export { Util, Validate, deepCopy, throttle, deBounce }

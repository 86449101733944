import React, { Component } from 'react'
import './index.less'

class Book extends Component {
  render() {
    const { cover, size = 180, hasRead = false } = this.props
    return (
      <div className={`book-component size-${size}`}>
        {hasRead ? <div className="has-read">已读</div> : null}
        <img src={cover || ''} className="book-img" alt="" />
        <div className="book-bottom"/>
      </div>
    )
  }
}

export default Book

import React, { Component } from 'react'
import './prizeModal.less'

export default class PrizeModal extends Component {

  render() {
    const {
      visible = false,
      title = '',
      size = 'normal',
      onClose = () => {
      },
      children
    } = this.props

    return (
      <div className={`prize-modal-wrapper ${visible ? 'show' : ''}`} style={{ zIndex: 10 }}>
        <div className={`prize-modal ${size}`}>
          <div className="prize-content">
            <div className={`title-img ${size}`}>{title || ''}</div>
            <div className="content">
              {children}
            </div>
          </div>
        </div>
        <div className="close-icon" onClick={onClose}/>
      </div>
    )
  }
}
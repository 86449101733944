import React from 'react'
import './page_6.less'

const Page_6 = (props) => {
	const { citySubCount } = props || {}

	return (
		<>
			<picture className='yr-page-6-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_6_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_6_bg.png'
					className='yr-page-6-bg-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-6-human'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_6_human.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_6_human.png'
					className='yr-page-6-human-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-6-text-wrapper'>
				你所在的城市共有
				<br />
				<span className='text' style={{ color: '#E455E6' }}>
					{citySubCount || ''}
				</span>
				&nbsp;个家庭加入了亲子共读的队列中，
				<br />
				借由绘本让爱在亲子间畅快流淌，
				<br />
				为孩子成为一名终身阅读者持续努力，
				<br />
				也期待你能够把这份美好
				<br />
				传递到周围更多家庭。
			</div>
		</>
	)
}

export default Page_6

import React, { Fragment, useEffect, useState } from 'react'
import { Util } from '../../../lib/util'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../../store'
import { isProd, storage } from '../../../lib/conf'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../../../lib/api'
import List from '../../../components/List'
import EventItem from './components/EventItem'
import './index.less'
import moment from 'moment'
import html2canvas from 'html2canvas'

export default function EventVote(props) {
  const store = useLocalStore(() => CommonStore)
  const [detail, setDetail] = useState({})
  const [userInfo, setUserInfo] = useState(null)
  const [list, setList] = useState({
    data: [],
    size: 20,
    pageNum: 1,
    hasMore: false
  })
  const [activityList, setActivityList] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [diffTime, setDiffTime] = useState(0)
  const [visible, setVisible] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState({})
  const [qrCode, setQrCode] = useState('')
  const { showLoading, hideLoading, showToast, env, showPageLoading, hidePageLoading, showModal, closeModal } = store
  document.title = '故事节投票'

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  const checkUser = () => {
    return new Promise((resolve, reject) => {
      if (env === 'app') {
        bridge.getUserInfoFromApp()
        .then((res) => {
          if (res) {
            setUserInfo({ ...res })
            initShareInfo(res)
            resolve()
          } else {
            bridge.loginFromApp().then((userInfo) => {
              if (userInfo) {
                setUserInfo({ ...userInfo })
                initShareInfo(userInfo)
                resolve()
              } else {
                showToast('请先登录')
                reject()
              }
            })
          }
        })
      } else {
        try {
          const token = Util.getParams('token') || ''
          if (token) {
            Util.setLocalStorage(
              storage.userInfo,
              JSON.stringify({ accountVo: { token: token || '' } })
            )
            fetchUser()
            .then(_ => {
              resolve()
            })
          } else {
            let _user = Util.getLocalStorage(storage.userInfo) || '{}'
            _user = JSON.parse(_user) || null
            if (_user && _user.id) {
              setUserInfo(_user)
              initShareInfo(_user)
              resolve()
            } else {
              wx.miniProgram.navigateTo({
                url: '/pages/login/index',
                success: () => {
                  reject()
                }
              })
            }
          }
          const shareId = Util.getParams('shareId') || ''
          if (shareId) {
            Util.setLocalStorage(storage.shareId, shareId + '')
          }
        } catch (err) {
          console.log(err)
          reject()
        }
      }
    })
  }

  /**
   * @description 获取用户信息（小程序及H5使用）
   */
  const fetchUser = async () => {
    try {
      const res = await serv.getUserInfo()
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        setUserInfo({ ...data })
        initShareInfo(data || {})
      }
    } catch (err) {
      console.log(err)
    }
  }

  const initData = async () => {
    const options = {
      title: '活动已结束~',
      okBtnText: '去其他地方看看',
      showCancelBtn: false,
      gravity: 0,
      onConfirm: () => {
        if (env === 'mp') {
          wx.miniProgram.reLaunch({
            url: '/pages/login/index'
          })
        } else if (env === 'app') {
          bridge.appClose()
        }
      }
    }
    showModal(options)
    await getDetail()
    await getList(true)
    await getActivityList()
    await getQrcode()
  }

  const getDetail = async () => {
    try {
      const res = await serv.getEventVoteDetail()
      if (res) {
        const { data } = res
        setDetail(data || {})
        const { endTime } = data || {}
        const now = moment(new Date()).valueOf()
        const _diffTime = endTime - now
        setDiffTime(Math.floor(_diffTime / 1000))
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getList = async (refresh = false) => {
    setLoaded(false)
    const { pageNum, size, data: oldList } = list
    let params = { pageNum, size }
    if (refresh) {
      params.pageNum = 1
    }
    try {
      const res = await serv.getEventVoteItemList(params)
      if (res) {
        const { data, hasMore } = res
        if (refresh) {
          setList({
            size, hasMore,
            data: [...(data || [])],
            pageNum: hasMore ? 1 + 1 : 1
          })
        } else {
          setList({
            size, hasMore,
            data: oldList.concat(data || []),
            pageNum: hasMore ? pageNum + 1 : pageNum
          })
        }
        setLoaded(true)
      }
    } catch (err) {
      console.log(err)
    }
    setLoaded(true)
  }

  const getActivityList = async () => {
    try {
      const res = await serv.getEventVoteActivityList()
      if (res) {
        const { data } = res
        setActivityList(data || [])
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    checkUser()
    .then(_ => {
      console.log('初始化用户信息')
    })
  }, [])

  /**
   * @description 初始化分享信息
   */
  const initShareInfo = (userInfo) => {
    const { id } = userInfo || {}
    // 本页面需要转发
    bridge.jsShowShareWechat(true)
    bridge.jsShareWechat({
      title: '好奇说首届故事节-让1001场故事会走进社区',
      thumb: 'https://cdn.kangarooread.com/mp-assets/event_veto_share_card_img_2.png',
      path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-vote&shareType=open&shareId=${id || ''}`
    })
    wx.miniProgram.postMessage({
      data: {
        title: '好奇说首届故事节-让1001场故事会走进社区',
        imageUrl: 'https://cdn.kangarooread.com/mp-assets/event_veto_share_card_img_2.png',
        path: `/subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-vote&shareType=open&shareId=${id || ''}`
      }
    })
  }

  useEffect(() => {
    showPageLoading()
    if (userInfo) {
      initData().then(_ => {
        hidePageLoading()
      })
    } else {
      hidePageLoading()
    }
  }, [userInfo])

  useEffect(() => {
    let timer = null
    timer = setTimeout(() => {
      if (diffTime > 1) {
        setDiffTime(diffTime => diffTime - 1)
      }
    }, 1000)
    if (diffTime < 1) {
      return () => {
        timer = null
        clearTimeout(timer)
      }
    }
  }, [diffTime])

  /**
   * @description 打开选择modal
   */
  const showSelectEventModal = () => {
    const { msg } = detail
    if (msg) {
      showToast(msg)
      return
    }
    setVisible(true)
  }

  /**
   * @description 关闭选择modal
   */
  const closeSelectEventModal = () => {
    setSelectedEvent({})
    setVisible(false)
  }

  /**
   * @description 选择一个活动
   * @param vo
   */
  const selectEvent = (vo) => {
    const { enrollStatus } = vo
    if (enrollStatus) {
      showToast('您已使用过该活动参赛了~')
      return
    }
    setSelectedEvent(vo)
  }

  /**
   * @description 选择一个活动去参赛
   */
  const toJoin = () => {
    const { activityId } = selectedEvent
    const { circleId, circleTitle } = detail
    if (!activityId) {
      showToast('请先选择一个活动')
      return
    }
    if (env === 'app') {
      bridge.appNavigate({ id: 116, params: `circleId=${circleId}&circleTitle=${circleTitle}&activityId=${activityId}` })
      setTimeout(() => {
        closeSelectEventModal()
      }, 300)
    } else if (env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/subPkg/main/pages/release-notes/index?circleId=${circleId}&activityId=${activityId}`,
        success: () => {
          closeSelectEventModal()
        }
      })
    } else {
      showToast('请在小程序或APP中打开该页面')
    }
  }

  /**
   * @description 刷新
   * @returns {Promise<void>}
   */
  const refresh = async () => {
    await initData()
  }

  /**
   * @description 投票
   * @param postId
   * @returns {Promise<void>}
   */
  const handleLike = async (postId) => {
    const { data: oList, hasMore, pageNum, size } = list
    showLoading('请稍后...')
    try {
      const res = await serv.listPost(postId)
      if (res) {
        const { data } = res
        if (data) {
          const nList = oList
          const idx = oList.findIndex(it => it.postId === postId)
          if (idx > -1) {
            if (nList[idx].myLiked) {
              nList[idx].myLiked = false
              nList[idx].goodCount = (nList[idx].goodCount || 0) - 1
              showToast('已取消投票')
            } else {
              nList[idx].myLiked = true
              nList[idx].goodCount = (nList[idx].goodCount || 0) + 1
              showToast('投票成功')
            }
          }
          setList({
            hasMore, pageNum, size,
            data: nList
          })
        } else {
          showToast('数据错误，请点击刷新按钮')
        }
      }
    } catch (err) {
      console.log()
    }
    hideLoading()
  }

  /**
   * @description 跳转详情
   * @param postId
   */
  const handleToDetail = (postId) => {
    if (env === 'app') {
      bridge.appNavigate({ id: 20, params: `id=${postId}` })
    }
    if (env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/subPkg/main/pages/read-notice-detail/index?postId=${postId}`
      })
    }
  }

  /**
   * @description 获取小程序码
   */
  const getQrcode = async () => {
    const { id: userId } = userInfo
    const params = {
      path: 'subPkg/extend/pages/web-link/index',
      scene: `m;4;${userId || ''};open`,
      type: 20
    }
    try {
      const res = await serv.getQrCode(params)
      if (res) {
        const { cdnUrl } = res.data || {}
        setQrCode(cdnUrl)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 渲染海报
   * @returns {Promise<void>}
   */
  const renderPoster = async () => {
    showLoading('绘制中...')
    const node = document.getElementById('poster-wrapper-2')
    html2canvas(node, {
      allowTaint: true,
      useCORS: true,
      scrollY: 0
    })
    .then(canvas => {
      if (canvas) {
        const src = canvas.toDataURL('image/png')
        if (env === 'app') {
          bridge.appShareBase64(src)
          setTimeout(() => {
            hideLoading()
          }, 200)
        } else if (env === 'mp') {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/img-preview-page/index?type=eventVote`,
            complete: () => {
              hideLoading()
            }
          })
        }
      }
    })
    .catch(err => {
      console.log(err)
      showLoading('绘制中...')
    })
  }

  const $moment = moment.duration(diffTime, 'second')
  const days = Math.floor($moment.asDays())
  const hours = $moment.hours()
  const minutes = $moment.minutes()
  const seconds = $moment.seconds()

  const { festivalCover, enrollFestivalBtn, inviteBtn, uploadBtn } = detail
  const { activityId } = selectedEvent
  const { accountVo } = userInfo || {}
  const { nickname } = accountVo || {}

  return (
    <div className="e-vote-wrapper">
      <div
        className="poster-wrapper"
        id="poster-wrapper-2"
        style={{
          position: 'absolute',
          left: -9999,
          top: -9999
        }}
      >
        <img src="https://cdn.kangarooread.com/mp-assets/event_veto_share_img_2_bg.png" crossOrigin="anonymous"
             className="poster-img" alt=""/>
        <div className="poster-info">
          <div className="p-i-left">
            <div className="nickname">{nickname || ''}</div>
            <div className="desc">
              邀请你为TA投票<img className="icon" crossOrigin="anonymous"
                           src="https://cdn.kangarooread.com/mp-assets/event_veto_share_img_2_icon_2.png" alt=""/>
            </div>
          </div>
          <img src={qrCode} className="qr-code" crossOrigin="anonymous" alt=""/>
        </div>
      </div>
      <div className="white-bg"/>
      <img className="header-img" src={festivalCover || ''} alt=""/>
      <div className="count-down-wrapper">
        距投票截止
        {/*<span className="time-label">{days || '0'}</span>天*/}
        {/*<span className="time-label">{hours || '0'}</span>时*/}
        {/*<span className="time-label">{minutes || '0'}</span>分*/}
        {/*<span className="time-label">{seconds || '0'}</span>秒*/}
        <span className="time-label">0</span>天
        <span className="time-label">0</span>时
        <span className="time-label">0</span>分
        <span className="time-label">0</span>秒
      </div>
      <div className="header-img-1">
        <div className="re-btn" onClick={refresh}>刷新<i className="icon"/></div>
      </div>
      <div className="list">
        <List
          next={getList}
          loaded={loaded}
          dataSource={list.data || []}
          hasMore={list.hasMore}
          endStr="没有更多作品了哦~"
          empty="暂时还没有参赛作品哦~"
        >
          {list.data && list.data.map((i, idx) => (
            <div className="item-wrapper" key={idx}>
              <EventItem data={i} onLike={handleLike} onDetail={handleToDetail}/>
            </div>
          ))}
        </List>
      </div>
      <div className="enter-btn-wrapper">
        {enrollFestivalBtn
          ? <div className="join-btn" onClick={showSelectEventModal}>
            <i className="icon"/>
            <div className="btn-text-wrapper">
              <span className="label">我要参赛</span>
              <span className="sub-label">上传作品</span>
            </div>
          </div>
          : <Fragment>
            {inviteBtn
              ? <div className="invite-btn" onClick={renderPoster}>邀请好友为我投票</div>
              : null
            }
            {uploadBtn
              ? <div className="upload-btn" onClick={showSelectEventModal}>
                <i className="icon"/>继续上传作品
              </div>
              : null
            }
          </Fragment>
        }
      </div>

      {visible
        ? <div className="mask"/>
        : null
      }
      {activityList && activityList.length > 0
        ? <div className={`e-modal-wrapper ${visible ? 'show' : ''}`}>
          <i className="close" onClick={closeSelectEventModal}>+</i>
          <div className="m-title">请选择参赛活动</div>
          <div className="m-e-list">
            {activityList && activityList.map(i => (
              <div className="m-e-item" key={i.activityId} onClick={() => selectEvent(i)}>
                <i className={`label ${activityId === i.activityId ? 'selected' : ''}`}/>
                <span className="text">{i.activityTitle || ''}</span>
                {i.enrollStatus
                  ? <span className="status">已参赛</span>
                  : null
                }
              </div>
            ))}
          </div>
          <div className="select-btn" onClick={toJoin}>去参赛</div>
        </div>
        : null
      }

    </div>
  )
}

import React, { Component } from 'react'
import { observable, action, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import dsBridge from 'dsbridge'
import { nativeReturnMsg } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import './index.less'

@inject('store')
@observer
class CommonRichText extends Component {
	@observable content = ''

	UNSAFE_componentWillMount() {
		this.getRichText()
	}

	@action
	getRichText = () => {
		dsBridge.register('jsSetRichText', (res) => {
			if (res) {
				const { content } = (Util.isJSON(res) && JSON.parse(res || '{}')) || {}
				runInAction(() => {
					this.content = Util.formatContext(content || '')
				})
				return nativeReturnMsg.SUCCESS
			}
			return nativeReturnMsg.ERROR
		})
	}

	handleClickRichText = (e) => {
		e.persist()
		const { nodeName, currentSrc, innerText } = e.target
		console.log(`nodeName => ${nodeName}; currentSrc => ${currentSrc}; innerText => ${innerText}`)
		if (nodeName === 'IMG') {
			bridge.appImagePreview({ src: currentSrc }).then((r) => console.log(r))
		} else if (nodeName === 'A') {
			bridge.appSetClipboardData({ text: innerText })
				.then(_ => {
					this.props.store.showToast('已复制到剪贴板')
				})
				.catch(_ => {
					this.props.store.showToast('复制失败')
				})
		}
	}

	render() {
		return (
			<div style={{ padding: '15px', background: '#fff' }}>
				{this.content && (
					<div
						className='rich-text-content'
						onClick={(e) => this.handleClickRichText(e)}
						dangerouslySetInnerHTML={{
							__html: this.content || ''
						}}
					/>
				)}
			</div>
		)
	}
}

export default CommonRichText

import React, { useEffect, useState } from 'react'
import serv from '../../../lib/api'
import { Util } from '../../../lib/util'

export default function EventAddQrcode() {
	const [url, setUrl] = useState('')

	const getAddQWQrcode = async (userId) => {
		try {
			// TODO: 调用接口
			// const res = await serv.xxx(userId)
			// if (res) {
			//   const { data } = res
			//   setUrl(data)
			// }
		} catch (err) {
			console.log(err)
		}
	}

	const initData = async () => {
		const userId = Util.getParams('user_id')
		await getAddQWQrcode(userId)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div style={{ padding: '80px 0 80px' }}>
			<img
				src={url}
				alt=''
				style={{
					display: 'block',
					margin: '0 auto',
					width: '90%',
					height: 'auto'
				}}
			/>
		</div>
	)
}

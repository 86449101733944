import React from 'react'
import './page_3.less'

const Page_3 = (props) => {
	const { subBookHeight, avgBookCount } = props || {}

	return (
		<>
			<picture className='yr-page-3-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_3_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_3_bg.png'
					className='yr-page-3-bg-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-3-text-wrapper-1'>
				如果把这些绘本叠高，
				<br />
				相当于&nbsp;
				<span className='text' style={{ color: '#9364FF' }}>
					{subBookHeight || ''}
				</span>
				&nbsp;层楼房的高度哦！
				<br />
				期待明年更上一层楼吧！
			</div>
			{avgBookCount && (
				<div className='yr-page-3-text-wrapper-2'>
					这一年，
					<br />
					有很多同龄人也和你孩子一样，
					<br />
					在绘本世界中尽情徜徉，
					<br />
					沐浴着阅读的芬芳，
					<br />
					他们平均阅读了&nbsp;
					<span className='text' style={{ color: '#FF9000' }}>
						{avgBookCount || ''}
					</span>
					&nbsp;本
				</div>
			)}
		</>
	)
}

export default Page_3

import fetch from '../fetch'

const order = {
	/**
	 * @description 获取抽奖订单记录列表
	 * @returns {*}
	 */
	getLotteryOrderList() {
		return fetch('/order/mp/points_goods_order/lottery_order_list')
	},

	/**
	 * @description 补充用户收货地址
	 * @param params
	 * @returns {*}
	 */
	commitUserAddress(params = {}) {
		return fetch('/order/mp/points_goods_order/commit_user_address', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取抽奖获奖名单
	 * @returns {*}
	 */
	getLotteryRollList() {
		return fetch('/order/mp/points_goods_order/get_lottery_roll_list')
	},

	/**
	 * @description 获取天选之子活动详情
	 * @returns {*}
	 */
	getSelectActivityDetail() {
		return fetch('/order/select_activity/detail_info')
	},

	/**
	 * @description 获取天选之子活动优惠券
	 * @returns {*}
	 */
	getCouponInEventSelectUser() {
		return fetch('/order/select_activity/get_user_coupon')
	},

	/**
	 * @description 获取天选之子分会长视角
	 * @returns {*}
	 */
	getUserEnrollListInEventSelectUser() {
		return fetch('/order/select_activity/get_user_enroll_list')
	},

	/**
	 * @description 获取小红花同学列表
	 * @param params
	 * @returns {*}
	 */
	getClassmateInfoList(params = {}) {
		return fetch('/order/app/camp_order/get_classmate_info', {
			params: { ...params }
		})
	},

	/**
	 *@description 提交生日收货地址
	 * @param params
	 * @returns {*}
	 */
	commitBirthdayAddress(params = {}) {
		return fetch(
			'/order/app/points_goods_order/commit_birthday_gift_address',
			{
				method: 'POST',
				data: { ...params }
			},
			'v2'
		)
	},

	getBirthdayOrderId() {
		return fetch(
			'/order/app/points_goods_order/get_birthday_order_id',
			{},
			'v2'
		)
	},

  /**
   * @description 获取520邀请记录
   * @returns 
   */
	get520InviteList() {
		return fetch('/order/app/520_activity/get_invite_list', {}, 'v2')
	}
}

export default order

import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../../store'
import serv from '../../../lib/api'
import { env, isProd } from '../../../lib/conf'
import useUpdateEffect from '../../../lib/useUpdateEffect'
import wx from 'weixin-js-sdk'
import { bridge } from '../../../lib/bridge'
import moment from 'moment'

export default function NewYearWish() {
  const store = useLocalStore(() => CommonStore)
  const [detail, setDetail] = useState(null)
  const containerRef = useRef(null)
  const [lineList, setLineList] = useState([])
  const { showPageLoading, hidePageLoading } = store

  // 本页面需要转发
  bridge.jsShowShareWechat && bridge.jsShowShareWechat(true)
  bridge.jsShareWechat && bridge.jsShareWechat({
    title: '叮，好奇说给你来信啦！',
    thumb: 'https://cdn.kangarooread.com/mp-assets/event/new_year_share_card.png',
    path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/new-year-wish&shareType=open`
  })
  wx.miniProgram.postMessage && wx.miniProgram.postMessage({
    data: {
      title: '叮，好奇说给你来信啦！',
      imageUrl: 'https://cdn.kangarooread.com/mp-assets/event/new_year_share_card.png',
      path: `/subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/new-year-wish&shareType=open`
    }
  })

  const getDetail = async () => {
    showPageLoading()
    try {
      const res = await serv.getTigerYearBless()
      if (res) {
        const { data } = res
        setDetail(data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      hidePageLoading()
    }
  }

  useEffect(() => {
    if (!detail) {
      getDetail()
    }
  }, [detail])

  useUpdateEffect(() => {
    const { offsetHeight, children } = containerRef && containerRef.current
    const firstChildren = Array.from(children).filter(it => it.className !== 'under-line')[0]
    const lineHeight = firstChildren.offsetHeight / 2
    const lineLength = offsetHeight / lineHeight
    const lineList = []
    for (let i = 0; i < lineLength + 1; i++) {
      lineList.push({
        lineHeight,
        key: i + 1
      })
    }
    setLineList(lineList)
  }, [detail])

  const {
    nickname,
    totalDays,
    activeTime,
    borrowCount,
    clockCount,
    totalAmount,
    firstCourseTime,
    courseTitle,
    applyCount,
    memberCount,
    videoUrl
  } = detail || {}

  return (
    <div className="new-year-wish-wrapper">
      <img src={env.IMG + 'event/new_year_bg_1.png'} className="bg-1" alt=""/>
      <div className="bg-2"/>
      <div className="bg-3"/>
      <div className="content-wrapper">
        <img className="new-year-title" src={env.IMG + 'event/new_year_title_img.png'} alt=""/>
        <div className="content-bg"/>
        <div className="content-head">
          <div className="text">亲爱的 {nickname}</div>
        </div>
        <div className="content-body" ref={containerRef}>
          {lineList && lineList.map(it => (
            <div className="under-line" key={it.key} style={{
              top: it.key * it.lineHeight,
              position: 'absolute',
              zIndex: 2
            }}/>
          ))}
          <div className="content-text">
            你和好奇说已经相遇 <span className="red-text">{totalDays}</span> 天了，我们彼此陪伴，度过了那些美妙的时光。
          </div>
          <div className="content-split"/>
          {
            borrowCount > 0 && <>
              <div className="content-text">
                你和孩子从 <span className="red-text">{moment(activeTime).format('YYYY-MM-DD')}</span> 开启了好奇说阅读之旅,
                至今你们已经阅读了 <span className="red-text">{borrowCount}</span> 本绘本，参与了 <span
                className="red-text">{clockCount}</span> 天伴读打卡， 相比于购买共节省了 <span
                className="red-text">{totalAmount}</span> 元。
              </div>
              <div className="content-text">这些绘本，在孩子成长的道路上，带给他们勇气、梦想、美好和爱！</div>
              <div className="content-text">这些温暖，足以帮他们抵御未来成长道路上的一切艰难险阻，让孩子成为一个坚强、自信、阳光的人。</div>
              <div className="content-split"/>
            </>
          }
          {
            applyCount > 0 && <>
              <div className="content-text">
                你在好奇说一直保持着学习的热情，从 <span
                className="red-text">{moment(firstCourseTime).format('YYYY-MM-DD')}</span> 开始，你在好奇说学习了第一门课程<span
                className="red-text">《{courseTitle}》</span>，你学习了 <span
                className="red-text">{applyCount}</span> 门课程，真是名副其实的学习型妈妈，你的孩子也一定会为此特别骄傲！
              </div>
              <div className="content-split"/>
            </>
          }
          {
            memberCount > 0 && <>
              <div className="content-text">
                你像"花婆婆"一样，用自己的行动，影响了 <span className="red-text">{memberCount}</span> 个家庭，带领他们走进绘本的奇妙世界。
              </div>
              <div className="content-split"/>
            </>
          }
          <div className="content-text">
            接下来的日子里，愿你能继续在好奇说，和孩子共享绘本之美！
          </div>
          <div className="content-text">
            一起亲子阅读，陪伴点亮成长!
          </div>
          <div className="content-text">
            最后，祝你和你的家人
          </div>
        </div>
        <div className="content-foot">
          <img src={env.IMG + 'event/new_year_gift_label.png'} className="text-img" alt=""/>
          <div className="text">
            好奇说总部
          </div>
          <div className="text">
            2022年1月
          </div>
          <div className="text-split"/>
          <img src={env.IMG + 'event/new_year_writing_paper_foot.png'} alt="" className="foot-img"/>
        </div>
      </div>
      <img src={env.IMG + 'event/new_year_video_title.png'} className="video-title" alt=""/>
      <video className="new-year-video" controls src={videoUrl} poster={env.IMG + 'event/new_year_video_poster_v2.png'}>
        <source src={videoUrl}/>
      </video>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import './index.less'
import serv from '../../lib/api'
import { Util } from '../../lib/util'

export default function ChannelPage() {
  const [uri, setUri] = useState('')

  const getMpUrl = async () => {
    const cId = Util.getParams('channelId')
    const params = {
      path: '/subPkg/extend/pages/keep-detail/index',
      query: `channelId=${cId || 1}&id=584&type=2`,
      version: 'trial'
    }
    try {
      const res = await serv.getWxRedirectPath(params)
      if (res) {
        const { data } = res
        if (data) {
          const result = JSON.parse(data)
          const { openlink } = result || {}
          setUri(openlink)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    // TODO: 判断iOS还是Android，设置不同的uri
    // setUri('')

    getMpUrl()
  }, [])

  const toDetail = () => {
    location.href = uri
  }


  return <div className="channel-page-wrapper">
    <img src="" className="img" alt="这是一张图片"/>
    {uri && <a href={uri} className="app-link-btn">
      免费领课
    </a>}

  </div>
}

import React  from 'react'
import { bridge } from '../lib/bridge'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../store'

const Demo = () => {
	const store = useLocalStore(() => CommonStore)
	const { showToast } = store

  const content = `
    <div style="margin-bottom: 0px;font-size: 16px;line-height: 2;color: #6c3d19;">
    	资料包链接：<em style="word-break:break-all;color:">https://cdn.kangarooread.com/resource/9800activity/3550.zip</em>
		</div>
  `

	const handleClickRichText = (e) => {
		e.persist()
		const { nodeName, currentSrc, innerText } = e.target
		console.log(`nodeName => ${nodeName}; currentSrc => ${currentSrc}; innerText => ${innerText}`)
		if (nodeName === 'IMG') {
			bridge.appImagePreview({ src: currentSrc }).then((r) => console.log(r))
		} else if (nodeName === 'A') {
			bridge.appSetClipboardData({ text: innerText })
				.then(_ => {
					showToast('已复制到剪贴板')
				})
				.catch(_ => {
					showToast('复制失败')
				})
		}
	}


  return <div className="protocol-wrapper"
              onClick={(e) => handleClickRichText(e)}
              dangerouslySetInnerHTML={{
                __html: content
              }}/>
}

export default Demo

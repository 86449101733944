import React, { useEffect, useState } from 'react'
import List from '../../components/List'
import serv from '../../lib/api'
import moment from 'moment'
import ActionSheet from '../../components/ActionSheet'
import { bridge } from '../../lib/bridge'

export default function ReadInviteUserList() {
	const [list, setList] = useState([])
	const [hasMore, setHasMore] = useState(false)
	const [pageNum, setPageNum] = useState(1)
	const [loaded, setLoaded] = useState(false)
	const [visible, setVisible] = useState(false)
	const [currentUser, setCurrentUser] = useState(null)
	const size = 10

	useEffect(() => {
		bridge.jsShowShareWechat(false)
		getList()
		setLoaded(true)
	}, [])

	const getList = async () => {
		const params = {
			size,
			pageNum
		}
		try {
			const res = await serv.inviteUserDetail(params)
			if (res) {
				const { hasMore, data } = res
				setList(list.concat(data || []))
				setHasMore(hasMore)
				setPageNum(hasMore ? pageNum + 1 : pageNum)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const showActionSheet = (userInfo) => {
		setCurrentUser(userInfo)
		setVisible(true)
	}

	const closeActionSheet = () => {
		setVisible(false)
	}

	const {
		avatar,
		nickname,
		startTime,
		breakOffTime,
		readType,
		onceCouponCount,
		receiveInvitationTime,
		rewardsPoints,
		rewardsType,
		unlockCoupon: cUnlockCoupon,
		totalCoupon: cTotalCoupon
	} = currentUser || {}

	return <>
		<List
			next={getList}
			loaded={loaded}
			dataSource={list || []}
			hasMore={hasMore}
			endStr="到底啦~"
		>
			{list.map((it, idx) => (
				<div className="invite-item" key={it.userId} onClick={() => showActionSheet(it)}>
					<img src={it.avatar} className="avatar" alt=""/>
					<div className="info-wrapper">
						<div className="info">
							<div className="nickname">{it.nickname || ''}</div>
							{it.rewardsType === 1 && (it.readType === 1 || it.readType === 4) && (
								<div className="progress-info">
									<div
										className="current"
										style={{
											width: `${
												(it.unlockCoupon / it.totalCoupon) * 100
											}%`
										}}
									/>
								</div>
							)}
						</div>
						<div className="desc-wrapper">
							{it.rewardsType === 1 && <>
								<div className="desc">
									已与你共读 {it.readCount || 0} 个月
									{(it.readType === 2 || it.readType === 3) &&
										'，已中断'}
								</div>
								{it.readType === 1 ? (
									<>
										{it.unlockCoupon < it.totalCoupon && (
											<div className="message">
												我已获得 {it.unlockCoupon} 张 共{' '}
												{it.totalCoupon} 张{' '}
											</div>
										)}
										{it.unlockCoupon === it.totalCoupon && (
											<div className="message">
												我已获得 {it.unlockCoupon} 张
											</div>
										)}
									</>
								) : (
									<div className="message">
										我共获得 {it.unlockCoupon} 张
									</div>
								)}
								<img
									src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
									className="right-arrow"
									alt=""
								/>
							</>}
							{it.rewardsType === 2 && it.readType === 1 && <div className="desc">
								订阅成功，留书券奖励+{it.onceCouponCount || 0}
							</div>}
							{it.rewardsType === 3 && it.readType === 1 && <div className="desc">
								邀请成功，积分奖励+{it.rewardsPoints || 0}
							</div>}
						</div>
					</div>
					<div className="split-line"/>
				</div>
			))}
		</List>

		<ActionSheet visible={visible} onClose={closeActionSheet}>
			<div className="invited-user-info">
				{visible && <img src={avatar} className="avatar" alt=""/>}
				<div className="nickname">{nickname}</div>
				{rewardsType === 1 && <>
					<div className="desc-item">
						<div className="label">开始共读</div>
						<div className="value">{moment(startTime).format('YYYY-MM-DD HH:mm')}</div>
					</div>
					<div className="desc-item">
						<div className="label">当前状态</div>
						<div className="value">
							{readType === 1 || readType === 4 ? '共读中' : '已中断'}
							{readType === 2 && <span className="error">{moment(breakOffTime).format('YYYY-MM-DD HH:mm')}TA中断订阅</span>}
							{readType === 3 && <span className="error">{moment(breakOffTime).format('YYYY-MM-DD HH:mm')}我中断订阅</span>}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">已为我解锁</div>
						<div className="value">{cUnlockCoupon || 0} 张共读留书券</div>
					</div>
					<div className="desc-item">
						<div className="label">待解锁</div>
						<div className="value">{(cTotalCoupon || 0) - (cUnlockCoupon || 0)} 张共读留书券</div>
					</div>
					<div className="tip">你与TA都连续订阅续费还可各获得的留书券数量</div>
				</>}
				{(rewardsType === 2 || rewardsType === 3) && <>
					<div className="desc-item">
						<div className="label">受邀时间</div>
						<div className="value">
							{moment(startTime).format('YYYY-MM-DD HH:mm')}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">订阅时间</div>
						<div className="value">
							{moment(receiveInvitationTime).format('YYYY-MM-DD HH:mm')}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">奖励</div>
						<div className="value">
							{rewardsType === 2 && `${onceCouponCount || 0} 张共读留书券`}
							{rewardsType === 3 && `积分 +${rewardsPoints || 0}`}
						</div>
					</div>
				</>}
				<div className="close-btn" onClick={closeActionSheet}>关闭</div>
			</div>
		</ActionSheet>
	</>
}

import React, { Component } from 'react'
import { DatePicker, List, Picker, InputItem, TextareaItem } from 'antd-mobile'
import { inject, observer } from 'mobx-react'
import { action, keys, observable } from 'mobx'
import cityOptions from '../../../lib/cities'
import './index.less'
import { Util } from '../../../lib/util'


@inject('store')
@observer
class AddAddress extends Component {
  @observable user = ''
  @observable phone = ''
  @observable area = ''
  @observable formDate = {
    province: '',
    city: '',
    county: ''
  }
  @observable addressValue = []

  submitUser = (e) => {
    this.user = e
  }

  submitPhone = (e) => {
    this.phone = e
  }

  submitArea = (e) => {
    this.area = e
  }

  submitAddress = (e) => {
    const { defaultAddress } = this.props.store
    const a = defaultAddress.find((it) => (it.value === e[0]))
    const b = a.children.find((it) => (it.value === e[1]))
    const c = b.children.find((it) => (it.value === e[2]))
    this.formDate.province = a.label
    this.formDate.city = b.label
    this.formDate.county = c.label
    this.addressValue = e
    const { updateAddress } = this.props.store
    updateAddress(e)
  }

  handleSubmit = () => {
    const { updateBirthday, birthdayList, showToast } = this.props.store
    const params = {
      user: this.user || birthdayList.user,
      phone: this.phone || birthdayList.phone,
      area: this.area || birthdayList.area,
      province: this.formDate.province || birthdayList.province,
      city: this.formDate.city || birthdayList.city,
      county: this.formDate.county || birthdayList.county
    }

    if (!params.user) return showToast('请输入联系人')
    if (!params.phone) return showToast('请输入手机号')
    if (!params.province) return showToast('请选择地址')
    if (!params.area) return showToast('请输入详细地址')

    const { history } = this.props
    updateBirthday(params)
    history.goBack()
  }

  componentDidMount() {
    document.title = '生日收货地址'
  }

  render() {
    const { birthdayList, addressList, defaultAddress } = this.props.store

    return (
      <div className="date-bg">
        <div className="date-wrapper">
          <List className="date-picker-list" style={{ backgroundColor: 'white' }}>
            <InputItem placeholder="请输入联系人" onChange={this.submitUser} defaultValue={birthdayList.user}>
              联系人
            </InputItem>
            <InputItem placeholder="请输入手机号" onChange={this.submitPhone} defaultValue={birthdayList.phone} type="phone">
              手机号码
            </InputItem>
            <Picker
              extra={!addressList.province && <span style={{ color: '#bbb' }}>请选择地区</span>}
              data={defaultAddress}
              title="地区信息"
              cascade
              className="date-address"
              onOk={this.submitAddress}
              value={addressList}
            >

              <List.Item>地区信息</List.Item>
            </Picker>
            <TextareaItem title="详细地址" className="textarea-item" placeholder="请输入详细地址" onChange={this.submitArea}
                          defaultValue={birthdayList.area}/>
          </List>
          <div className="date-btn" onClick={this.handleSubmit}>确定</div>
        </div>
      </div>
    )
  }
}

export default AddAddress

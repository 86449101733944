import React, { useEffect, useState } from 'react'
import Star from '../../components/Star'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'
import serv from '../../lib/api'

export default function RateServiceQuestionnaire(props) {
  const store = useLocalStore(() => CommonStore)
  const [list, setList] = useState([])
  const [questionCount, setQuestionCount] = useState(0)
  const { showPageLoading, hidePageLoading, showLoading, hideLoading, showToast } = store
  document.title = '服务评价'
  const { id } = props.match.params

  useEffect(() => {
    showPageLoading()
    getList()
    .then(() => {
      hidePageLoading()
    })
    .catch(() => {
      hidePageLoading()
    })
  }, [])

  const getList = async () => {
    try {
      const res = await serv.getQuestionnaire()
      if (res) {
        const { data } = res
        const { serviceQuestionList } = data || {}
        let list = [],
          subList = []
        if (serviceQuestionList && serviceQuestionList.length > 0) {
          list = serviceQuestionList.filter((it) => it.type === 1)
          list.forEach((el) => {
            el.children = []
          })
          subList = serviceQuestionList.filter((it) => it.type === 2)
        }
        for (let i = 0; i < subList.length; i++) {
          for (let j = 0; j < list.length; j++) {
            if (subList[i].relatedQuestionId === list[j].id) {
              list[j]['children'].push(subList[i])
            }
          }
        }
        setList(list || [])
        setQuestionCount(subList.length || 0)
      } else {
        const { goBack } = props.history
        goBack()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async () => {
    const params = {
      answerList: [],
      status: 'OVER'
    }
    try {
      list.forEach((it) => {
        it.children.forEach((i) => {
          if ((i.stars && i.stars > 0) || (i.score && i.score > 0)) {
            params.answerList.push({
              supId: id === '0' ? '' : id,
              questionId: i.id,
              questionnaireId: i.questionnaireId,
              score: i.stars || i.score
            })
          }
        })
      })
      if (params.answerList.length !== questionCount) {
        showToast('打分不能为空，请选择完整')
        return
      }
      await submitAnswerList(params)
    } catch (err) {
      console.log(err)
    }
  }

  const submitAnswerList = async (params = {}) => {
    showLoading('请稍后..')
    try {
      const res = serv.submitAnswerList(params)
      if (res) {
        const { goBack } = props.history
        showToast('提交成功')
        goBack()
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  const getStar = (starList, index, idx) => {
    const newList = [...list]
    newList[index].children[idx].stars = starList && starList.filter((it) => it.status === 'active').length
    setList(newList)
  }

  return (
    <div className="rate-service-q-wrapper">
      <div className="list">
        {list.map((it, index) => (
          <div className="item" key={it.id}>
            <div className="title">{it.content || ''}</div>
            {it.children && it.children.map((i, idx) => (
              <div className="q-item" key={i.id}>
                <div className="q-i-title">{i.content || ''}</div>
                <div className="star-wrapper">
                  <Star
                    className="star-list"
                    size={25}
                    active={i.score || 0}
                    onChange={(list) => getStar(list, index, idx)}/>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="button" onClick={handleSubmit}>提 交</div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import './index.less'
import api from '../../lib/api'
import { useLocalStore } from 'mobx-react'
import { bridge } from '../../lib/bridge'
import wx from 'weixin-js-sdk'
import CommonStore from '../../store'

// let userInfo =JSON.parse(Util.getLocalStorage(storage.userInfo)|| {}) || null

export default function BirthdayAddress(props) {
  const store = useLocalStore(() => CommonStore)
  const [showEdit, setShowEdit] = useState(true)
  const [showAddress, setShowAddress] = useState(false)
  const { env, birthdayList, userInfo, showToast } = store
  const [birthday, setBirthday] = useState('')

  const toAddress = () => {
    const { push } = props.history
    push(`/add-address`)
  }

  const handleOk = async () => {
    if (!birthdayList.province) return showToast('请填写收货地址')

    const params = {
      address: birthdayList.area,
      city: birthdayList.city,
      contactName: birthdayList.user,
      contactPhone: birthdayList.phone.replace(/[ ]/g, ''),
      county: birthdayList.county,
      province: birthdayList.province,
      userId: userInfo.id
    }
    try {
      const res = await api.commitBirthdayAddress(params)
      if (res) {
        setShowEdit(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const toOrder = async () => {
    const res = await api.getBirthdayOrderId()
    if (res) {
      const { data } = res || {}
      if (env === 'app') {
        bridge.appNavigate({ id: 104, params: `type=14&id=${data}` })
      } else {
        console.log(data)
        wx.miniProgram.navigateTo({
          url: `/subPkg/main/pages/order-confirm/index?id=${data}&type=14`
        })
      }
    }
  }


  const init = async () => {
    if (birthdayList.province) {
      setShowAddress(true)
    }
    await getBirthdayInfo()
  }


  const getBirthdayInfo = async () => {
    const res = await api.getBirthdayInfo()
    if (res) {
      const { data } = res || {}
      if (data.birthDay) {
        const a = data.birthDay.split('-')
        const birthday = `${a[1]}月${a[2]}日`
        setBirthday(birthday)
      }

    }
  }

  useEffect(() => {
    document.title = '生日收货地址'
    init()
  }, [])
  // const { avatar, accountVo } = userInfo || {}
  // const { avatar2 } = accountVo || {}

  return (
    <div className="birthday-address-bg">
      <div className="birthday-wrapper">
        <div className="avatar-wrapper">
          <img src={userInfo.accountVo && userInfo.accountVo.avatar || userInfo.avatar || ''} className="avatar"/>
        </div>
        <div className="name">{userInfo.nickname || ''}</div>
        <div className="pic"/>
        <div className="desc">{birthday} 是你的生日，好奇说为你准备了一份小礼物，请尽快填写收货地址以便我们为你寄出～</div>

        {
          showAddress ?
            <div className="address-info" onClick={showEdit ? toAddress : null}>
              <div
                className="province-city">{`${birthdayList.province}${birthdayList.city}${birthdayList.county}`}
              </div>
              <div className="address">
                {`${birthdayList.area}`} {showEdit ? <div className="icon">修改
                <div className={`right-arrow`}/>
              </div> : null}
              </div>
              <div className="info">
                <span>{`${birthdayList.user}    ${birthdayList.phone.replace(/[ ]/g, '')}`}</span>
              </div>
            </div> :
            <div className="address-wrapper" onClick={toAddress}>
              <span className="icon"/> 添加地址
            </div>
        }
        <div className={`btn-wrapper`}>
          {
            showEdit ?
              <div className="birthday-btn" onClick={handleOk}>确认</div> :
              <div className="birthday-btn submit" onClick={toOrder}>已提交，查看配送订单</div>
          }
        </div>
      </div>
    </div>
  )
}

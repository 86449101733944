import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import { env } from '../../lib/conf'
import { bridge } from '../../lib/bridge'
import './index.less'

const { IMG } = env

@inject('store')
@observer
class EventWeb extends Component {
  componentDidMount() {
    document.title = '好奇说故事历'
  }

  toBuy = () => {
    const { env } = this.props.store
    if (env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/reward-points-exchange-list/index',
      })
    } else if (env === 'app') {
      bridge.appNavigate({ id: 7 })
      .then(res => {
        if (res) {
          console.log('跳转成功')
        }
      })
    }
  }

  render() {
    return (
      <div className="event-web-wrapper">
        <div className="wrapper-content">
          <div className="desc">亲子伴读的每一刻，都是弥足珍贵的。</div>
          <div className="desc">孩子一天天长大，总有一天，我们会放手，目送他远去。</div>
          <div className="desc">而那些共度的阅读时光，永远铭记在心中。</div>
          <div className="desc">好奇说故事周历，记录下陪伴的每一天~</div>
          <div className="desc">马上就要迎来新一年，送孩子什么礼物比较好呢？</div>
          <div className="desc">玩具？图书？一次旅行？</div>
          <div className="desc">其实，好奇说已经贴心地准备了一份别有新意的礼物——</div>
          <div className="desc">集趣味猜谜、彩绘涂鸦、伴读打卡于一体的好奇说定制故事周历！</div>
          <img src={`${IMG}1222_event/event_1.jpg`} className="img" alt="图片"/>
          <img src={`${IMG}1222_event/event_2.jpg`} className="img" alt="图片"/>
          <img src={`${IMG}1222_event/event_3.jpg`} className="img" alt="图片"/>
          <div className="title">01. 趣味猜谜</div>
          <div className="desc">每一页都有一副小袋鼠插画，是好奇说小姐姐一笔笔细心手绘出来的。</div>
          <div className="desc">这可不是简单的插画，是从这一周挑出一个节日，关联好奇说精选书单中的某一本绘本加工绘制而成。</div>
          <div className="desc">比如母亲节挑选《我妈妈》这本绘本，再赋予小袋鼠绘本中妈妈睡衣的特色。</div>
          <img src={`${IMG}1222_event/event_4.jpg`} className="img" alt="图片"/>
          <div className="desc">每个插图下面有个二维码，扫码就能揭晓这本绘本的名称。</div>
          <div className="desc">先别急着扫一扫，猜一猜才有趣！</div>
          <div className="desc">让孩子看一看插图和节日，想一想在好奇说订阅过的绘本，还是没猜出来？别急，每一幅插图下面还有一行提示语呢。</div>
          <div className="desc">比一比谁猜出的绘本多吧！</div>
          <div className="title">02. 彩绘涂鸦</div>
          <div className="desc">礼盒中赠送的蜡笔是干什么的呢？</div>
          <img src={`${IMG}1222_event/event_5.jpg`} className="img" alt="图片"/>
          <div className="desc">周历每一页的背面，是清爽的黑白线稿，有了蜡笔，孩子就可以对小袋鼠进行自由涂色啦。</div>
          <img src={`${IMG}1222_event/event_6.jpg`} className="img" alt="图片"/>
          <div className="desc">一年以后，这本周历将成为孩子的纪念画册。稚嫩的涂鸦，明亮的颜色，构成了五彩缤纷的快乐童年~</div>
          <div className="title">03. 伴读打卡</div>
          <div className="desc">周历每一页顶部，都有一句阅读、教育金句。每每看到，会不会意识到亲子伴读对于孩子未来的重要性？</div>
          <div className="desc">每一页日期后，都有一片留白。远远望到，会不会觉察这周还没有陪伴孩子阅读？</div>
          <img src={`${IMG}1222_event/event_7.jpg`} className="img" alt="图片"/>
          <div className="desc">伴读打卡成功，可以贴上附赠的小贴纸，也可以写上几句伴读感言，让这本周历，记载下每一刻的眷恋温柔。</div>
          <div className="desc">这份极具收藏意义的礼物，</div>
          <div className="desc">小朋友大朋友是不是都想要！</div>

          <div className="desc red">限时五折！仅售99元。</div>
          <div className="desc red"> 1月1号后，立即恢复原价199元！</div>
          <div className="desc red">一个小玩具的价格，每周都能收获一点小快乐~</div>

          <div className="bottom-btn" onClick={() => this.toBuy()}>点击购买</div>
        </div>

      </div>
    )
  }
}

export default EventWeb

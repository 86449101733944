const PM_LIST = [
  {
    id: 1,
    url: '',
  },
  {
    id: 2,
    url: 'https://cdn.kangarooread.com/video/pm_2.mp4',
    title: '如何领取书袋'
  },
  {
    id: 3,
    url: 'https://cdn.kangarooread.com/video/pm_3.mp4',
    title: '如何预约快递、归还书袋'
  },
  {
    id: 4,
    url: 'https://cdn.kangarooread.com/video/pm_4.mp4',
    title: '如何将谷兜兜配网'
  },
  {
    id: 5,
    url: 'https://cdn.kangarooread.com/video/pm_5.mp4',
    title: '如何使用谷兜兜进行故事配音'
  }
]

export default PM_LIST

import React, {Component, Fragment} from "react";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import emotionData from '../../lib/emotionData'
import './index.less'

@inject('store')
@observer
class ExpandPage extends Component {
  @observable id = ''
  @observable detail = {}

  UNSAFE_componentWillMount() {
    document.title = "情绪伙伴卡片使用指南"
  }

  async componentDidMount() {
    const {id} = this.props.match.params
    this.id = id * 1
    await this.getContent()
  }

  /**
   * @desc 获取该页面的富文本内容
   * @returns {Promise<void>}
   */
  getContent = async () => {
    const {showLoading, hideLoading} = this.props.store
    showLoading('加载中...')
    const item = emotionData.find(it => it.id === this.id)
    item.keyWordStr = item.keyWords.join(' / ')
    this.detail = {...item}
    hideLoading()
  }

  render() {
    const {img, desc, keyWordStr, longDesc} = this.detail
    return (
      <div className="emotion-card-wrapper">
        <img className="card-img" alt="" src={img}/>
        {desc && desc.map((it, idx) => (
          it === '\n'
            ? <div className="desc-blank" key={idx}/>
            : <div className="desc" key={idx}>{it}</div>
        ))}
        <div className="desc-blank"/>
        <div className="solid-line">
          <div className="line"/>
        </div>
        <div className="desc-blank"/>
        <div className="key-words-wrapper">
          {keyWordStr}
        </div>
        <div className="desc-blank"/>
        <div className="long-desc">{longDesc}</div>
        <div className="desc-blank"/>
        <div className="author">—— 如花大叔</div>
        <div className="desc-blank"/>
        <div className="solid-line">
          <div className="line"/>
        </div>
        <div className="tip">权利声明：</div>
        <div className="tip">本文图形及文字，为如花大叔原创内容。</div>
        <div className="tip">如花大叔对上述内容拥有完整著作权。</div>
        <div className="desc-blank"/>
      </div>
    )
  }
}

export default ExpandPage
import React, { Component, Fragment } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import SwipeableViews from 'react-swipeable-views'
import './index.less'
import { storage, env } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import serv from '../../lib/api'

@inject('store')
@observer
class NewbieTask extends Component {
  @observable env = 'app'
  @observable userInfo = {}
  @observable title = ''
  @observable subTitle = ''
  @observable currentLesson = 0
  @observable lessonList = []
  @observable pointList = []
  @observable rewardList = []
  @observable missionComplete = false
  @observable count = 0

  componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    showLoading('加载中...')
    // 动态修改title
    document.title = '新人专享'
    // 获取运行环境
    this.getEnv()
    setTimeout(() => {
      hideLoading()
    }, 1000)
  }

  componentWillUnmount() {
  }

  @action
  getEnv = () => {
    if (bridge.isAppEnv()) {
      this.env = 'app'
      this.initAppData()
    } else {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.env = 'mp'
            this.initMpData()
          } else {
            this.env = 'h5'
            this.initH5Data()
          }
        })
      } else {
        this.env = 'h5'
        this.initH5Data()
      }
    }
  }

  initAppData = () => {
    bridge.getUserInfoFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getNewbieInfo()
      } else {
        this.loginInApp()
      }
    })
  }

  /**
   * @desc APP 登录时间通知
   * @memberof NewbieTask
   */
  loginInApp = () => {
    bridge.loginFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getNewbieInfo()
      } else {
        this.loginInApp()
      }
    })
  }

  initMpData = () => {
    try {
      const token = Util.getParams('token')
      Util.setLocalStorage(
        storage.userInfo,
        JSON.stringify({ accountVo: { token: token || '' } })
      )
      if (token) {
        this.fetchUser()
        setTimeout(() => {
          this.getNewbieInfo()
        }, 300)
      } else {
        wx.miniProgram.navigateTo({
          url: '/pages/login/index'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  initH5Data = () => {
    const token = Util.getParams('token')
    Util.setLocalStorage(
      storage.userInfo,
      JSON.stringify({ accountVo: { token: token || '' } })
    )
    if (token) {
      this.fetchUser()
      setTimeout(() => {
        this.getNewbieInfo()
      }, 300)
    }
  }

  /**
   * @desc 获取活动主页信息
   * @memberof NewbieTask
   */
  getNewbieInfo = async () => {
    const res = await serv.getNewbieInfo()
    if (res) {
      const { data } = res
      if (data) {
        const { pageInfo, activeLoad } = data || {}
        if (activeLoad && activeLoad === '5-1') {
          this.missionComplete = true
        }
        let [cl, csl] = activeLoad && activeLoad.split('-')
        cl = cl * 1
        csl = csl && csl.split(',')
        csl = csl && csl.map((it) => it * 1)
        const { pointList, rewardList, lesson, title, subTitle } =
        pageInfo || {}
        const { list } = lesson || {}
        this.lessonList = [...(list || [])]
        this.currentLesson = this.lessonList.findIndex((i) => i.id === cl)
        this.lessonList.forEach((it) => {
          it.missionList.forEach((i, idx) => {
            i.id = idx + 1
          })
        })
        this.lessonList.forEach((it) => {
          it.missionList.forEach((i) => {
            if (it.id < cl) {
              i.status = 2
            } else if (it.id === cl) {
              i.status = csl && csl.some((v) => v === i.id) ? 2 : 1
            } else {
              i.status = 1
            }
          })
        })
        // 判断 大课的状态  1：待开始  2：未开始  3：已完成  4：明日解锁
        this.lessonList.forEach((it, idx) => {
          // 小于今日 默认全部完成
          if (it.id < cl) {
            it.status = 3
          }
          // 大于今日 默认待开始
          if (it.id > cl) {
            it.status = 2
          }
          if (it.id === cl) {
            // 今日的情况
            const allFinshed = it.missionList.filter((i) =>
              csl.some((v) => v === i.id)
            )
            it.status = allFinshed.length === it.missionList.length ? 3 : 1
          }
          if (idx - 1 === this.currentLesson) {
            // 明日的情况单独判断
            const allFinshed = this.lessonList[
              this.currentLesson
              ].missionList.filter((i) => csl.some((v) => v === i.id))
            it.status =
              allFinshed.length ===
              this.lessonList[this.currentLesson].missionList.length
                ? 4
                : 2
          }
        })
        this.pointList = [...(pointList || [])]
        this.pointList.forEach((it) => {
          if (it.id < cl) {
            it.status = 2
          }
          if (it.id === cl) {
            const allFinshed = this.lessonList[
              this.currentLesson
              ].missionList.filter((i) => csl.some((v) => v === i.id))
            it.status =
              allFinshed.length ===
              this.lessonList[this.currentLesson].missionList.length
                ? 2
                : 1
          }
          if (it.id > cl) {
            it.status = 1
          }
        })
        this.rewardList = [...(rewardList || [])]
        this.title = title || ''
        this.subTitle = subTitle || ''
      } else {
        Util.removeLocalStorage(storage.userInfo)
      }
    }
  }

  /**
   * @desc 获取用户信息（小程序及H5使用）
   * @memberof NewbieTask
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 切换lessonSwiper
   * @memberof NewbieTask
   */
  @action
  changeSwiper = (index) => {
    this.currentLesson = index
  }

  /**
   * @desc 新人专享任务开始任务
   * @param {string} [activeLesson='']
   * @param {*} [{ id: activeSubLesson, type, params, status }=it]
   * @memberof NewbieTask
   */
  @action
  startMission = (
    activeLesson = '',
    { id: activeSubLesson, type, params, status } = it
  ) => {
    // if (status === 2) return
    let api = '',
      navigate = ''
    switch (type) {
      case 1:
        break
      case 2:
        api = 'appClockIn'
        navigate = '/subPkg/main/pages/mission/index'
        break
      case 3:
        api = 'appTakeNotes'
        navigate = '/subPkg/main/pages/release-notes/index'
        break
      case 4:
        api = 'appDiscussTopic'
        navigate = '/pages/topic-detail/index'
        break
      case 5:
        api = 'appTakeLesson'
        navigate = '/subPkg/extend/pages/keep-detail/index'
        break
      case 6:
        api = 'appPrepareLesson'
        navigate = '/subPkg/main/pages/book-house/index'
        break
      default:
        break
    }

    // app 环境
    if (this.env === 'app') {
      const paramList = params && params.split('&')
      const p = {}
      if (paramList && paramList.length > 0) {
        paramList.forEach((it) => {
          const i = it.split('=')
          p[i[0]] = i[1]
        })
      }
      // if (api === 'appTakeLesson') {
      //   this.completeMission({ activeLesson, activeSubLesson })
      // }
      bridge[api](p || '{}').then((res) => {
        console.log(`获取app回调结果 => ${res}`)
        // if (res) {
        // 	console.log('开始完成任务方法')
        // 	this.completeMission({ activeLesson, activeSubLesson })
        // }
        // this.completeMission({ activeLesson, activeSubLesson })
      })
      this.completeMission({ activeLesson, activeSubLesson })
    }

    // 小程序环境
    if (this.env === 'mp') {
      const url = params
        ? `${navigate}?${params}&task=newbie&activeLesson=${activeLesson}&activeSubLesson=${activeSubLesson}&postSource=12`
        : `${navigate}?task=newbie&activeLesson=${activeLesson}&activeSubLesson=${activeSubLesson}&postSource=12`
      wx.miniProgram.navigateTo({ url })
    }
  }

  /**
   * @desc 完成任务
   * @memberof NewbieTask
   */
  completeMission = (params = {}) => {
    console.log('开始完成任务接口')
    serv.commitNewUserActivity(params).then((res) => {
      console.log(`获取完成任务response => ${JSON.stringify(res)}`)
      if (res) {
        console.log('开始刷新数据')
        this.getNewbieInfo()
      }
    })
  }

  /**
   * @desc 监听视频播放事件
   * @memberof NewbieTask
   */
  getVideo = (status) => {
    if (status === 1) {
      this.completeMission({ activeLesson: 1, activeSubLesson: 1 })
    }
  }

  toStart = () => {
    // app
    if (this.env === 'app') {
      bridge.appNavigate({ id: 15 })
    }
    // 小程序
    if (this.env === 'mp') {
      wx.miniProgram.reLaunch({ url: '/pages/read/index' })
    }
  }

  render() {
    return (
      <Fragment>
        <div className='newbie-wrapper'>
          <h4 className='title'>{this.title}</h4>
          <p className='sub-title'>{this.subTitle}</p>
          {/* card-wrapper */}
          <div className='card-wrapper'>
            {this.rewardList.map((it) => (
              <div className='card' key={it.id}>
                <p className='award-label'>{it.label}</p>
                <img src={it.icon} className='award-img' alt=''/>
                <h4 className='award-title'>{it.title}</h4>
              </div>
            ))}
          </div>
          {/* point-wrapper */}
          <div className='award-list'>
            {this.pointList.map((it) => (
              <div className='item' key={it.id}>
                {it.status === 1 ? <div className='unlock'></div> : null}
                <div className='item-label'>+{it.point}积分</div>
                <div className='item-content'>
                  <h4 className='item-title'>{it.title}</h4>
                  <div className={`status-tag ${it.status === 1 ? 'sp' : ''}`}>
                    {it.status === 1 ? '未解锁' : '已完成'}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* lesson-swiper */}
          <div className='swiper-wrapper'>
            <SwipeableViews
              className='swiper'
              slideClassName='slide-item'
              index={this.currentLesson * 1}
              onChangeIndex={this.changeSwiper}>
              {this.lessonList.map((item, index) => (
                <div className='item' key={item.id}>
                  {item.status === 2 ? (
                    <div className='unlock'>完成上一课后再来解锁哦～</div>
                  ) : null}
                  {item.status === 4 ? (
                    <div className='unlock'>明日解锁哦～</div>
                  ) : null}
                  {item.status === 3 ? (
                    <img
                      className='finish-icon'
                      src={`${env.IMG}lesson_finish_icon.png`}
                      alt=''
                    />
                  ) : null}
                  <div className='lesson-title'>
                    <h4 className='text'>{item.title || ''}</h4>
                  </div>
                  <div className='lesson-content'>
                    <p className='description'>{item.description || ''}</p>
                    <div className='mission-list'>
                      {item.missionList.map((it, idx) =>
                        it.type === 1 ? (
                          <video
                            className='video'
                            controls
                            poster={it.poster}
                            key={`${item.id}-${it.mId}`}
                            onPlaying={() => this.getVideo(it.status)}>
                            <source src={it.src} type='video/mp4'></source>
                          </video>
                        ) : (
                          <div className='m-item' key={`${item.id}-${it.mId}`}>
                            <div className='m-title-wrapper'>
                              <span className='idx'>{idx + 1}</span>
                              <span className='text'>{it.title}</span>
                              <span
                                className={`m-btn ${
                                  it.status === 2 ? 'finished' : ''
                                }`}
                                onClick={() => this.startMission(item.id, it)}>
																{it.status === 2 ? '已完成' : it.btnText}
															</span>
                            </div>
                            <div className='m-desc'>{it.desc}</div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className='swiper-tip'>左滑进入下一课程</div>
                </div>
              ))}
            </SwipeableViews>
            <div className='dots'>
              {this.lessonList.map((it, idx) => (
                <span
                  className={`dot${
                    idx === this.currentLesson ? ' active' : ''
                  }`}
                  key={idx}></span>
              ))}
            </div>
          </div>
          <div className='tip'>
            <p className='text'>
              请关注「好奇说绘本」公众号，不错过任何平台提醒
            </p>
          </div>
        </div>
        {this.missionComplete ? (
          <div className='newbie-mission-complete'>
            <div className='mask'></div>
            <div className='content'>
              <h4 className='modal-title'>
								<span className='text'>
									<span className='left'/>
									恭喜你完成新手任务
									<span className='right'/>
								</span>
              </h4>
              <img
                src={`${env.IMG}newbie/newbie_finished_icon.png`}
                className='modal-icon'
                alt=''
              />
              <div className='modal-desc'>祝你在好奇说绘本有一段完美的历程</div>
              <div className='modal-btn' onClick={() => this.toStart()}>
                开启好奇说奇妙之旅
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default NewbieTask

import React from 'react'
import './page_8.less'

const Page_8 = (props) => {
	const {
		avatar,
		nickname,
		userTitleImg,
		userTitleDesc,
		onShareWechat,
		onShareImg
	} = props || {}

	const shareImg = () => {
		onShareImg && onShareImg()
	}

	const shareWechat = () => {
		onShareWechat && onShareWechat()
	}

	return (
		<>
			<picture className='yr-page-8-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_8_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_8_bg.png'
					className='yr-page-8-bg-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-8-card-wrapper'>
				<picture className='yr-page-8-card'>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_8_card.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_page_8_card.png'
						className='yr-page-8-card-img'
						alt=''
					/>
				</picture>
				<img src={avatar || ''} className='avatar' alt='头像' />
				<div className='nickname'>{nickname || ''}</div>
				<div className='label'>2022年，你和孩子在好奇说是：</div>
				<img src={userTitleImg || ''} className='user-title' alt='头衔' />
				<div className='tip'>{userTitleDesc || ''}</div>
			</div>
			<div className='yr-page-8-btn-wrapper'>
				<picture className='yr-page-8-btn' onClick={shareImg}>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_8_btn_1.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_page_8_btn_1.png'
						className='yr-page-8-btn-img'
						alt=''
					/>
				</picture>
				<picture className='yr-page-8-btn' onClick={shareWechat}>
					<source
						srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_8_btn_2.webp'
						type='image/webp'
					/>
					<img
						src='https://cdn.kangarooread.com/resource/year_report/yr_page_8_btn_2.png'
						className='yr-page-8-btn-img'
						alt=''
					/>
				</picture>
			</div>
		</>
	)
}

export default Page_8

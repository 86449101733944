import React, { Component } from 'react'

export default class Card extends Component {

  render() {
    const { children, bg } = this.props

    return <div
      className="sp-card-normal"
      style={{
        background: bg ? bg : '#fff'
      }}
    >
      {children}
    </div>
  }
}

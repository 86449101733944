import React, { Fragment } from 'react'

export default function EventItem(props) {
  const { data, onLike, onDetail } = props
  const { avatar, goodCount, indexId, ownFlag, picList, postId, title, userName, myLiked } = data || {}

  const like = (e) => {
    e.stopPropagation()
    onLike && onLike(postId)
  }

  const toDetail = () => {
    onDetail && onDetail(postId)
  }

  return (
    <div className={`item ${ownFlag ? 'mine' : ''}`} onClick={() => toDetail()}>
      <div className="tag"><span className="important">{indexId}</span>号</div>
      <div className="img-wrapper">
        <img src={picList && picList[0]} className="pic" alt=""/>
      </div>
      <div className="title">{title || ''}</div>
      <div className="no-wrapper">
        {ownFlag
          ? <Fragment>
            <i className="my-icon"/>
            <span className="user-name">我的作品</span>
          </Fragment>
          : <Fragment>
            <img src={avatar || ''} className="avatar" alt=""/>
            <span className="user-name">{userName || ''}</span>
          </Fragment>
        }

      </div>
      <div className={`vote-btn ${myLiked ? 'liked' : ''}`} onClick={(e) => like(e)}>{myLiked ? '已' : ''}为TA投票</div>
      {/*<div className="vote-count">{goodCount || 0}票</div>*/}
      <div className="vote-count">- 票</div>
    </div>
  )
}

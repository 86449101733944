import React, { Component } from 'react'
import { env, isProd, storage } from '../../lib/conf'
import './index.less'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'
import ActionSheet from '../../components/ActionSheet'
import serv from '../../lib/api'
import moment from 'moment'
import Item from './components/Item'
import List from '../../components/List'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import wx from 'weixin-js-sdk'

@inject('store')
@observer
class SendFlowerMain extends Component {
	@observable campId = 0
	@observable icon = ''
	@observable detail = {}
	@observable visible = false
	@observable form = {
		amount: 1,
		message: ''
	}
	@observable loaded = false
	@observable list = {
		list: [],
		hasMore: false,
		pageSize: 10,
		pageNum: 1
	}
	@observable currentUser = {}
	@observable submitLoading = false

	initData = async () => {
		const { showPageLoading, hidePageLoading } = this.props.store
		showPageLoading()
		this.initShareInfo()
		await this.getFlowerConfig()
		await this.getDetail()
		await this.getList()
		hidePageLoading()
	}

	/**
	 * @description 获取小红花页面的配置
	 * @returns {Promise<void>}
	 */
	getFlowerConfig = async () => {
		try {
			const res = await serv.getFlowerConfig()
			if (res) {
				const { data } = res
				const { campId, icon } = data || {}
				this.campId = campId || 0
				this.icon = icon || ''
			}
		} catch (err) {
			console.log(err)
		}
	}

	/**
	 * @description 获取小红花页面的详情
	 * @returns {Promise<void>}
	 */
	getDetail = async () => {
		try {
			const res = await serv.getFlowerHomepage()
			if (res) {
				const { data } = res
				this.detail = data || {}
			}
		} catch (err) {
			console.log(err)
		}
	}

	/**
	 * @description 获取我的同学列表
	 * @returns {Promise<void>}
	 */
	getList = async () => {
		this.loaded = false
		const { pageNum, pageSize, list: oldList } = this.list
		const params = { pageNum, pageSize, campId: this.campId }
		try {
			const res = await serv.getClassmateInfoList(params)
			if (res) {
				const { data, hasMore } = res
				this.list.list = oldList.concat(data || [])
				this.list.hasMore = hasMore
				this.list.pageNum = hasMore ? pageNum + 1 : pageNum
			}
		} catch (err) {
			console.log(err)
		}
		this.loaded = true
	}

	/**
	 * @description 初始化分享信息
	 */
	initShareInfo = () => {
		let _user = Util.getLocalStorage(storage.userInfo)
		_user = _user && JSON.parse(_user)
		const { id: userId } = _user
		// 本页面需要转发
		bridge.jsShowShareWechat(true)
		bridge.jsShareWechat({
			title: '送你一朵小红花~',
			thumb:
				'https://cdn.kangarooread.com/mp-assets/send_flower_main_share_img.png',
			path: `subPkg/extend/pages/web-link/index?src=https://web${
				isProd ? '' : '-test'
			}.kangarooread.com/#/send-flower-main&shareType=open&shareId=${userId}`
		})
		wx.miniProgram.postMessage({
			data: {
				title: '送你一朵小红花~',
				imageUrl:
					'https://cdn.kangarooread.com/mp-assets/send_flower_main_share_img.png',
				path: `/subPkg/extend/pages/web-link/index?src=https://web${
					isProd ? '' : '-test'
				}.kangarooread.com/#/send-flower-main&shareType=open&shareId=${userId}`
			}
		})
	}

	componentDidMount() {
		document.title = '我的班级'
		this.initData()
	}

	@action
	showSendModal = (_user) => {
		this.currentUser = _user
		this.visible = true
	}

	@action
	closeSendModal = () => {
		this.visible = false
		setTimeout(() => {
			this.currentUser = {}
			this.form.message = ''
			this.form.amount = 1
		}, 300)
	}

	@action
	handleInput = (e, type) => {
		this.form[type] = e.target.value
	}

	handleSubmit = async () => {
		this.submitLoading = true
		const { showToast } = this.props.store
		const { userId: receiveUserId } = this.currentUser
		const { amount, message } = this.form
		const { availableCount } = this.detail
		if (!receiveUserId) {
			showToast('缺少用户ID')
			this.submitLoading = false
			return
		}
		if (!amount || amount < 1) {
			showToast('赠送数量最小为1朵')
			this.submitLoading = false
			return
		}
		if (amount && amount > availableCount) {
			showToast('可用数量不足')
			this.submitLoading = false
			return
		}
		if (!message) {
			showToast('请填写寄语')
			this.submitLoading = false
			return
		}
		const params = {
			...this.form,
			receiveUserId
		}
		try {
			const res = await serv.sendFlower(params)
			if (res) {
				const { data } = res
				if (data) {
					showToast('赠送成功')
					await this.getDetail()
					this.closeSendModal()
				} else {
					showToast('赠送失败')
				}
			}
		} catch (err) {
			console.log(err)
		}
		this.submitLoading = false
	}

	toRecord = (type) => {
		let _user = Util.getLocalStorage(storage.userInfo)
		_user = _user && JSON.parse(_user)
		const { id } = _user
		const { push } = this.props.history
		push(`/send-flower-list/${type}/${id}?from=main`)
	}

	render() {
		const {
			availableCount,
			avatar,
			expiredCount,
			expiredTime,
			groupTitle,
			receiveCount,
			sendCount
		} = this.detail

		const { nickname: cNickname, avatar: cAvatar } = this.currentUser

		return (
			<div className='send-flower-main'>
				<img src={`${env.IMG}send_flower_main_bg.png`} className='bg' alt='' />
				<img src={avatar} className='user-avatar' alt='' />
				<div className='card-info'>
					<div className='group-title'>{groupTitle || '-'}</div>
					<div className='f-card-item' onClick={() => this.toRecord(1)}>
						<div className='f-card-value'>{receiveCount || 0}</div>
						<div className='f-card-label'>收到</div>
					</div>
					<div className='f-card-item' onClick={() => this.toRecord(2)}>
						<div className='f-card-value'>{sendCount || 0}</div>
						<div className='f-card-label'>送出</div>
					</div>
					<div className='f-card-item'>
						<div className='f-card-value'>{availableCount || 0}</div>
						<div className='f-card-label'>流动小红花</div>
						{expiredCount && (
							<div className='f-card-desc'>
								{expiredCount || 0}朵将于
								{moment(expiredTime).format('MM月DD日HH点')}过期
							</div>
						)}
					</div>
				</div>
				<div className='list-card-info'>
					<div className='list-title'>我的同学</div>
					<div className='list'>
						<List
							next={this.getList}
							loaded={this.loaded}
							dataSource={this.list.list || []}
							hasMore={this.list.hasMore}
							endStr='到底啦~'>
							{this.list.list &&
								this.list.list.map((it) => (
									<Item onSend={this.showSendModal} {...it} key={it.userId} />
								))}
						</List>
					</div>
				</div>

				{/*赠送弹框*/}
				<ActionSheet visible={this.visible} onClose={this.closeSendModal}>
					<div className='modal-title'>
						<span className='text'>送</span>
						<img src={this.icon} className='flower-icon' alt='' />
						<span className='text'>给</span>
						<div className='user-info'>
							<img src={cAvatar || ''} className='avatar' alt='' />
							<div className='name'>{cNickname || ''}</div>
						</div>
					</div>
					<div className='form-item center'>
						<span className='label'>数量</span>
						<input
							className='count-input'
							type='number'
							value={this.form.amount}
							min={1}
							max={availableCount}
							onChange={(e) => this.handleInput(e, 'amount')}
						/>
					</div>
					<div className='form-item'>
						<span className='label'>寄语</span>
						<textarea
							className='send-word-input'
							value={this.form.message}
							placeholder='请填写若干字的寄语，TA能看到并更受鼓舞哦'
							maxLength={200}
							onChange={(e) => this.handleInput(e, 'message')}
						/>
						<span className='text-count-tip'>
							{this.form.message.length || 0} / 200
						</span>
					</div>
					<button
						className='submit-btn'
						disabled={this.submitLoading}
						onClick={this.handleSubmit}>
						赠 送 {this.submitLoading ? '中...' : ''}
					</button>
				</ActionSheet>
			</div>
		)
	}
}

export default SendFlowerMain

import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade } from 'swiper'
import PageStart from './page_start'
import serv from '../../../lib/api'
import ProgressBar from 'progressbar.js'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../../store'
import wx from 'weixin-js-sdk'
import { bridge } from '../../../lib/bridge'
import { isProd } from '../../../lib/conf'
import html2canvas from 'html2canvas'
import 'swiper/swiper.less'
import 'swiper/modules/effect-fade/effect-fade.less'
import './index.less'

import Page_1 from './page_1'
import Page_2 from './page_2'
import Page_3 from './page_3'
import Page_4 from './page_4'
import Page_5 from './page_5'
import Page_6 from './page_6'
import Page_7 from './page_7'
import Page_8 from './page_8'
import Page_course from './page_course'

export default () => {
	const store = useLocalStore(() => CommonStore)
	const [loaded, setLoaded] = useState(false)
	const [detail, setDetail] = useState(null)
	const [allowSlideNext, setAllowSlideNext] = useState(true)
	const [swiperInstance, setSwiperInstance] = useState(null)
	const [qrCode, setQrCode] = useState('')
	const progressRef = useRef(null)

	const {
		env: runtimeEnv,
		userInfo,
		showModal,
		closeModal,
		showLoading,
		hideLoading
	} = store
	document.title = '年度报告'

	const getDetail = async () => {
		try {
			const res = await serv.getAnnualReport()
			if (res) {
				const { data } = res
				setDetail(data || {})
			}
		} catch (err) {
			console.log(err)
		}
	}

	const initData = async () => {
		initShareInfo()
		const line = new ProgressBar.Line('#progress-bar', {
			strokeWidth: 2.1,
			color: '#FFD659',
			text: {
				value: '正在为您生成年度报告...',
				className: 'progress-text',
				style: {
					color: '#999',
					position: 'absolute',
					left: '50%',
					top: '20px',
					padding: 0,
					margin: 0,
					transform: {
						prefix: true,
						value: 'translateX(-50%)'
					}
				}
			},
			step: (_, shape) => {
				shape.setText(
					`正在为您生成年度报告... ${Math.round(shape.value() * 100) + ' %'}`
				)
			}
		})
		await getDetail()
		line.animate(
			1,
			{
				duration: 2000
			},
			() => {
				setLoaded(true)
			}
		)
		await getQrcode()
	}

	useEffect(() => {
		if (!detail) {
			initData()
		}
	}, [detail])

	const slideChange = (sw) => {
		if (sw.visibleSlides[0].className.indexOf('slide-1') > -1) {
			setAllowSlideNext(false)
		} else {
			setAllowSlideNext(true)
		}
	}

	const clickBtn = (type) => {
		switch (type) {
			case 1:
				if (runtimeEnv === 'app') {
					bridge.appNavigate({ id: 1 })
				} else if (runtimeEnv === 'mp') {
					wx.miniProgram.navigateTo({
						url: '/subPkg/main/pages/new-subscribe-detail/index'
					})
				}
				break
			case 2:
				setAllowSlideNext(true)
				setTimeout(() => {
					swiperInstance.slideNext()
				}, 25)
				break
			case 3:
				setAllowSlideNext(true)
				setTimeout(() => {
					swiperInstance.slideNext()
				}, 25)
			default:
				break
		}
	}

	const selectCourse = (type) => {
		const { babyCampaignId01, babyCampaignId02, babyFlowCampaignType } =
			detail || {}
		let url =
			babyFlowCampaignType === 1
				? '/subPkg/extend/pages/keep-detail/index'
				: '/subPkg/main/pages/price-get-detail/index'
		const rt = babyFlowCampaignType === 1 ? 27 : 107
		let params = ''
		switch (type) {
			case 1:
				url += `?id=${babyCampaignId01}&type=2`
				params = `id=${babyCampaignId01}&type=2`
				break
			case 2:
				url += `?id=${babyCampaignId02}&type=2`
				params = `id=${babyCampaignId02}&type=2`
				break
			default:
				break
		}
		if (runtimeEnv === 'app') {
			bridge.appNavigate({ id: rt, params })
		} else if (runtimeEnv === 'mp') {
			wx.miniProgram.navigateTo({
				url
			})
		}
	}

	const initShareInfo = () => {
		const { id: userId } = userInfo || {}
		wx.miniProgram.postMessage({
			data: {
				title: '2022-我的亲子阅读记忆',
				imageUrl:
					'https://cdn.kangarooread.com/resource/year_report/yr_wechat_card_img.jpg',
				path: `/subPkg/extend/pages/web-link/index?src=https://web${
					isProd ? '' : '-test'
				}.kangarooread.com/#/year-report&shareType=open&shareId=${userId}`
			}
		})
	}

	const shareWechat = () => {
		const { id: userId } = userInfo || {}
		if (runtimeEnv === 'app') {
			bridge.appShareWechat({
				title: '2022-我的亲子阅读记忆',
				thumb:
					'https://cdn.kangarooread.com/resource/year_report/yr_wechat_card_img.jpg',
				path: `subPkg/extend/pages/web-link/index?src=https://web${
					isProd ? '' : '-test'
				}.kangarooread.com/#/year-report&shareType=open&shareId=${userId}`
			})
		} else if (runtimeEnv === 'mp') {
			const options = {
				title: '转发提示',
				content: `请点击右上角 "..." 进行转发`,
				okBtnText: '知道了',
				showCancelBtn: false,
				gravity: 0,
				onConfirm: () => {
					closeModal()
				}
			}
			showModal(options)
			serv.dataTrackingStorage({
				current: 'EVENTREPORTSHAREWECHAT',
				content: JSON.stringify({ userId })
			})
		}
	}

	const getQrcode = async () => {
		const { id: userId } = userInfo || {}
		const params = {
			path: 'subPkg/extend/pages/web-link/index',
			scene: `m;6;${userId || ''};open`,
			type: 20
		}
		try {
			const res = await serv.getQrCode(params)
			if (res) {
				const { cdnUrl } = res.data || {}
				setQrCode(cdnUrl)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const shareImg = () => {
		const node = document.getElementById('yr-share-poster')
		showLoading('加载中...')
		html2canvas(node, {
			allowTaint: true,
			useCORS: true,
			scrollY: 0
		})
			.then((canvas) => {
				if (canvas) {
					const src = canvas.toDataURL('image/jpg')
					if (runtimeEnv === 'app') {
						bridge.appShareBase64(src)
						setTimeout(() => {
							hideLoading()
						}, 200)
					} else if (runtimeEnv === 'mp') {
						wx.miniProgram.navigateTo({
							url: `/subPkg/extend/pages/img-preview-page/index?type=eventYearReport`,
							complete: () => {
								hideLoading()
							}
						})
					}
				}
			})
			.catch((err) => {
				console.log(err)
				hideLoading()
			})
		serv.dataTrackingStorage({
			current: 'EVENTREPORTSHAREIMG',
			content: JSON.stringify({ userId })
		})
	}

	const {
		condition, // 流程type

		subEndMonth, // 订阅服务结束月份
		subStartMonth, // 订阅服务开始月份
		year, // 年
		registerDay, // 已注册天数

		subBookCount, // 订阅绘本数量
		subBookMarketPrice, // 订阅绘本市场价

		subBookHeight, // 订阅绘本高度
		avgBookCount, // 平均阅读绘本数量

		accompanyMinute, // 陪伴分钟
		maxPurchaseBookMonth, // 最大留书数量的月份
		maxPurchaseBookCount, // 最大留书数量

		maxGddReadOneBookName, //  最大古兜哦度阅读的一本书
		maxGddReadOneBookCount, // 最大谷兜兜阅读的一本书次数
		maxGddRecBookCount, // 最大谷兜兜录制绘本数量

		citySubCount, // 城市订阅数量

		mamaStartMonth, // 故事妈妈开始月份
		mamaCourseTitle, // 故事妈妈课程名称
		jinjieStartMonth, // 进阶课开始月份

		userId, // 用户Id
		avatar, // 头像
		nickname, // 昵称
		userTitleImg, // 用户头衔
		userTitleDesc // 头衔说明
	} = detail || {}

	return (
		<>
			{loaded ? (
				<Swiper
					modules={[EffectFade]}
					effect='fade'
					speed={500}
					onSlideChange={slideChange}
					onSwiper={(swiper) => setSwiperInstance(swiper)}
					allowSlideNext={allowSlideNext}>
					<SwiperSlide className='slide-start' key={0}>
						<div className='slide'>
							<PageStart />
						</div>
					</SwiperSlide>
					{(condition === 1 || condition === 3 || condition === 4) && (
						<SwiperSlide className='slide-1' key={1}>
							<div className='slide'>
								<Page_1
									condition={condition}
									year={year}
									subStartMonth={subStartMonth}
									subEndMonth={subEndMonth}
									registerDay={registerDay}
									onSelect={clickBtn}
								/>
							</div>
						</SwiperSlide>
					)}
					{(condition === 1 || condition === 2) && (
						<>
							<SwiperSlide className='slide-2' key={2}>
								<div className='slide'>
									<Page_2
										nickname={nickname}
										subBookCount={subBookCount}
										subBookMarketPrice={subBookMarketPrice}
									/>
								</div>
							</SwiperSlide>
							<SwiperSlide className='slide-3' key={3}>
								<div className='slide'>
									<Page_3
										subBookHeight={subBookHeight}
										avgBookCount={avgBookCount}
									/>
								</div>
							</SwiperSlide>
							{(accompanyMinute || maxPurchaseBookCount) && (
								<SwiperSlide className='slide-4' key={4}>
									<div className='slide'>
										<Page_4
											accompanyMinute={accompanyMinute}
											maxPurchaseBookMonth={maxPurchaseBookMonth}
											maxPurchaseBookCount={maxPurchaseBookCount}
										/>
									</div>
								</SwiperSlide>
							)}
							{maxGddReadOneBookCount && (
								<SwiperSlide className='slide-5' key={5}>
									<div className='slide'>
										<Page_5
											maxGddReadOneBookName={maxGddReadOneBookName}
											maxGddReadOneBookCount={maxGddReadOneBookCount}
											maxGddRecBookCount={maxGddRecBookCount}
										/>
									</div>
								</SwiperSlide>
							)}
							{citySubCount && (
								<SwiperSlide className='slide-6' key={6}>
									<div className='slide'>
										<Page_6 citySubCount={citySubCount} />
									</div>
								</SwiperSlide>
							)}
							{(mamaStartMonth || jinjieStartMonth) && (
								<SwiperSlide className='slide-7' key={7}>
									<div className='slide'>
										<Page_7
											mamaStartMonth={mamaStartMonth}
											mamaCourseTitle={mamaCourseTitle}
											jinjieStartMonth={jinjieStartMonth}
										/>
									</div>
								</SwiperSlide>
							)}
							<SwiperSlide className='slide-8' key={8}>
								<div className='slide slide-result'>
									<Page_8
										avatar={avatar}
										nickname={nickname}
										userTitleImg={userTitleImg}
										userTitleDesc={userTitleDesc}
										onShareWechat={shareWechat}
										onShareImg={shareImg}
									/>
								</div>
							</SwiperSlide>
						</>
					)}
					{(condition === 3 || condition === 4) && (
						<>
							<SwiperSlide className='slide-course' key={9}>
								<div className='slide'>
									<Page_course onSelect={selectCourse} />
								</div>
							</SwiperSlide>
						</>
					)}
				</Swiper>
			) : (
				<div className='year-report-wrapper'>
					<div ref={progressRef} id='progress-bar' />
				</div>
			)}

			<div
				className='yr-share-poster'
				id='yr-share-poster'
				style={{ left: -1000, top: -99999 }}>
				<div className='content'>
					<img
						src={`https://cdn.kangarooread.com/resource/year_report/yr_share_post_bg.jpg?timeStamp=${new Date().getTime()}`}
						className='img'
						alt=''
						crossOrigin='anonymous'
					/>
					<img
						src={`${avatar}?timeStamp=${new Date().getTime()}`}
						className='avatar'
						alt=''
						crossOrigin='anonymous'
					/>
					<div className='nickname'>{nickname || ''}</div>
					<div className='label'>2022年，你和孩子在好奇说是：</div>
					<img
						src={`${userTitleImg || ''}?timeStamp=${new Date().getTime()}`}
						className='user-title'
						alt=''
						crossOrigin='anonymous'
					/>
					<div className='user-title-desc'>{userTitleDesc || ''}</div>
				</div>
				<div className='info-wrapper'>
					<img
						src={`${avatar}?timeStamp=${new Date().getTime()}`}
						className='avatar'
						alt=''
						crossOrigin='anonymous'
					/>
					<div className='name-wrapper'>
						<div className='name'>{nickname || ''}</div>
						<div className='desc'>邀请你查看年度阅读报告</div>
					</div>
					<img
						src={`${qrCode}?timeStamp=${new Date().getTime()}`}
						className='qr-code'
						alt=''
						crossOrigin='anonymous'
					/>
				</div>
			</div>
		</>
	)
}

import React, { Component, Fragment } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import './index.less'
import serv from '../../lib/api'

@inject('store')
@observer
class AppDownload extends Component {
  @observable env = ''
  @observable ANversion = ''
  @observable IOSversion = ''

  async componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    showLoading('加载中...')
    this.getEnv()
    await this.getAppVersion()
    hideLoading()
  }

  getAppVersion = async () => {
    try {
      const res = await serv.getAppVersion()
      if (res) {
        const { data } = res
        const { ANversion, IOSversion } = data || {}
        this.ANversion = ANversion || ''
        this.IOSversion = IOSversion || ''
      }
    } catch (err) {
      console.log(err)
    }
  }

  @action
  getEnv = () => {
    let ua = navigator.userAgent
    if (ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1) {
	    ua = navigator.userAgent.toLowerCase()
	    if (/micromessenger/i.test(ua)) {
				this.env = 'wx'
	    } else {
		    this.env = 'android'
	    }
    } else if (ua.indexOf('iPhone') > -1) {
      this.env = 'ios'
    } else {
      this.env = 'other'
    }
  }

  toAndroid = () => {
    window.open(
      'https://cdn.kangarooread.com/resource/apk/app-haoqigu-release.apk'
    )
  }

  toIOS = () => {
    window.open(
      'https://apps.apple.com/cn/app/%E5%A5%BD%E5%A5%87%E8%AF%B4%E7%BB%98%E6%9C%AC/id1504366151'
    )
  }

	toBrowser = () => {
		const { showToast } = this.props.store
		showToast('请点击右上角选择"在浏览器打开"后下载')
	}

  render() {
    return (
      <Fragment>
        <div className="download-wrapper">
          <img
            className="logo"
            src="https://cdn.kangarooread.com/mp-assets/app-download/icon_v2.png"
            alt="logo"
          />
          <p className="title">好奇说绘本</p>
          {this.env === 'ios'
            ? <p className="ver">最新公测版：v{this.IOSversion}</p>
            : (this.env === 'android' || this.env === 'wx')
              ? <p className="ver">最新公测版：v{this.ANversion}</p>
              : <p className="ver">安卓版：v{this.ANversion}、iOS版：v{this.IOSversion}</p>
          }
          {(this.env === 'ios' || this.env === 'other') && (
            <button className="download-btn ios" onClick={() => this.toIOS()}>
              <i className="icon"/>
              iOS客户端下载
            </button>
          )}
          {(this.env === 'android' || this.env === 'other') && (
            <button
              className="download-btn android"
              onClick={() => this.toAndroid()}
            >
              <i className="icon"/>
              安卓客户端下载
            </button>
          )}
	        {this.env === 'wx' && <button
		        className="download-btn android"
		        onClick={() => this.toBrowser()}
	        >
		        <i className="icon"/>
		        安卓客户端下载
	        </button>}
          {this.env === 'other' && <p className="desc">根据你的手机系统类型点击下载相应的客户端</p>}
          <div className="bg-2"/>
          <strong className="slogan">一起亲子阅读，陪伴点亮成长</strong>
        </div>
      </Fragment>
    )
  }
}

export default AppDownload

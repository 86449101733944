import { useEffect, useRef } from 'react'

const useUpdateEffect = (effect = () => {
}, deps = []) => {
  const flag = useRef(true)

  useEffect(() => {
    if (flag.current) {
      flag.current = false
    } else {
      return effect()
    }
  }, deps)
}

export default useUpdateEffect

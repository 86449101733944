import React from 'react'
import { env } from '../../lib/conf'

export default function NoData(props) {
  const { emptyStr } = props

  return <div className="no-data">
    <div>
      <img src={`${env.IMG}m-web/record_no_data.png`} alt=""/>
    </div>
    <div>{emptyStr || '暂时还没有相关信息哦 ~'}</div>
  </div>
}

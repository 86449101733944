import wx from 'weixin-js-sdk'
import dsBridge from 'dsbridge'
import { nativeReturnMsg, storage } from './conf'
import { Util } from './util'

const bridge = {
	// 是否在app环境内
	isAppEnv: () => {
		const ua = navigator.userAgent
		return ua.indexOf('Native_') > -1
	},

	// 是否在小程序环境内
	isMpEnv: () => {
		if (/MicroMessenger/i.test(navigator.userAgent)) {
			wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					return true
				}
				return false
			})
		}
	},

	// 从app获取富文本
	appGetRichText: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appGetRichText')) {
				dsBridge.call('appGetRichText', '{}', (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						const rs = JSON.parse(res)
						resolve(rs)
					}
				})
			} else {
				resolve()
			}
		})
	},

	// 从app获取用户信息
	getUserInfoFromApp: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appFetchUser')) {
				dsBridge.call('appFetchUser', '{}', (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						Util.setLocalStorage(storage.userInfo, res)
						const rs = JSON.parse(res)
						resolve(rs)
					}
				})
			} else {
				resolve()
			}
		})
	},

	// 从app登录
	loginFromApp: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appLogin')) {
				dsBridge.call('appLogin', '{}', (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						Util.setLocalStorage(storage.userInfo, res)
						const rs = JSON.parse(res)
						resolve(rs)
					}
				})
			} else {
				resolve()
			}
		})
	},

	// app跳转页面
	appNavigate: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appNavigate')) {
				dsBridge.call('appNavigate', JSON.stringify(params), (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						resolve(res)
					}
				})
			}
		})
	},

	appClose: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appClose')) {
				dsBridge.call('appClose', JSON.stringify(params), (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						resolve(res)
					}
				})
			}
		})
	},

	// app 打卡
	appClockIn: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appClockIn')) {
				dsBridge.call('appClockIn', '{}', (res) => {
					console.log(res)
					if (res) {
						const { result } = JSON.parse(res || '{}')
						resolve(result === 'ok')
					} else {
						resolve()
					}
				})
			} else {
				resolve()
			}
		})
	},

	// app写笔记
	appTakeNotes: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appTakeNotes')) {
				dsBridge.call('appTakeNotes', '{}', (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	// app话题选择
	appDiscussTopic: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appDiscussTopic')) {
				dsBridge.call('appDiscussTopic', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	// app听课
	appTakeLesson: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appTakeLesson')) {
				dsBridge.call('appTakeLesson', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	// app伴读预习
	appPrepareLesson: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appPrepareLesson')) {
				dsBridge.call('appPrepareLesson', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	// app更新宝宝信息
	appUpdateBabyInfo: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appUpdateBabyInfo')) {
				dsBridge.call('appUpdateBabyInfo', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	// app 更新宝宝信息（进入激活样式页）
	appUpdateBabyInfoSp: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appUpdateBabyInfoSp')) {
				dsBridge.call('appUpdateBabyInfoSp', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	/**
	 * @description 预览大图
	 * @param params
	 * @returns {Promise<boolean>}
	 */
	appImagePreview: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appImagePreview')) {
				console.log('进入预览请求')
				dsBridge.call('appImagePreview', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				resolve()
			}
		})
	},

	/**
	 * @description 预览大图列表
	 * @param params
	 * @returns {Promise<unknown>}
	 */
	appImagePreviewList: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appImagePreviewList')) {
				dsBridge.call('appImagePreviewList', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				reject()
			}
		})
	},

	/**
	 * @description 下载文件（图片、视频）至相册
	 * @param params
	 * @returns {Promise<unknown>}
	 */
	appDownloadImages: (params) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appDownloadImages')) {
				dsBridge.call('appDownloadImages', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				reject()
			}
		})
	},

	/**
	 * @description 唤起原生端modal框
	 * @param title string 标题
	 * @param content string 内容
	 * @param okBtnText string 确认按钮文案
	 * @param cancelBtnText string 取消按钮文案
	 * @param showCancelBtn string 是否显示取消按钮 'true' / 'false' 兼容ios
	 * @param gravity number 对齐方式：1 center 2 start
	 * @returns {Promise<string>}
	 */
	appShowDialog: ({
		title,
		content,
		okBtnText,
		cancelBtnText,
		showCancelBtn,
		gravity
	}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appShowDialog')) {
				dsBridge.call(
					'appShowDialog',
					JSON.stringify({
						title,
						content,
						okBtnText,
						cancelBtnText,
						showCancelBtn,
						gravity
					}),
					(res) => {
						const { result } = JSON.parse(res || '{}')
						if (result === 'fail') {
							resolve('fail')
						} else {
							resolve(result)
						}
					}
				)
			} else {
				resolve('fail')
			}
		})
	},

	/**
	 * @description 唤起原生端微信分享（小程序卡片）
	 * @param title string 标题
	 * @param thumb string 缩略图
	 * @param path string 目标路径
	 * @returns {Promise<string>}
	 */
	appShareWechat: ({ title, thumb, path }) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appShareWechat')) {
				dsBridge.call(
					'appShareWechat',
					JSON.stringify({ title, thumb, path }),
					(res) => {
						const { result } = JSON.parse(res || '{}')
						if (result === 'fail') {
							resolve('fail')
						} else {
							resolve(result)
						}
					}
				)
			} else {
				resolve('fail')
			}
		})
	},

	/**
	 * @description 获取原生端的定位信息
	 * @returns {Promise<unknown>}
	 */
	appGetLocationInfo: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appGetLocationInfo')) {
				dsBridge.call('appGetLocationInfo', '{}', (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						Util.setLocalStorage(storage.locationInfo, res)
						const rs = JSON.parse(res)
						resolve(rs)
					}
				})
			} else {
				resolve()
			}
		})
	},

	/**
	 * @description 获取APP当前的版本号
	 * @returns {Promise<unknown>}
	 */
	appCheckCurrentVersion: () => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appCheckCurrentVersion')) {
				dsBridge.call('appCheckCurrentVersion', '{}', (res) => {
					if (!res || res === '{}') {
						resolve()
					} else {
						const rs = JSON.parse(res)
						resolve(rs)
					}
				})
			} else {
				resolve()
			}
		})
	},

	/**
	 * @description 传输base64图片至APP
	 * @param base64Str
	 * @returns {Promise<unknown>}
	 */
	appShareBase64: (base64Str = '') => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appShareBase64')) {
				dsBridge.call(
					'appShareBase64',
					JSON.stringify({ base64Str }),
					(res) => {
						if (!res || res === '{}') {
							resolve()
						} else {
							const rs = JSON.parse(res)
							resolve(rs)
						}
					}
				)
			} else {
				reject()
			}
		})
	},

	/**
	 * @description 点地图选择目标地址后通知原生端
	 * @param params
	 * @returns {Promise<unknown>}
	 */
	appSetLocation: (params = {}) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appSetLocation')) {
				dsBridge.call('appSetLocation', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					resolve(result === 'ok')
				})
			} else {
				reject()
			}
		})
	},

	/**
	 * @description 设置剪贴板内容
	 * @param params
	 * @returns {Promise<unknown>}
	 */
	appSetClipboardData: (params) => {
		return new Promise((resolve, reject) => {
			if (dsBridge.hasNativeMethod('appSetClipboardData')) {
				dsBridge.call('appSetClipboardData', JSON.stringify(params), (res) => {
					const { result } = JSON.parse(res || '{}')
					if (result === 'fail') {
						reject('fail')
					} else {
						resolve(result)
					}
				})
			} else {
				reject('fail')
			}
		})
	},

	/**
	 * @description js注册用于app调用webview是否需要转发功能
	 * @param visibility 是否需要显示转发
	 */
	jsShowShareWechat: (visibility = false) => {
		dsBridge.register('jsShowShareWechat', () => {
			return JSON.stringify({
				visibility
			})
		})
	},

	/**
	 * js注册用于app调用webview转发信息
	 * @param title
	 * @param thumb
	 * @param path
	 */
	jsShareWechat: ({ title = '', thumb = '', path = '' } = {}) => {
		dsBridge.register('jsShareWechat', () => {
			return JSON.stringify({
				title,
				thumb,
				path
			})
		})
	}
}

export { bridge }

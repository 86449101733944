import React, { useEffect, useState } from 'react'
import AMapLoader from '@amap/amap-jsapi-loader'
import './index.less'

export default function AmapPage() {
  const [map, setMap] = useState(null)

  useEffect(() => {
    initAmap()
  }, [])

  const initAmap = () => {
    AMapLoader.load({
      key: 'a7b64e6734354baa480d915df89fc43c',
      version: '2.0',
      plugins: ['AMap.PlaceSearch', 'AMap.AutoComplete'],
    })
    .then(AMap => {
      const m = new AMap.Map('amap-container')
      m.on('complete', () => {
        const auto = new AMap.AutoComplete({ input: 'tip-input' })
        const placeSearch = new AMap.PlaceSearch({ map: m })  //构造地点查询类
        auto.on('select', select) //注册监听，当选中某条记录时会触发
        function select(e) {
          console.log(e)
          const { adcode, name } = e.poi || {}
          placeSearch.setCity(adcode)
          placeSearch.search(name)  //关键字查询查询
        }
      })
      setMap(map)
    })
    .catch(err => {
      console.log(err)
    })
  }

  return (
    <>
      <div
        id="amap-container"
        style={{
          width: '100vw',
          height: '100vh'
        }}
      />
      <div className="map-search-wrapper">
        <div className="label">请输入关键字</div>
        <input type="text" id="tip-input"/>
      </div>
    </>
  )
}

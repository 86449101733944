import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable, toJS } from 'mobx'
import './index.less'
import SwipeableViews from 'react-swipeable-views'
import Question from '../../components/Question'
import serv from '../../lib/api'
import { Util } from '../../lib/util'

@inject('store')
@observer
class UserQuestionnaire extends Component {
  @observable currentSwiperIndex = 0      //当前轮播图index
  @observable baseInfo = {}               //问题须知
  @observable baseSubmitInfo = {}         //1-2问题提交参数子项
  @observable submitBaseSubmitInfo = []   //1-2问题提交参数
  @observable questionList = []           //3-11问题
  @observable isChange = false            //选项是否选中

  componentDidMount() {
    this.getRecommendInfo()
  }

  /**
   * @description 获取用户需知基本信息
   * @returns {Promise<void>}
   */
  getRecommendInfo = async () => {
    try {
      const res = await serv.getRecommendInfo()
      if (res) {
        const { data } = res
        this.baseInfo = data || {}
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * @description 获取3-11
   * @returns {Promise<void>}
   */
  getRecommendQuestionList = async (params) => {
    try {
      const res = await serv.getRecommendQuestionList(params)
      if (res) {
        const { data } = res
        this.questionList = [...data] || []
      }
    } catch (e) {
      console.log(e)
    }
  }

  startStep = () => {
    this.currentSwiperIndex = this.currentSwiperIndex + 1
  }

  /**
   * @description 上一题
   */
  prevStep = (index) => {
    this.currentSwiperIndex = this.currentSwiperIndex - 1
    if (index === 2) {
      console.log(index)
      this.baseInfo.questionList.splice(2, this.baseInfo.questionList.length - 1)
      this.questionList = []
    }
  }

  /**
   * @description 下一题
   */
  nextStep = async (index, id) => {
    const { showToast } = this.props.store
    const params = this.baseInfo.questionList.map(it => it.submitInfo).filter((it) => it)
    let msg = ''

    if (params.length > 0) {
      for (let i = 0; i < params.length; i++) {
        if (params[i].questionId === id) {
          this.currentSwiperIndex = this.currentSwiperIndex + 1
          msg = ''
          break
        } else {
          msg = '请选择题目'
        }
      }
    } else {
      msg = '请选择题目'
    }


    if (msg) {
      return showToast(msg)
    }

    //第二题获取后3-11题答案
    if (index === 1) {
      const p = params.filter((it, index) => index <= 1)
      await this.getRecommendQuestionList(p)
      this.baseInfo.questionList = this.baseInfo.questionList.concat(...this.questionList || [])
    }
  }

  /**
   * @description 选项回调
   * @param v
   */
  changeQuestion = (params) => {
    const qItem = this.baseInfo.questionList.find((it) => it.questionId === params.questionId)
    qItem.submitInfo = params

  }

  submitAllQuestionList = async () => {
    const params = this.baseInfo.questionList.map(it => it.submitInfo).filter((it) => it)
    try {
      const res = await serv.submitQuestionAnswer(params)
      if (res) {
        const { data } = res
        if (data) {
          this.props.history.push('/user/result')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  toResult = () => {
    this.props.history.push('/user/result')
  }

  render() {
    const {
      describes,
      finish,
      questionList,
      title: baseTitle
    } = this.baseInfo
    return (
      <>
        <div className="home-page-wrapper">
          <div className="home-bg"/>
          <SwipeableViews
            index={this.currentSwiperIndex}
            className="home-swiper"
            slideClassName="slide-item"
            disabled={true}
          >
            <div className="home-swiper-item-wrapper">
              <div className="home-swiper-item">
                <div className="home-container">
                  <div className="title-wrapper">
                    <img src="https://cdn.kangarooread.com/mp-assets/user-question-title.png" alt="" className="title"/>
                  </div>
                  <div className="home-line"/>
                  {
                    (describes || []).map((it, index) => (
                      <p key={index}>{it}</p>
                    ))
                  }
                </div>
                {
                  finish ? <div className="home-btn" onClick={() => this.toResult()}>查看结果</div>
                    : <div className="home-btn" onClick={() => this.startStep()}>开始测试</div>

                }
              </div>
            </div>
            {
              questionList && questionList.map((it, index) => (
                <div className="home-swiper-item-wrapper" key={index}>
                  <div className="home-swiper-item">
                    <Question
                      title={it.content}
                      optionList={it.optionList}
                      multiple={it.multiple}
                      total={it.optionList.length || 0}
                      currentIdx={index + 1}
                      questionItem={it}
                      onChange={this.changeQuestion}
                    />
                    <div className="home-question-btn-wrapper">
                      {
                        index === 0 ? <div/> :
                          <div className="home-question-btn prev"
                               onClick={() => this.prevStep(index, it.questionId)}>上一题</div>
                      }
                      {
                        this.questionList.length > 0 && index + 1 === questionList.length ?
                          <div className="home-question-btn next"
                               onClick={() => this.submitAllQuestionList()}>提交</div> :
                          <div className="home-question-btn next"
                               onClick={() => this.nextStep(index, it.questionId)}>下一题</div>

                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </SwipeableViews>
        </div>
      </>
    )
  }
}

export default UserQuestionnaire
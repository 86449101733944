import React, { useEffect } from 'react'
import wx from 'weixin-js-sdk'
import CommonStore from '../../../store'
import { useLocalStore } from 'mobx-react'
import serv from '../../../lib/api'

const IMG_LIST = [
	'https://cdn.kangarooread.com/resource/event/event_5_share_detail_2.jpg'
]

export default (props) => {
	const store = useLocalStore(() => CommonStore)
	const { env: runtimeEnv, userInfo } = store
	document.title = '发圈赢积分'

	const reportVisit = () => {
		const { id: userId } = userInfo
		serv.dataTrackingStorage({
			current: 'EVENT5CIRCLEPAGE',
			content: JSON.stringify({ userId })
		})
	}

	useEffect(() => {
		reportVisit()
	}, [])

	const toAdd = () => {
		if (runtimeEnv === 'app') {
			props.history.push('/event-5-circle-add')
		} else if (runtimeEnv === 'mp') {
			wx.miniProgram.navigateTo({
				url: '/subPkg/event/pages/event-5-circle-add/index'
			})
		} else {
			props.history.push('/event-5-circle-add')
		}
		const { id: userId } = userInfo
		serv.dataTrackingStorage({
			current: 'EVENT5CIRCLE',
			content: JSON.stringify({ userId })
		})
	}

	return (
		<div
			style={{
				position: 'relative',
				zIndex: 1,
				paddingBottom: 100
			}}>
			{IMG_LIST.map((img, idx) => (
				<img
					style={{
						width: '100%',
						display: 'block'
					}}
					src={img}
					alt=''
					key={idx}
				/>
			))}
			<div
				style={{
					position: 'fixed',
					zIndex: 2,
					left: '50%',
					bottom: 20,
					transform: 'translateX(-50%)',
					width: '80%',
					height: '45px',
					lineHeight: '45px',
					textAlign: 'center',
					color: 'white',
					fontSize: 16,
					borderRadius: 25,
					background: '#FFCE40'
				}}
				onClick={toAdd}>
				添加「陪伴官微信」领取奖励
			</div>
		</div>
	)
}

import React, { useEffect, useState } from 'react'
import './index.less'
import moment from 'moment'
import serv from '../../../lib/api'
import NoData from '../../../components/NoData'

export default () => {
	const [list, setList] = useState([])
	const [loaded, setLoaded] = useState(false)

	const getList = async () => {
		try {
			const res = await serv.get520InviteList()
			if (res) {
				const { data } = res
				setList(data || [])
			}
		} catch (err) {
			console.log(err)
		} finally {
			setLoaded(true)
		}
	}

	useEffect(() => {
		if (!loaded) {
			getList()
		}
	}, [loaded])

	return (
		<>
			{list.map((it, idx) => (
				<div className='record-item' key={idx}>
					<img src={it.avatar} alt='' className='avatar' />
					<div className='info'>
						<div className='nickname-wrapper'>
							<div className='nickname'>{it.nickname || ''}</div>
							<div className='time'>{moment(it.time).format('YYYY-MM-DD')}</div>
						</div>
						<div className='desc'>
							{it.awardType
								? `邀请成功，${it.awardType} + ${it.count}`
								: '暂未订阅'}
						</div>
					</div>
					<div className='split-line' />
				</div>
			))}

			{loaded && list.length === 0 && <NoData emptyStr='暂无邀请记录' />}
		</>
	)
}

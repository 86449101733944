import React, { useState } from 'react'
import './index.less'

const imgStyle = {
	width: '100%',
	height: 'auto',
	display: 'block'
}

const eventJson = [
	{
		type: 'csldz',
		imgs: [
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_1.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_2.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_3.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_4.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_5.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_6.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_7.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_8.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_9.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_10.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_11.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_12.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_13.jpg',
			'https://cdn.kangarooread.com/h5/event/csldz/csldz_14.jpg'
		]
	},
	{
		type: 'dyhy',
		imgs: [
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_1.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_2.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_3.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_4.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_5.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_6.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_7.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_8.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_9.jpg',
			'https://cdn.kangarooread.com/h5/event/dyhy/dyhy_10.jpg'
		]
	},
	{
		type: 'gsmm',
		imgs: [
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_1.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_2.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_3.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_4.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_5.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_6.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_7.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_8.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_9.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_10.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_11.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_12.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_13.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_14.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_15.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_16.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_17.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_18.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_19.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_20.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_21.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_22.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_23.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_24.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_25.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_26.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_27.jpg',
			'https://cdn.kangarooread.com/h5/event/gsmm/gsmm_v2_28.jpg'
		]
	}
]

export default function EventArticle(props) {
	const [visible, setVisible] = useState(false)
	const { type } = props.match.params
	const { imgs } = eventJson.find((it) => it.type === type)

	const toWenjuanxing = () => {
		setVisible(true)
	}

	return (
		<>
			<div
				style={{
					paddingBottom: 100
				}}>
				{imgs &&
					imgs.map((it, idx) => (
						<img src={it} style={imgStyle} alt='图片' key={idx} />
					))}
				<button
					type='button'
					onClick={toWenjuanxing}
					className='wenjuanxing-btn'>
					我要咨询
				</button>
			</div>

			{visible && (
				<div className='gsmm-mask' onClick={() => setVisible(false)} />
			)}
			{visible && (
				<img
					className='gsmm-qrcode'
					src='https://cdn.kangarooread.com/h5/event/gsmm/gsmm_qrcode.jpg'
					alt=''
				/>
			)}
		</>
	)
}

import React, { Component, Fragment } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import './index.less'
import { storage } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import serv from '../../lib/api'
import Book from '../../components/Book'

@inject('store')
@observer
class ExContext extends Component {
  @observable env = 'app'
  @observable userInfo = {}
  @observable baseInfo = {}
  @observable bookList = []
  @observable babyAge = 0

  async componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    this.babyAge = Util.getParams('babyAge')
    showLoading('加载中...')
    // 动态修改title
    document.title = '伴读技巧、互动方案'
    // 获取运行环境
    await this.getBookContextInfo()
    await this.getEnv()
    setTimeout(() => {
      hideLoading()
    }, 1000)
  }

  getBookContextInfo = async () => {
    const res = await serv.getBookContextInfo(this.babyAge)
    try {
      if (res) {
        const { data } = res
        this.baseInfo = { ...(data || {}) }
      }
    } catch (err) {
      console.log(err)
    }
  }

  @action
  getEnv = () => {
    if (bridge.isAppEnv()) {
      this.env = 'app'
      this.initAppData()
    } else {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.env = 'mp'
            this.initMpData()
          } else {
            this.env = 'h5'
            this.initH5Data()
          }
        })
      } else {
        this.env = 'h5'
        this.initH5Data()
      }
    }
  }

  initAppData = () => {
    bridge.getUserInfoFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getExContextInfo()
      } else {
        this.loginInApp()
      }
    })
  }

  /**
   * @desc APP 登录时间通知
   * @memberof ExContext
   */
  loginInApp = () => {
    bridge.loginFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getExContextInfo()
      } else {
        this.loginInApp()
      }
    })
  }

  initMpData = () => {
    try {
      const token = Util.getParams('token')
      Util.setLocalStorage(
        storage.userInfo,
        JSON.stringify({ accountVo: { token: token || '' } })
      )
      if (token) {
        this.fetchUser()
        setTimeout(() => {
          this.getExContextInfo()
        }, 300)
      } else {
        wx.miniProgram.navigateTo({
          url: '/pages/login/index',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  initH5Data = () => {
    const token = Util.getParams('token')
    Util.setLocalStorage(
      storage.userInfo,
      JSON.stringify({ accountVo: { token: token || '' } })
    )
    if (token) {
      this.fetchUser()
      setTimeout(() => {
        this.getExContextInfo()
      }, 300)
    }
  }

  /**
   * @desc 获取绘本解析内容
   * @memberof ExContext
   */
  getExContextInfo = async (source = 'detail') => {
    const { showLoading, hideLoading } = this.props.store
    showLoading('请稍后')
    const { bookListId } = this.baseInfo
    const bookIds = (this.bookList || []).map((it) => `%${it.id}%`).join(',')
    const params = {
      bookListId,
      bookIds,
    }
    this.bookList = []
    if (source) {
      params.source = source
    }
    const res = await serv.getBookListContextInfo(params)
    if (res) {
      const { data } = res
      const { contextBookVoList } = data || {}
      this.bookList = [...(contextBookVoList || [])]
    }
    hideLoading()
  }

  /**
   * @desc 获取用户信息（小程序及H5使用）
   * @memberof ExContext
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 跳转购卡页面
   * @memberof ExContext
   */
  toBuyCard = () => {
    if (this.env === 'app') {
      bridge.appNavigate({ id: 3 })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/buy-card/index?source=exContext',
      })
    }
  }

  /**
   * @desc 绘本详情
   * @memberof ExContext
   */
  toBookDetail = async (id = '') => {
    if (this.env === 'app') {
      bridge.appNavigate({ id: 19, params: `id=${id}` })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/subPkg/main/pages/book-detail/index?id=${id}`,
      })
    }
  }

  render() {
    const { img, content, title } = this.baseInfo
    return (
      <Fragment>
        <div className="e-c-wrapper">
          <div className="info-wrapper">
            <img className="cover-pic" alt="" src={img || ''} />
            <div className="info">
              <h4 className="title">{title || ''}</h4>
              <p className="desc">{content || ''}</p>
            </div>
          </div>
          <div className="title-wrapper">
            <span className="title-icon"></span>
            <h4 className="title">你可以免费体验三本书</h4>
            {this.bookList.filter((it) => it.hasRead).length === 3 ? null : (
              <div
                className="change"
                onClick={() => this.getExContextInfo('change')}
              >
                换一批<span className="change-icon"></span>
              </div>
            )}
          </div>
          <div className="book-list">
            {this.bookList.map((it) => (
              <div
                className="item"
                key={it.id}
                onClick={() => this.toBookDetail(it.id)}
              >
                <Book cover={it.coverPicture} hasRead={it.hasRead} size={180} />
                <div className="book-info">
                  <h4 className="sale-title">{it.saleTitle || ''}</h4>
                  <div className="tag-wrapper">
                    {(it.tagNameList || []).map((i) => (
                      <span className="tag" key={i}>
                        {i || ''}
                      </span>
                    ))}
                  </div>
                  <p className="count">{it.readCount || 0}人已掌握</p>
                  <div className="l-btn">马上体验</div>
                </div>
              </div>
            ))}
          </div>

          <div className="bottom-btn" onClick={() => this.toBuyCard()}>
            成为会员，解锁全部
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ExContext

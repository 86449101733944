import React, { Component } from 'react'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import { KeepaliveRouterSwitch, KeepaliveRoute } from 'react-keepalive-router'
import { storage } from '../lib/conf'
import { Util } from '../lib/util'
import { bridge } from '../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../lib/api'
import './index.less'

// components
import Toast from '../components/Toast'
import Loading from '../components/Loading'
import Modal from '../components/Modal'
import PageLoading from '../components/PageLoading'

//pages
import NoPage from '../views/404'
import NewbieTask from '../views/NewbieTask'
import ExContext from '../views/ContextExperience'
import ExCommunity from '../views/CommunityExperience'
import ExBookList from '../views/BookListExperience'
import BookListDetail from '../views/BookListDetail'
import ExClockIn from '../views/ClockInExperience'
import Publicity from '../views/Publicity'
import ExWeeklyCourse from '../views/WeeklyCourse'
import BookTutor from '../views/BookTutor'
import Agreement from '../views/Agreement'
import ZhifufenAgreement from '../views/ZhifufenAgreement'
import AppDownload from '../views/AppDownload'
import EventWeb from '../views/EventWeb'
import GetDetail from '../views/GetDetail'
import ExpandPage from '../views/ExpandPage'
import ExpandPageIndex from '../views/ExpandPageIndex'
import Questionnaire from '../views/Questionnaire'
import CommonRichText from '../views/CommonRichText'
import CommonRichTextV2 from '../views/CommonRIchTextV2'
import RateServiceInfo from '../views/RateServiceInfo'
import EventPlayTogether from '../views/Event/EventPlayTogether'
import EventLottery from '../views/Event/EventLottery'
import GmSign from '../views/GmSign'
import GongMaoProtocol from '../views/Protocol/GongMao'
import UpgradeRole2 from '../views/Protocol/UpgradeRole2'
import UpgradeRole3 from '../views/Protocol/UpgradeRole3'
import AmapPage from '../views/AmapPage'
import EventStoryDay from '../views/Event/EventStoryDay'
import CommonQuestionnaire from '../views/CommonQuestionnaire'
import EventCourseGift from '../views/Event/EventCourseGift'
import EventVote from '../views/Event/EventVote'
import EventSelectUser from '../views/Event/EventSelectUser'
import EventEnrollListInSelectUser from '../views/Event/EventEnrollListInSelectUser'
import RateServiceQuestionnaire from '../views/RateServiceQuestionnaire'
import MaintenanceInfo from '../views/MaintenanceInfo'
import SendFlowerMain from '../views/SendFlowerMain'
import SendFlowerList from '../views/SendFlowerList'
import BirthdayCard from '../views/BirthdayCard'
import BirthdayAddress from '../views/BirthdayAddress'
import AddAddress from '../views/BirthdayAddress/AddAddress/index'
import EventExChangeCereal from '../views/Event/EventExChangeCereal'
import EventArticle from '../views/Event/EventArticle'
import PublicityNotice from '../views/PublicityNotice'
import NewYearWish from '../views/Event/NewYearWish'
import TempMeeting from '../views/Event/tempMeeting'
import QrcodeToGroup from '../views/QrcodeToGroup'
import PublicityV2 from '../views/PublicityV2'
import FadadaCbPage from '../views/FadadaCbPage'
import ProductManual from '../views/ProductManual'
import PdfReader from '../views/PdfReader'
import SubscribeNew from '../views/CommonAgreement/SubscribeNew'
import SubscribeOldToNew from '../views/CommonAgreement/SubscribeOldToNew'
import SubscribeXufei from '../views/CommonAgreement/SubscribeXufei'
import ReadInvite from '../views/ReadInvite'
import ReadInviteRecord from '../views/ReadInvite/record'
import ReadInviteUserList from '../views/ReadInvite/user'
import MapSearch from '../views/MapSearch'
import TalkShowAppointment from '../views/Event/TalkShowAppointment'
import ChannelPage from '../views/ChannelPage'
import ProtocolGdd from '../views/CommonAgreement/ProtocolGdd'
import Protocol188 from '../views/CommonAgreement/Protocol188'
import Protocol888 from '../views/CommonAgreement/Protocol888'
import Protocol1288 from '../views/CommonAgreement/Protocol1288'
import ReadInviteRecordDetail from '../views/ReadInvite/record-detail'
import UserQuestionnaire from '../views/UserQuestionnaire'
import UserResultPage from '../views/UserQuestionnaire/result'
import SearchCertification from '../views/SearchCertification'
import EventAddStaff from '../views/Event/EventAddStaff'
import YearReport from '../views/Event/YearReport'
import EventRenew from '../views/Event/EventRenew'
import Event5Renew from '../views/Event/Event5Renew'
import Event5Circle from '../views/Event/Event5Circle'
import Event5CircleAdd from '../views/Event/Event5Circle/add'
import Event5Share from '../views/Event/Event5Share'
import Event5ShareRecord from '../views/Event/Event5Share/record'
import EventAddQrcode from '../views/Event/EventAddQrcode'

// 协议
import WYMMYYZDS from '../views/CommonAgreement/wuyoumamayangyuzhidaoshi'
import Demo from '../views/demo'

@inject('store')
@withRouter
@observer
class App extends Component {
	@observable loaded = false
	@observable env = ''

	componentDidMount() {
		const { showLoading, hideLoading } = this.props.store
		this.loaded = false
		const shareId = Util.getParams('shareId')
		if (shareId) {
			Util.setLocalStorage(storage.shareId, shareId + '')
		}
		showLoading()
		this.getEnv()
		this.checkUser()
			.then(() => {
				this.loaded = true
				hideLoading()
			})
			.catch((err) => {
				console.log(`checkUser failed: ${JSON.stringify(err)}`)
				Util.removeLocalStorage(storage.userInfo)
				this.loaded = true
				hideLoading()
			})
		this.getAddress()
	}

	/**
	 * @description 检查用户登录
	 * @returns {Promise<void>}
	 */
	@action
	checkUser = () => {
		const { updateUserInfo } = this.props.store
		return new Promise((resolve, reject) => {
			if (this.env === 'app') {
				console.log('checkUser env => app')
				console.log('checkUser res')
				bridge.getUserInfoFromApp().then((res) => {
					console.log(`checkUser res => ${res}`)
					if (res) {
						console.log('checkUser get res success')
						updateUserInfo(res)
						resolve()
					} else {
						reject()
					}
				})
			} else {
				console.log('checkUser env => not app')
				try {
					const token = Util.getParams('token')
					if (token) {
						Util.setLocalStorage(
							storage.userInfo,
							JSON.stringify({ accountVo: { token: token || '' } })
						)
						this.fetchUser().then((_) => {
							resolve()
						})
					} else {
						reject()
						// let _user = Util.getLocalStorage(storage.userInfo) || '{}'
						// _user = JSON.parse(_user) || null
						// if (_user && _user.id) {
						// 	resolve()
						// } else {
						// 	if (this.env === 'h5') {
						// 		reject()
						// 	} else {
						// 		wx.miniProgram.navigateTo({
						// 			url: '/pages/login/index',
						// 			complete: () => {
						//         reject()
						//       }
						// 		})
						// 	}
						// }
					}
				} catch (err) {
					console.log(err)
					reject()
				}
			}
		})
	}

	/**
	 * @description 获取用户信息（小程序及H5使用）
	 */
	fetchUser = async () => {
		const { updateUserInfo } = this.props.store
		try {
			const res = await serv.getUserInfo()
			if (res) {
				const { data } = res
				updateUserInfo(data)
				Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
			}
		} catch (err) {
			console.log(err)
		}
	}

	@action
	getEnv = () => {
		const { updateEnv } = this.props.store
		if (navigator.userAgent.indexOf('MicroMessenger') === -1) {
			if (bridge.isAppEnv()) {
				updateEnv('app')
				this.env = 'app'
				console.log('app')
			} else {
				updateEnv('h5')
				this.env = 'h5'
				console.log('h5')
			}
		} else {
			wx.miniProgram.getEnv((res) => {
				if (res.miniprogram) {
					updateEnv('mp')
					this.env = 'mp'
					console.log('mp')
				} else {
					updateEnv('h5')
					this.env = 'h5'
					console.log('h5')
				}
			})
		}
	}

	async getAddress() {
		const queryType = 4
		const { getAddressInfo } = this.props.store
		const res = await serv.getAddress(queryType)
		if (res) {
			const { data } = res || {}
			getAddressInfo(data.address)
		}
	}

	render() {
		const {
			toastVisible,
			toastText,
			loadingVisible,
			loadingText,
			modalOptions,
			modalVisible,
			pageLoadingVisible
		} = this.props.store
		return (
			<div className='main'>
				{/* toast */}
				<Toast text={toastText} visible={toastVisible} />
				{/* loading */}
				<Loading text={loadingText} visible={loadingVisible} />
				{/*Modal*/}
				<Modal options={modalOptions} visible={modalVisible} />
				{/*pageLoading*/}
				<PageLoading visible={pageLoadingVisible} />
				{/* page */}
				{this.loaded ? (
					<KeepaliveRouterSwitch>
						<Route component={NewbieTask} path='/newbie-task' />
						<Route component={ExContext} path='/context-experience' />
						<Route component={ExCommunity} path='/community-experience' />
						<Route component={ExBookList} path='/bookList-experience' />
						<Route
							component={BookListDetail}
							path='/bookList-detail/:id/:type/:babyAge'
						/>
						<Route component={ExClockIn} path='/clockin-experience' />
						<Route component={ExWeeklyCourse} path='/weekly-course' />
						<Route component={Publicity} path='/publicity' />
						<Route component={BookTutor} path='/book-tutor' />
						<Route component={Agreement} path='/member-agreement' />
						<Route component={ZhifufenAgreement} path='/zhifufen-agreement' />
						<Route component={AppDownload} path='/app-download' />
						<Route component={EventWeb} path='/event-web' />
						<Route component={GetDetail} path='/get-detail' />
						<Route component={ExpandPage} path='/expand-page/:id' />
						<Route component={ExpandPageIndex} path='/expand-page-index' />
						<Route component={Questionnaire} path='/questionnaire' />
						<Route component={CommonRichText} path='/common-rich-text' />
						<Route component={CommonRichTextV2} path='/common-rich-text-v2' />
						<Route component={RateServiceInfo} path='/rate-service-info' />
						<Route component={EventPlayTogether} path='/event-play-together' />
						<Route component={EventLottery} path='/event-lottery' />
						<KeepaliveRoute component={GmSign} path='/gm-sign' />
						<Route component={WYMMYYZDS} path='/wuyoumamayangyuzhidaoshi' />
						<Route component={GongMaoProtocol} path='/protocol/gm' />
						<Route component={UpgradeRole2} path='/protocol/upgrade-role-2' />
						<Route component={UpgradeRole3} path='/protocol/upgrade-role-3' />
						<Route component={AmapPage} path='/mapPage' />
						<Route component={EventStoryDay} path='/event-story-day' />
						<Route
							component={CommonQuestionnaire}
							path='/common-questionnaire/:aid/:id'
						/>
						<Route component={EventCourseGift} path='/event-course-gift/:id' />
						<Route component={EventVote} path='/event-vote' />
						<Route component={EventSelectUser} path='/event-select-user' />
						<Route
							component={EventEnrollListInSelectUser}
							path='/event-enroll-in-select-user'
						/>
						<Route
							component={RateServiceQuestionnaire}
							path='/rate-service-questionnaire/:id'
						/>
						<Route
							component={MaintenanceInfo}
							path='/maintenance-info-birthday'
						/>
						<Route component={SendFlowerMain} path='/send-flower-main' />
						<Route
							component={SendFlowerList}
							path='/send-flower-list/:type/:userId'
						/>
						<Route component={BirthdayCard} path='/birthday-card' />
						<Route component={BirthdayAddress} path='/birthday-address' />
						<Route component={AddAddress} path='/add-address' />
						<Route
							component={EventExChangeCereal}
							path='/event-exchange-cereal'
						/>
						<Route component={EventArticle} path='/event-article/:type' />
						<Route component={PublicityNotice} path='/publicity-notice' />
						<Route component={NewYearWish} path='/new-year-wish' />
						<Route component={TempMeeting} path='/temp-meeting' />
						<Route component={QrcodeToGroup} path='/qrcode-to-group' />
						<Route component={PublicityV2} path='/publicity-v2' />
						<Route component={FadadaCbPage} path='/fadada-cb-page' />
						<Route component={ProductManual} path='/product-manual/:id' />
						<Route component={PdfReader} path='/pdf-reader' />
						<Route component={SubscribeNew} path='/subscribe-new' />
						<Route component={SubscribeOldToNew} path='/subscribe-old-to-new' />
						<Route component={SubscribeXufei} path='/subscribe-xufei' />
						<Route component={ReadInvite} path='/read-invite' />
						<Route component={ReadInviteRecord} path='/read-invite-record' />
						<Route component={ReadInviteUserList} path='/read-invite-user' />
						<Route
							component={ReadInviteRecordDetail}
							path='/read-invite-record-detail'
						/>
						<Route component={MapSearch} path='/map-search' />
						<Route component={TalkShowAppointment} path='/talk-show' />
						<Route component={ChannelPage} path='/channel-page' />
						<Route component={ProtocolGdd} path='/protocol-gdd' />
						<Route component={Protocol188} path='/protocol-188' />
						<Route component={Protocol888} path='/protocol-888' />
						<Route component={Protocol1288} path='/protocol-1288' />
						<Route component={UserQuestionnaire} path='/user/questionnaire' />
						<Route component={UserResultPage} path='/user/result' />
						<Route
							component={SearchCertification}
							path='/search-certification'
						/>
						<Route component={EventAddStaff} path='/event-add-staff' />
						<Route component={YearReport} path='/year-report' />
						<Route component={EventRenew} path='/event-renew' />
						<Route component={Event5Renew} path='/event-5-renew' />
						<Route component={Event5Circle} path='/event-5-circle' />
						<Route component={Event5CircleAdd} path='/event-5-circle-add' />
						<Route component={Event5Share} path='/event-5-share' />
						<Route component={Event5ShareRecord} path='/event-5-share-record' />
						<Route componen={EventAddQrcode} path='/event-add-qw-qrcode' />
						<Route component={NoPage} isExact path='/' />
					</KeepaliveRouterSwitch>
				) : null}
			</div>
		)
	}
}

export default App

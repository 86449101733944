import React, { Component } from 'react'

class Blank extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 40
    }
  }

  componentDidMount() {
    const { height } = this.props
    this.setState({
      height
    })
  }

  render() {
    return <div
      style={{
        width: '100%',
        height: this.state.height
      }}
    />
  }
}

export default Blank
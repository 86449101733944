import React, { Component } from 'react'

export default class Card extends Component {

  render() {
    const { children } = this.props

    return <div className="sp-card">
      <img src="https://cdn.kangarooread.com/mp-assets/event-lottery/2021_card_bg.png" className="bg" alt=""/>
      {children}
    </div>
  }
}

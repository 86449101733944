import React from 'react'
import './page_4.less'

const Page_4 = (props) => {
	const { accompanyMinute, maxPurchaseBookMonth, maxPurchaseBookCount } =
		props || {}

	return (
		<>
			<picture className='yr-page-4-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_4_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_4_bg.png'
					className='yr-page-4-bg-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-4-text-wrapper'>
				{accompanyMinute && (
					<>
						在这一年里，
						<br />
						你一共花了&nbsp;
						<span className='text' style={{ color: '#FF9600' }}>
							{accompanyMinute || ''}
						</span>
						&nbsp;分钟，
						<br />
						陪伴孩子读绘本。
						<br />
					</>
				)}
				{maxPurchaseBookCount && (
					<>
						在&nbsp;
						<span className='text' style={{ color: '#6192F2' }}>
							{maxPurchaseBookMonth || ''}
						</span>
						&nbsp;月，
						<br />
						你们留下的书最多，达到了&nbsp;
						<span className='text' style={{ color: '#16CC95' }}>
							{maxPurchaseBookCount || ''}
						</span>
						&nbsp;本，
						<br />
						是精神食粮大丰收的一个月！
					</>
				)}
			</div>
		</>
	)
}

export default Page_4

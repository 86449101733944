import React from 'react'
import './index.less'

export default function ActionSheet(props) {

  const handleClose = () => {
    props.onClose && props.onClose()
  }

  const { showCloseIcon = true, bg = '#ffffff' } = props

  return (
    <>
      {props.visible && <div className="action-sheet-mask" onClick={handleClose}/>}
      <div className={`action-sheet-content ${props.visible ? 'show' : 'hide'}`} style={{ background: bg }}>
        {showCloseIcon && <i className="close" onClick={handleClose}/>}
        {props.children}
      </div>
    </>
  )
}

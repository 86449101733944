import React, { Component, Fragment } from 'react'
import AMapLoader from '@amap/amap-jsapi-loader'
import { Util } from '../../../lib/util'
import { inject, observer } from 'mobx-react'
import { isProd, storage } from '../../../lib/conf'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../../../lib/api'
import List from '../../../components/List'
import './index.less'
import EventItem from './components/EventItem'
import html2canvas from 'html2canvas'
import { action, observable, runInAction } from 'mobx'

@inject('store')
@observer
class EventStoryDay extends Component {
  @observable appVersion = ''
  @observable env = ''
  @observable location = {}
  @observable detail = {}
  @observable userInfo = {}
  @observable list = {
    data: [],
    size: 20,
    pageNum: 1,
    hasMore: false,
    type: 1
  }
  @observable loaded = false
  @observable hasFinished = false
  @observable qrCode = ''
  @observable map = null

  componentDidMount() {
    document.title = '好奇说故事节'
    const { showPageLoading, hidePageLoading } = this.props.store
    this.getEnv()
    showPageLoading()
    const shareId = this.getQuery('shareId')
    Util.setLocalStorage(storage.shareId, shareId || '')
    this.checkUser().then(_ => {
      this.initData().then(_ => {
        hidePageLoading()
      })
    })
  }

  // const { showLoading, hideLoading, showToast, env, closeModal, showModal, showPageLoading, hidePageLoading } = store

  getQuery = (type = 'location') => {
    if (type === 'location') {
      const longitude = Util.getParams('lon') || ''
      const latitude = Util.getParams('lat') || ''
      const city = Util.getParams('city') || ''
      return { longitude, latitude, city: decodeURIComponent(city || '') }
    }
    if (type === 'token') {
      return Util.getParams('token') || ''
    }
    if (type === 'shareId') {
      return Util.getParams('shareId') || ''
    }
    return null
  }

  /**
   * @description 获取详情
   * @param northEast
   * @param southWest
   * @returns {Promise<null>}
   */
  getDetail = async (northEast = '', southWest = '') => {
    const questionaireId = 1
    const params = { questionaireId, northEast, southWest }
    try {
      const res = await serv.storytellingFestivalHeatMap(params)
      if (res) {
        const { data } = res
        this.detail = { ...(data || {}) }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取当前环境
   */
  @action
  getEnv = () => {
    const { env } = this.props.store
    this.env = env
  }

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  @action
  checkUser = () => {
    const { showToast } = this.props.store
    return new Promise((resolve, reject) => {
      if (this.env === 'app') {
        bridge.getUserInfoFromApp().then((res) => {
          if (res) {
            runInAction(() => {
              this.userInfo = { ...(res || {}) }
            })
            resolve()
          } else {
            bridge.loginFromApp().then((user) => {
              if (user) {
                runInAction(() => {
                  this.userInfo = { ...(user || {}) }
                })
                resolve()
              } else {
                showToast('请先登录')
                reject()
              }
            })
          }
        })
      } else {
        try {
          const token = this.getQuery('token')
          if (token) {
            Util.setLocalStorage(
              storage.userInfo,
              JSON.stringify({ accountVo: { token: token || '' } })
            )
            this.fetchUser().then(_ => {
              resolve()
            })
          } else {
            let _user = Util.getLocalStorage(storage.userInfo) || '{}'
            _user = JSON.parse(_user) || null
            if (_user && _user.id) {
              runInAction(() => {
                this.userInfo = { ...(_user || {}) }
              })
              resolve()
            } else {
              wx.miniProgram.navigateTo({
                url: '/pages/login/index',
                success: () => {
                  reject()
                }
              })
            }
          }
        } catch (err) {
          console.log(err)
          reject()
        }
      }
    })
  }

  /**
   * @description 获取用户信息（小程序及H5使用）
   */
  fetchUser = async () => {
    try {
      const res = await serv.getUserInfo()
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...(data || {}) }
      }
    } catch (err) {
      console.log(err)
    }
  }

  @action
  getAppVersion = () => {
    if (this.env === 'app') {
      bridge.appCheckCurrentVersion()
      .then(res => {
        if (res) {
          const { version } = res
          this.appVersion = version
        } else {
          this.appVersion = '2.9.0'
        }
      })
      .catch(err => {
        this.appVersion = '2.9.0'
      })
    }
  }

  initData = async () => {
    let location = {}
    if (this.env === 'mp' || this.env === 'h5') {
      location = this.getQuery('location')
    } else if (this.env === 'app') {
      location = bridge.appGetLocationInfo()
    }
    // 更新组件内的坐标信息
    this.location = { ...(location || {}) }
    // 更新本地的坐标信息
    Util.setLocalStorage(storage.locationInfo, JSON.stringify({ ...location }))
    window.forceWebGL = true
    try {
      // this.initShareInfo()
      // await this.getQrcode()
      const _local = await this.initAmap(location)
      if (_local) {
        this.location = { ...(_local || {}) }
      }
      const bounds = this.map.getBounds()
      const northEast = bounds.northEast.toString()
      const southWest = bounds.southWest.toString()
      await this.getDetail(northEast, southWest)
      this.initPoints()
      this.initWindowInfos()
      this.checkFinishQuestionaire()
      .then(hasFinished => {
        this.hasFinished = hasFinished
      })
      await this.getList()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 初始化地图
   * @param longitude
   * @param latitude
   * @returns {Promise<unknown>}
   */
  initAmap = ({ longitude, latitude } = {}) => {
    return new Promise(resolve => {
      AMapLoader.load({
        key: 'a7b64e6734354baa480d915df89fc43c',
        plugins: ['AMap.Geolocation, AMap.Geocoder'],
        version: '2.0',
        Loca: {
          version: '2.0'
        },
        AMapUI: {}
      })
      .then(AMap => {
        if (!longitude && !latitude) {
          // 构造定位函数
          AMap.plugin('AMap.Geolocation', () => {
            const geolocation = new AMap.Geolocation({
              enableHighAccuracy: true,
              showButton: false,
              showMarker: false,
              showCircle: false
            })
            // 进行定位
            geolocation.getCurrentPosition((status, result) => {
              if (status === 'complete') {
                onComplete(result)
              } else {
                onError(result)
              }
            })

            // 定位成功的回调
            const onComplete = (data) => {
              const { lng, lat } = data.position
              // 根据坐标逆解析
              AMap.plugin('AMap.Geocoder', () => {
                const geocoder = new AMap.Geocoder()
                geocoder.getAddress([lng, lat], (status, result) => {
                  if (status === 'complete' && result.info === 'OK') {
                    const { addressComponent } = result.regeocode
                    const { city, province } = addressComponent
                    this.map = new AMap.Map('amap-container', {
                      resizeEnable: true,
                      center: [lng, lat],
                      zoom: 1,
                      mapStyle: 'amap://styles/d6649e235f113069a06787c6fa22f812',
                      viewMode: '2D',
                      lang: 'zh_cn'
                    })
                    const locationInfo = { longitude: lng, latitude: lat, city: city || province || '', province: province || '' }
                    // 更新本地的坐标信息
                    Util.setLocalStorage(storage.locationInfo, JSON.stringify(locationInfo))
                    resolve(locationInfo)
                  }
                })
              })
            }

            const onError = (err) => {
              console.log(err)
              this.map = new AMap.Map('amap-container', {
                resizeEnable: true,
                center: ['120.209947', '30.245853'],
                zoom: 5,
                mapStyle: 'amap://styles/d6649e235f113069a06787c6fa22f812',
                viewMode: '2D',
                lang: 'zh_cn'
              })
              const locationInfo = { latitude: '30.245853', longitude: '120.209947', province: '浙江省', city: '杭州市' }
              // 更新本地的坐标信息
              Util.setLocalStorage(storage.locationInfo, JSON.stringify(locationInfo))
              resolve(locationInfo)
            }
          })
        } else {
          this.map = new AMap.Map('amap-container', {
            resizeEnable: true,
            center: [longitude, latitude],
            zoom: 5,
            mapStyle: 'amap://styles/d6649e235f113069a06787c6fa22f812',
            viewMode: '2D',
            lang: 'zh_cn'
          })
          resolve()
        }
      })
      .catch(err => {
        console.log(err)
      })
    })
  }

  /**
   * @description 初始化所有的点位
   */
  initPoints = () => {
    const { showToast } = this.props.store
    const { hotLights } = this.detail
    const data = hotLights && hotLights.map(i => ({ position: [i.longitude, i.latitude] }))
    AMapUI.loadUI(['misc/PointSimplifier'], (PointSimplifier) => {
      if (!PointSimplifier.supportCanvas) {
        showToast('当前环境不支持！')
        return
      }
      const pointSimplifierIns = new PointSimplifier({
        map: this.map,
        zIndex: 1,
        compareDataItem: (a, b, aIndex, bIndex) => {
          return aIndex > bIndex ? -1 : 1
        },
        getPosition: (dataItem) => {
          return dataItem.position
        },
        renderOptions: {
          pointStyle: {
            fillStyle: '#F7B500',
            width: 5,
            height: 5,
            offset: ['-50%', '-50%']
          }
        }
      })

      pointSimplifierIns.setData(data)
    })
  }

  /**
   * @description 初始化所有的信息窗体
   */
  initWindowInfos = () => {
    const { highlights } = this.detail
    highlights && highlights.forEach(it => {
      const { postId, img, longitude, latitude } = it
      try {
        const win = new AMap.Marker({
          content: `
            <div class="post-window-info-wrapper">
              <div class="post-window-info">
                <div class="post-window">
                  <img src="${img}" class="post-window-img" alt=""/>
                </div>
              </div>
              <div class="map-point-wrapper">
                <div class="point"></div>
                <div class="w1 circle"></div>
                <div class="w2 circle"></div>
                <div class="w3 circle"></div>
              </div>
            </div>`,
          position: new AMap.LngLat(longitude, latitude),
          offset: new AMap.Pixel(0, 0),
          anchor: 'bottom-center',
          zIndex: 99999,
          topWhenClick: true
        })
        win.on('click', (e) => {
          console.log(e)
          this.toEventItemDetail(postId)
        })
        this.map.add(win)
      } catch (e) {
        console.log('绘制窗体异常')
      }
    })
  }

  getList = async () => {
    this.loaded = false
    const { pageNum, size, data: oldList } = this.list
    const params = {
      pageNum, size,
      type: 2
    }
    try {
      const res = await serv.searchNearActivity(params)
      if (res) {
        const { data, hasMore } = res
        this.list.data = oldList.concat(data || [])
        this.list.hasMore = hasMore
        this.list.pageNum = hasMore ? pageNum + 1 : pageNum
        this.loaded = true
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 初始化分享信息
   */
  initShareInfo = () => {
    let _user = Util.getLocalStorage(storage.userInfo)
    _user = JSON.parse(_user || '{}')
    const { id: userId } = _user
    // 本页面需要转发
    bridge.jsShowShareWechat(true)
    bridge.jsShareWechat({
      title: '快来获取你的亲子阅读现状报告，有好礼哦~',
      thumb: 'https://cdn.kangarooread.com/mp-assets/event_story_day_share_img.png',
      path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-story-day&shareType=open&shareId=${userId}`
    })
    wx.miniProgram.postMessage({
      data: {
        title: '快来获取你的亲子阅读现状报告，有好礼哦~',
        imageUrl: 'https://cdn.kangarooread.com/mp-assets/event_story_day_share_img.png',
        path: `/subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-story-day&shareType=open&shareId=${userId}`
      }
    })
  }

  /**
   * @description 跳转问卷详情 / 报告结果页
   * @returns {Promise<void>}
   */
  toQaPage = () => {
    // if (env === 'app') {
    //   if (Util.compareVersion(appVersion, '2.9.2') < 0) {
    //     showToast('请将APP更新至最新版本后体验该功能')
    //     return
    //   }
    // }
    const { showLoading, hideLoading } = this.props.store
    showLoading('请稍后...')
    this.checkFinishQuestionaire()
    .then(sf => {
      if (sf) {
        let locationInfo = Util.getLocalStorage(storage.locationInfo) || '{}'
        locationInfo = JSON.parse(locationInfo)
        const { city, longitude, latitude, province } = locationInfo || {}
        if (this.env === 'mp') {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/questionnaire-result/index?questionaireId=1&city=${encodeURIComponent(city || '')}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${encodeURIComponent(province || '')}`,
            success: () => {
              hideLoading()
            }
          })
        } else if (this.env === 'app') {
          bridge.appNavigate({ id: 114, params: `questionaireId=1&city=${city || ''}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${province || ''}` })
          hideLoading()
        } else {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/questionnaire-result/index?questionaireId=1&city=${encodeURIComponent(city || '')}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${encodeURIComponent(province || '')}`,
            fail: () => {
              bridge.appNavigate({ id: 114, params: `questionaireId=1&city=${city || ''}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${province || ''}` })
            },
            complete: () => {
              hideLoading()
            }
          })
        }
      } else {
        hideLoading()
        const { push } = this.props.history
        console.log(this.props.history)
        push(`/common-questionnaire/1/start`)
      }
    })
  }

  /**
   * @description 检查用户是否已完成问卷
   * @returns {Promise<boolean|*>}
   */
  checkFinishQuestionaire = () => {
    return new Promise((resolve, reject) => {
      serv.checkFinishQuestionaire(1)
      .then(res => {
        if (res) {
          const { data } = res
          resolve(data)
        } else {
          resolve()
        }
      })
      .catch(err => {
        reject(err)
      })
    })

  }

  /**
   * @description 去领取课程
   */
  toGetGift = () => {
    // if (env === 'app') {
    //   if (Util.compareVersion(appVersion, '2.9.2') < 0) {
    //     showToast('请将APP更新至最新版本后体验该功能')
    //     return
    //   }
    // }
    this.checkFinishQuestionaire()
    .then(sf => {
      if (sf) {
        let locationInfo = Util.getLocalStorage(storage.locationInfo) || '{}'
        locationInfo = JSON.parse(locationInfo)
        const { city, longitude, latitude, province } = locationInfo || {}
        if (this.env === 'mp') {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/questionnaire-result/index?questionaireId=1&city=${encodeURIComponent(city || '')}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${encodeURIComponent(province || '')}`
          })
        } else if (this.env === 'app') {
          bridge.appNavigate({ id: 114, params: `questionaireId=1&city=${city || ''}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${province || ''}` })
          .then(_ => {
            console.log('finish')
          })
        } else {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/questionnaire-result/index?questionaireId=1&city=${encodeURIComponent(city || '')}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${encodeURIComponent(province || '')}`,
            fail: () => {
              bridge.appNavigate({ id: 114, params: `questionaireId=1&city=${city || ''}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${province || ''}` })
            }
          })
        }
      } else {
        const { showToast } = this.props.store
        showToast('活动已结束')
        // const options = {
        //   title: '温馨提示',
        //   content: '您需要先完成问卷才可领取课程',
        //   okBtnText: '现在就去',
        //   cancelBtnText: '下次一定',
        //   showCancelBtn: true,
        //   gravity: 1,
        //   onConfirm: async () => {
        //     const { push } = this.props.history
        //     push(`/common-questionnaire/1/start`)
        //     closeModal()
        //   },
        //   onClose: () => {
        //     closeModal()
        //   }
        // }
        // showModal(options)

      }
    })
  }

  /**
   * @description 跳转转盘抽奖（如果检测路由栈数量 > 1，则进行返回）
   */
  toLotteryPage = () => {
    const { length, push, goBack } = this.props.history
    if (length > 1) {
      goBack()
    } else {
      const { accountVo: { token } } = this.userInfo
      push(`/event-lottery?token=${token}`)
    }
  }

  /**
   * @description 跳转活动详情
   * @param id
   */
  toEventItemDetail = (id) => {
    if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/pages/circle/event-detail/index?id=${id}`
      })
    } else if (this.env === 'app') {
      bridge.appNavigate({ id: 115, params: `id=${id}` })
    } else {
      wx.miniProgram.navigateTo({
        url: `/pages/circle/event-detail/index?id=${id}`,
        fail: () => {
          bridge.appNavigate({ id: 115, params: `id=${id}` })
        }
      })
    }
  }

  /**
   * @description 获取小程序码
   */
  getQrcode = async () => {
    let _user = Util.getLocalStorage(storage.userInfo)
    _user = JSON.parse(_user || '{}')
    const { id } = _user
    console.log(id)
    const params = {
      path: 'subPkg/extend/pages/web-link/index',
      scene: `m;2;${id || ''};open`,
      type: 20
    }
    try {
      const res = await serv.getQrCode(params)
      if (res) {
        const { cdnUrl } = res.data || {}
        this.qrCode = cdnUrl
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 渲染海报
   * @returns {Promise<void>}
   */
  renderPoster = async () => {
    const { showLoading, hideLoading } = this.props.store
    showLoading('绘制中...')
    const node = document.getElementById('poster-wrapper-1')
    html2canvas(node, {
      allowTaint: true,
      useCORS: true,
      scrollY: 0
    })
    .then(canvas => {
      if (canvas) {
        const src = canvas.toDataURL('image/png')
        console.log(src)
        if (this.env === 'app') {
          bridge.appShareBase64(src)
          setTimeout(() => {
            hideLoading()
          }, 200)
        } else if (this.env === 'mp') {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/img-preview-page/index?type=eventStoryDay`,
            complete: () => {
              hideLoading()
            }
          })
        } else {
          wx.miniProgram.navigateTo({
            url: `/subPkg/extend/pages/img-preview-page/index?type=eventStoryDay`,
            fail: () => {
              bridge.appShareBase64(src)
              setTimeout(() => {
                hideLoading()
              }, 200)
            },
            complete: () => {
              hideLoading()
            }
          })
        }
      }
    })
    .catch(err => {
      console.log(err)
      showLoading('绘制中...')
    })
  }

  stopEvent = () => {
    const { showToast } = this.props.store
    showToast('活动已结束')
  }


  render() {
    const { familyCnt, provinceCnt, cityCnt, attenderCnt, showCourse, invitationCnt, haveGift } = this.detail || {}
    const { city } = this.location || {}
    const { accountVo } = this.userInfo || {}
    const { nickname } = accountVo || {}

    return (
      <div className="event-story-day-wrapper">
        <div
          className="poster-wrapper"
          id="poster-wrapper-1"
          style={{
            position: 'absolute',
            left: -9999,
            top: -9999
          }}
        >
          <img src="https://cdn.kangarooread.com/mp-assets/event_story_day_share_img_1_bg.png" crossOrigin="anonymous"
               className="poster-img" alt=""/>
          <div className="poster-info">
            <div className="p-i-left">
              <div className="nickname">{nickname || ''}</div>
              <div className="desc">
                邀请你一起参加<img className="icon" crossOrigin="anonymous"
                            src="https://cdn.kangarooread.com/mp-assets/event_story_day_share_img_1_icon_1.png" alt=""/>
              </div>
            </div>
            <img src={this.qrCode} className="qr-code" crossOrigin="anonymous" alt=""/>
          </div>
        </div>
        <div className="title-info-wrapper">
          <div className="title-bg"/>
        </div>
        <div
          id="amap-container"
          className="amap-container"
        />
        <div className="map-info-wrapper">
          <div className="desc">
            <span className="important">·&nbsp;</span>已举办
            <span className="important">&nbsp;{familyCnt || '--'}</span>&nbsp;场线下故事会，覆盖&nbsp;&nbsp;<span
            className="important">{provinceCnt || '--'}</span>&nbsp;省&nbsp;&nbsp;<span
            className="important">{cityCnt || '--'}</span>&nbsp;市
          </div>
          <div className="desc">
            <span className="important">·&nbsp;</span>已有
            <span className="important">&nbsp;{attenderCnt || '--'}</span>&nbsp;人参与亲子阅读现状分析
          </div>
        </div>

        <div className="btn-wrapper">
          <div className="btns">
            {this.hasFinished
              ? <Fragment>
                <div className="btn" onClick={(e) => this.toQaPage(e)}>下载亲子阅读报告</div>
                <div className="ani-btn" onClick={() => this.stopEvent()}>
                  邀请好友
                  <i className="icon"/>
                </div>
              </Fragment>
              : <div className="ani-btn width480" onClick={(e) => this.toQaPage(e)}>
                获取我的亲子阅读现状分析
                <i className="icon"/>
              </div>
            }

          </div>
          {showCourse
            ? haveGift
              ? <div className="gift-info-wrapper">
                <i className="icon"/>
                <span className="label">邀请即赠送抽奖机会 1 次</span>
                <span className="v-split"/>
                {invitationCnt > 0
                  ? <Fragment>
                    <span className="iv-count">已邀请{invitationCnt}人</span>
                    <span className="v-split"/>
                  </Fragment>
                  : null
                }
                <span className="check-btn" onClick={() => this.toLotteryPage()}>查看</span>
              </div>
              : <div className="gift-info-wrapper">
                <i className="icon"/>
                <span className="label">参与即赠送¥99的课程</span>
                <span className="v-split"/>
                <span className="check-btn" onClick={() => this.toGetGift()}>查看</span>
              </div>
            : <div className="gift-info-wrapper">
              <i className="icon"/>
              <span className="label">邀请即赠送抽奖机会 1 次</span>
              <span className="v-split"/>
              {invitationCnt > 0
                ? <Fragment>
                  <span className="iv-count">已邀请{invitationCnt}人</span>
                  <span className="v-split"/>
                </Fragment>
                : null
              }
              <span className="check-btn" onClick={() => this.toLotteryPage()}>查看</span>
            </div>
          }
        </div>

        <img src="https://cdn.kangarooread.com/mp-assets/event_story_day_img_1.png" alt="" className="story-day-img"/>

        <div className="event-title">
          <i className="title-icon"/>
          <div className="location-info">
            <i className="icon"/>
            <span className="label">{city ? city + '附近' : '定位中'}</span>
          </div>
        </div>

        <div className="event-list">
          <List
            next={this.getList}
            loaded={this.loaded}
            dataSource={this.list.data || []}
            hasMore={this.list.hasMore}
            endStr="没有活动了哦~"
          >
            {this.list.data && this.list.data.map((i, idx) => (
              <EventItem data={i} key={idx} toDetail={this.toEventItemDetail}/>
            ))}
          </List>
        </div>
      </div>
    )
  }
}

export default EventStoryDay

import React, { Component } from 'react'
import './index.less'

class Agreement extends Component {
  componentDidMount() {
    document.title = '好奇说绘本会员服务协议'
  }

  render() {
    return (
      <div className="agreement-wrapper">
        <div className="desc bold">
          欢迎使用好奇说绘本会员服务，您在使用本服务时，即表示您已阅读并接受下列条款和约束，请认真阅读以下条款：
        </div>
        <div className="desc bold">一、总则</div>
        <div className="desc">
          1.1 本程序的所有权和运营权归杭州好奇谷信息科技有限公司所有。
        </div>
        <div className="desc">
          1.2
          用户同意协议条款并完成注册流程，才能成为好奇说绘本的正式用户。用户在使用好奇说绘本及好奇说绘本提供的各项服务时，承诺接受并遵守各项相关规定。
        </div>
        <div className="desc">
          1.3
          本协议可通过「好奇说绘本」程序随时更新，用户应当及时关注并同意，杭州好奇谷信息科技有限公司不承担通知义务。本公司的通知、公告、声明或其它类似内容是本协议的一部分。
        </div>
        <div className="desc bold">二、用户权利与义务</div>
        <div className="desc">
          2.1
          用户请妥善保管好注册好奇说绘本的手机号，用户须对以其用户账户进行的所有活动和事件负法律责任。
        </div>
        <div className="desc">
          2.2
          用户有义务在使用过程中提供自己的真实信息，并保证诸如联系方式、联系地址等内容的有效性及安全性，同时用户也有义务在相关资料实际变更时及时更新资料。
        </div>
        <div className="desc">
          2.3
          用户应当保证在账号名称、头像等注册信息中不出现违法和不良信息。如果用户提供的资料违反本款要求，用户需承担因此引起的相应责任及后果，并且好奇说绘本将保留终止用户使用好奇说绘本会员服务或采取其他处理措施的权利。
        </div>
        <div className="desc">
          2.4
          用户承诺不以任何方式利用好奇说绘本直接或间接从事违反中国法律以及社会公德的行为，如有违反导致任何法律后果的发生，用户将以自己的名义独自承担所有相应的法律责任；若因此给好奇说绘本或其他第三方造成损失（包括但不限于经济损失、商誉损失等），好奇说绘本或其他第三方有权要求用户进行全额赔偿。
        </div>
        <div className="desc">
          2.5
          未经本平台授权或许可，任何用户不得借用本平台的名义从事任何商业活动，也不得将本平台作为从事商业活动的场所、平台或其他任何形式的媒介。禁止将本平台用作从事各种非法活动的场所、平台或其他任何形式的媒介。如用户违反上述规定，则好奇说绘本有权采取一切必要措施，包括但不限于删除用户积分、奖励，暂停或查封账号，乃至通过诉讼形式追究用户法律责任等。
        </div>
        <div className="desc bold">三、平台权利和义务</div>
        <div className="desc">
          3.1
          本公司有义务在现有技术上维护整个平台的正常运行，并努力提升和改善技术，使用户在好奇说绘本的服务交易得以顺利进行；对用户注册使用好奇说绘本时所遇到的与交易或注册相关的问题反馈，好奇说绘本应及时作出回复。
        </div>
        <div className="desc">
          3.2
          本公司有权对用户使用好奇说绘本的情况进行审查和监督，对于用户在好奇说绘本平台上的不当行为，好奇说绘本或其授权人有权要求用户改正或直接采取一切必要措施（包括但不限于删除相关信息、删除交易记录、封禁IP、终止服务、注销账号等），而无需征得用户同意。用户在好奇说绘本平台的不当行为包括但不限于：
        </div>
        <div className="desc bold">违反国家法律、法规禁止性规定；</div>
        <div className="desc">
          政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或教唆犯罪；
        </div>
        <div className="desc">
          侵犯他人知识产权或涉及第三方商业秘密及其他专有权利；
        </div>
        <div className="desc">
          采用包括但不限于机器爬虫等方式批量获取好奇说绘本数据；
        </div>
        <div className="desc">利用多个账号对好奇说绘本进行访问攻击；</div>
        <div className="desc">投机行为、恶意交易、高价倒卖；</div>
        <div className="desc">
          恶意或不友善行为（包括但不限于对好奇说绘本服务人员有侮辱行为等）；
        </div>
        <div className="desc">其他扰乱或破坏好奇说绘本服务的行为。</div>
        <div className="desc">
          3.3
          如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请通知本公司。因黑客行为或用户的保管疏忽导致帐号非法使用，本公司不承担任何责任。
        </div>
        <div className="desc">
          3.4
          对于因不可抗力或本公司不能控制的原因造成的服务中断或其它缺陷，本公司不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
        </div>
        <div className="desc bold">四、会员服务说明</div>
        <div className="desc">
          4.1
          会员卡一旦购买之后，自购买日起七天内未使用的，可以申请全额；购买后超过七天，但未使用的会员卡，如需退款，平台需要扣除应退金额的20%作为手续费；会员一旦激活使用后，无法退款；
        </div>
        <div className="desc">
          4.2
          每张会员卡平台收取￥199的服务押金。用户可以选择直接支付￥199押金，或申请使用微信支付分免押金担保，微信支付分大于等于550分可享受免押金订阅。服务结束后若未发生违约行为，支付的押金全额退款，微信支付分担保订单实时完结。违约行为包括但不限于以下：
        </div>
        <div className="desc">（1）会期结束后拒不还书；</div>
        <div className="desc">
          （2）由个人或第三方物流公司产生的损坏/遗失绘本、书盒拒不赔付；
        </div>
        <div className="desc">
          4.3
          会员卡有效期内，用户可以根据不同卡种的服务次数借阅绘本，每次可借阅的绘本数量为8本。用户同时最多借2个书袋，达到2个书袋后，需要归还部分订单才能继续借阅。归还以仓库验收通过为准。
        </div>
        <div className="desc">
          4.4
          用户起始借阅的时间不能晚于服务的有效期；在服务有效期到期后，用户需要在借阅归还期结束前归还。
        </div>
        <div className="desc">
          4.5
          平台负责往返包邮，用户无需承担任何物流费用，物流服务商由平台指定；归还的时候，用户只需要在【待归还订单】里点击归还按纽，预约快递就可以了，无需付费；所有订单均只来回包邮一次，如因用户漏寄等情况导致额外的物流费用，均需要用户自行承担；
        </div>
        <div className="desc">
          4.6
          因如下情况造成订单延迟或无法配送等，平台不承担延迟配送或无法配送的责任：
        </div>
        <div className="desc">
          （1）用户提供的信息错误、地址不详细等原因导致的；
        </div>
        <div className="desc">
          （2）货物送达后无人签收，导致无法配送或延迟配送的；
        </div>
        <div className="desc">（3）第三方物流配送延迟或取消导致的；</div>
        <div className="desc">
          （4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。
        </div>
        <div className="desc">
          4.7
          绘本借阅过程中，由于人为的原因造成了绘本的损坏，并影响了后续的使用与借阅，用户可能需要承担相应的赔偿；
        </div>
        <div className="desc">
          4.8
          用户在归还订单里务必确保商品完整无损并取出所有私人物品，由此造成的损失，平台不承担任何责任；
        </div>
        <div className="desc">
          4.9 好奇说绘本订阅卡仅限帐户拥有者本人使用，且不可转让；
        </div>
        <div className="desc">
          4.10
          一旦用户加入会员，必须遵守平台的规则。如果出现违反平台规则的情况，平台有权停止相应服务。
        </div>
        <div className="desc bold">五、隐私保护</div>
        <div className="desc">
          5.1
          本公司不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本公司的非公开内容，但下列情况除外：
        </div>
        <div className="desc">事先获得用户的明确授权；</div>
        <div className="desc">根据有关的法律法规要求；</div>
        <div className="desc">按照相关政府主管部门的要求；</div>
        <div className="desc">为维护社会公众的利益。</div>
        <div className="desc">
          5.2
          在不透露单个用户隐私资料的前提下，本公司有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
        </div>
        <div className="desc bold">六、版权声明</div>
        <div className="desc">
          6.1
          本程序中涉及的文字、图片、音频、视频等版权均归杭州好奇谷信息科技有限公司享有，未经许可，不得任意转载。
        </div>
        <div className="desc">
          6.2
          本程序中特有的标识、版面设计、编排方式等版权均为杭州好奇谷信息科技有限公司享有，未经许可，不得任意复制或转载。
        </div>
        <div className="desc">
          6.3
          读书笔记的内容仅代表作者自己的立场和观点，与本公司无关，由作者本人承担一切法律责任。
        </div>
        <div className="desc">
          6.4 恶意转载本程序中内容的，将保留诉诸法律的权利。
        </div>
        <div className="desc bold">七、附则</div>
        <div className="desc">
          7.1
          本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律，发生争议时，由本公司注册地法院管辖。
        </div>
        <div className="desc">
          7.2
          如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
        </div>
        <div className="desc">
          7.3 本协议解释权及修订权归杭州好奇谷信息科技有限公司所有。
        </div>
        <div className="desc">杭州好奇谷信息科技有限公司</div>
        <div className="desc">2020年4月20日</div>
      </div>
    )
  }
}

export default Agreement

import React, { useEffect } from 'react'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'
import { bridge } from '../../lib/bridge'
import { env, storage } from '../../lib/conf'
import wx from 'weixin-js-sdk'
import { Util } from '../../lib/util'

export default function Publicity(props) {
  // window.location.replace('/publicity-v2')
  props.history.replace('/publicity-v2')
  return <></>
  // const store = useLocalStore(() => CommonStore)
  // const { userInfo, env: runtimeEnv, showToast, updateUserInfo } = store
  //
  // /**
  //  * @description 初始化分享信息
  //  */
  // const initShareInfo = () => {
  //   const { id: userId } = userInfo || {}
  //   // 本页面需要转发
  //   bridge.jsShowShareWechat(true)
  //   bridge.jsShareWechat({
  //     title: '「好读家庭」儿童订阅计划',
  //     thumb: 'https://cdn.kangarooread.com/mp-assets/subscribe/share_card_img.png',
  //     path: `subPkg/extend/pages/publicity/index?shareId=${userId}`
  //   })
  //   wx.miniProgram.postMessage({
  //     data: {
  //       title: '「好读家庭」儿童订阅计划',
  //       imageUrl: 'https://cdn.kangarooread.com/mp-assets/subscribe/share_card_img.png',
  //       path: `/subPkg/extend/pages/publicity/index?shareId=${userId}`
  //     }
  //   })
  // }
  //
  // useEffect(() => {
  //   if (userInfo.id) {
  //     initShareInfo()
  //   }
  // }, [userInfo])
  //
  // /**
  //  * @description 跳转升级服务/订阅页面
  //  */
  // const toBuyCard = () => {
  //   Util.checkAuth().then((hasLogin) => {
  //     if (hasLogin) {
  //       if (runtimeEnv === 'app') {
  //         bridge.appNavigate({ id: 3 })
  //       } else if (runtimeEnv === 'mp') {
  //         wx.miniProgram.navigateTo({
  //           url: '/subPkg/main/pages/subscribe-detail/index'
  //         })
  //       } else {
  //         showToast('请在小程序或APP访问该页面')
  //       }
  //     } else {
  //       if (runtimeEnv === 'app') {
  //         loginInApp()
  //       } else if (runtimeEnv === 'mp') {
  //         wx.miniProgram.navigateTo({
  //           url: '/pages/login/index'
  //         })
  //       } else {
  //         showToast('请先登录')
  //       }
  //     }
  //   })
  // }
  //
  // /**
  //  * @description APP 登录事件通知
  //  */
  // const loginInApp = () => {
  //   bridge.loginFromApp().then((res) => {
  //     if (res) {
  //       updateUserInfo(res)
  //     } else {
  //       Util.removeLocalStorage(storage.userInfo)
  //     }
  //   })
  // }
  //
  // const toMySub = () => {
  //   const { serviceId } = userInfo || {}
  //   Util.checkAuth().then((hasLogin) => {
  //     if (hasLogin) {
  //       if (runtimeEnv === 'app') {
  //         bridge.appNavigate({ id: 10 })
  //       } else if (runtimeEnv === 'mp') {
  //         let url = '/subPkg/extend/pages/my-card/index'
  //         if (serviceId > 1) {
  //           url = '/subPkg/extend/pages/my-subscription/index'
  //         }
  //         wx.miniProgram.navigateTo({
  //           url
  //         })
  //       }
  //     } else {
  //       if (runtimeEnv === 'app') {
  //         loginInApp()
  //       } else if (runtimeEnv === 'mp') {
  //         wx.miniProgram.navigateTo({
  //           url: '/pages/login/index'
  //         })
  //       }
  //     }
  //   })
  // }
  //
  // const { cardStatus, serviceId, id, tutor, allowSubscription, allowUpdateSubscription, allowUpdateSubscriptionInTime } = userInfo || {}
  //
  // return (
  //   <>
  //     <div className="publicity-wrapper-v2">
  //       <div className="img-wrapper">
  //         <img className="img" src={`${env.IMG}publicity/image_v5_1.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_2.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_3.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_4.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_5.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_6.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_7.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_8.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_9.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_10.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_11.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_12.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_13.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_14.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_15.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_16.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_17.jpg`} alt=""/>
  //         <img className="img" src={`${env.IMG}publicity/image_v5_18.jpg`} alt=""/>
  //       </div>
  //
  //       <div className="bottom-btn-wrapper">
  //         {
  //           !id && <div className="bottom-btn primary" onClick={toBuyCard}>
  //             成为会员
  //           </div>
  //         }
  //         {allowSubscription ? <div className="bottom-btn primary" onClick={toBuyCard}>
  //           成为会员
  //         </div> : <>
  //           {
  //             (cardStatus === 4 || cardStatus === 5) && <div className="bottom-btn primary" onClick={toBuyCard}>
  //               成为会员
  //             </div>
  //           }
  //           {
  //             cardStatus === 2 && !tutor && <div className="bottom-btn primary" onClick={toMySub}>
  //               激活服务
  //             </div>
  //           }
  //           {
  //             serviceId === 1 && (cardStatus === 1 || cardStatus === 3) && allowUpdateSubscription && allowUpdateSubscriptionInTime &&
  //             <div className="bottom-btn primary" onClick={toBuyCard}>
  //               领取限时福利，升级服务
  //             </div>
  //           }
  //           {
  //             serviceId === 1 && (cardStatus === 1 || cardStatus === 3) && !allowUpdateSubscription &&
  //             <div className="bottom-btn primary disabled">
  //               升级权益已使用，无法再次升级
  //             </div>
  //           }
  //           {
  //             serviceId === 1 && (cardStatus === 1 || cardStatus === 3) && allowUpdateSubscription && !allowUpdateSubscriptionInTime &&
  //             <div className="bottom-btn primary disabled">此账号暂不支持购买</div>
  //           }
  //           {
  //             serviceId === 1 && cardStatus === 6 && <div className="bottom-btn primary" onClick={toBuyCard}>
  //               成为会员
  //             </div>
  //           }
  //           {
  //             serviceId > 1 && (cardStatus === 1 || cardStatus === 3) &&
  //             <div className="bottom-btn primary" onClick={toMySub}>
  //               订阅中，查看我的服务
  //             </div>
  //           }
  //           {
  //             serviceId > 1 && cardStatus === 6 && <div className="bottom-btn primary" onClick={toBuyCard}>
  //               马上续订
  //             </div>
  //           }
  //         </>}
  //       </div>
  //     </div>
  //   </>
  // )
}

import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Util } from '../../lib/util'

export default function PdfReader() {
  const [fileUrl, setFileUrl] = useState(null)
  const [total, setTotal] = useState(0)

  const initUrl = () => {
    const url = Util.getParams('file')
    url && setFileUrl(decodeURIComponent(url))
  }

  useEffect(() => {
    if (!fileUrl) {
      initUrl()
    }
  }, [fileUrl])

  const loadSuccess = ({numPages}) => {
    setTotal(numPages)
  }

  return <Document
    file={fileUrl}
    loading={<div style={{ textAlign: 'center' }}
    >加载中，请稍后</div>}
    onLoadSuccess={loadSuccess}
  >
    {new Array(total).fill('').map((_, idx) => (
      <div key={idx}>
        <Page pageNumber={idx + 1} width={375}/>
      </div>
    ))}
  </Document>
}

import React from 'react'

const imgStyle = {
  display: 'block',
  width: '100%'
}

export default function SubscribeNew() {
  return <img src="https://cdn.kangarooread.com/mp-assets/protocol/168.png" style={imgStyle} alt=""/>
}

import React from 'react'
import './page_2.less'

const Page_2 = (props) => {
	const { subBookCount, subBookMarketPrice, nickname } = props || {}

	return (
		<>
			<picture className='yr-page-2-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_2_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_2_bg.png'
					className='yr-page-2-bg-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-2-text'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_2_text.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_2_text.png'
					className='yr-page-2-text-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-2-text-wrapper'>
				Hi，亲爱的&nbsp;
				<span className='nickname'>{nickname || '好奇说用户'}</span>
				<br />
				这一年，
				<br />
				你和孩子在好奇说
				<br />
				一共阅读了&nbsp;<span className='text'>{subBookCount || ''}</span>
				&nbsp;本绘本
				<br />
				相比于购买节省了&nbsp;
				<span className='text'>{subBookMarketPrice || ''}</span>
				&nbsp;元
			</div>
		</>
	)
}

export default Page_2

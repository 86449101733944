import React from 'react'
import moment from 'moment'

export default function Item(props) {
  const { type, avatar, amount, message, nickname, time, icon } = props
  return (
    <div className="item">
      <img
        src={avatar}
        className="avatar" alt=""/>
      <div className="info">
        <div className="title-wrapper">
          <div className="title">{nickname}</div>
          <div className="label">{type === 1 ? '送我' : '收到'}</div>
          <div className="flower-item">
            <div className="count">{amount || 0}</div>
            <div className="symbol">+</div>
            <img className="icon" src={icon} alt=""/>
          </div>
        </div>
        <div className="time">{moment(time).format('YYYY年MM月DD日')}</div>
        <div className="word">{message}</div>
        <div className="line"/>
      </div>
    </div>
  )
}

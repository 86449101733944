import React, { Component } from 'react'

export default class Title extends Component {

  render() {
    const { title } = this.props

    return <div className="sp-title">
      <div className="title-icon-1"/>
      <div className="title-icon-2"/>
      <div className="title-icon-3"/>
      <span className="title">{title || ''}</span>
      <div className="title-icon-3"/>
      <div className="title-icon-2"/>
      <div className="title-icon-1"/>
    </div>
  }
}

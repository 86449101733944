import fetch from '../fetch'

const distribution = {
  /**
   * @description 获取评分页面基本信息
   * @returns {*}
   */
  getServiceScoreInfo() {
    return fetch('/distribution/mp/service_score/info')
  },

  /**
   * @description 获取问卷完整信息
   * @returns {*}
   */
  getQuestionnaire() {
    return fetch('/distribution/mp/service_score/get_questionnaire')
  },

  /**
   * @description 提交问卷
   * @param params
   * @returns {*}
   */
  submitAnswerList(params = {}) {
    return fetch('/distribution/mp/service_score/record', {
      method: 'POST',
      data: {
        ...params
      }
    })
  },
}

export default distribution

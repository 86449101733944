import React, {Component, Fragment} from 'react'
import {inject, observer} from 'mobx-react'
import wx from 'weixin-js-sdk'
import {env} from '../../lib/conf'
import {bridge, Util} from '../../lib/util'
import './index.less'
import serv from "../../lib/api";
import {observable} from "mobx";
import moment from 'moment'

@inject('store')
@observer
class GetDetail extends Component {
  @observable id = ''
  @observable detail = {}

  async componentDidMount() {
    document.title = '故事妈妈学习营'
    const {showToast} = this.props.store
    this.id = Util.getParams('id')
    if (!this.id) {
      showToast('错误的GET详情')
      return
    }
    await this.getCampaignInfo()
  }

  getCampaignInfo = async () => {
    const {showLoading, hideLoading} = this.props.store
    showLoading('加载中...')
    const res = await serv.getCampaignInfo(this.id)
    try {
      if (res) {
        const {
          data
        } = res
        this.id = data.id
        this.detail = {...(data || {})}
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  render() {
    const {
      coverPicture, title, part, salePrice, marketPrice, hasSubCourse, classCount, joinCount, postCount, applyEndTime, applyStartTime, startTime, content
    } = this.detail
    return <div className="get-course-detail">
      <div className="banner">
        <img className="img" src={coverPicture || ''} alt=''/>
      </div>

      <div className="get-info-card">
        <div className="title-wrapper">{title || ''}——{part || ''}</div>
        <div className="price-wrapper">
          <div className="price">
            ¥{salePrice || ''}
          </div>
          <div className="tag">限时价</div>
          <div className="market-price">
            ¥{marketPrice}
          </div>
        </div>
        <div className="split-line"/>
        <div className="get-info">
          <div className="info-item">
            <div className="value">
              {hasSubCourse ? classCount : '0'}
              <div className="unit">节</div>
            </div>
            <div className="label">课程数</div>
          </div>
          <div className="info-item">
            <div className="value">
              {joinCount || '0'}
              <div className="unit">人</div>
            </div>
            <div className="label">加入学习</div>
          </div>
          <div className="info-item">
            <div className="value">
              {postCount || '0'}
              <div className="unit">篇</div>
            </div>
            <div className="label">学习笔记</div>
          </div>
        </div>
      </div>
      <div className="sub">
        报名时间：{moment(applyStartTime).format('MM月DD日 HH:mm')} - {moment(applyEndTime).format('MM月DD日 HH:mm')}
      </div>
      <div className="sub">
        开课时间：{moment(startTime).format('MM月DD日 HH:mm')}
      </div>

      <div
        className="detail-rich-text"
        dangerouslySetInnerHTML={{
          __html: content || '',
        }}
      />
    </div>
  }
}

export default GetDetail
import React, { Component } from 'react'
import './index.less'
import { env } from '../../lib/conf'

class PageLoading extends Component {
  render() {
    const { visible } = this.props
    return (
      <div className={`page-loading-wrapper ${visible ? 'show' : ''}`}>
        <div className="mask"/>
        <img src={`${env.IMG}page_loading.gif`} className="icon" alt="" />
      </div>
    )
  }
}

export default PageLoading

import { observable, action } from 'mobx'

class Store {
  @observable env = ''
  @observable toastVisible = false
  @observable toastText = ''
  @observable loadingVisible = false
  @observable loadingText = ''
  @observable modalVisible = false
  @observable modalOptions = {}
  @observable pageLoadingVisible = false
  @observable currentQuestionId = ''
  @observable commonQuestionList = []
  @observable birthdayList = {}
  @observable addressList = []
  @observable userInfo = {}
  @observable defaultAddress = []
  @observable submitBaseSubmitInfo = []

  @action.bound
  showToast(text = '', duration = 1500) {
    if (this.loadingVisible) {
      this.loadingVisible = false
    }
    this.toastVisible = true
    this.toastText = text
    setTimeout(() => {
      this.toastVisible = false
      this.toastText = ''
    }, duration)
  }

  @action.bound
  showLoading(text = '加载中...') {
    this.loadingVisible = true
    this.loadingText = text
  }

  @action.bound
  hideLoading() {
    this.loadingVisible = false
    this.loadingText = ''
  }

  @action.bound
  showModal(options = {}) {
    this.modalOptions = options
    this.modalVisible = true
  }

  @action.bound
  closeModal() {
    this.modalVisible = false
    setTimeout(() => {
      this.modalOptions = {}
    }, 300)
  }

  @action.bound
  showPageLoading() {
    this.pageLoadingVisible = true
  }

  @action.bound
  hidePageLoading() {
    this.pageLoadingVisible = false
  }

  @action.bound
  updateEnv(env = '') {
    this.env = env
  }

  @action.bound
  updateBirthday(list = '') {
    this.birthdayList = list
  }


  /**
   * @description 更新当前题
   * @param id
   */
  @action.bound
  updateCurrentQuestionId(id = '') {
    this.currentQuestionId = id
  }

  /**
   * @description 更新下一题
   * @param list
   */
  @action.bound
  updateCommonQuestionList(list = []) {
    this.commonQuestionList = [ ...list ]
  }


  @action.bound
  updateAddress(list = []) {
    this.addressList = list
  }

  @action.bound
  updateUserInfo(userInfo = {}) {
    this.userInfo = userInfo
  }

  @action.bound
  getAddressInfo(defaultAddress){
    this.defaultAddress = defaultAddress || []
  }

  @action.bound
  updateBaseInfo(submitBaseSubmitInfo){
    this.submitBaseSubmitInfo = submitBaseSubmitInfo || []
  }
}

export default new Store()

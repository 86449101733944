import React, { Component } from 'react'
import './index.less'

class QuestionnaireCard extends Component {
  handleClick = () => {
    const { onTipClick } = this.props
    onTipClick()
  }

  render() {
    const { title, comment, children, childrenOffset = 'top', tip = false } = this.props

    return (
      <div className="c-q-card">
        <div className="c-q-title">
          <img src={title} alt={''} className="c-q-avatar"/>
          {tip && <div className="q-tip" onClick={() => this.handleClick()}/>}
        </div>
        {childrenOffset === 'top' && children}
        <div className="c-q-comment-wrapper">
          <div className="c-q-comment-icon"/>
          <div className="c-q-comment">{comment}</div>
        </div>
        {childrenOffset === 'bottom' && children}
      </div>
    )
  }
}

export default QuestionnaireCard
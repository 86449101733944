import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import './index.less'
import { storage } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import serv from '../../lib/api'
import Book from '../../components/Book'

@inject('store')
@observer
class ExBookList extends Component {
  @observable env = 'app'
  @observable babyAge = 0
  @observable userInfo = {}
  @observable baseInfo = {}
  @observable themeVoList = []
  @observable appAssignedBookListVoList = []
  @observable awardWinningWorks = []
  @observable authors = []
  pressList = [
    {
      id: 1,
      url: 'https://cdn.kangarooread.com/mp-assets/press_logo_1.jpg?v=2',
    },
    {
      id: 2,
      url: 'https://cdn.kangarooread.com/mp-assets/press_logo_2.jpg?v=2',
    },
    {
      id: 3,
      url: 'https://cdn.kangarooread.com/mp-assets/press_logo_3.jpg?v=2',
    },
    {
      id: 4,
      url: 'https://cdn.kangarooread.com/mp-assets/press_logo_4.jpg?v=2',
    },
    {
      id: 5,
      url: 'https://cdn.kangarooread.com/mp-assets/press_logo_5.jpg?v=2',
    },
  ]

  async componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    this.babyAge = Util.getParams('babyAge')
    showLoading('加载中...')
    // 动态修改title
    document.title = '专家定制书单'
    // 获取运行环境
    await this.getEnv()
    setTimeout(() => {
      hideLoading()
    }, 300)
  }

  @action
  getEnv = async () => {
    if (bridge.isAppEnv()) {
      this.env = 'app'
      this.initAppData()
    } else {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        wx.miniProgram.getEnv(async (res) => {
          if (res.miniprogram) {
            this.env = 'mp'
            await this.initMpData()
          } else {
            this.env = 'h5'
            await this.initH5Data()
          }
        })
      } else {
        this.env = 'h5'
        await this.initH5Data()
      }
    }
  }

  initAppData = () => {
    bridge.getUserInfoFromApp().then(async (res) => {
      if (res) {
        this.userInfo = { ...res }
        await this.getCustomizedBookList()
      } else {
        this.loginInApp()
      }
    })
  }

  /**
   * @desc APP 登录时间通知
   * @memberof ExBookList
   */
  loginInApp = () => {
    bridge.loginFromApp().then(async (res) => {
      if (res) {
        this.userInfo = { ...res }
        await this.getCustomizedBookList()
      } else {
        this.loginInApp()
      }
    })
  }

  initMpData = async () => {
    try {
      const token = Util.getParams('token')
      if (token) {
        Util.setLocalStorage(
          storage.userInfo,
          JSON.stringify({ accountVo: { token } })
        )
        await this.fetchUser()
        setTimeout(async () => {
          await this.getCustomizedBookList()
        }, 300)
      } else {
        wx.miniProgram.navigateTo({
          url: '/pages/login/index',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  initH5Data = async () => {
    const token = Util.getParams('token')
    Util.setLocalStorage(
      storage.userInfo,
      JSON.stringify({ accountVo: { token: token || '' } })
    )
    if (token) {
      await this.fetchUser()
      setTimeout(async () => {
        await this.getCustomizedBookList()
      }, 300)
    }
  }

  /**
   * @desc 获取绘本解析内容
   * @memberof ExBookList
   */
  getCustomizedBookList = async () => {
    const res = await serv.getCustomizedBookList(this.babyAge)
    if (res) {
      const { data } = res
      if (data) {
        const {
          img,
          title,
          content,
          themeVoList,
          appAssignedBookListVoList,
          awardWinningWorks,
          authors,
        } = data
        this.baseInfo = { img, title, content }
        this.themeVoList = [...(themeVoList || [])]
        this.appAssignedBookListVoList = [...(appAssignedBookListVoList || [])]
        this.awardWinningWorks = [...(awardWinningWorks || [])]
        this.authors = [...(authors || [])]
      }
    }
  }

  /**
   * @desc 获取用户信息（小程序及H5使用）
   * @memberof ExBookList
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  toDetail = (type = 'normal', id = '') => {
    if (!id) {
      const { showToast } = this.props.store
      showToast('错误的书单信息')
      return
    }
    if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/pages/unsubscription-book-list-detail/index?id=${id}&type=${type}&babyAge=${this.babyAge}`,
      })
    } else {
      const { history } = this.props
      history.push(`/bookList-detail/${id}/${type}/${this.babyAge}`)
    }
  }

  render() {
    const { img, title, content } = this.baseInfo
    return (
      <div className="b-l-e-wrapper">
        <div className="info-wrapper">
          <img className="cover-pic" alt="" src={img || ''}/>
          <div className="info">
            <h4 className="l-title">{title || ''}</h4>
            <p className="desc">{content || ''}</p>
          </div>
        </div>
        <div className="theme-book-wrapper">
          <h4 className="l-title">主题书单</h4>
          <div className="l-list">
            {this.themeVoList.map((it) => (
              <img
                src={it.img}
                alt=""
                className="tag"
                key={it.id}
                onClick={() => this.toDetail('tag', it.id)}
              />
            ))}
          </div>
        </div>
        <div className="book-list-wrapper">
          {this.appAssignedBookListVoList.map((it) => (
            <div className="book-list" key={it.id}>
              <h4 className="book-list-title">{it.title || ''}</h4>
              <div className="l-list">
                {it.assignedBookList.map((i) => (
                  <div
                    className="l-item"
                    key={i.id}
                    onClick={() => this.toDetail('normal', it.id)}
                  >
                    <Book cover={i.coverPicture} size={180}/>
                    <p className="sale-title">{i.saleTitle}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="book-list">
            <h4 className="book-list-title">国际大奖获奖作品</h4>
            <div className="prize-book-list">
              {this.awardWinningWorks.map((it) => (
                <div
                  className="prize-item"
                  key={it.bookListId}
                  onClick={() => this.toDetail('normal', it.bookListId)}
                >
                  <div className="l-item">
                    <div className="blank"/>
                    <img className="img" src={it.img || ''} alt=""/>
                    <div className="info">
                      <div className="name">{it.title || ''}</div>
                      <p className="desc">{it.introduction || ''}</p>
                    </div>
                    <div className="link-btn">
                      查看书单 <span className="right-arrow"/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="book-list">
            <h4 className="book-list-title">国际绘本大家获奖作品</h4>
            <div className="author-list">
              {this.authors.map((it) => (
                <div
                  className="l-item"
                  key={it.bookListId}
                  onClick={() => this.toDetail('normal', it.bookListId)}
                >
                  <img className="avatar" src={it.img || ''} alt=""/>
                  <p className="author-name">{it.title || ''}</p>
                  <div className="l-btn">查看书单</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="press-list-wrapper">
          <h4 className="title-wrapper">
            <span className="line"/>
            国内顶级绘本品牌和出版社作品
            <span className="line"/>
          </h4>
          <div className="press-list">
            {this.pressList.map((it) => (
              <div className="l-item" key={it.id}>
                <img className="press" src={it.url} alt=""/>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default ExBookList

import React, { useEffect, useState } from 'react'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../../store'
import serv from '../../../lib/api'
import moment from 'moment'
import wx from 'weixin-js-sdk'

export default function EventExChangeCereal() {
  const store = useLocalStore(() => CommonStore)
  const [detail, setDetail] = useState(null)
  const [count, setCount] = useState(null)
  const { showLoading, hideLoading, showToast, showPageLoading, hidePageLoading, showModal, closeModal } = store

  document.title = '兑换谷粒'

  useEffect(() => {
    showPageLoading()
    getDetail().then(_ => {
      hidePageLoading()
    })
  }, [])

  /**
   * @description 获取详情
   * @returns {Promise<void>}
   */
  const getDetail = async () => {
    showLoading('加载中...')
    try {
      const res = await serv.getEventExChangeCerealDetail()
      if (res) {
        const { data } = res
        setDetail({ ...data })
      }
    } catch (err) {
      console.log(err)
    } finally {
      hideLoading()
    }
  }

  /**
   * @description 输入框回调
   * @param e
   */
  const handleInput = (e) => {
    let { value } = e.target
    const count = value * 1
    if (count < 0) {
      setCount(0)
    } else if (isNaN(count)) {
      showToast('请输入数字')
    } else {
      setCount(count)
    }
  }

  /**
   * @description 确认是否兑换
   */
  const confirmExChange = () => {
    if (!count || count === 0) {
      showToast('请填写数量')
      return
    }
    if (count > (detail?.totalCount - detail?.hasTransCount)) {
      showToast('超过最大兑换数量')
      return
    }
    const options = {
      title: '确认兑换',
      content: `谷粒：${count * 688}\n兑换的谷粒有效期：\n${moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD')} 00:00`,
      showCancelBtn: true,
      gravity: 0,
      onConfirm: async () => {
        await handleSubmit()
        closeModal()
      },
      onClose: () => {
        closeModal()
      }
    }
    showModal(options)
  }

  /**
   * @description 确认兑换
   * @returns {Promise<void>}
   */
  const handleSubmit = async () => {
    showLoading('请稍后...')
    try {
      const res = await serv.card2CerealConvert({ count })
      if (res) {
        const { data } = res
        if (data) {
          setCount(0)
          getDetail()
          wx.miniProgram.navigateTo({
            url: '/subPkg/main/pages/cereal-detail/index',
          })
        } else {
          showToast('兑换失败')
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      hideLoading()
    }
  }

  return (
    <div className="e-exchange-cereal-wrapper">
      <div className="info-card">
        <div className="count-item">
          <div className="label">可兑换总数</div>
          <div className="value">{detail?.totalCount || 0}<span className="unit">张</span></div>
        </div>
        <div className="count-item">
          <div className="label">已兑换</div>
          <div className="value">{detail?.hasTransCount || 0}<span className="unit">张</span></div>
        </div>
        <img src="https://cdn.kangarooread.com/mp-assets/event-exchange/event_exchange_cereal_card_icon_v2.png"
             className="img" alt=""/>
      </div>
      <div className="rule-wrapper">
        <div className="r-item">
          <div className="r-title"><i className="icon"/>参与对象</div>
          <div className="r-desc">好奇说分会长和推广人</div>
        </div>
        <div className="r-item">
          <div className="r-title"><i className="icon"/>时间</div>
          <div className="r-desc">2021年10月8日 10:00 - 10月31日 24:00</div>
        </div>
        <div className="r-item">
          <div className="r-title"><i className="icon"/>规则</div>
          <div className="r-desc">1. 分会长卡包内余卡大于10张，超出部分可申请兑换谷粒</div>
          <div className="r-desc">2. 推广人卡包内余卡大于3张，超出部分可申请兑换谷粒</div>
          <div className="r-desc">举例：分会A 余卡15张，可兑换5张；推广人B 余卡8张，可兑换5张</div>
          <div className="r-desc">3. 仅2021年9月28日24:00点前购买的囤卡可兑换成谷粒</div>
        </div>
      </div>
      <input type="text" className="e-exchange-input" value={count || ''} onChange={handleInput} placeholder="请输入兑换张数"/>
      <div
        className={`e-exchange-btn ${!count ? 'disabled' : ''}`}
        onClick={confirmExChange}>{count && count > 0 ? `确认兑换成${count * 688}谷粒` : '确认兑换'}</div>
    </div>
  )
}

import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import './index.less'
import { env } from '../../lib/conf'

export default function List(props) {
  const { dataSource, hasMore, loaded, next, children, endStr, empty } = props
  return (
    <>
      <InfiniteScroll
        dataLength={dataSource.length}
        next={next}
        hasMore={hasMore}
        loader={<div className="loading">正在努力加载中...</div>}
        scrollThreshold="50px">
        {children}
      </InfiniteScroll>
      {loaded &&
      !hasMore &&
      dataSource.length > 1 ? (
        <div className="loading">{endStr || '没有更多信息了~'}</div>
      ) : null}
      {(loaded && dataSource.length === 0) ? (
        <div className="no-data">
          <div>
            <img src={`${env.IMG}m-web/record_no_data.png`} alt=""/>
          </div>
          <div>{empty || '暂时还没有相关信息哦 ~'}</div>
        </div>
      ) : null}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'
import { Util } from '../../lib/util'
import serv from '../../lib/api'

export default function SearchCertification() {
	const store = useLocalStore(() => CommonStore)
	const [code, setCode] = useState('')
	const [identityCard, setIdentityCard] = useState('')
	const [result, setResult] = useState('')
	const { showLoading, hideLoading, showToast, showModal, closeModal } = store

	document.title = '查询证书'

	const initData = () => {
		const queryCode = Util.getLocalStorage('query_code')
		queryCode && setCode(queryCode)
		const code = Util.getParams('code') || ''
		const identityCard = Util.getParams('identityCard') || ''
		if (code && identityCard) {
			handleQuery(code, identityCard)
		}
	}

	useEffect(() => {
		initData()
	}, [])

	const handleQuery = async (c, i) => {
		let params = {}
		if (c && i) {
			params = {
				code: c,
				identityCard: i
			}
		} else {
			if (!code) {
				showToast('请填写编码')
				return
			}
			if (!identityCard) {
				showToast('请填写身份证号')
				return
			}
			params = {
				code,
				identityCard
			}
		}
		showLoading('查询中...')
		try {
			const res = await serv.readerAbilityHome(params)
			if (res) {
				const { data } = res
				if (data) {
					setResult(data || '')
					Util.setLocalStorage('query_code', code)
				} else {
					const options = {
						title: '查询失败',
						content: `未找到有效证书，\n请核实姓名或身份证号再试试`,
						okBtnText: '确定',
						showCancelBtn: false,
						gravity: 1,
						onConfirm: () => {
							closeModal()
						}
					}
					showModal(options)
				}
			}
		} catch (err) {
			console.log(err)
		} finally {
			hideLoading()
		}
	}

	const handleInput = (e, fn) => {
		const { value } = e.target
		fn && fn(value)
	}

	return <div className="s-c-wrapper">
		{result ? <>
			<div className="s-c-result-tip">长按保存证书</div>
			<img src={result} style={{
				display: 'block',
				width: '100%'
			}} alt=""/>
		</> : <>
			<img src="https://cdn.kangarooread.com/mp-assets/search_certification_banner.jpg" className="s-c-img" alt=""/>
			<div className="form">
				<div className="form-item">
					<input className="input" type="text" placeholder="请输入证书编号" value={code}
					       onChange={(e) => handleInput(e, setCode)}/>
				</div>
				<div className="form-item">
					<input className="input" type="text" placeholder="请输入身份证号码" value={identityCard}
					       onChange={(e) => handleInput(e, setIdentityCard)}/>
				</div>
			</div>
			<div className="button" onClick={handleQuery}>查询</div>
		</>}
	</div>
}

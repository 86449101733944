import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import { env, storage } from '../../../lib/conf'
import { bridge } from '../../../lib/bridge'
import { action, observable } from 'mobx'
import './index.less'
import serv from '../../../lib/api'
import { Util } from '../../../lib/util'

const { IMG } = env

@inject('store')
@observer
class EventPlayTogether extends Component {
  @observable env = ''
  @observable currentGender = 2
  @observable phone = ''
  @observable userInfo = {}
  @observable targetInfo = {}
  @observable detail = {}
  @observable bindInfo = {}
  myRef = React.createRef()

  gender = [
    { id: 2, label: '丈夫' },
    { id: 1, label: '妻子' },
  ]

  handleScrollToElement = () => {
    window.scrollTo(300, this.myRef.current.offsetTop)
  }

  async componentDidMount() {
    document.title = '夫妻共学，伴学半价'
    this.getEnv()
    await this.checkUser()
    await this.getHomeRelation()
    wx.miniProgram.postMessage({
      data: {
        title: '邀请你参加夫妻共学，伴学半价',
        imageUrl: `${IMG}event_play_together_share_img.png`,
        path: `/subPkg/event/pages/play-together/index?shareId=${this.userInfo.id}`
      }
    })
  }

  @action
  getEnv = () => {
    const { env } = this.props.store
    this.env = env
  }

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  checkUser = async () => {
    const { showToast } = this.props.store
    if (this.env === 'app') {
      bridge.getUserInfoFromApp().then((res) => {
        if (res) {
          this.userInfo = { ...res }
        } else {
          bridge.loginFromApp().then((userInfo) => {
            if (userInfo) {
              this.userInfo = { ...userInfo }
            } else {
              showToast('请先登录')
            }
          })
        }
      })
    } else {
      try {
        const token = Util.getParams('token')
        Util.setLocalStorage(
          storage.userInfo,
          JSON.stringify({ accountVo: { token: token || '' } })
        )
        if (token) {
          await this.fetchUser()
        } else {
          wx.miniProgram.navigateTo({
            url: '/pages/login/index'
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  /**
   * @description 获取用户信息（小程序及H5使用）
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取绑定关系
   * @returns {Promise<void>}
   */
  getHomeRelation = async () => {
    const { showLoading, hideLoading } = this.props.store
    showLoading('加载中...')
    try {
      const res = await serv.getHomeRelation()
      if (res) {
        const { data } = res
        this.detail = { ...(data || {}) }
        const { husbandName, husbandUserId, husbandPhone, wifeName, wifePhone } = data || {}
        if (husbandUserId === this.userInfo.id) {
          this.bindInfo = {
            name: wifeName,
            phone: wifePhone,
            gender: 2,
          }
        } else {
          this.bindInfo = {
            name: husbandName,
            phone: husbandPhone,
            gender: 1,
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  /**
   * @description 切换称谓
   * @param id
   */
  @action
  selectGender = (id) => {
    if (id === this.currentGender) {
      this.currentGender = ''
    } else {
      this.currentGender = id
    }
  }

  /**
   * @description 输入回调
   * @param e
   */
  handleInput = (e) => {
    const { value } = e.target
    this.phone = value
  }

  /**
   * @description 提交验证
   * @returns {Promise<void>}
   */
  handleSubmit = async () => {
    const { showToast } = this.props.store
    if (!this.currentGender) {
      showToast('请选择伴侣称谓')
      return
    }
    if (this.phone.trim().length === 0) {
      showToast('手机号不能为空')
      return
    }
    await this.getUserInfoByPhone()
  }

  /**
   * @description 根据手机获取用户信息
   * @returns {Promise<void>}
   */
  getUserInfoByPhone = async () => {
    const { showToast } = this.props.store
    try {
      const res = await serv.getUserinfoByPhone(this.phone)
      if (res) {
        const { data } = res
        if (data) {
          const { nickname, avatar } = data || {}
          this.targetInfo = {
            nickname, avatar
          }
          if (this.env === 'app') {
            this.openAppModal()
          } else {
            this.openModal()
          }
        } else {
          showToast('无法找到该用户')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 提交
   */
  submit = async () => {
    const { showLoading, hideLoading, showToast } = this.props.store
    showLoading()
    const params = {
      phoneNum: this.phone,
      type: this.currentGender
    }
    try {
      const res = await serv.commitHomeRelation(params)
      if (res) {
        const { data } = res
        if (data) {
          showToast('绑定成功')
          await this.getHomeRelation()
        } else {
          showToast('绑定失败')
        }
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  /**
   * @description 唤起APP弹窗
   */
  openAppModal = () => {
    const { nickname } = this.targetInfo
    const option = {
      title: '确认提交',
      content: `手机号 ${this.phone}\n昵  称 ${nickname}\n\n家庭关系一经绑定，无法解除，请仔细核对伴侣信息`,
      okBtnText: '确定',
      cancelBtnText: '取消',
      showCancelBtn: 'true',
      gravity: 2
    }
    bridge.appShowDialog(option).then(async (res) => {
      if (res === 'ok') {
        await this.submit()
      } else if (res === 'cancel') {
        console.log('点击了取消按钮')
      }
    })
  }

  openModal = () => {
    const { nickname } = this.targetInfo
    const { showModal, closeModal } = this.props.store
    const options = {
      title: '确认提交',
      content: `手机号 ${this.phone}\n昵  称 ${nickname}\n\n家庭关系一经绑定，无法解除，请仔细核对伴侣信息`,
      okBtnText: '确定',
      cancelBtnText: '取消',
      showCancelBtn: true,
      gravity: 2,
      onConfirm: async () => {
        await this.submit()
        closeModal()
      },
      onClose: () => {
        closeModal()
      }
    }
    showModal(options)
  }

  /**
   * @description 回调
   */
  handleShare = () => {
    if (this.env === 'app') {
      this.shareMessage()
    } else {
      const { showModal, closeModal } = this.props.store
      const options = {
        title: '转发提示',
        content: `请点击右上角 "..." 进行转发`,
        okBtnText: '知道了',
        gravity: 0,
        onConfirm: async () => {
          closeModal()
        }
      }
      showModal(options)
    }
  }

  /**
   * @description app分享
   */
  shareMessage = () => {
    const { id } = this.userInfo
    const option = {
      title: '邀请你参加夫妻共学，伴学半价',
      thumb: `${IMG}event_play_together_share_img.png`,
      path: `subPkg/event/pages/play-together/index?shareId=${id}`
    }
    bridge.appShareWechat(option).then((res) => {
      if (res) {
        const { showToast } = this.props.store
        showToast('分享成功')
      }
    })
  }

  render() {
    const { bindRelation } = this.detail
    const { name, gender, phone } = this.bindInfo

    return (
      <div className="event-play-together-wrapper">
        <div className="rule-tag" onClick={() => this.handleScrollToElement()}>活动规则</div>
        {/*<div className="banner">*/}
        {/*  <img src={`${IMG}event_play_together_img_1.png`} className="img" alt=""/>*/}
        {/*</div>*/}
        {bindRelation
          ? <Fragment>
            <div className="bind-info">
              <img src={`${IMG}event_play_together_${gender === 1 ? 'male' : 'female'}.png`} className="bing-info-img"
                   alt=""/>
              <div className="bind-info-right">
                <div className="bind-info-name-wrapper">
                  <div className="name">{name || ''}</div>
                  <div className={`tag tag-${gender || 0}`}>你的{gender === 1 ? '丈夫' : '妻子'}</div>
                </div>
                <div className="phone">{phone && phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</div>
              </div>
            </div>
            <div className="submit-btn f-28" onClick={() => this.handleShare()}>邀请TA报名故事妈妈赋能营，TA可半价学习</div>
          </Fragment>
          : <Fragment>
            <div className="form-title">输入你伴侣的信息</div>
            <div className="form-wrapper">
              <div className="label">TA是你的</div>
              <div className="radio-wrapper">
                {this.gender.map(it => (
                  <button
                    className={`radio ${this.currentGender === it.id ? 'selected' : ''}`}
                    key={it.id}
                    onClick={() => this.selectGender(it.id)}
                  >
                    {it.label}
                  </button>
                ))}
              </div>
              <input value={this.phone} placeholder="手机号" className="phone-input" onChange={this.handleInput}/>
            </div>
            <div className="submit-btn" onClick={() => this.handleSubmit()}>确定</div>
          </Fragment>
        }
        <div className="rule-content" ref={this.myRef}>
          <div className="rule-title">
            <div className="icon"/>
            活动规则
          </div>
          <div className="rule-list">
            <div>1. 活动时间：2021年1月18日0点-2021年2月8日24点；</div>
            <div>2. 已报名30天故事妈妈赋能营的用户，填写配偶信息，邀请配偶报名，配偶享受半价学习权益；</div>
            <div>3. 配偶信息一旦提交不可更改。资料经过后台二次审核后确认半价返现资格；</div>
            <div>4. 如何享受半价：被活动邀请的配偶学员原价支付，2月28日开营后优惠金额原账号返回；</div>
            <div>5. 活动订单无法退款。</div>
            <div>最终解释权归好奇说绘本所有</div>
          </div>
        </div>
      </div>
    )
  }
}

export default EventPlayTogether

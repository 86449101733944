import React, { useEffect, useState } from 'react'
import wx from 'weixin-js-sdk'
import CommonStore from '../../../store'
import { useLocalStore } from 'mobx-react'
import ActionSheet from '../../../components/ActionSheet'
import html2canvas from 'html2canvas'
import { bridge } from '../../../lib/bridge'
import serv from '../../../lib/api'
import './index.less'

const IMG_LIST = [
	'https://cdn.kangarooread.com/resource/event/event_5_share_detail_1.jpg'
]

export default (props) => {
	const store = useLocalStore(() => CommonStore)
	const {
		env: runtimeEnv,
		userInfo,
		showLoading,
		hideLoading,
		showModal,
		closeModal
	} = store
	const [shareVisible, setShareVisible] = useState(false)
	const [qrCode, setQrCode] = useState('')

	document.title = '邀请有礼'

	const initShareInfo = () => {
		const { id: userId } = userInfo || {}
		// bridge.jsShowShareWechat(true)
		// bridge.jsShareWechat({
		// 	title: '邀请你加入「好读家庭」儿童订阅计划',
		// 	thumb: `https://cdn.kangarooread.com/resource/event/event_5_share_card.jpg`,
		// 	path: `subPkg/main/pages/new-subscribe-detail/index?shareId=${userId}&source=EVENT_5_SHARE`
		// })
		wx.miniProgram.postMessage({
			data: {
				title: '邀请你加入「好读家庭」儿童订阅计划',
				imageUrl:
					'https://cdn.kangarooread.com/resource/event/event_5_share_card.jpg',
				path: `/subPkg/main/pages/new-subscribe-detail/index?shareId=${userId}&source=EVENT_5_SHARE`
			}
		})
	}

	const getQrcode = async () => {
		const { id: userId } = userInfo
		const path = 'subPkg/main/pages/new-subscribe-detail/index'
		const scene = `m;;;${userId || ''};1;NO_168`
		const type = 23
		const params = {
			path,
			scene,
			type
		}
		try {
			const res = await serv.getQrCode(params)
			if (res) {
				const { cdnUrl } = res.data || {}
				setQrCode(cdnUrl)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const reportVisit = () => {
		const { id: userId } = userInfo
		serv.dataTrackingStorage({
			current: 'EVENT5SHAREPAGE',
			content: JSON.stringify({ userId })
		})
	}

	const initData = () => {
		initShareInfo()
		getQrcode()
		reportVisit()
	}

	useEffect(() => {
		initData()
	}, [])

	const showShareVisible = () => {
		setShareVisible(true)
		serv.dataTrackingStorage({
			current: 'EVENT5SHARE'
		})
	}

	const closeShareVisible = () => {
		setShareVisible(false)
	}

	const shareWechat = () => {
		const { id: userId } = userInfo
		if (runtimeEnv === 'mp') {
			showModal({
				content: '请点击右上角"..."进行转发',
				okBtnText: '知道了',
				showCancelBtn: false,
				gravity: 1,
				onConfirm: () => {
					closeModal()
				}
			})
		} else {
			bridge.appShareWechat({
				title: '邀请你加入「好读家庭」儿童订阅计划',
				thumb: `https://cdn.kangarooread.com/resource/event/event_5_share_card.jpg`,
				path: `subPkg/main/pages/new-subscribe-detail/index?shareId=${userId}&source=EVENT_5_SHARE`
			})
		}
		closeShareVisible()
		serv.dataTrackingStorage({
			current: 'EVENT5SHAREWECHAT',
			content: JSON.stringify({ userId })
		})
	}

	const shareImg = () => {
		if (runtimeEnv === 'mp') {
			wx.miniProgram.navigateTo({
				url: `/subPkg/extend/pages/img-preview-page/index?type=event5Share`
			})
			closeShareVisible()
		} else if (runtimeEnv === 'app') {
			const node = document.getElementById('poster-wrapper')
			closeShareVisible()
			showLoading('绘制中...')
			html2canvas(node, {
				allowTaint: true,
				useCORS: true,
				scrollY: 0
			})
				.then((canvas) => {
					if (canvas) {
						const src = canvas.toDataURL('image/jpg')
						bridge.appShareBase64(src)
					}
					hideLoading()
				})
				.catch((err) => {
					console.log(err)
					hideLoading()
				})
		}
		const { id: userId } = userInfo
		serv.dataTrackingStorage({
			current: 'EVENT5SHAREPOSTER',
			content: JSON.stringify({ userId })
		})
	}

	const toRecord = () => {
		props.history.push('/event-5-share-record')
	}

	const { accountVo } = userInfo || {}
	const { nickname: n, avatar: a } = accountVo || {}

	return (
		<div
			style={{
				position: 'relative',
				zIndex: 1,
				paddingBottom: 100
			}}>
			<img
				src='https://cdn.kangarooread.com/mp-assets/invite/invite_record_btn_v3.png'
				className='invite-record-btn'
				alt=''
				onClick={toRecord}
			/>
			{IMG_LIST.map((img, idx) => (
				<img
					style={{
						width: '100%',
						display: 'block'
					}}
					src={img}
					alt=''
					key={idx}
				/>
			))}
			<div
				style={{
					position: 'fixed',
					zIndex: 2,
					left: '50%',
					bottom: 20,
					transform: 'translateX(-50%)',
					width: '80%',
					height: '45px',
					lineHeight: '45px',
					textAlign: 'center',
					color: 'white',
					fontSize: 16,
					borderRadius: 25,
					background: '#FFCE40'
				}}
				onClick={showShareVisible}>
				邀请好友加入「好读家庭」
			</div>

			{shareVisible && (runtimeEnv === 'app' || runtimeEnv === 'h5') && (
				<div className='share-img-wrapper'>
					<div className='share-img' style={{ transform: 'scale(.7)' }}>
						<img
							src='https://cdn.kangarooread.com/resource/event/event_5_share_poster.png'
							className='img'
							alt=''
						/>
						<div className='info-wrapper'>
							<img src={a} className='avatar' alt='' />
							<div className='text-wrapper'>
								<div className='name-wrapper'>
									<span className='name'>{n}</span>
									{/* <span className='text-1'>点击购买</span> */}
								</div>
								<div className='text-2'>点击购买，得专属书袋礼</div>
							</div>
							<img src={qrCode} className='qr-code' alt='' />
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					position: 'fixed',
					left: -1000,
					top: -99999
				}}>
				<div className='share-img' id='poster-wrapper'>
					<img
						src={`https://cdn.kangarooread.com/resource/event/event_5_share_poster.png?timeStamp=${new Date().getTime()}`}
						className='img'
						alt=''
						crossOrigin='anonymous'
					/>
					<div className='info-wrapper'>
						<img
							src={`${a}?timeStamp=${new Date().getTime()}`}
							className='avatar'
							alt=''
							crossOrigin='anonymous'
						/>
						<div className='text-wrapper'>
							<div className='name-wrapper'>
								<span className='name'>{n}</span>
								{/* <span className='text-1'>点击购买</span> */}
							</div>
							<div className='text-2'>点击购买，得专属书袋礼</div>
						</div>
						<img
							src={`${qrCode}?timeStamp=${new Date().getTime()}`}
							className='qr-code'
							alt=''
							crossOrigin='anonymous'
						/>
					</div>
				</div>
			</div>

			<ActionSheet
				visible={shareVisible}
				bg='#F6F6F6'
				showCloseIcon={false}
				onClose={closeShareVisible}>
				<div className='op-wrapper'>
					<div className='item' onClick={shareWechat}>
						<img
							src='https://cdn.kangarooread.com/mp-assets/common/wechat_icon.png'
							alt=''
							className='icon'
						/>
						<div className='label'>微信</div>
					</div>
					<div className='item' onClick={shareImg}>
						<img
							src='https://cdn.kangarooread.com/mp-assets/common/pic_icon.png'
							alt=''
							className='icon'
						/>
						<div className='label'>保存图片</div>
					</div>
				</div>
				<div className='share-close-btn' onClick={closeShareVisible}>
					取消
				</div>
			</ActionSheet>
		</div>
	)
}

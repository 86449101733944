import React from 'react'

export default function SelectInput(props) {
  return (
    <div className={`select-option-wrapper select ${props.selected ? 'active' : ''}`}>
      <span className={`${props.selected ? 'select-icon' : 'default-icon'}`}/>
      <div className="text">{props.content}</div>
    </div>
  )
}

import React, { Component } from 'react'
import { DatePicker, List, Picker } from 'antd-mobile'
import './index.less'
import moment from 'moment'
import { bridge } from '../../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../../lib/api'
import { inject, observer } from 'mobx-react'
import { action, computed, observable } from 'mobx'
import { Util } from '../../lib/util'
import { storage } from '../../lib/conf'


@inject('store')
@observer
class MaintenanceInfo extends Component {
  @observable date = ''
  @observable address = ''
  @observable needArea = ''
  @observable needBirthday = ''
  @observable birthdayInfo = {}
  @observable flag = 1
  @observable province = ''
  @observable city = ''
  @observable county = ''
  @observable datePickerVisible = false
  @observable newDate = ''

  @computed get getEnv() {
    return this.props.store.env
  }

  componentDidMount() {
    document.title = '信息完善'
    let _user = Util.getLocalStorage(storage.userInfo) || '{}'
    _user = JSON.parse(_user) || null
    if (_user && _user.accountVo && _user.accountVo.token) {
      this.getBirthdayInfo()
    } else {
      const env = this.getEnv()
      if (env === 'app') {
        this.checkUser()
        .then(_ => {
          this.getBirthdayInfo()
        })
      }
    }
  }

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  @action
  checkUser = () => {
    const { showToast, updateUserInfo } = this.props.store
    return new Promise((resolve, reject) => {
      bridge.getUserInfoFromApp().then((res) => {
        if (res) {
          updateUserInfo(res)
          resolve()
        } else {
          bridge.loginFromApp().then((user) => {
            if (user) {
              updateUserInfo(user)
              resolve()
            } else {
              showToast('请先登录')
              reject()
            }
          })
        }
      })
    })
  }

  /**
   * @desc  选择日期
   * @param value
   */
  @action
  submitDate = (value) => {
    // setDate(value)
    this.date = value
  }

  /**
   * @desc 选择地点
   * @param value
   */
  @action
  submitAddress = (value) => {
    const { defaultAddress } = this.props.store
    const [provinceValue, cityValue, countyValue] = value
    let province, city, county
    province = defaultAddress.find((it) => (it.value === provinceValue))
    city = province && province.children && province.children.find((it) => (it.value === cityValue))
    county = city && city.children && city.children.find((it) => (it.value === countyValue))
    this.province = province && province.label
    this.city = city && city.label
    this.county = county && county.label
    this.address = value
  }

  /**
   * @desc 页面确认
   */
  submitInfo = async () => {
    const { showToast, env } = this.props.store
    const params = {
      province: this.province,
      city: this.city,
      county: this.county
    }
    if (this.birthdayInfo.needBirthday) {
      params.birthday = moment(this.date).format('YYYY-MM-DD')
    }
    if ((this.birthdayInfo.needBirthday && (params.birthday.indexOf('-') === -1 || !params.province)) || (!this.birthdayInfo.needBirthday && !params.province))
      return showToast('请填写全部信息')
    try {
      const res = await serv.submitBirthdayInfo(params)
      if (res) {
        if(res.data){
          showToast('提交成功')
          if (env === 'app') {
            if (Util.appEnv() === 'ios') {
              bridge.appClose({ id: 119 })
            } else {
              bridge.appClose()
              bridge.appNavigate({ id: 119 })
            }
          } else if (env === 'mp') {
            wx.miniProgram.navigateTo({
              url: '/subPkg/extend/pages/my-info/index'
            })
          }
        }else{
          showToast('提交失败')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  getBirthdayInfo = async () => {
    try {
      const res = await serv.getBirthdayInfo()
      if (res) {
        const { data } = res || {}
        this.birthdayInfo = data
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const { defaultAddress } = this.props.store

    return (
      <div className="info-bg">
        <div className="msg">{this.birthdayInfo.headerStr}</div>
        <List className="date-picker-list" style={{ backgroundColor: 'white' }}>
          {this.birthdayInfo.needBirthday
            ? <DatePicker
              mode="date"
              title="生日"
              extra={!this.date && <span style={{ color: '#bbb' }}>请选择</span>}
              onOk={this.submitDate}
              value={this.date}
              maxDate={new Date()}
              minDate={new Date(1950, 1, 1, 0, 0, 0)}
            >
              <List.Item arrow="horizontal">请选择生日</List.Item>
            </DatePicker> : null
          }
          <div className={`arrow-line`}/>
          <Picker
            extra={!this.address && <span style={{ color: '#bbb' }}>请选择</span>}
            data={defaultAddress}
            title="经营地区"
            onOk={this.submitAddress}
            value={this.address}
            cascade
          >
            <List.Item arrow="horizontal">请选择经营地区</List.Item>
          </Picker>
        </List>
        <div className="line"/>
        <div className="btn" onClick={this.submitInfo}>确认提交</div>
      </div>
    )
  }
}


export default MaintenanceInfo


import React from 'react'
import { env } from '../../../lib/conf'
import './index.less'

export default function TalkShowAppointment() {
  return <>
    <div className="talk-show-page-title">长按保存到手机，微信扫一扫-选择右下角图片，进入“好奇说”视频号后，点击“预约”按钮，即可预约直播</div>
    <img src={env.IMG + 'talkShow/talk_show_qrcode.jpg'} className="talk-show-page-qrcode" alt=""/>
    <img src={env.IMG + 'talkShow/talk_show_pic_v2.png'} className="talk-show-page-img" alt=""/>
  </>
}

import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable, toJS } from 'mobx'
import './index.less'

@inject('store')
@observer
class Question extends Component {
  @observable singleCurrent = ''
  @observable multipleList = []
  @observable multipleShowLaberList = []

  handleSingleClick = (v, showLaber) => {
    const { onChange, questionItem, currentIdx } = this.props
    const { displayOrder, testId, questionId } = questionItem
    this.singleCurrent = v
    const params = {
      optionId: v + '',
      displayOrder: displayOrder,
      testId: testId,
      questionId: questionId,
      showLaber
    }
    onChange(params)
  }

  handleMultipleClick = (v, showLaber) => {
    const { onChange, questionItem, currentIdx } = this.props
    const { displayOrder, testId, questionId } = questionItem

    if (this.multipleList.includes(v)) {
      const index = this.multipleList.findIndex((it) => it === v)
      this.multipleList.splice(index, 1)
      this.multipleShowLaberList.splice(index, 1)
    } else {
      this.multipleList.push(v)
      this.multipleShowLaberList.push(showLaber)
    }


    const params = {
      optionId: this.multipleList.join(','),
      displayOrder: displayOrder,
      testId: testId,
      questionId: questionId,
      showLaber: this.multipleShowLaberList.join(',')
    }
    onChange(params)
  }

  render() {
    const {
      title,              //问题题目
      optionList,         //问题选项
      multiple = false,   //是否多选
      currentIdx, //当前题号
    } = this.props

    return (
      <div className="q-container">
        {
          multiple ?
            <div className="q-wrapper">
              <div className="title-wrapper">
                <div className="q-number">{`${currentIdx}、`}</div>
                <div>{title}</div>
              </div>
              <div className="q-line"/>
              {
                optionList && optionList.map((it, index) => (
                  <div className={'q-item'} key={index}
                       onClick={() => this.handleMultipleClick(it.optionId, it.showLaber)}>
                    <div className={`q-option-icon ${this.multipleList.includes(it.optionId) ? 'active' : ''}`}/>
                    <div className="q-item-content">{`${it.showLaber}：${it.content}`}</div>
                  </div>
                ))
              }
            </div> :
            <div className="q-wrapper">
              <div className="title-wrapper">
                <div className="q-number">{`${currentIdx}、`}</div>
                <div>{title}</div>

              </div>
              <div className="q-line"/>
              {
                optionList && optionList.map((it, index) => (
                  <div className={'q-item'} key={index}
                       onClick={() => this.handleSingleClick(it.optionId, it.showLaber)}>
                    <div className={`q-option-icon ${this.singleCurrent === it.optionId && 'active'}`}/>
                    <div className="q-item-content">{`${it.showLaber}：${it.content}`}</div>
                  </div>
                ))
              }
            </div>
        }
      </div>

    )
  }
}

export default Question
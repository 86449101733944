import React, { useEffect, useState } from 'react'
import PM_LIST from './config'
import './index.less'

export default function ProductManual(props) {
  const [item, setItem] = useState(null)

  const initData = () => {
    const { id } = props.match.params
    const currentItem = PM_LIST.find(it => it.id === id * 1)
    currentItem && setItem(currentItem)
  }

  useEffect(() => {
    initData()
  }, [])


  return <div className="product-manual-wrapper">
    {item && <>
      <video controls className="video">
        <source src={item.url}/>
      </video>

      <div className="desc">{item.title}</div>
    </>}

  </div>
}

import React, { useEffect, useState } from 'react'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'
import serv from '../../lib/api'
import './index.less'
import moment from 'moment'
import ActionSheet from '../../components/ActionSheet'
import NoData from '../../components/NoData'

export default function ReadInviteRecordDetail(props) {
	const store = useLocalStore(() => CommonStore)
	const {
		userInfo
	} = store

	const [detail, setDetail] = useState(null)
	const [list, setList] = useState([])
	const [totalCount, setTotalCount] = useState(0)
	const [visible, setVisible] = useState(false)
	const [currentUser, setCurrentUser] = useState(null)

	const initData = async () => {
		await getDetail()
		await getInviteUserDetail()
	}

	const getDetail = async () => {
		try {
			const res = await serv.inviteActivityDetail()
			if (res) {
				const { data } = res
				const detail = {
					...data
				}
				setDetail(detail)
			}
		} catch (err) {
			console.log(err)
		}
	}

	const getInviteUserDetail = async () => {
		const params = {
			size: 10,
			pageNum: 1
		}
		try {
			const res = await serv.inviteUserDetail(params)
			if (res) {
				const { data, dataCount } = res
				setList(data || [])
				setTotalCount(dataCount)
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		initData()
	}, [])

	const showActionSheet = (userInfo, mine) => {
		setCurrentUser({ ...userInfo, mine })
		setVisible(true)
	}

	const closeActionSheet = () => {
		setVisible(false)
	}

	const toInviteUserList = () => {
		props.history.push('/read-invite-user')
	}

	const {
		myInfo
	} = detail || {}

	const {
		avatar,
		nickname,
		startTime,
		breakOffTime,
		readType,
		mine,
		onceCouponCount,
		receiveInvitationTime,
		rewardsPoints,
		rewardsType,
		unlockCoupon: cUnlockCoupon,
		totalCoupon: cTotalCoupon
	} = currentUser || {}

	const { level } = userInfo || {}

	return <div className="read-invite-record-detail-wrapper">
		{level && level > 0 ? (
			<div className="level-info" style={{
				marginBottom: 30
			}}>
				亲爱的领读者：「共读有礼」为会员活动，领读者邀请会员订阅双方均不享受活动奖励
			</div>
		) : null}

		{myInfo && (
			<div
				className="list-wrapper"
				style={{ marginBottom: 32, paddingLeft: 0, paddingRight: 0 }}>
				<img
					src="https://cdn.kangarooread.com/mp-assets/invite/label_invite_me.png"
					className="label invited-label"
					alt=""
				/>
				<div className="invite-list">
					<div
						className="invite-item"
						onClick={() => showActionSheet(myInfo, true)}>
						<img src={myInfo.avatar} className="avatar" alt=""/>
						<div className="info-wrapper">
							<div className="info">
								<div className="nickname">我</div>
								{myInfo.rewardsType === 1 && (myInfo.readType === 1 || myInfo.readType === 4) && (
									<div className="progress-info">
										<div
											className="current"
											style={{
												width: `${
													(myInfo.unlockCoupon / myInfo.totalCoupon) *
													100
												}%`
											}}
										/>
									</div>
								)}
							</div>
							<div className="desc-wrapper">
								{myInfo.rewardsType === 1 && <>
									<div className="desc">
										已共读 {myInfo.readCount || 0} 个月
										{(myInfo.readType === 2 || myInfo.readType === 3) &&
											'，已中断'}
									</div>
									{myInfo.readType === 1 ? (
										<>
											{myInfo.unlockCoupon < myInfo.totalCoupon && (
												<div className="message">
													我已获得 {myInfo.unlockCoupon} 张 共{' '}
													{myInfo.totalCoupon} 张{' '}
												</div>
											)}
											{myInfo.unlockCoupon === myInfo.totalCoupon && (
												<div className="message">
													我已获得 {myInfo.unlockCoupon} 张
												</div>
											)}
										</>
									) : (
										<div className="message">
											我共获得 {myInfo.unlockCoupon} 张
										</div>
									)}
									<img
										src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
										className="right-arrow"
										alt=""
									/></>}
								{myInfo.rewardsType === 2 && myInfo.readType === 1 && <>
									<div className="desc">
										订阅成功，留书券奖励+{myInfo.onceCouponCount || 0}
									</div>
									<img
										src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
										className="right-arrow"
										alt=""
									/>
								</>}
								{myInfo.rewardsType === 3 && myInfo.readType === 1 && <>
									<div className="desc">
										邀请成功，积分奖励+{myInfo.rewardsPoints || 0}
									</div>
									<img
										src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
										className="right-arrow"
										alt=""
									/>
								</>}
							</div>
						</div>
					</div>
				</div>
			</div>
		)}

		<div
			className="list-wrapper"
			style={{ paddingLeft: 0, paddingRight: 0 }}>
			<img
				src="https://cdn.kangarooread.com/mp-assets/invite/label_invite.png"
				className="label invited-label"
				alt=""
			/>
			<div className="invite-list">
				{list && list.map((it) => (
					<div
						className="invite-item"
						key={it.userId}
						onClick={() => showActionSheet(it, false)}>
						<img src={it.avatar} className="avatar" alt=""/>
						<div className="info-wrapper">
							<div className="info">
								<div className="nickname">{it.nickname || ''}</div>
								{it.rewardsType === 1 && (it.readType === 1 || it.readType === 4) && (
									<div className="progress-info">
										<div
											className="current"
											style={{
												width: `${
													(it.unlockCoupon / it.totalCoupon) * 100
												}%`
											}}
										/>
									</div>
								)}
							</div>
							<div className="desc-wrapper">
								{it.rewardsType === 1 && <>
									<div className="desc">
										已与你共读 {it.readCount || 0} 个月
										{(it.readType === 2 || it.readType === 3) &&
											'，已中断'}
									</div>
									{it.readType === 1 ? (
										<>
											{it.unlockCoupon < it.totalCoupon && (
												<div className="message">
													我已获得 {it.unlockCoupon} 张 共{' '}
													{it.totalCoupon} 张{' '}
												</div>
											)}
											{it.unlockCoupon === it.totalCoupon && (
												<div className="message">
													我已获得 {it.unlockCoupon} 张
												</div>
											)}
										</>
									) : (
										<div className="message">
											我共获得 {it.unlockCoupon} 张
										</div>
									)}
									<img
										src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
										className="right-arrow"
										alt=""
									/>
								</>}
								{it.rewardsType === 2 && it.readType === 1 && <>
									<div className="desc">
										订阅成功，留书券奖励+{it.onceCouponCount || 0}
									</div>
									<img
										src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
										className="right-arrow"
										alt=""
									/>
								</>}
								{it.rewardsType === 3 && it.readType === 1 && <>
									<div className="desc">
										邀请成功，积分奖励+{it.rewardsPoints || 0}
									</div>
									<img
										src="https://cdn.kangarooread.com/mp-assets/common/right_arrow_icon.png"
										className="right-arrow"
										alt=""
									/>
								</>}
							</div>
						</div>
						<div className="split-line"/>
					</div>
				))}
			</div>
			{totalCount && totalCount > 10 ? <div className="more-btn" onClick={toInviteUserList}>
				查看全部{totalCount}人
			</div> : null}
			{list.length === 0 && <NoData emptyStr="暂无邀请记录~"/>}
		</div>

		<ActionSheet visible={visible} onClose={closeActionSheet}>
			<div className="invited-user-info">
				{visible && <img src={avatar} className="avatar" alt=""/>}
				<div className="nickname">{nickname}</div>
				{rewardsType === 1 && <>
					<div className="desc-item">
						<div className="label">开始共读</div>
						<div className="value">
							{moment(startTime).format('YYYY-MM-DD HH:mm')}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">当前状态</div>
						<div className="value">
							{readType === 1 || readType === 4 ? '共读中' : '已中断'}
							{readType === 2 && (
								<span className="error">
									{moment(breakOffTime).format('YYYY-MM-DD HH:mm')}{mine ? '我' : 'TA'}中断订阅
								</span>
							)}
							{readType === 3 && (
								<span className="error">
									{moment(breakOffTime).format('YYYY-MM-DD HH:mm')}{mine ? 'TA' : '我'}中断订阅
								</span>
							)}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">已为我解锁</div>
						<div className="value">{cUnlockCoupon || 0} 张共读留书券</div>
					</div>
					<div className="desc-item">
						<div className="label">待解锁</div>
						<div className="value">
							{(cTotalCoupon || 0) - (cUnlockCoupon || 0)} 张共读留书券
						</div>
					</div>
					<div className="tip">你与TA都连续订阅续费还可各获得的留书券数量</div>
				</>}
				{(rewardsType === 2 || rewardsType === 3) && <>
					<div className="desc-item">
						<div className="label">受邀时间</div>
						<div className="value">
							{moment(startTime).format('YYYY-MM-DD HH:mm')}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">订阅时间</div>
						<div className="value">
							{moment(receiveInvitationTime).format('YYYY-MM-DD HH:mm')}
						</div>
					</div>
					<div className="desc-item">
						<div className="label">奖励</div>
						<div className="value">
							{rewardsType === 2 && `${onceCouponCount || 0} 张共读留书券`}
							{rewardsType === 3 && `积分 +${rewardsPoints || 0}`}
						</div>
					</div>
				</>}
				<div className="close-btn" onClick={closeActionSheet}>
					关闭
				</div>
			</div>
		</ActionSheet>

	</div>
}

import React, { useEffect, useState } from 'react'
import Blank from '../../components/Blank'
import serv from '../../lib/api'
import moment from 'moment'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'

export default function RateServiceInfo(props) {
  const store = useLocalStore(() => CommonStore)
  const [detail, setDetail] = useState()
  const { showPageLoading, hidePageLoading, showModal, closeModal } = store
  document.title = '服务评分'

  const { pageTitle, myScoreTitle, myScoreIsOperate, deadLine, myGetScore, myUpCompanyName, myUpCompanyNickName, myGetScoreTitle, getScoreNum, myTeamTitle, myTeamNum, myScoreUserNum } = detail || {}

  const initData = () => {
    showPageLoading()
    getDetail()
    .then(() => {
      hidePageLoading()
    })
    .catch(() => {
      hidePageLoading()
    })
  }

  useEffect(() => {
    initData()
  }, [])

  const getDetail = async () => {
    try {
      const res = await serv.getServiceScoreInfo()
      if (res) {
        const { data } = res
        const { myGetScore, myScoreDeadLine } = data || {}
        data.getScoreNum =
          parseFloat(myGetScore).toString() === 'NaN'
            ? ''
            : parseFloat(myGetScore)
        const now = new Date().getTime()
        data.deadLine =
          now < myScoreDeadLine
            ? `${
              moment(myScoreDeadLine).format('MM月DD日')
            } 截止`
            : ''
        setDetail({ ...(data || {}) })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const showConfirmModal = () => {
    const options = {
      title: '你的打分对象',
      content: `上级：${myUpCompanyName}\n负责人：${myUpCompanyNickName}`,
      okBtnText: '去评价',
      showCancelBtn: true,
      gravity: 1,
      onConfirm: () => {
        toRate()
        closeModal()
      },
      onClose: () => {
        closeModal()
      }
    }
    showModal(options)
  }

  const toRate = () => {
    const { myUpCompanyId } = detail || {}
    const { push } = props.history
    push(`/rate-service-questionnaire/${myUpCompanyId || 0}`)
  }

  return (
    <div className="rate-service-wrapper">
      <div className="base-info">
        <div className="text-wrapper">
          <Blank height={20}/>
          <div className="text">
            为了推进长期的服务提升，在2020第一季度末，我们上线了【服务评分体系】；并且采用“自下而上”的评价方式：
            <span className="text-title">以推广人为最小评分单位，以好奇说总部为最大受评单位，每一级都向直属上级服务单位打分</span>
            （例：推广人对直属分会或服务中心打分、分会长对直属的服务中心或公司总部打分）。
          </div>
          <Blank height={20}/>
          <div className="text">每一份评分都将匿名处理，提交后无法查看、明细不公开。</div>
          <div className="text">初始评分数据将通过智能算法进行多个维度的分析处理，从而保障最终服务分的公平性与有效性。</div>
          <div className="text-title">请根据真实情况负责任评分。</div>
        </div>
        <div className="service-info">
          <div className="page-title">{pageTitle || ''}</div>
          <div className="info-list">
            <div className="rate-service-item">
              <div className="rate-service-item-desc">{myScoreTitle || ''}</div>
              {myScoreIsOperate === 1 ? <div className="status">已打分</div> : null}
              {myScoreIsOperate === -1 ? <div className="status">未开始</div> : null}
              {myScoreIsOperate === 0 && deadLine ?
                <>
                  <div className="rate-service-btn" onClick={() => showConfirmModal()}>马上打分</div>
                  <div className="dead-line">{deadLine}</div>
                </> : null
              }
              {myScoreIsOperate === 0 && !deadLine ? <div className="status">未打分</div> : null}
              <div className={`bottom ${myGetScore && myGetScore === 'NO' ? 'center' : ''}`}>
                <span>上级：{myUpCompanyName || ''}</span>
                <span>负责人：{myUpCompanyNickName || ''}</span>
              </div>
              <div className="line"/>
            </div>
            {myGetScore !== 'NO' ? <div className="rate-service-item">
              <div className="rate-service-item-desc">{myGetScoreTitle || ''}</div>
              {getScoreNum && getScoreNum > 0 ?
                <div className="score">
                  {getScoreNum}
                </div> : <div className="status">{myGetScore || ''}</div>
              }
              <div className="bottom">
                <span>{myTeamTitle || '直属团队'}：{myTeamNum || '0'}人</span>
                <span>打分人数：{myScoreUserNum || 0}人</span>
              </div>
            </div> : null}
          </div>
        </div>
      </div>
      <div className="role-info">
        <div className="role-title">
          <div className="title">
            细则说明
          </div>
        </div>
        <div className="text-wrapper">
          <div className="text">1. 打分须知：</div>
          <div className="text-item">1）下级服务单位给直属上级服务单位打分；</div>
          <div className="text-item">2）每一份评分都将匿名处理、提交后无法查看，明细不公开；</div>
          <div className="text-item">3）评分从团队的方向目标、氛围建设、解决问题等方面展开，每一个评价项目打分从1星到5星（1星为低、5星为高）；</div>
          <div className="text-item">4）评分的初始数据将进入定制的智能算法进行多个维度的数据处理，考虑多种影响因素，从而得出有效的综合服务分。</div>
          <div className="text">2. 特殊情况：</div>
          <div className="text-item">1）未在评分期间提交打分的用户，结束后默认以打1星计入上级的得分统计；</div>
          <div className="text-item">2）对于直属团队推广人小于5人的阅读分会，不参与得分统计。</div>
          <div className="text">3. 统计周期：</div>
          <div className="text-item">1）2022年1月10日10点-1月16日24点 评分开启；</div>
          <div className="text-item">2）2022年1月17日-1月18日 系统计分；</div>
          <div className="text-item">3）2022年1月19日统计完成，个人可查看自己的最终得分。</div>
          {/*<div className="text">4. 本次有效填写信息赠送50积分/人（11月15日发放）</div>*/}
        </div>
      </div>
      <Blank/>
    </div>
  )
}

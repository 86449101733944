import React from 'react'
import { env } from '../../../lib/conf'
import './index.less'

export default function TempMeeting() {
  return (
    <div className="temp-meeting-wrapper">
      <div className="item-wrapper">
        <div className="desc">
          <span role="img" aria-label="tiger">🐯</span> | 如虎添亿，如你所期<br/>
          <br/>
          每年讲故事的牛校长<br/>
          今年剧透什么新故事<br/>
          <br/>
          <span role="img" aria-label="search">🔍</span><span className="bold">扫码预约腾讯会议</span><br/>
          <span role="img" aria-label="get">🉐</span>锁定好奇说线上春晚<br/>
          1月30日晚8点，不见不散！<br/>
        </div>
        <img src={env.IMG + 'event/event_temp_meeting_img_1.jpg'} className="pic" alt=""/>
      </div>
      <div className="item-wrapper">
        <div className="desc">
          <span role="img" aria-label="tiger">🐯</span> | 如虎添亿，如你所期<br/>
          <br/>
          听犟犟老师讲述<br/>
          那些故事妈妈的妈妈故事<br/>
          <br/>
          <span role="img" aria-label="search">🔍</span><span className="bold">扫码预约腾讯会议</span><br/>
          <span role="img" aria-label="get">🉐</span>锁定好奇说线上春晚<br/>
          1月30日晚8点，不见不散！<br/>
        </div>
        <img src={env.IMG + 'event/event_temp_meeting_img_2.jpg'} className="pic" alt=""/>
      </div>
      <div className="item-wrapper">
        <div className="desc">
          <span role="img" aria-label="tiger">🐯</span> | 如虎添亿，如你所期<br/>
          <br/>
          牵手绘本界神仙姐姐<br/>
          与好奇说妈妈们共创「绘本共读」新篇章<br/>
          <br/>
          <span role="img" aria-label="search">🔍</span><span className="bold">扫码预约腾讯会议</span><br/>
          <span role="img" aria-label="get">🉐</span>锁定好奇说线上春晚<br/>
          1月30日晚8点，不见不散！<br/>
        </div>
        <img src={env.IMG + 'event/event_temp_meeting_img_3.jpg'} className="pic" alt=""/>
      </div>
      <div className="item-wrapper">
        <div className="desc">
          <span role="img" aria-label="tiger">🐯</span> | 如虎添亿，如你所期<br/>
          <br/>
          彩虹姐姐为你揭秘<br/>
          让妈妈们省心省力、满满安全感的<br/>
          ——全新运营赋能计划<br/>
          <br/>
          <span role="img" aria-label="search">🔍</span><span className="bold">扫码预约腾讯会议</span><br/>
          <span role="img" aria-label="get">🉐</span>锁定好奇说线上春晚<br/>
          1月30日晚8点，不见不散！<br/>
        </div>
        <img src={env.IMG + 'event/event_temp_meeting_img_4.jpg'} className="pic" alt=""/>
      </div>
    </div>
  )
}

import fetch from '../fetch'

const goods = {
  /**
   * @desc 获取专家定制书单信息
   *
   * @param {number} [babyAge=1]
   * @returns
   */
  getCustomizedBookList(babyAge = 0) {
    return fetch(`/goods/app/book/list/customized/${babyAge}`)
  },

  /**
   * @description 获取根据TAG搜索的书籍详情
   * @param bookListId
   * @param babyAge
   * @param source
   * @param pageNum
   * @param pageSize
   * @returns {*}
   */
  getBookListTagDetail({
                         bookListId = '',
                         babyAge = '',
                         source = '',
                         pageNum = 1,
                         pageSize = 10
                       } = {}) {
    return fetch('/goods/app/book/list/book_list_tag_detail', {
      method: 'POST',
      data: { bookListId, babyAge, pageNum, pageSize, source }
    })
  },

  /**
   * @desc 获取书单详情
   * @param {string} [bookListId='']
   * @returns
   */
  getBookListDetail({ bookListId = '' } = {}) {
    return fetch(`/goods/app/book/list/book_list_detail/${bookListId}`)
  },

  /**
   * @desc 获取打卡说明
   * @param {*} [babyAge=0]
   * @returns
   */
  getClockInDes(babyAge = 0) {
    return fetch(`/goods/app/book/list/clock_in_des/${babyAge}`)
  },

  /**
   * @desc 获取每周课程
   * @param {number} [babyAge=0]
   * @returns
   */
  getWeeklyCourse(babyAge = 0) {
    return fetch(`/goods/app/book/list/weekly_course/${babyAge}`)
  },

  /**
   * @desc 获取绘本阅读指导师
   * @param {number} [babyAge=0]
   * @returns
   */
  getBookTutor(babyAge = 0) {
    return fetch(`/goods/app/book/list/book_tutor/${babyAge}`)
  },

  /**
   * @desc 获取绘本解析信息
   * @param {number} [babyAge=0]
   * @returns
   */
  getBookContextInfo(babyAge = 0) {
    return fetch(`/goods/app/book/list/book_context/${babyAge}`)
  },

  /**
   * @desc 根据书单Id获取解析
   * @param {*} [params={}]
   * @returns
   */
  getBookListContextInfo(params = {}) {
    return fetch('/goods/app/book/list/context', {
      params: { ...params }
    })
  },

  /**
   * @desc 获取Camp详情
   * @param campId
   * @param type
   * @returns {undefined}
   */
  getCampaignInfo(campId = '', type = 2) {
    return fetch('/goods/camp/campaign_info_v2', {
      params: {
        campId,
        type
      }
    })
  },

  /**
   * @description 获取最新一期2680Id
   * @returns {Promise}
   */
  getLastCampId() {
    return fetch('/goods/camp/campaign/get_latest_camp')
  },

  /**
   * @description 获取小红花送来送去活动首页数据
   * @returns {*}
   */
  getFlowerHomepage() {
    return fetch('/goods/app/camp_interaction/flower_homepage')
  },

  /**
   * @description 获取我收到的小红花记录
   * @param params
   * @returns {*}
   */
  getReceiveFlowerRecord(params = {}) {
    return fetch('/goods/app/camp_interaction/receive_flower_record', {
      params: { ...params }
    })
  },

  /**
   * @description 获取我赠送的小红花记录
   * @param params
   * @returns {*}
   */
  getSendFlowerRecord(params = {}) {
    return fetch('/goods/app/camp_interaction/send_flower_record', {
      params: { ...params }
    })
  },

  /**
   * @description 获取小红花页面的配置
   * @returns {*}
   */
  getFlowerConfig() {
    return fetch('/goods/app/camp_interaction/flower_config')
  },

  /**
   * @description 赠送小红花
   * @param params
   * @returns {*}
   */
  sendFlower(params = {}) {
    return fetch('/goods/app/camp_interaction/send_flower', {
      method: 'POST',
      data: { ...params }
    })
  }


}

export default goods

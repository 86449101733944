import React, { useEffect, useState } from 'react'

const imgStyle = {
	display: 'block',
	width: '100%',
	height: 'auto'
}

export default function Protocol1288() {
	const [imgs, setImgs] = useState([])

	const initImgs = () => {
		let list = []
		for (let i = 0; i < 16; i++) {
			list.push(
				`https://cdn.kangarooread.com/resource/protocol/1288/1288_${i + 1}.jpeg`
			)
		}
		setImgs(list)
	}

	useEffect(() => {
		initImgs()
	}, [])

	return (
		<>
			{imgs.map((it, idx) => (
				<img src={it} style={imgStyle} key={idx} alt='' />
			))}
		</>
	)
}

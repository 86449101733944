import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { env } from '../../lib/conf'
import { observable } from 'mobx'
import { Carousel } from 'antd-mobile'
import './index.less'
import api from '../../lib/api'

@inject('store')
@observer
class BirthdayCard extends Component {
  @observable type = 1
  @observable current = 0
  @observable loaded = false
  @observable userInfo = {}
  @observable nickname = ''
  @observable autoPlay = false

  afterChange = (e) => {
    this.current = e
    if (this.current === 2) {
      this.getEventListener()
    }
  }

  getUserInfo = async () => {
    const res = await api.getUserInfo()
    if (res) {
      const { data } = res || res
      this.userInfo = { ...data }
      if (data.accountVo.nickname.length > 10) {
        this.nickname = data.accountVo.nickname.slice(0, 10) + '...'
      } else {
        this.nickname = data.accountVo.nickname
      }
    }
  }

  getEventListener() {
    let video = document.getElementById('videoPlayer')
    let audio = document.getElementById('audioPlayer')

    video.addEventListener('play', (e) => {
        if (e.type === 'play') {
          audio.pause()
        }
      }
    )

    video.addEventListener('pause', (e) => {
        console.log(e)
        if (e.type === 'pause') {
          audio.play()
        }
      }
    )

    video.addEventListener('ended', (e) => {
        console.log(e)
        if (e.type === 'ended') {
          audio.play()
        }
      }
    )
  }


  initAudio() {
    document.addEventListener('touchstart', () => {
      let audio = document.getElementById('audioPlayer')
      audio.play()
    })
  }

  async componentDidMount() {
    this.loaded = false
    const { hidePageLoading, showPageLoading } = this.props.store
    showPageLoading()
    setTimeout(() => {
      this.loaded = true
      this.autoPlay = true
      this.initAudio()
      hidePageLoading()
    }, 2000)
    await this.getUserInfo()
    document.title = '生日贺卡'
  }

  componentWillUnmount() {
    let video = document.getElementById('videoPlayer')
    video.removeEventListener('play', () => {
    })
    video.removeEventListener('pause', () => {
    })
    video.removeEventListener('ended', () => {
    })
    document.removeEventListener('touchstart', () => {
    })
  }

  render() {
    const { role, level } = this.userInfo

    return (
      <div className={`birthday-card`}>
        {this.loaded ? <div className="birthday-card-wrapper">
          <Carousel
            vertical={true}
            dots={false}
            beforeChange={this.beforeChange}
            afterChange={this.afterChange}
            selectedIndex={this.current}
          >
            <div className="item one">
              <audio id={`audioPlayer`} autoPlay={this.loaded}
                     src={`https://cdn.kangarooread.com/mp-assets/birthday-pic/birthday-music.mp3`} loop={true}/>
              <img src={`${env.IMG}birthday-pic/light.png`} className={`${this.current === 0 ? 'go' : ''} bg-light`}/>
              <img src={`${env.IMG}birthday-pic/logo.png`} className={`${this.current === 0 ? 'go' : ''} logo`}/>
              <img src={`${env.IMG}birthday-pic/birthday-text.png`}
                   className={`${this.current === 0 ? 'go' : ''} birthday-text`}/>
              {
                level && level > 0 ? <div>
                  {
                    (level === 4 || level === 5) && <img src={`${env.IMG}birthday-pic/reader-text.png`}
                                                         className={`${this.current === 0 ? 'go' : ''} role-text3`}/>
                  }
                  {
                    level === 6 && <img src={`${env.IMG}birthday-pic/reader-text-level6.png`}
                                        className={`${this.current === 0 ? 'go' : ''} role-text4`}/>
                  }
                </div> : <div>
                  {
                    role === 3 ? <img src={`${env.IMG}birthday-pic/role-text.png`}
                                      className={`${this.current === 0 ? 'go' : ''} role-text`}/> :
                      role === 4 ? <img src={`${env.IMG}birthday-pic/role-text2.png`}
                                        className={`${this.current === 0 ? 'go' : ''} role-text2`}/> : null
                  }
                </div>
              }

              <div className={`${this.current === 0 ? 'go' : ''} name-bg-wrapper`}>
                <img src={`${env.IMG}birthday-pic/name-bg.png`}
                     className={`${this.current === 0 ? 'go' : ''} name-bg`}/>
                <div className={`name`}>{this.userInfo.realName || this.nickname}</div>
              </div>

              <img src={`${env.IMG}birthday-pic/center-pic.png`}
                   className={`${this.current === 0 ? 'go' : ''} center-pic `}/>
              <img src={`${env.IMG}birthday-pic/balloon1.png`}
                   className={`${this.current === 0 ? 'go' : ''} balloon1`}/>
              <img src={`${env.IMG}birthday-pic/balloon2.png`}
                   className={`${this.current === 0 ? 'go' : ''} balloon2`}/>
              <img src={`${env.IMG}birthday-pic/small-icon.png`}
                   className={`${this.current === 0 ? 'go' : ''} small-icon`}/>
              <img src={`${env.IMG}birthday-pic/page-icon1.png`}
                   className={`${this.current === 0 ? 'go' : ''} page-icon1`}/>
            </div>
            <div className="item two">
              <img src={`${env.IMG}birthday-pic/p2_top_bg.png`}
                   className={`${this.current === 1 ? 'go' : ''} p2-top-bg`}/>
              <img src={`${env.IMG}birthday-pic/p2_logo.png`} className={`${this.current === 1 ? 'go' : ''} p2-logo`}/>
              <img src={`${env.IMG}birthday-pic/p2_balloon.gif`}
                   className={`${this.current === 1 ? 'go' : ''} p2-balloon`}/>
              <img src={`${env.IMG}birthday-pic/p2_center.png`}
                   className={`${this.current === 1 ? 'go' : ''} p2-center`}/>
              <img src={`${env.IMG}birthday-pic/p2_center_bg.png`}
                   className={`${this.current === 1 ? 'go' : ''} p2-center-bg`}/>
              <img src={`${env.IMG}birthday-pic/p2_font1.png`} className={`${this.current === 1 ? 'go' : ''} p2-font`}/>
              <img src={`${env.IMG}birthday-pic/p2_doudou_pic.png`}
                   className={`${this.current === 1 ? 'go' : ''} p2-doudou-pic`}/>
              <img src={`${env.IMG}birthday-pic/p2_happy_birthday.png`}
                   className={`${this.current === 1 ? 'go' : ''} p2-happy-birthday`}/>
              <img src={`${env.IMG}birthday-pic/belt1.gif`} className={`${this.current === 1 ? 'go' : ''} belt1`}/>
              <img src={`${env.IMG}birthday-pic/page-icon2.png`}
                   className={`${this.current === 1 ? 'go' : ''} page-icon2`}/>
            </div>
            <div className="item three">
              <video id={`videoPlayer`} className={`video`} controls
                     poster={`https://cdn.kangarooread.com/mp-assets/birthday-pic/birthday-poster.png`}>
                <source src={`https://cdn.kangarooread.com/mp-assets/birthday-pic/birthday-video2.mp4`}/>
              </video>
              <img src={`${env.IMG}birthday-pic/p3_video_pic_V4.png`}
                   className={`${this.current === 2 ? 'go' : ''} p3-video-pic`}/>
              <img src={`${env.IMG}birthday-pic/p3_bottom_pic1.png`}
                   className={`${this.current === 2 ? 'go' : ''} p3-bottom`}/>
              <img src={`${env.IMG}birthday-pic/belt2.gif`} className={`${this.current === 2 ? 'go' : ''} belt2`}/>
            </div>
          </Carousel>
        </div> : null}
      </div>
    )
  }
}

export default BirthdayCard
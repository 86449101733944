/**
 * @description 公共组件-Modal框
 * @author Joe.shen
 * @augments visible boolean 显示
 * @augments options object 对象
 */

import React, { Component } from 'react'
import './index.less'

class Modal extends Component {
  render() {
    const { options, visible } = this.props
    const { title = '', content = '', okBtnText = '确定', cancelBtnText = '取消', showCancelBtn = false, gravity = 0, onClose = () => {}, onConfirm = () => {} } = options
    return (
      <div className={`modal-wrapper ${visible ? 'show' : ''}`}>

        <div className={`modal ${visible ? 'show' : ''}`}>
          <div className="title">{title}</div>
          {content ? <div className={`content content-${gravity}`}>
            {content}
          </div> : null}
          <div className="btn-wrapper">
            {showCancelBtn ? <div className="cancel" onClick={onClose}>{cancelBtnText}</div> : null}
            <div className="confirm" onClick={onConfirm}>{okBtnText}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal

import axios from 'axios'
import Q from 'q'
import { createHashHistory } from 'history'
import { env, storage, headerConfig } from './conf'
import { Util } from './util'
import store from '../store'

const { longitude, latitude, city } = storage

axios.defaults.timeout = 60000
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
axios.defaults.baseURL = `${env.DOMAIN}`

// 全局请求拦截
axios.interceptors.request.use(
  (config) => {
    config.headers.platform = headerConfig.platform
    config.headers.appVersion = headerConfig.appVersion
    config.headers.protocolVersion = headerConfig.protocolVersion
    config.headers.deviceId = headerConfig.deviceId
    config.headers.bigSmarter = headerConfig.bigSmarter.action.call(
      headerConfig
    )
    config.headers.longitude = longitude || ''
    config.headers.latitude = latitude || ''
    config.headers.city = city || ''

    const tk = JSON.parse(Util.getLocalStorage(storage.userInfo) || '{}')
    const shareId = Util.getLocalStorage(storage.shareId) || ''
    config.headers.token = (tk && tk.accountVo && tk.accountVo.token) || ''
    config.headers.shareId = shareId || ''
    return config
  },
  (error) => console.log(error)
)

// 全局响应拦截
axios.interceptors.response.use(
  (response) => {
    const { showToast } = store
    const res = response.data || {}
    const status = res.resultStatus || {}
    const { code, message: msg } = status

    if (code === 1000) {
      return res
    } else if (code === 1001) {
      // 登录态失效
      showToast('登录态失效,请重新登录')
      Util.removeLocalStorage(storage.userInfo)
      createHashHistory().replace('/login')
      return null
    } else if (code === 30000) {
      // 无权限访问
      createHashHistory().replace('/noPermission')
      return null
    } else {
      // 接口异常
      showToast(msg || '请求错误')
      // window.vRoute.replace({ path: '/serverExp' });
      return null
    }
  },
  (error) => {
    const { showToast, hideLoading } = store
    hideLoading()
    showToast('网络异常~')
    throw error
  }
)

// fetch fn
export default function fetch(
  url,
  { method = 'GET', params = {}, data = {}, headers = {}, ...options } = {},
  apiVersion = 'v1'
) {
  try {
    return Q.Promise((success) => {
      axios({
        data,
        headers,
        method,
        params,
        url: `${apiVersion}${url}`,
        ...options
      })
      .then((res) => {
        success(res)
      })
      .catch((e) => {
        console.log(e)
        throw e
      })
    })
  } catch (e) {
    throw e
  }
}

import React, { useEffect, useState } from 'react'
import serv from '../../../lib/api'
import { bridge } from '../../../lib/bridge'

export default () => {
	const [qrcode, setQrcode] = useState('')

	document.title = '添加陪伴官'

	const getDetail = async () => {
		try {
			const res = await serv.getGroupAccompanist()
			if (res) {
				const { data } = res
				const { superiorQrCode } = data || {}
				setQrcode(superiorQrCode)
			}
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getDetail()
	}, [])

	const toSave = () => {
		bridge.appDownloadImages({ list: [qrcode] })
	}

	return (
		<div
			style={{
				position: 'relative',
				zIndex: 1,
				paddingTop: 40,
				paddingBottom: 40
			}}>
			<img
				style={{
					display: 'block',
					width: '90%',
					margin: '0 auto 40px'
				}}
				src={qrcode}
			/>
			<div
				style={{
					marginBottom: 40,
					fontSize: 16,
					color: '#999',
					textAlign: 'center'
				}}>
				保存后在微信内扫码打开
			</div>
			<div
				style={{
					margin: '0 auto',
					width: '80%',
					height: '45px',
					lineHeight: '45px',
					textAlign: 'center',
					color: 'white',
					fontSize: 16,
					borderRadius: 25,
					background: '#FFCE40'
				}}
				onClick={toSave}>
				保存至本地
			</div>
		</div>
	)
}

import React, { useEffect, useState } from 'react'
import './index.less'

export default function Star(props) {
  const [starList, setStarList] = useState([])
  const { max = 5, active, couldTap = true, size = 44, sizeRatio = 1, pic = 'star', marginRight = 0, justifyContent = 'space-around', onChange } = props || {}

  useEffect(() => {
    initStar()
  }, [active])

  const initStar = () => {
    const arr = []
    for (let i = 0; i < max; i++) {
      arr.push({
        status: 'default'
      })
    }
    for (let i = 0; i < active; i++) {
      arr[i].status = 'active'
    }
    setStarList(arr)
  }

  const checkStar = (idx) => {
    const newList = [...starList]
    if (!couldTap) return
    newList.forEach((it, i) => {
      it.status = i <= idx ? 'active' : 'default'
    })
    setStarList(newList)
    onChange && onChange(newList)
  }

  return (
    <div className="star-component-wrapper" style={{
      justifyContent: justifyContent
    }}>
      {starList.map((i, idx) => (
        <img
          className="img"
          src={`https://cdn.kangarooread.com/mp-assets/${pic}_${i.status}_icon.png`}
          key={idx}
          onClick={() => checkStar(idx)}
          style={{
            width: `${size}px`, marginRight: `${marginRight}px`
          }}
          alt="star"
        />
      ))}
    </div>
  )
}

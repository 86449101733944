import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Item from './components/Item'
import { observable } from 'mobx'
import './index.less'
import List from '../../components/List'
import serv from '../../lib/api'
import { withRouter } from 'react-router'
import { Util } from '../../lib/util'

@inject('store')
@withRouter
@observer
class SendFlowerList extends Component {
  @observable type = 1
  @observable userId = ''
  @observable icon = ''
  @observable list = {
    list: [],
    hasMore: false,
    pageNum: 1,
    pageSize: 10
  }
  @observable loaded = false

  initData = async () => {
    const { showPageLoading, hidePageLoading } = this.props.store
    showPageLoading()
    await this.getFlowerConfig()
    await this.getList()
    hidePageLoading()
  }

  /**
   * @description 获取小红花页面的配置
   * @returns {Promise<void>}
   */
  getFlowerConfig = async () => {
    try {
      const res = await serv.getFlowerConfig()
      if (res) {
        const { data } = res
        const { icon } = data || {}
        this.icon = icon || ''
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取记录列表
   * @returns {Promise<void>}
   */
  getList = async () => {
    this.loaded = false
    const { pageNum, pageSize, list: oldList } = this.list
    const params = { pageNum, pageSize, userId: this.userId }
    const api = this.type === 1 ? 'getReceiveFlowerRecord' : 'getSendFlowerRecord'
    try {
      const res = await serv[api](params)
      if (res) {
        const { data, hasMore } = res
        this.list.list = oldList.concat(data || [])
        this.list.hasMore = hasMore
        this.list.pageNum = hasMore ? pageNum + 1 : pageNum
      }
    } catch (err) {
      console.log(err)
    }
    this.loaded = true
  }

  handleBack = () => {
    const { goBack, replace } = this.props.history
    const from = Util.getParams('from')
    if (from && from === 'main') {
      goBack()
    } else  {
      replace('/send-flower-main')
    }
  }

  componentDidMount() {
    const { type, userId } = this.props.match.params
    this.type = type * 1
    this.userId = userId || ''
    document.title = this.type === 1 ? '我收到的小红花' : '我送出的小红花'
    this.initData()
  }

  render() {
    return (
      <div className="send-flower-list">
        <List
          next={this.getList}
          loaded={this.loaded}
          dataSource={this.list.list || []}
          hasMore={this.list.hasMore}
          endStr="到底啦~"
        >
          {this.list.list && this.list.list.map((it, idx) => (
            <Item type={this.type} {...it} icon={this.icon} key={idx}/>
          ))}
        </List>
        <button className="back-btn" onClick={this.handleBack}>返回上一页</button>
      </div>
    )
  }
}

export default SendFlowerList

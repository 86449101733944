import React, { Component, Fragment } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import './index.less'
import { storage } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import serv from '../../lib/api'

@inject('store')
@observer
class ExCommunity extends Component {
  @observable env = 'app'
  @observable userInfo = {}
  @observable babyAge = 0
  @observable baseInfo = {}
  @observable communityTopicList = []
  @observable communityTalkList = []

  componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    this.babyAge = Util.getParams('babyAge')
    showLoading('加载中...')
    // 动态修改title
    document.title = '学习社区成功育儿经验'
    // 获取运行环境
    this.getEnv()
    setTimeout(() => {
      hideLoading()
    }, 1000)
  }

  @action
  getEnv = () => {
    if (bridge.isAppEnv()) {
      this.env = 'app'
      this.initAppData()
    } else {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.env = 'mp'
            this.initMpData()
          } else {
            this.env = 'h5'
            this.initH5Data()
          }
        })
      } else {
        this.env = 'h5'
        this.initH5Data()
      }
    }
  }

  initAppData = () => {
    bridge.getUserInfoFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getCommunityInfo()
      } else {
        this.loginInApp()
      }
    })
  }

  /**
   * @desc APP 登录时间通知
   * @memberof ExCommunity
   */
  loginInApp = () => {
    bridge.loginFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getCommunityInfo()
      } else {
        this.loginInApp()
      }
    })
  }

  initMpData = () => {
    try {
      const token = Util.getParams('token')
      if (token) {
        Util.setLocalStorage(
          storage.userInfo,
          JSON.stringify({ accountVo: { token: token || '' } })
        )
        this.fetchUser()
        setTimeout(() => {
          this.getCommunityInfo()
        }, 300)
      } else {
        wx.miniProgram.navigateTo({
          url: '/pages/login/index',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  initH5Data = () => {
    const token = Util.getParams('token')
    if (token) {
      Util.setLocalStorage(
        storage.userInfo,
        JSON.stringify({ accountVo: { token: token || '' } })
      )
      this.fetchUser()
      setTimeout(() => {
        this.getCommunityInfo()
      }, 300)
    }
  }

  /**
   * @desc 获取绘本解析内容
   * @memberof ExCommunity
   */
  getCommunityInfo = async () => {
    const res = await serv.getCommunityInfo(this.babyAge)
    if (res) {
      const { data } = res
      const { title, img, content, communityTopicList, communityTalkList } =
        data || {}
      this.baseInfo = { title, img, content }
      this.communityTopicList = [...(communityTopicList || [])]
      this.communityTalkList = [...(communityTalkList || [])]
    }
  }

  /**
   * @desc 获取用户信息（小程序及H5使用）
   * @memberof ExCommunity
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 去发布阅读笔记
   * @memberof ExCommunity
   */
  releaseNotes = () => {
    if (this.env === 'app') {
      bridge.appTakeNotes()
    } else {
      wx.miniProgram.navigateTo({
        url: '/subPkg/main/pages/release-notes/index?postSource=12&type=1',
      })
    }
  }

  toTopicDetail = (id = '') => {
    const { showToast } = this.props.store
    if (!id) {
      showToast('错误的话题信息，请稍后再试')
      return
    }
    if (this.env === 'app') {
      bridge.appNavigate({ id: 4, params: `id=${id}` })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/pages/topic-detail/index?topicId=${id}&source=exCommunity`,
      })
    }
  }

  toTalkDetail = (id = '') => {
    const { showToast } = this.props.store
    if (!id) {
      showToast('错误的话题信息，请稍后再试')
      return
    }
    if (this.env === 'app') {
      bridge.appNavigate({ id: 20, params: `id=${id}` })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/subPkg/main/pages/read-notice-detail/index?postId=${id}&source=exCommunity`,
      })
    }
  }

  render() {
    const { img, title, content } = this.baseInfo
    return (
      <Fragment>
        <div className="c-c-wrapper">
          <div className="info-wrapper">
            <img className="cover-pic" alt="" src={img || ''} />
            <div className="info">
              <h4 className="title">{title || ''}</h4>
              <p className="desc">{content || ''}</p>
            </div>
          </div>
          <div className="content-item">
            <h4 className="title">热议话题</h4>
            <div className="card-wrapper">
              {this.communityTopicList.map((it) => (
                <div
                  className="card"
                  key={it.topicId}
                  onClick={() => this.toTopicDetail(it.topicId)}
                >
                  <div className="card-title">
                    <span className="icon-1" />
                    <div className="detail-link">
                      查看话题<span className="right-arrow"/>
                    </div>
                  </div>
                  <h4 className="topic-title">{it.title || ''}</h4>
                  <p className="count">{it.attendCount || 0}人正在热议</p>
                  <div className="topic-viewpoint">
                    {(it.optionList || []).map((i) => (
                      <span className="item" key={i.topicOptionId}>
                        {i.content || ''}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="content-item">
            <h4 className="title">热门读书笔记</h4>
            <div className="card-wrapper">
              {this.communityTalkList.map((it) => (
                <div
                  className="card"
                  style={{ overflow: 'hidden' }}
                  key={it.postId}
                  onClick={() => this.toTalkDetail(it.postId)}
                >
                  <div className="mask"/>
                  <img className="bg" src={it.img || ''} alt="" />
                  <div className="card-title-2">
                    <span className="icon-2" />
                  </div>
                  <h4 className="topic-title-2">{it.title || ''}</h4>
                  <p className="name">@{it.nickname || ''}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="bottom-btn" onClick={() => this.releaseNotes()}>
            我也发布一篇笔记，记录孩子的阅读成长
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ExCommunity

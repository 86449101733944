import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../../../lib/api'
import './index.less'
import { Util } from '../../../lib/util'
import { isProd, storage } from '../../../lib/conf'
import EnrollItem from './item'
import NoData from '../../../components/NoData'

@inject('store')
@observer
class EventEnrollListInSelectUser extends Component {
  @observable loaded = false
  @observable hasAuth = false
  @observable list = []
  @observable userInfo = {}

  componentDidMount() {
    document.title = '兜爱读家庭专属奖励名单'
    let _user = Util.getLocalStorage(storage.userInfo)
    _user = JSON.parse(_user || '{}')
    this.userInfo = { ...(_user || {}) }
    this.initShareInfo()
    this.initData()
  }

  /**
   * @description 初始化分享信息
   */
  initShareInfo = () => {
    const { id: userId } = this.userInfo
    console.log(`inSelectUser: user_Id => ${userId}`)
    // 本页面需要转发
    bridge.jsShowShareWechat(true)
    bridge.jsShareWechat({
      title: '快来领取你的专属福利，续卡多送6个月',
      thumb: 'https://cdn.kangarooread.com/mp-assets/event_enroll_select_user_share_img.png',
      path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-select-user&shareType=open&shareId=${userId}`
    })
    wx.miniProgram.postMessage({
      data: {
        title: '快来领取你的专属福利，续卡多送6个月',
        imageUrl: 'https://cdn.kangarooread.com/mp-assets/event_enroll_select_user_share_img.png',
        path: `/subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-select-user&shareType=open&shareId=${userId}`
      }
    })
  }

  @action
  initData = () => {
    const { showPageLoading, hidePageLoading } = this.props.store
    showPageLoading()
    this.getList()
    .then(_ => {
      hidePageLoading()
    })
    .catch(err => {
      console.log(`getList failed: ${JSON.stringify(err)}`)
      hidePageLoading()
    })
  }

  getList = async () => {
    this.loaded = false
    try {
      const res = await serv.getUserEnrollListInEventSelectUser()
      if (res) {
        const { data } = res
        this.list = [...(data || [])]
        this.hasAuth = true
      }
    } catch (err) {
      console.log(err)
    }
    this.loaded = true
  }

  sendMsg = () => {
    const { env, showModal, closeModal } = this.props.store
    if (env === 'mp') {
      const options = {
        title: '转发提示',
        content: `请点击右上角 "..." 进行转发`,
        okBtnText: '知道了',
        showCancelBtn: false,
        gravity: 0,
        onConfirm: () => {
          closeModal()
        }
      }
      showModal(options)
    } else if (env === 'app') {
      const { id: userId } = this.userInfo
      bridge.appShareWechat({
        title: '快来领取你的专属福利，续卡多送6个月',
        thumb: 'https://cdn.kangarooread.com/mp-assets/event_enroll_select_user_share_img.png',
        path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/event-select-user&shareType=open&shareId=${userId}`
      })
    }
  }

  render() {
    const { accountVo } = this.userInfo
    const { realName, nickname } = accountVo || {}
    return (
      <>
        {this.loaded && this.hasAuth
          ? <div className="e-enroll-list-in-select-user">
            <div className="info">
              <div className="real-name">{realName || nickname || ''}</div>
              <div className="desc">你的团队中有{this.list.length || 0}人被选为“<span className="important">兜爱读家庭</span>”。6月30日前完成在线续卡(线上买卡并激活使用)，在6月续卡活动多送3个月的基础上，再多送3个月。
              </div>
            </div>
            <div className="enroll-list">
              {this.list.map((it, idx) => (
                <EnrollItem data={it} key={idx} onSend={() => this.sendMsg()}/>
              ))}
            </div>
            {this.loaded && this.list.length === 0
              ? <NoData/>
              : null}
          </div>
          : <NoData emptyStr="无查看权限"/>
        }
      </>
    )
  }
}

export default EventEnrollListInSelectUser

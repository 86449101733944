import React from 'react'

const imgStyle = {
  display: 'block',
  width: '100%'
}

export default function SubscribeOldToNew() {
  return <img src="https://cdn.kangarooread.com/mp-assets/protocol/30.png" style={imgStyle} alt=""/>
}

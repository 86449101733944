import React from 'react'

export default function PublicityNotice() {
	return (
		<img
			src='https://cdn.kangarooread.com/resource/notice_img/2023chunjiewuliu.jpg'
			alt=''
			style={{
				display: 'block',
				width: '100%',
				margin: '0 auto'
			}}
		/>
	)
}

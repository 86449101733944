import React from 'react'

export default function EnrollItem(props) {
  const { data, onSend } = props
  const { enrollFlag, phone, role, superDetail, userName, userPhoto } = data || {}

  const handleSend = () => {
    onSend && onSend()
  }

  return (
    <div className="enroll-item">
      <div className="user-info">
        <img src={userPhoto || ''} alt="" className="avatar"/>
        <div className="user-info-wrapper">
          <div className="name-wrapper">
            <span className="name">{userName || ''}</span>
            <span className="split"/>
            <span className="phone">{phone || ''}</span>
          </div>
          <div className="super-info">归属关系：{superDetail}</div>
        </div>
      </div>
      <div className="sub-info">
        <img src={`https://cdn.kangarooread.com/mp-assets/event_select_user_tag_icon_${role}_${enrollFlag ? 1 : 2}.png`}
             alt="" className="tag"/>
        <div className="btn" onClick={handleSend}>发给TA</div>
      </div>
    </div>
  )
}

import React, { Component } from 'react'
import './index.less'
import { env } from '../../lib/conf'

class Loading extends Component {
  render() {
    const { text, visible } = this.props
    return (
      <div className={`loading-wrapper ${visible ? 'show' : ''}`}>
        <div className="mask"></div>
        <div className="loading">
          <img src={`${env.IMG}loading.png`} className="icon" alt="" />
          {text ? <div className="text">{text}</div> : null}
        </div>
      </div>
    )
  }
}

export default Loading

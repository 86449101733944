import fetch from '../fetch'

const user = {
	/**
	 * 获取用户信息
	 */
	getUserInfo() {
		return fetch('/user/member_page_info/get_user_info', {}, 'v2')
	},

	/**
	 * @desc 获取新人专享主页信息
	 * @returns
	 */
	getNewbieInfo() {
		return fetch('/user/new_user_activity/get_new_user_activity_homepage')
	},

	/**
	 * @desc 提交任务完成
	 * @param {*} [params={}]
	 * @returns
	 */
	commitNewUserActivity(params = {}) {
		return fetch('/user/new_user_activity/commit_new_user_activity', {
			params: { ...params }
		})
	},

	/**
	 * @desc 检查绑定关系
	 */
	checkRefer() {
		return fetch('/user/p/my_info/check_refer', {
			method: 'POST'
		})
	},

	/**
	 * @desc 获取宝宝信息
	 */
	getBabyInfo() {
		return fetch('/user/p/my_info/getBabyInfo')
	},

	/**
	 * @desc 获取育儿社区体验相关信息
	 * @param {number} [babyAge=0]
	 * @returns
	 */
	getCommunityInfo(babyAge = 0) {
		return fetch(`/user/experience/plan/community/${babyAge}`)
	},

	/**
	 * @desc 获取app版本号
	 * @returns
	 */
	getAppVersion() {
		return fetch(`/user/home/app_an_version`)
	},

	/**
	 * @description 根据手机号查询用户信息
	 * @param userPhone number
	 * @returns {*}
	 */
	getUserinfoByPhone(userPhone) {
		return fetch('/user/user_homepage/get_userinfo_by_phone', {
			params: { userPhone }
		})
	},

	/**
	 * @description 获取活动绑定关系
	 * @returns {*}
	 */
	getHomeRelation() {
		return fetch('/user/user_homepage/get_home_relation')
	},

	/**
	 * @description 提交夫妻共学绑定关系
	 * @param params
	 * @returns {*}
	 */
	commitHomeRelation(params = {}) {
		return fetch('/user/user_homepage/commit_home_relation', {
			params: { ...params }
		})
	},

	/**
	 * @description 大转盘信息
	 * @returns {*}
	 */
	getLotteryInfo() {
		return fetch('/user/lottery_active/get_lottery_info', {}, 'v2')
	},

	/**
	 * @description 大转盘抽奖
	 * @returns {*}
	 */
	joinLotteryActive() {
		return fetch('/user/lottery_active/join_lottery_active', {}, 'v2')
	},

	/**
	 * @description 使用积分兑换抽奖次数
	 * @returns {*}
	 */
	exchangeLotteryActiveCount() {
		return fetch('/user/lottery_active/exchange_lottery_active_count', {}, 'v2')
	},

	/**
	 * @description 领取转盘抽奖次数
	 * @param lotteryType
	 * @returns {*}
	 */
	fetchLotteryCount(lotteryType) {
		return fetch(
			'/user/lottery_active/fetch_lottery_count',
			{
				params: { lotteryType }
			},
			'v2'
		)
	},

	/**
	 * @description 获取地址列表
	 * @returns {*}
	 */
	getUserAddressList() {
		return fetch('/user/p/my_info/getUserAddressList')
	},

	/**
	 * @description 获取答卷的第一题
	 * @param questionaireId
	 * @returns {*}
	 */
	getUserFirstQuestion(questionaireId = '') {
		return fetch('/user/app/questionaire/get_user_first_question', {
			params: { questionaireId }
		})
	},

	/**
	 * @description 提交并获取下一题
	 * @returns {*}
	 */
	submitAndGetNextQuestion(params = {}) {
		return fetch('/user/app/questionaire/submit_and_get_next_question', {
			method: 'POST',
			data: { ...params }
		})
	},

	/**
	 * @description 返回上一题（记录）
	 * @param preQuestionId
	 * @param thisQuestionId
	 * @returns {*}
	 */
	getPreviousQuestion(preQuestionId, thisQuestionId) {
		return fetch('/user/app/questionaire/get_previous_question', {
			params: { preQuestionId, thisQuestionId }
		})
	},

	/**
	 * @description 获取问卷调查的完成状态
	 * @param questionaireId 问卷ID
	 */
	checkFinishQuestionaire(questionaireId = '') {
		return fetch('/user/app/questionaire/check_finish_questionaire', {
			params: { questionaireId }
		})
	},

	/**
	 * @description 故事会活动页数据
	 * @param params
	 * @returns {*}
	 */
	storytellingFestivalHeatMap(params = {}) {
		return fetch('/user/circle_activity/storytelling_festival_heat_map', {
			params: { ...params }
		})
	},

	/**
	 * @description 发现页附近活动列表
	 * @param params
	 * @returns {Promise}
	 */
	searchNearActivity(params = {}) {
		return fetch('/user/card_store/search_near_activity', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取问卷礼物
	 * @param questionaireId
	 * @returns {*}
	 */
	getQuestionaireGift(questionaireId = '') {
		return fetch('/user/app/questionaire/get_questionaire_gift', {
			params: { questionaireId }
		})
	},

	/**
	 * @description 6月活动，续卡送3个月
	 * @returns {*}
	 */
	getActivitySubStatus() {
		return fetch('/user/experience/plan/get_activity_sub_status')
	},

	/**
	 * @description 获取故事节投票活动详情
	 * @returns {*}
	 */
	getEventVoteDetail() {
		return fetch('/user/festival/festival_detail')
	},

	/**
	 * @description 获取故事节投票参赛作品
	 * @param params
	 * @returns {*}
	 */
	getEventVoteItemList(params = {}) {
		return fetch('/user/festival/festival_post_list', {
			params: { ...params }
		})
	},

	/**
	 * @description 获取可选择参赛活动列表
	 * @returns {*}
	 */
	getEventVoteActivityList() {
		return fetch('/user/festival/festival_activity_list')
	},

	/**
	 * @description 帖子点赞/投票
	 * @param postId
	 * @returns {*}
	 */
	listPost(postId = '') {
		return fetch(`/user/post/like_post/${postId || ''}`)
	},

	/**
	 * @description 提交生日和经营地区
	 * @param params
	 * @returns {*}
	 */
	submitBirthdayInfo(params = {}) {
		return fetch(
			`/user/p/my_info/submit_birthday_info`,
			{
				method: 'POST',
				data: { ...params }
			},
			'v2'
		)
	},

	/**
	 * @description 获取生日地址信息
	 * @returns {*}
	 */
	getBirthdayInfo() {
		return fetch(`/user/p/my_info/check_birthday_condition`, {}, 'v2')
	},

	/**
	 * @description 获取地址信息
	 */
	getAddress(queryType = '') {
		return fetch(`/user/p/my_info/config_detail_info`, {
			params: { queryType }
		})
	},

	/**
	 * @description 获取订阅卡兑换谷粒详情
	 * @returns {*}
	 */
	getEventExChangeCerealDetail() {
		return fetch(`/user/app/card_stock/activity_info`)
	},

	/**
	 * @description 兑换订阅卡至谷粒
	 * @param params
	 * @returns {*}
	 */
	card2CerealConvert(params = {}) {
		return fetch(`/user/app/card_stock/card_2_cereal_convert`, {
			params: { ...params }
		})
	},

	/**
	 * @description 获取虎年祝福信息
	 * @returns {*}
	 */
	getTigerYearBless() {
		return fetch('/user/temp_activity/get_tiger_year_bless')
	},

	/**
	 * @description 共读有礼详情
	 * @returns {*}
	 */
	inviteActivityDetail() {
		return fetch('/user/invite_activity/invite_activity_detail', {}, 'v2')
	},

	/**
	 * @description 点击邀请链接上报
	 * @returns {*}
	 */
	clickInviteLink() {
		return fetch('/user/invite_activity/click_invite_link', {}, 'v2')
	},

	/**
	 * @description 被邀请人页面详情
	 * @returns {*}
	 */
	invitePageDetail() {
		return fetch('/user/invite_activity/invite_page_detail', {}, 'v2')
	},

	/**
	 * @description 邀请记录
	 * @param params
	 * @returns {*}
	 */
	readInviteRecord(params = {}) {
		return fetch(
			'/user/invite_activity/invite_record',
			{
				params: { ...params }
			},
			'v2'
		)
	},

	/**
	 * @description 被邀请人列表
	 * @param params
	 * @returns {*}
	 */
	inviteUserDetail(params = {}) {
		return fetch(
			'/user/invite_activity/invite_user_detail',
			{
				params: { ...params }
			},
			'v2'
		)
	},

	/**
	 * @description 获取共读有礼弹幕列表
	 * @param params
	 * @returns {*}
	 */
	inviteUserFlow(params) {
		return fetch(
			'/user/invite_activity/invite_user_flow',
			{
				params: { ...params }
			},
			'v2'
		)
	},

	/**
	 * @description 我获取问卷测评须知
	 * @returns {*}
	 */
	getRecommendInfo() {
		return fetch(`/user/app/recommend/title`, {}, 'v2')
	},

	/**
	 * @description 获取问卷题目3-11
	 * @param params
	 * @returns {*}
	 */
	getRecommendQuestionList(params = []) {
		return fetch(
			`/user/app/recommend/question/list`,
			{
				method: 'POST',
				data: [...params]
			},
			'v2'
		)
	},

	/**
	 * @description 提交问题答案
	 * @param params
	 * @returns {*}
	 */
	submitQuestionAnswer(params = []) {
		return fetch(
			`/user/app/recommend/question/answer`,
			{
				method: 'POST',
				data: [...params]
			},
			'v2'
		)
	},

	/**
	 * @description 获取问卷结果
	 * @returns {*}
	 */
	getRecommendResult() {
		return fetch(
			`/user/app/recommend/result`,
			{
				method: 'POST'
			},
			'v2'
		)
	},

	/**
	 * @description 获取领读者证书
	 * @param params
	 * @returns {*}
	 */
	readerAbilityHome(params) {
		return fetch(
			'/user/certificate/reader_ability_home',
			{
				method: 'POST',
				data: { ...params }
			},
			'v2'
		)
	},

	/**
	 * @description 获取2022年度报告
	 * @returns
	 */
	getAnnualReport() {
		return fetch('/user/app/annual_report/2022', {}, 'v2')
	},

	/**
	 * @description 获取陪伴官二维码
	 * @returns 
	 */
	getGroupAccompanist() {
		return fetch('/user/user_service/group_accompanist', {}, 'v2')
	},
	
}

export default user

import React from 'react'

export default function Item(props) {

  const { onSend, avatar, nickname, userId } = props

  const handleSend = () => {
    onSend && onSend({ avatar, nickname, userId })
  }

  return (
    <div className="item">
      <img
        src={avatar || ''}
        className="avatar" alt=""/>
      <div className="name">{nickname}</div>
      <div className="send-btn-wrapper">
        <div className="send-btn" onClick={handleSend}>送TA小红花</div>
      </div>
      <div className="line"/>
    </div>
  )
}

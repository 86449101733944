import React, { Component } from 'react'
import './index.less'

class Toast extends Component {
  render() {
    const { text, visible } = this.props
    return (
      <div className={`toast-wrapper ${visible ? 'show' : ''}`}>
        <div className={`toast ${visible ? 'show' : ''}`}>{text}</div>
      </div>
    )
  }
}

export default Toast

import React, { useState, useEffect, useRef } from 'react'
import dsBridge from 'dsbridge'
import { nativeReturnMsg } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import './index.less'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'

export default function CommonRichTextV2() {
	const store = useLocalStore(() => CommonStore)
	const [content, setContent] = useState('')

	const { showToast } = store
	const contentRef = useRef(null)


	let getRichText = () => {
		dsBridge.register('jsSetRichText', (res) => {
			if (res) {
				const { content } = (Util.isJSON(res) && JSON.parse(res || '{}')) || {}
				setContent(Util.formatContext(content || ''))
				return nativeReturnMsg.SUCCESS
			}
			return nativeReturnMsg.ERROR
		})
	}

	useEffect(() => {
		getRichText()

		return () => {
			getRichText = null
		}
	}, [])

	const handleClickRichText = (e) => {
		e.persist()
		const { nodeName, currentSrc, innerText } = e.target
		console.log(`nodeName => ${nodeName}; currentSrc => ${currentSrc}; innerText => ${innerText}`)
		if (nodeName === 'IMG') {
			bridge.appImagePreview({ src: currentSrc }).then((r) => console.log(r))
		} else if (nodeName === 'A') {
			bridge.appSetClipboardData({ text: innerText })
				.then(_ => {
					showToast('已复制到剪贴板')
				})
				.catch(_ => {
					showToast('复制失败')
				})
		}
	}

	return (
		<div style={{ padding: '15px', background: '#fff' }}>
			<div
				className='rich-text-content'
				ref={contentRef}
				onClick={(e) => handleClickRichText(e)}
				dangerouslySetInnerHTML={{
					__html: content || ''
				}}
			/>
		</div>
	)
}

import React from 'react'
import './page_start.less'

const PageStart = () => {
	return (
		<>
			<picture className='yr-start-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_bg.png'
					className='yr-start-bg-img'
					alt=''
				/>
			</picture>
			<picture className='yr-start-human'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_human.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_human.png'
					className='yr-start-human-img'
					alt=''
				/>
			</picture>
			<picture className='yr-start-book'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_book.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_book.png'
					className='yr-start-book-img'
					alt=''
				/>
			</picture>
			<picture className='yr-start-title'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_title.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_title.png'
					className='yr-start-title-img'
					alt='年度报告'
				/>
			</picture>
			<picture className='yr-start-btn'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_btn.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_btn.png'
					className='yr-start-btn-img'
					alt='左滑开启年度报告'
				/>
			</picture>
			<picture className='yr-start-left-circle'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_left_circle.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_left_circle.png'
					className='yr-start-left-circle-img'
					alt=''
				/>
			</picture>
			<picture className='yr-start-right-circle'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_right_circle.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_right_circle.png'
					className='yr-start-right-circle-img'
					alt=''
				/>
			</picture>
			<picture className='yr-start-bottom-circle'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_start_bottom_circle.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_start_bottom_circle.png'
					className='yr-start-bottom-circle-img'
					alt=''
				/>
			</picture>
		</>
	)
}

export default PageStart

import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import './index.less'
import { storage } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import serv from '../../lib/api'
import Book from '../../components/Book'

@inject('store')
@observer
class BookListDetail extends Component {
  @observable env = 'app'
  @observable id = ''
  @observable type = ''
  @observable babyAge = ''
  @observable detail = {}

  async componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    const {
      params: { id, type, babyAge },
    } = this.props.match
    this.id = id * 1
    this.type = type
    this.babyAge = babyAge * 1
    showLoading('加载中...')
    // 动态修改title
    document.title = '主题书单'
    // 获取运行环境
    await this.getEnv()
    await this.getBookListDetail()
    setTimeout(() => {
      hideLoading()
    }, 1000)
  }

  @action
  getEnv = () => {
    if (bridge.isAppEnv()) {
      this.env = 'app'
    } else {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.env = 'mp'
          } else {
            this.env = 'h5'
          }
        })
      } else {
        this.env = 'h5'
      }
    }
  }

  /**
   * @desc 获取绘本解析内容
   * @memberof BookListDetail
   */
  getBookListDetail = async () => {
		if (!this.id) {
			this.props.store.showToast('未知错误')
			return
		}
    const api =
      this.type === 'tag' ? 'getBookListTagDetail' : 'getBookListDetail'
    const params = {
      bookListId: this.id,
    }
    if (this.type === 'tag') {
      params.babyAge = this.babyAge
      params.pageSize = 4
      params.source = 'bookList'
    }
    const res = await serv[api](params)
    if (res) {
      const { data } = res
      if (data) {
        this.detail = { ...(data || {}) }
      }
    }
  }

  /**
   * @desc 获取用户信息（小程序及H5使用）
   * @memberof ExBookList
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 跳转购卡页面
   * @memberof ExBookList
   */
  toBuyCard = () => {
    if (this.env === 'app') {
      bridge.appNavigate({ id: 3 })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/buy-card/index?source=h5BookListDetail',
      })
    }
  }

  /**
   * @desc 绘本详情
   * @memberof ExBookList
   */
  toBookDetail = async (id = '') => {
    if (this.env === 'app') {
      bridge.appNavigate({ id: 19, params: `id=${id}` })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: `/subPkg/main/pages/book-detail/index?id=${id}`,
      })
    }
  }

  render() {
    const { title, assignedBookList } = this.detail
    return (
      <div className="b-l-d-wrapper">
        <div className="title-wrapper">
          <span className="title-icon"></span>
          <h4 className="title">{title || ''}</h4>
        </div>
        <div className="book-list">
          {(assignedBookList || []).map((it) => (
            <div
              className="item"
              key={it.id}
              onClick={() => this.toBookDetail(it.id)}
            >
              <Book cover={it.coverPicture} size={180} />
              <div className="book-info">
                <h4 className="sale-title">{it.saleTitle || ''}</h4>
                <p className="sale-price">
                  市场价格：¥{it.marketPrice || '--'}
                </p>
                <div className="tag-wrapper">
                  {(it.tagNameList || []).map((i) => (
                    <span className="tag" key={i}>
                      {i}
                    </span>
                  ))}
                </div>
                <p className="desc">{it.description || ''}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="bottom-btn" onClick={() => this.toBuyCard()}>
          <p className="primary">成为会员可查看更多</p>
          <p className="sub">全年为你省¥3000+元</p>
        </div>
      </div>
    )
  }
}

export default BookListDetail

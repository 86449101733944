import React, { Component } from 'react'
import QuestionnaireCard from './components/questionnaireCard'
import * as echarts from 'echarts'
import serv from '../../lib/api'
import { inject, observer } from 'mobx-react'
import { action, observable, runInAction } from 'mobx'
import ActionSheet from '../../components/ActionSheet'
import { bridge } from '../../lib/bridge'
import html2canvas from 'html2canvas'
import { isProd, storage } from '../../lib/conf'
import wx from 'weixin-js-sdk'
import { Util } from '../../lib/util'


const START_LEVEL = [
  { value: 1, label: 'E' },
  { value: 2, label: 'D' },
  { value: 3, label: 'C' },
  { value: 4, label: 'B' },
  { value: 5, label: 'A' }
]

@inject('store')
@observer
class UserResultPage extends Component {
  @observable resultInfo = {}
  @observable userInfo = {}
  @observable visible = false
  @observable shareVisible = false
  @observable qrCode = ''
  @observable userId = ''

  componentDidMount() {
    this.initData()

  }

  componentWillMount() {
    const { closeModal } = this.props.store
    closeModal()
  }

  initData = async () => {
    await this.getRecommendResult()
    await this.getQrcode()
    this.initShareInfo()
  }


  getRecommendResult = async () => {
    try {
      const res = await serv.getRecommendResult()
      if (res) {
        const { data } = res || {}
        const { diagnosis: { radarCharts }, user: { userId } } = data
        this.resultInfo = data
        this.userId = userId
        this.initCharts(radarCharts)
      }
    } catch (e) {
      console.log(e)
    }
  }


  initShareInfo = () => {
    wx.miniProgram.postMessage({
        data: {
          title: '我家宝宝刚做了阅读测评，蛮准的，你也来试试吧！',
          imageUrl: `https://cdn.kangarooread.com/mp-assets/user-result-wx-share_75.png`,
          path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/user/questionnaire&shareType=open&shareId=${this.userId || ''}`
        }
      }
    )
  }


  initCharts = (data) => {
    const myChart = echarts.init(document.getElementById('q-radar'))
    const labelList = data.map((it) => ({
      text: it.label,
      max: 6, min: 1
    }))
    const valueList = data.map((it) => it.value)
    myChart.setOption({
      color: '#F7B500',
      legend: {},
      radar: {
        indicator: labelList,
        center: ['50%', '50%'],
        radius: 100, //半径
        symbolSize: 16,
        shape: 'circle',
        axisName: {
          color: '#333333'
        }, //文本
        splitArea: {
          areaStyle: {
            color: [
              'rgba(247, 181, 0, 0.7)',
              'rgba(247, 181, 0, 0.5)',
              'rgba(247, 181, 0, 0.3)',
              'rgba(247, 181, 0, 0.2)',
              'rgba(247, 181, 0, 0.1)']
          }
        },
        axisLine: {
          lineStyle: {
            color: '#F8C948'
          },
          symbolOffset: [0, 0]
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0)'
          }
        }
      },
      series: [
        {
          type: 'radar',
          lineStyle: {
            width: 4
          },
          symbolSize: 10,
          symbol: 'image://https://cdn.kangarooread.com/mp-assets/user-result-radar-icon.png',
          data: [
            {
              value: valueList
            }
          ]
        }
      ]
    })
  }

  getQrcode = async () => {
    const params = {
      path: 'subPkg/extend/pages/web-link/index',
      scene: `m;5;${this.userId};open`,
      type: 20
    }
    try {
      const res = await serv.getQrCode(params)
      if (res) {
        const { cdnUrl } = res.data || {}
        this.qrCode = cdnUrl
      }
    } catch (err) {
      console.log(err)
    }
  }


  shareWechat = () => {
    const {
      env: runtimeEnv,
      closeModal,
      showModal
    } = this.props.store
    const { user: { userId } } = this.resultInfo
    if (runtimeEnv === 'app') {
      bridge.appShareWechat({
        title: '我家宝宝刚做了阅读测评，蛮准的，你也来试试吧！',
        thumb: `https://cdn.kangarooread.com/mp-assets/user-result-wx-share_75.png`,
        path: `subPkg/extend/pages/web-link/index?src=https://web${isProd ? '' : '-test'}.kangarooread.com/#/user/questionnaire&shareType=open&shareId=${userId || ''}`
      })
    } else if (runtimeEnv === 'mp') {
      this.shareVisible = false
      showModal({
        content: '请点击右上角"..."进行转发',
        okBtnText: '知道了',
        showCancelBtn: false,
        gravity: 1,
        onConfirm: () => {
          closeModal()
        }
      })
    }
  }

  shareImg = (str) => {
    const {
      env: runtimeEnv,
      showLoading,
      hideLoading,
      showToast
    } = this.props.store
    const node = document.getElementById(str)
    this.shareVisible = false
    this.visible = false
    showLoading('加载中...')
    html2canvas(node, {
      allowTaint: true,
      useCORS: true,
      scrollY: 0
    })
    .then((canvas) => {
      if (canvas) {
        const src = canvas.toDataURL('image/png')
        console.log(src)
        if (runtimeEnv === 'app') {
          bridge.appShareBase64(src).then(r => {
            console.log(r)
          }).catch(err => {
            console.log(err)
          })

        } else if (runtimeEnv === 'mp') {
          wx.miniProgram.navigateTo({
            url: '/subPkg/extend/pages/img-preview-page/index?type=userQuestionnaire'
          })
        }
      }
      hideLoading()
    })
    .catch((err) => {
      console.log(err)
      showToast('图片加载失败')
      hideLoading()
    })
  }

  toAppPage = () => {
    const {
      env: runtimeEnv
    } = this.props.store
    if (runtimeEnv === 'app') {
      bridge.appNavigate({ id: 1 })
    } else if (runtimeEnv === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/main/pages/new-subscribe-detail/index'
      })
    }
  }

  handleClickTip = () => {
    const {
      diagnosis: { radarCharts }
    } = this.resultInfo
    const {
      showModal,
      closeModal
    } = this.props.store
    showModal({
      title: <div className="radar-desc-wrapper">
        {
          radarCharts.map((it) => (
            <p className="radar-desc">{it.label}：<br/>{it.desc}</p>
          ))
        }
      </div>,
      okBtnText: '知道了',
      showCancelBtn: false,
      gravity: 2,
      onConfirm: () => {
        closeModal()
      }
    })
  }

  render() {
    const {
      user,
      starting,
      number,
      diagnosis,
      suggest,
      recommend,
      viewMore
    } = this.resultInfo
    const {
      env: runtimeEnv
    } = this.props.store

    return (
      <>
        <div className="result-page-wrapper">
          {
            viewMore && viewMore.show &&
            <img src={'https://cdn.kangarooread.com/mp-assets/user-result-contact-icon1.png'} alt={''}
                 className="result-contact-icon" onClick={() => this.visible = true}/>
          }
          <div className="result-bg">
            <div className="result-bg-padding">
              <img src={user && (user.avatar || 'https://cdn-test.kangarooread.com/resource/avatar/default.png')} alt=""
                   className="result-user-avatar"/>
              <div>{user && user.nickname}</div>
            </div>

          </div>
          <div className="result-container-wrapper">
            {/*阅读起点*/}
            <QuestionnaireCard
              title="https://cdn.kangarooread.com/mp-assets/user-result-title1.png"
              comment={starting && (starting.comments || '')}
            >
              <img src={`https://cdn.kangarooread.com/mp-assets/user-result-start${
                starting && (START_LEVEL.find((it) => it.label === starting.showLaber) || {}).value
              }.png`} alt={''}
                   className="start-chart-img"/>
            </QuestionnaireCard>

            {/*阅读数量*/}
            {
              number && number.show && <QuestionnaireCard
                title="https://cdn.kangarooread.com/mp-assets/user-result-title2.png"
                comment={number && number.comments}>
                <div className={'container-wrapper'}>
                  <img src="https://cdn.kangarooread.com/mp-assets/user-result-read-number.png" alt=""
                       className="read-number"/>
                  <div className="read-labels-wrapper">
                    {
                      number && number.labels.reverse().map((it, index) => (
                        <div className={`read-labels-item-wrapper`} key={index}>
                          <div className={`labels-icon ${
                            number &&
                            (START_LEVEL.find((it) => it.label === number.showLaber) || {}).value === index + 1 ?
                              'active' : ''}`}/>
                          <div className={`read-labels-item ${
                            number &&
                            (START_LEVEL.find((it) => it.label === number.showLaber) || {}).value === index + 1 ?
                              'active' : ''}`}>{it}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </QuestionnaireCard>
            }


            {/*阅读诊断*/}
            <QuestionnaireCard
              title="https://cdn.kangarooread.com/mp-assets/user-result-title3.png"
              comment={
                diagnosis && diagnosis.comments.map((it, index) => (
                  <div className="result-comments " key={index}>{it}</div>
                ))
              }
              tip={true}
              onTipClick={() => this.handleClickTip()}
            >
              <div id="q-radar"/>
            </QuestionnaireCard>

            {/*家长建议*/}
            {
              suggest && suggest.show && <QuestionnaireCard
                title="https://cdn.kangarooread.com/mp-assets/user-result-title4.png"
                comment={
                  <div>
                    <div className="result-comments">{suggest && suggest.subtitle}</div>
                    {
                      suggest && suggest.suggests.map((it, index) => (
                        <div className="result-comments" key={index}>{it}</div>
                      ))
                    }
                  </div>
                }/>
            }

            {/*绘本推荐*/}
            <QuestionnaireCard
              title="https://cdn.kangarooread.com/mp-assets/user-result-title5.png"
              childrenOffset="bottom"
              comment={
                recommend && recommend.contents.map((it, index) => (
                  <div className="result-comments " key={index}>{it}</div>
                ))
              }>
              <div>
                <div className="result-book-recommend-wrapper">
                  {
                    recommend && recommend.bookList.map((it, index) => (
                      <img className="result-book-item" src={it.coverPicture} alt="" key={index}/>
                    ))
                  }
                </div>
                {
                  viewMore && viewMore.show && <div className="result-vague-bg">

                    <img src="https://cdn.kangarooread.com/mp-assets/user-result-book-tip.png" className="result-tip"
                         onClick={() => this.toAppPage()}/>
                  </div>
                }
              </div>
            </QuestionnaireCard>
            <div className="result-padding"/>
          </div>
          <div className="result-btn" onClick={() => this.shareVisible = true}>
            分享好友一起参与
          </div>
        </div>

        {/*展示联系二维码*/}
        {
          this.visible && <div className="r-qr-code-bg-wrapper">
            <div className="r-qr-bg">
              <div className="r-qr-title">添加专属指导老师</div>
              <div className="r-qr-subtitle">享1对1咨询服务</div>
              <img className="r-qr-code" alt=""
                   src={(viewMore && viewMore.qrCode) || ''}/>
              {
                runtimeEnv === 'mp' && <div className="r-desc">长按二维码保存图片</div>
              }
            </div>
            {
              runtimeEnv !== 'mp' &&
              <div className="r-qr-btn" onClick={() => this.shareImg('r-share-qr-code')}>保存</div>
            }
            <div className="r-qr-close-icon" onClick={() => this.visible = false}/>
          </div>
        }

        {/*联系二维码分享图*/}

        <div
          className="r-share-wrapper"
        >
          <div className="r-share-position" id="r-share-qr-code">
            <img
              src={`https://cdn.kangarooread.com/mp-assets/user-result-modal-bg_75.png?timeStamp=${new Date().getTime()}`}
              className="r-img"
              alt=""
              crossOrigin="anonymous"
            />
            <div className="r-share-qr-title">添加专属指导老师</div>
            <div className="r-share-qr-subtitle">享1对1咨询服务</div>
            <img className="r-share-qr-code"
                 alt=""
                 src={`${(viewMore && viewMore.qrCode) || ''}`}
                 crossOrigin="anonymous"
            />
          </div>
        </div>

        {/*测试结果*/}
        <ActionSheet
          visible={this.shareVisible}
          showCloseIcon={false}
          bg="#F6F6F6"
          onClose={() => this.shareVisible = false}
        >
          <div className="op-wrapper">
            <div className="item" onClick={() => this.shareWechat()}>
              <img
                src="https://cdn.kangarooread.com/mp-assets/common/wechat_icon.png"
                alt=""
                className="icon"
              />
              <div className="label">分享测试</div>
            </div>
            <div className="item" onClick={() => this.shareImg('r-share-pic')}>
              <img
                src="https://cdn.kangarooread.com/mp-assets/common/pic_icon.png"
                alt=""
                className="icon"
              />
              <div className="label">保存结果</div>
            </div>
          </div>
        </ActionSheet>
        {
          this.shareVisible && <div className="share-result-wrapper">
            <div className="share-bottom-info">
              <img className="share-result-avatar" alt=""
                   src={(user && user.avatar) || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}/>
              <div>

                <div className="share-result-nickname">{(user && user.nickname)}</div>
                <div className="share-result-info">
                  <div>这个测评蛮准的</div>
                  <div>你也快给宝宝试试吧！</div>
                </div>
              </div>
              <img className="share-result-qrcode" src={this.qrCode} alt=""/>
            </div>
          </div>
        }
        {/*测试结果分享*/}
        <div
          className="r-share-result-wrapper"
        >
          <div className="r-share-result" id="r-share-pic">
            <img
              src={`https://cdn.kangarooread.com/mp-assets/user-result-share-bg-s.png?timeStamp=${new Date().getTime()}`}
              alt=""
              className="r-share-result-bg"
              crossOrigin="anonymous"
            />
            <div className="r-share-result-bottom-info">
              <img
                className="r-share-result-avatar"
                alt=""
                src={`${(user && user.avatar) || 'https://cdn-test.kangarooread.com/resource/avatar/default.png'}?timeStamp=${new Date().getTime()}`}
                crossOrigin="anonymous"
              />
              <div>
                <div className="r-share-result-nickname">{(user && user.nickname)}</div>
                <div className="r-share-result-info">
                  <div>这个测评蛮准的</div>
                  <div>你也快给宝宝试试吧！</div>
                </div>
              </div>
              <img
                className="r-share-result-qrcode"
                src={`${this.qrCode}?timeStamp=${new Date().getTime()}`}
                alt=""
                crossOrigin="anonymous"
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default UserResultPage

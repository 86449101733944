import React from 'react'
import '../index.less'

const content = `
<p style="margin-bottom:10px;text-align:center;line-height:125%" align="center">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:24px"><span style="font-family:宋体"><strong>服务协议</strong></span></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体"><strong>【提示条款】</strong></span></span></strong>
</p>
<p style="margin-bottom:10px;text-indent:32px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体"><strong>在接受本协议之前，请您仔细阅读本协议的全部内容。理解和同意本协议的全部内容是甲乙双方达成合作的前提，乙方承诺并接受本协议的全部内容，届时乙方不应以未阅读本协议的内容或者未获得甲方对乙方问询的解答等理由，主张本协议无效或要求撤销本协议。如乙方对本协议有任何疑问，请在签订本协议时对甲方进行询问，甲方将对乙方解释条款内容；如乙方不同意本协议任何内容或无法理解条款内容，请乙方及时停止申请</strong></span><strong>/注册/签署程序。</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">&nbsp;</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体"><strong>【协议条款】</strong></span></span></strong>
</p>
<p style="line-height:29px">
    <span style=";font-family:宋体;color:#000000;font-size:16px"><span style="font-family:宋体">根据国家法律法规相关规定，甲乙双方在平等自愿、协商一致的基础上，就甲方选择乙方为甲方的服务合作单位【</span></span><strong><span style=";font-family:仿宋;color:#000000;font-size:16px"><span style="font-family:仿宋"><strong>杭州好奇谷信息科技有限公司</strong></span></span></strong><span style=";font-family:宋体;color:#000000;font-size:16px"><span style="font-family:宋体">】及其关联公司（以下简称</span>“被服务方”）提供众包项目所需的【</span><span style=";font-family:仿宋;color:#000000;font-size:16px"><span style="font-family:仿宋">教育信息咨询</span></span><span style=";font-family:宋体;color:#000000;font-size:16px"><span style="font-family:宋体">】服务，并由甲方向乙方提供技术服务支持的事宜订立本协议。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>1.服务内容</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">1.1 根据品牌方与甲方的约定,甲方根据品牌方提供的服务要求和规则服务于品牌方,乙方也必须遵守甲方所需要遵守的品牌方给甲方的要求,乙方服务行为视为甲方的服务行为,乙方具体根据以下内容向品牌方提供/完成相关服务：</span>
</p>
<p style="line-height:29px">
    <span style=";font-family:宋体;color:#000000;font-size:16px">1.1.1</span><span style=";font-family:仿宋;color:#000000;font-size:16px">&nbsp;<span style="font-family:仿宋">众包项目所涉具体服务内容：</span>“</span><strong><span style=";font-family:仿宋;color:#000000;font-size:16px"><span style="font-family:仿宋"><strong>好奇说</strong></span></span></strong><span style=";font-family:仿宋;color:#000000;font-size:16px">”的教育信息咨询、市场推广服务</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">1.1.2甲方根据品牌方要求给出的政策规则（包括但不限于平台规则、用户注册协议）；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">1.1.3 因品牌方的具体需要,甲方与品牌方进行的指导、安排与要求；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">1.1.4 乙方同时也要遵守甲方与品牌方相关协议约定。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">1.2 上述第1.1条规定内容，包括但不限于乙方服务内容、服务方式、服务流程、服务规范、培训学习、费用结算以及乙方要求了解的其他与服务相关的必要知悉情况，乙方在申请/注册/签署本协议前应充分询问，确保知悉并理解相关规定内容。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">1.3 甲方向乙方提供在线合同（电签）、在线实名认证、费用查询、咨询等技术信息服务。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>2.协议效力</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">2.1 本协议期限为自乙方申请/注册/签署之日起1年。协议期限届满，如双方未签署新协议，而以实际行动继续履行本协议内容的，视为本协议期限顺延直至按照本协议约定终止或解除时为止。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">2.2 乙方基于准备和开展服务之目的，按甲方依据品牌方相关规定所必须注册/申请/签署的协议（如《用户注册协议》等）与本协议均具有法律效力，如二者有冲突，可以优先适用前者，但不得损害甲方利益。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>3.</strong></span></strong><strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体"><strong>甲方</strong></span></span></strong><strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体"><strong>权利义务</strong></span></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">3.1 甲方有权</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">要求乙方</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">根据本协议第</span>1.1条对乙方服务进行检查和验收</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">3.2甲方</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">有权根据</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">品牌方</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">业务经营情况对乙方</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">所提供的</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">服务</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">内容、服务方式、服务流程、服务规范等作出合理建议或调整。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">3.</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">3</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">&nbsp;<span style="font-family:宋体">甲方</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">有权不定期根据品牌方要求审查乙方所提供服务的效果，甲方有权依据品牌方反馈情况向乙方提出改进要求及各项改进指标</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">3.</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">&nbsp;</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">为提高乙方法律知识、专业技能和服务水平，甲方有权依据品牌方要求对乙方进行培训或指导，乙方应积极配合。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">3.5甲方依约向乙方提供在线合同、线上实名认证等技术信息服务，并根据被服务方提供的服务数据进行结算向乙方支付费用，如乙方费用涉及税费，乙方委托甲方帮助其履行纳税义务。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>4.乙方权利义务</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.1乙方根据本协议第1.1条规定按时、按质、按量提供/完成</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">相关</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">服务，达到规定的标准，配合各方审查，根据改进要求不断优化服务。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.2 乙方根据本协议第1.1条规定或经甲方同意，乙方可自行招募或邀请合格的广商提供/完成</span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">相关</span></span><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">服务。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.3乙方理解并确认，其作为甲方服务合作人员，只有在甲方收到品牌方相应服务款项后方可向甲方要求支付费用，且不应直接向品牌方要求支付费用。乙方向品牌方发起的申请支付的行为，应视为向甲方发起，而非向品牌方发起。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.4乙方服务期间须遵守相关法律法规规定，不得以虚假宣传等方式侵犯第三方合法权益；须维护甲方和品牌方的品牌、形象和声誉，并对自己的推广行为负责。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.5乙方服务工作中因过错致人损害或损失的，乙方应自行承担赔偿责任。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.6除本协议约定的乙方服务内容外，乙方不能以甲方或品牌方名义开展任何与服务内容无关的业务或活动。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.7 若品牌方发生主体变更，乙方收到甲方关于主体变更通知后24小时内未按照通知内容回复或品牌方主体变更后乙方仍以实际行动继续为新的品牌方提供相同服务的，视为乙方同意品牌方主体变更。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.8乙方同意授权甲方，甲方有权为了乙方利益最大化和账户安全考虑，在合同服务期内，将对其的服务义务及其相应权利转让给第三方主体，从而更好的为乙方进行服务。转让后由受让的第三方承接甲方的权利义务。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">4.9乙方需自配服务所需的各类工具，例如电脑、硬件设施等，乙方的服务地点不定，由被服务方客户需求决定，甲方不予提供固定工作场所。交付验收采取当面交付或者远程数据传输等。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>5.费用支付</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">5.1甲方向乙方按时足额支付服务费用，乙方服务费用的计算、统计、结算、支付等以本协议第1.1条相关规定为准。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">5.2 甲方依据本协议第1.3条向乙方收取支持费用的，该费用以甲方额外产生的成本为计算依据，并可在向乙方支付的服务费用中予以扣除。额外产生的成本指因乙方过错导致电签、在线实名认证、费用发放错误或失败，需要变更在线合同、另行签署在线合同或重新进行实名认证等情形下甲方产生的成本，因前述情形导致甲方不能按时支付乙方服务费用的，甲方免责。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">5.3 乙方应向甲方提供实名银行账户或其他收款账户用以收取甲方支付的服务费用。乙方确认因提供收款帐户不实造成的一切损失由乙方自行承担；如乙方收款账户变更或发生不可用等情况时，应及时进行变更操作并作有效通知（书面形式），否则由此造成的一切损失由乙方自行承担。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">5.4 乙方可针对电签、在线实名认证、费用发放时间和发放金额等问题向甲方咨询。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">5.5 乙方知晓并确认，在本协议有效期限内，业务结算规则根据运营需要会浮动调整；业务结算规则调整后，乙方以实际行动表明继续履行本协议的，视为乙方同意调整后的业务结算规则。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>6.保证责任</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">6.1 甲方保证其具备履行本协议所需的合法资质，并且与品牌方相关协议真实合法有效。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">6.2 乙方保证其提供的身份、账户、联系方式等所有信息真实、准确、有效，不会影响本协议效力；因乙方原因导致电签、在线实名认证、费用发放错误或失败需要重新进行电签等手续的，乙方须承担相应技术信息服务产生的成本费用。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">6.3 乙方采用的销售推广渠道、推广工具、推广内容、招募或邀请销售推广商等行为应不违反任何应予适用的法律、法规及政策, 不会侵犯任何第三方合法权益，如因此给甲方或被服务方造成损失，或因此涉及诉讼等司法程序的，由乙方承担由此引发的所有赔偿责任和法律后果。若导致甲方或被服务方承担在先责任的，甲方或被服务方有权向乙方追偿。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">6.4 乙方知晓并确认，甲乙双方通过本协议建立的是服务合作关系，适用《中华人民共和国合同法》等相关法律。乙方承诺并保证其无意也无法通过本协议与甲方建立劳动合同关系，且申请/注册/签署本协议也无法成为甲方在职职工。基于以上，甲方除依据本协议第5条向乙方支付费用外，不承担乙方任何社会保险、公积金、残保金和福利待遇。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">6.5 乙方保证在签署本协议时，不存在与本协议内容冲突的其他工作任务或合作关系，能够独立、完整、及时地完成本协议内容。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>7.保密责任</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">7.1 甲方应对乙方身份、账户、联系方式等非公开信息进行严格保密，非因法定或约定原因，甲方不得披露或使用乙方信息。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">7.2 乙方应在完成全部申请或书面签署本协议后任何时间，保守因工作需要接触或掌握的甲方及品牌方的任何商业秘密、保密信息，包括但不限于：甲方及品牌方的知识产权、合作模式、经营情况、品牌方服务对象信息、本协议签署事实、本协议条款信息、乙方服务安排、乙方费用及税费、本协议履行情况等。除甲方书面许可外，乙方不得在任何时间以任何方式和目的披露、泄露、使用或允许他人使用以前项手段获取的甲方及品牌方的商业秘密和保密信息。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>8.协议生效、解除与终止</strong></span></strong>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.1 本协议自双方签字盖章之日起生效。经双方协商一致，可以另行签署书面协议对本协议进行修订和补充。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.2 本协议在发生下列情形时解除：</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.2.1 双方协商一致后，可解除本协议；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.2.2 甲方连续7天或累计15天无法与乙方取得有效联系的，甲方可单方解除本协议；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.2.3 乙方故意或因疏忽导致严重损害或违背甲方利益或合理要求，不能胜任服务内容，致使不能实现本协议目的的，甲方可单方解除本协议。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.3本协议在发生下列情形时终止：</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.3.1 协议期限届满双方未签署新协议，且不再继续履行本协议内容的；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.3.2 甲方和品牌方变更业务合作模式而不需要乙方继续履行本协议的；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.3.3 甲方和品牌方终止合作的；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.3.4 因法律法规政策变动导致甲方履行义务不能的；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.3.5 其他原因导致本协议不可能或不适合再继续履行的。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.4 协议解除或终止时，乙方应按照要求办理必要的工作交接手续（如有），若因交接不及时给甲方或品牌方造成损失的，乙方应承担赔偿责任。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">8.5 本协议的提前终止不影响已经产生的权利义务关系。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <strong><span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><strong>9.违约责任</strong></span></strong>
</p>
<p style="margin-bottom:10px;text-indent:32px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px"><span style="font-family:宋体">甲乙双方应按本协议约定内容行使权力和义务，如一方违反本协议，另一方有权要求对方及时改正；如造成对方损害或损失，守约方有权要求违约方赔偿。</span></span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">9.1 甲方应如期向乙方支付服务费用，延期支付的应按照本协议1.1款的具体约定承担违约责任。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">9.2 乙方未按照约定期限完成服务的，应支付数额为服务费用总额30%的违约金；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">9.3 在服务期限内，发现品牌方提供的数据、资料、工具、条件不符合安全性或实操性的，应及时反馈沟通，因未及时沟通造成的一切后果由乙方自行承担；</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">9.4 违反协议约定擅自将有关数据、资料、工具、样品、成果等转移、引用、贩卖、转让或到第三方处使用等，应支付数额为总报酬30%的违约金，并赔偿相关损失、承担法律后果。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">10.争议解决</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">10.1 因履行本协议发生争议的，双方应秉着友好合作的态度协商解决；协商不成的，应提请甲方所在地人民法院诉讼解决。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">10.2 因本协议产生之纠纷，由违约方承担守约方因此而支付的包括但不限于诉讼费、律师费、咨询费、取证费、公证费、执行费等相关费用。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">11.附则</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">11.1 甲方根据现有技术对乙方提供技术支持，但不对网络的及时性、安全性、准确性做任何明示的或默示的担保，特别是因网络故障及政府监管措施，黑客攻击等不可抗力或不可预见的行为，甲方应尽力采取补救措施，但无需承担任何责任。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">11.2 甲乙双方对本协议下所有条款认真、仔细阅读并完全理解，且在签订本协议时对所有条款均作出了详细确认，双方共同确认本协议不构成任何一方的格式合同。双方再次确认均应秉持契约精神全面履行本协议项下各自权利义务。</span>
</p>
<p style="margin-bottom:10px;line-height:125%">
    <span style=";font-family:宋体;line-height:125%;color:#000000;font-size:16px">11.3 甲方根据本协议4.8条的约定，在进行服务主体变更时会发送短信到乙方预留的手机号，发送成功即为乙方收到短信，若乙方在收到短信后的三日内未向被服务方或者甲方提出书面异议，则视为乙方同意变更。</span>
</p>`

export default () => <div
  className="protocol-wrapper"
  dangerouslySetInnerHTML={{
    __html: content
  }}
/>

import React from 'react'
import './page_7.less'

const Page_7 = (props) => {
	const { mamaStartMonth, jinjieStartMonth, mamaCourseTitle } = props

	return (
		<>
			<picture className='yr-page-7-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_7_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_7_bg.png'
					className='yr-page-7-bg-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-7-human'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_7_human.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_7_human.png'
					className='yr-page-7-human-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-7-text'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_7_text.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_7_text.png'
					className='yr-page-7-text-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-7-circle'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_7_circle.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_7_circle.png'
					className='yr-page-7-circle-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-7-text-wrapper'>
				这一年
				<br />
				你还不断精进自己，持续成长
				<br />
				{mamaStartMonth && (
					<>
						在&nbsp;
						<span className='text' style={{ color: '#EF8514' }}>
							{mamaStartMonth}
						</span>
						&nbsp;月
						<br />
						你开始学习&nbsp;
						<span style={{ color: '#EF8514' }}>
							《{mamaCourseTitle || ''}》
						</span>
						<br />
					</>
				)}
				{jinjieStartMonth && (
					<>
						在&nbsp;
						<span className='text' style={{ color: '#EF8514' }}>
							{jinjieStartMonth}
						</span>
						&nbsp;月
						<br />
						你开始学习&nbsp;
						<span style={{ color: '#EF8514' }}>《绘本领读进阶课》</span>
					</>
				)}
			</div>
		</>
	)
}

export default Page_7

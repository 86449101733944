import React from 'react'
import './page_5.less'

const Page_5 = (props) => {
	const { maxGddReadOneBookName, maxGddReadOneBookCount, maxGddRecBookCount } =
		props || {}

	return (
		<>
			<picture className='yr-page-5-bg'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_5_bg.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_5_bg.png'
					className='yr-page-5-bg-img'
					alt=''
				/>
			</picture>
			<picture className='yr-page-5-gdd'>
				<source
					srcSet='https://cdn.kangarooread.com/resource/year_report/yr_page_5_gdd.webp'
					type='image/webp'
				/>
				<img
					src='https://cdn.kangarooread.com/resource/year_report/yr_page_5_gdd.png'
					className='yr-page-5-gdd-img'
					alt=''
				/>
			</picture>
			<div className='yr-page-5-text-wrapper'>
				这一年，
				<br />
				你把谷兜兜魔法棒带回了家，
				<br />
				它陪伴孩子阅读最多的一本书是
				<br />
				<span
					style={{
						color: '#FF7713'
					}}>
					《{maxGddReadOneBookName || ''}》
				</span>
				，
				<br />
				读了一共&nbsp;
				<span
					className='text'
					style={{
						color: '#FF7713'
					}}>
					{maxGddReadOneBookCount || ''}
				</span>
				&nbsp;次
				{maxGddRecBookCount && (
					<>
						，
						<br />
						<br />
						用科技赋能，让阅读随时发生
						<br />
						你一共为孩子用谷兜兜录制了&nbsp;
						<span className='text' style={{ color: '#379FFF' }}>
							{maxGddRecBookCount || ''}
						</span>
						&nbsp;本绘本，
						<br />
						谷兜兜中流淌出的熟悉声音和美好故事，
						<br />
						让陪伴一直都在。
					</>
				)}
			</div>
		</>
	)
}

export default Page_5

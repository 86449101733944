import React, {Component} from "react";
import emotionData from '../../lib/emotionData'
import './index.less'
import {Util} from "../../lib/util";

class ExpandPageIndex extends Component {

  UNSAFE_componentWillMount() {
    document.title = "情绪伙伴卡片使用指南"
  }

  toDetail = (id = '') => {
    Util.pushRouter(`/expand-page/${id}`)
  }

  render() {
    return (
      <div className="emotion-index-wrapper">
        {emotionData.map(it => (
          <img className="index-img" src={it.img} alt="" key={it.id} onClick={() => this.toDetail(it.id)}/>
        ))}
      </div>
    )
  }
}

export default ExpandPageIndex
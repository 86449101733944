import { Util } from './util'

const { location } = window
const { href } = location

const envOptions = {
	// STAGING: 'https://api-test.kangarooread.com/',
	// STAGING: 'http://47.98.125.156:8801/',
	STAGING: "https://api.kangarooread.com/",
	PROD: 'https://api.kangarooread.com/',
	sourceDomain: 'https://cdn.kangarooread.com/'
}

const sourceType = {
	IMG: 'mp-assets/',
	VIDEO: 'video/'
}

const prodReg = /^((http|https):\/\/web\.kangarooread\.com)/
const isProd = prodReg.test(href)

const serverEnv = isProd ? envOptions.PROD : envOptions.STAGING

const env = {
	DOMAIN: `${serverEnv}`,
	IMG: `${envOptions.sourceDomain}${sourceType.IMG}`,
	VIDEO: `${envOptions.sourceDomain}${sourceType.VIDEO}`
}

const nativeReturnMsg = {
	SUCCESS: JSON.stringify({
		result: 'ok'
	}),
	ERROR: JSON.stringify({
		result: 'error'
	})
}

const headerConfig = {
	platform: 5, // h5
	appVersion: '1.0.0',
	protocolVersion: 2,
	deviceId: Util.getDeviceId(),
	bigSmarter: {
		action: function () {
			return Util.setMD5(`${this.deviceId}_${this.platform}_${this.appVersion}`)
		}
	}
}

const storage = {
	userInfo: 'user_info',
	accessToken: 'access_token',
	shareId: 'share_id',
	locationInfo: 'location_info'
}

export { env, isProd, storage, headerConfig, nativeReturnMsg }

import React from 'react'

export default function QrcodeToGroup() {
	const img = 'https://cdn.kangarooread.com/camp/20230613_make_up_exam_9.jpg'

	return (
		<div>
			<h4
				style={{
					padding: 20,
					textAlign: 'center',
					fontSize: 12
				}}>
				截屏保存到手机，微信扫一扫-选择右下角图片 即可进补考群
			</h4>
			<img
				src={img}
				alt=''
				style={{
					display: 'block',
					width: '90%',
					margin: '0 auto'
				}}
			/>
		</div>
	)
}

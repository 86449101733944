import React, {Component} from 'react'
import './index.less'
import {inject, observer} from "mobx-react";
import {computed} from "mobx";
import {CopyToClipboard} from 'react-copy-to-clipboard'

@inject('store')
@observer
class Questionnaire extends Component {

  @computed get env() {
    return this.props.store.env
  }

  handleCopy = () => {
    const { showToast } = this.props.store
    showToast('已复制至剪贴板')
  }

  handleJoin = () => {
    window.location.href = 'https://qywx.wjx.cn/jq/96159131.aspx'
  }

  render() {
    return (
      <div className="question-wrapper">
        <div className="q-content">
          <img className="q-bg" src="https://cdn.kangarooread.com/mp-assets/web_question_img1.png" alt=""/>
          <div className="q-card">
            <i className="icon left"/>
            <i className="icon right"/>
            <div className="title">有奖问卷调查</div>
            <div className="dash-line"/>
            <div className="desc">
              <span className="normal">为了给大家提供更丰富的</span>
              <span className="bold">家庭教育课程</span>
              <span className="normal">好奇说诚邀参加此问卷调查。</span>
            </div>
            <div className="desc">
              <span className="normal">每位参与此问卷的</span>
              <span className="bold">推广人/分会长</span>
              <span className="normal">我们将奉上</span>
              <span className="bold">50积分</span>
              <span className="normal">作为小心意；</span>
            </div>
            <div className="desc">
              <span className="normal">期待收到你的需求。</span>
            </div>
            <div
              style={{
                marginBottom: 110
              }}
            />
            {
              this.env === 'mp'
                ? <CopyToClipboard
                  text='https://qywx.wjx.cn/jq/96159131.aspx'
                  onCopy={this.handleCopy}
                >
                  <button className="button">复制链接，粘贴至浏览器或微信中参与</button>
                </CopyToClipboard>
                : <button className="button" onClick={() => this.handleJoin()}>马上参与</button>
            }
            <div className="tip">
              请在问卷中正确填写你的注册手机号
              <br/>
              50积分奖励将在提交问卷1周内发放
            </div>
          </div>
        </div>
        <div className="q-footer">
          <img className="f-img" src="https://cdn.kangarooread.com/mp-assets/web_question_img2.png" alt=""/>
        </div>
      </div>
    )
  }
}

export default Questionnaire
import React from 'react'

const EventAddStaff = () => {
	return <>
		<img
			src="https://cdn.kangarooread.com/resource/add_staff_qrcode.jpg"
			style={{
				display: 'block',
				width: '100%',
				height: 'auto'
			}}
			alt=""
		/>
	</>
}

export default EventAddStaff

import fetch from '../fetch'

const datacenter = {
	/**
	 * @description 数据埋点上报-通用
	 * @param {*} params
	 */
	dataTrackingStorage(params = {}) {
		return fetch('/datacenter/base/data_tracking_storage', {
			method: 'POST',
			data: { ...params }
		})
	}
}

export default datacenter

import React, { useEffect, useState } from 'react'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../../store'
import serv from '../../../lib/api'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import './index.less'
import { Util } from '../../../lib/util'
import { storage } from '../../../lib/conf'

export default function EventCourseGift(props) {
  const store = useLocalStore(() => CommonStore)
  const [id, setId] = useState('')
  const [userInfo, setUserInfo] = useState({})
  const { showLoading, hideLoading, showToast, env } = store

  const initData = () => {
    const { id } = props.match.params
    setId(id)
    checkUser()
  }

  useEffect(() => {
    initData()
  }, [])

  /**
   * @description 检查用户登录
   * @returns {Promise<void>}
   */
  const checkUser = () => {
    return new Promise((resolve, reject) => {
      if (env === 'app') {
        bridge.getUserInfoFromApp().then((res) => {
          if (res) {
            setUserInfo({ ...res })
            resolve()
          } else {
            bridge.loginFromApp().then((userInfo) => {
              if (userInfo) {
                setUserInfo({ ...userInfo })
                resolve()
              } else {
                showToast('请先登录')
                reject()
              }
            })
          }
        })
      } else {
        try {
          const token = Util.getParams('token')
          if (token) {
            Util.setLocalStorage(
              storage.userInfo,
              JSON.stringify({ accountVo: { token: token || '' } })
            )
            fetchUser().then(_ => {
              resolve()
            })
          } else {
            let _user = Util.getLocalStorage(storage.userInfo) || '{}'
            _user = JSON.parse(_user) || null
            if (_user && _user.id) {
              setUserInfo(_user)
              resolve()
            } else {
              wx.miniProgram.navigateTo({
                url: '/pages/login/index',
                success: () => {
                  reject()
                }
              })
            }
          }
        } catch (err) {
          console.log(err)
          reject()
        }
      }
    })
  }

  /**
   * @description 获取用户信息（小程序及H5使用）
   */
  const fetchUser = async () => {
    try {
      const res = await serv.getUserInfo()
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        setUserInfo({ ...data })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 领取礼物
   * @returns {Promise<void>}
   */
  const handleGetGift = async () => {
    showLoading('领取中...')
    try {
      const res = await serv.getQuestionaireGift(1)
      if (res) {
        const { data } = res
        if (data) {
          showToast('领取成功')
          setTimeout(() => {
            if (env === 'app') {
              bridge.appNavigate({id: 117, params: `id=${id}`})
            } else {
              wx.miniProgram.navigateTo({
                url: `/subPkg/extend/pages/keep-add-group/index?id=${id}`
              })
            }
          } , 1000)
        } else {
          showToast('领取失败')
        }
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  return (
    <div className="e-course-gift-wrapper">
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_1.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_2.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_3.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_4.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_5.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_6.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_7.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_8.png" alt="" className="bg-img"/>
      <img src="https://cdn.kangarooread.com/mp-assets/eventGift/event_get_gift_detail_img_9.png" alt="" className="bg-img"/>
      <div className="btn-wrapper">
        <div className="btn" onClick={() => handleGetGift()}>领取课程</div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import List from '../../components/List'
import serv from '../../lib/api'
import moment from 'moment'
import { useLocalStore } from 'mobx-react'
import CommonStore from '../../store'

export default function ReadInviteRecord() {
  const store = useLocalStore(() => CommonStore)
  const { userInfo } = store

  const [list, setList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [loaded, setLoaded] = useState(false)
  const size = 10

  document.title = '邀请记录'

  useEffect(() => {
    getList()
    setLoaded(true)
  }, [])

  const getList = async () => {
    const params = {
      size,
      pageNum
    }
    try {
      const res = await serv.readInviteRecord(params)
      if (res) {
        const { hasMore, data } = res
        setList(list.concat(data || []))
        setHasMore(hasMore)
        setPageNum(hasMore ? pageNum + 1 : pageNum)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const { level } = userInfo || {}

  return <>
    {(level && level > 0)
      ? <div className="level-tip">亲爱的领读者：以下仅为邀请记录,完整会员数据请通过经营中心查看</div>
      : null}
    <List
      next={getList}
      loaded={loaded}
      dataSource={list || []}
      hasMore={hasMore}
      endStr='到底啦~'
    >
      {list.map((it, idx) => (
        <div className="record-item" key={idx}>
          <img src={it.avatar} alt="" className="avatar"/>
          <div className="info">
            <div className="nickname-wrapper">
              <div className="nickname">{it.nickname || ''}</div>
              <div className="time">{moment(it.time).format('YYYY-MM-DD')}</div>
            </div>
            <div className="desc">
              {it.status === 1 && '已是订阅会员，邀请无效'}
              {it.status === 2 && '未购买，邀请无效'}
              {it.status === 3 && <span style={{ color: '#13BD7A' }}>邀请成功</span>}
              {it.status === 4 && '已被他人邀请，邀请无效'}
              {it.status === 5 && '已订阅暂未扣费成功'}
            </div>
          </div>
          <div className="split-line"/>
        </div>
      ))}
    </List>
  </>
}

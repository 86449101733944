import React, { useEffect, useState } from 'react'
import { useLocalStore } from 'mobx-react'
import { useLocation } from 'react-router'
import CommonStore from '../../store'
import RadioInput from './Radioinput'
import SelectInput from './SelectInput'
import serv from '../../lib/api'
import { bridge } from '../../lib/bridge'
import wx from 'weixin-js-sdk'
import './index.less'
import { Util } from '../../lib/util'
import { storage } from '../../lib/conf'

export default function CommonQuestionnaire(props) {
  const store = useLocalStore(() => CommonStore)
  const { pathname } = useLocation()
  const [aid, setAId] = useState('')
  const [detail, setDetail] = useState({})
  const [selectedRadio, setSelectedRadio] = useState({})
  const [selectOptions, setSelectOptions] = useState([])
  const { showLoading, hideLoading, showToast, env, commonQuestionList, updateCurrentQuestionId, updateCommonQuestionList } = store

  const initData = () => {
    const { aid, id } = props.match.params
    setAId(aid)
    initQa(aid, id)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    initData()
  }, [pathname])

  /**
   * @description 初始化题目
   * @param aid 问卷的id
   * @param id 题目的id
   * @returns {Promise<void>}
   */
  const initQa = async (aid = '', id = '') => {
    setSelectedRadio({})
    setSelectOptions([])
    showLoading('加载中...')
    if (id === 'start') {
      await getQuestion(aid, true)
    } else {
      getQuestionInStore(id * 1, aid)
    }
    hideLoading()
  }

  /**
   * @description 处理初始化数据
   * @param q
   */
  const processQuestionCache = (q) => {
    const { questionType, hasAnswered, selectedRadioId, questionaireOptionList, questionId } = q
    if (hasAnswered) {
      const qList = commonQuestionList
      const _currentIdx = qList.findIndex(it => it.questionId === questionId)
      const _next = qList[_currentIdx + 1]
      const _nextId = _next.questionId
      if (_next) {
        getPreviousQuestion(questionId, _nextId)
        qList.pop()
        updateCommonQuestionList(qList)
      }
      if (questionType === 1) {
        const item = questionaireOptionList.find(it => it.optionId === selectedRadioId)
        if (item) {
          setSelectedRadio(item)
        }
      }
    }
  }

  const getPreviousQuestion = async (preQuestionId, thisQuestionId) => {
    try {
      await serv.getPreviousQuestion(preQuestionId, thisQuestionId)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 获取第一题
   * @param aid
   * @param needReFresh
   * @returns {Promise<void>}
   */
  const getQuestion = async (aid, needReFresh) => {
    try {
      const res = await serv.getUserFirstQuestion(aid)
      if (res) {
        const { data } = res
        const { questionId, questionType, questionaireOptionList } = data || {}
        if (needReFresh) {
          // 重定向至第一题
          props.history.replace(`/common-questionnaire/${aid}/${questionId}`)
        } else {
          // 如果是多选题，则初始化选项的选中状态
          if (questionType === 2) {
            questionaireOptionList && questionaireOptionList.forEach(it => {
              it.selected = false
            })
            setSelectOptions(questionaireOptionList || [])
          }
          setDetail(data || {})
          const newList = [...commonQuestionList, { ...(data || {}) }]
          updateCommonQuestionList(newList)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @description 从store从获取题目
   * @param id 题目id
   * @param aid 问卷id
   */
  const getQuestionInStore = (id, aid) => {
    const currentQuestion = commonQuestionList.find(it => it.questionId === id)
    if (currentQuestion) {
      updateCurrentQuestionId(id)
      const { questionType, questionaireOptionList } = currentQuestion
      if (questionType === 2) {
        setSelectOptions(questionaireOptionList || [])
      }
      processQuestionCache(currentQuestion)
      setDetail(currentQuestion)
    } else {
      getQuestion(aid)
    }
  }

  /**
   * @description 提交选择结果并获取下一题
   * @returns {Promise<void>}
   */
  const submitResult = async () => {
    showLoading('提交中...')
    const { questionType, questionId, minSelectCount } = detail
    const params = {
      questionId,
      questionaireId: aid
    }
    if (questionType === 1) {
      if (!selectedRadio.optionId) {
        showToast('请选择')
        hideLoading()
        return
      }
      params.questionaireOptionList = [
        { questionId, optionId: selectedRadio.optionId }
      ]
    }
    if (questionType === 2) {
      const selectedList = selectOptions
      .filter(it => it.selected)
      .map(it => ({ questionId, optionId: it.optionId }))
      if (selectedList.length < minSelectCount) {
        showToast(`请至少选择${minSelectCount}个选项`)
        hideLoading()
        return
      }
      params.questionaireOptionList = selectedList
    }
    try {
      const res = await serv.submitAndGetNextQuestion(params)
      if (res) {
        let next, nextId
        if (questionType === 1) {
          next = selectedRadio.hasMoreQuestion
          nextId = selectedRadio.nextQuestionId
        }
        if (questionType === 2) {
          const selectedList = selectOptions.filter(it => it.selected)
          next = selectedList[0].hasMoreQuestion
          nextId = selectedList[0].nextQuestionId
        }
        if (next && nextId) {
          processCurrentQuestion()
          const { data } = res
          const { questionType: qt, questionaireOptionList: ql } = data || {}
          if (qt === 2) {
            ql && ql.forEach(it => {
              it.selected = false
            })
          }
          const newList = [...commonQuestionList, { ...(data || {}) }]
          updateCommonQuestionList(newList)
          const { push } = props.history
          push(`/common-questionnaire/${aid}/${nextId}`)
        } else {
          let locationInfo = Util.getLocalStorage(storage.locationInfo)
          locationInfo = JSON.parse(locationInfo || '{}')
          const { city, longitude, latitude, province } = locationInfo || {}
          if (env === 'mp') {
            wx.miniProgram.reLaunch({
              url: `/subPkg/extend/pages/questionnaire-result/index?questionaireId=1&city=${encodeURIComponent(city || '')}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${encodeURIComponent(province || '')}`
            })
          }
          if (env === 'app') {
            bridge.appNavigate({ id: 114, params: `questionaireId=1&city=${city || ''}&longitude=${longitude || 0}&latitude=${latitude || 0}&province=${province || ''}` })
            setTimeout(() => {
              bridge.appClose()
            }, 100)
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  /**
   * @description 处理放入缓存中的当前题目的答案
   */
  const processCurrentQuestion = () => {
    const list = commonQuestionList
    const curIdx = list.findIndex(it => it.questionId === detail.questionId)
    list[curIdx].hasAnswered = true
    const { questionType } = list[curIdx]
    if (questionType === 1) {
      list[curIdx].selectedRadioId = selectedRadio.optionId
    } else {
      list[curIdx].questionaireOptionList = selectOptions
    }
  }

  /**
   * @description 选择选项
   * @param item
   */
  const selectItem = (item = {}) => {
    const { questionType, maxSelectCount } = detail
    if (questionType === 1) {
      setSelectedRadio(item)
    }
    if (questionType === 2) {
      const { optionId } = item
      const idx = selectOptions.findIndex(it => it.optionId === optionId)
      if (idx > -1) {
        const newList = [...selectOptions]
        const { selected } = newList[idx]
        const selectedLength = selectOptions.filter(it => it.selected).length
        if (selected) {
          newList[idx].selected = !selected
          setSelectOptions(newList)
        } else {
          if (selectedLength >= maxSelectCount) {
            showToast(`最多选择${maxSelectCount}项`)
          } else {
            newList[idx].selected = !selected
            setSelectOptions(newList)
          }
        }
      }
    }
  }

  return (
    <div className="c-q-wrapper">
      <div className="bg"/>
      <div className="progress-wrapper">
        <div className="progress-content">
          <div className="cur-icon"
               style={{ left: `${((detail.displayOrder || 0) / (detail.totalQuestionCount || 0)) * 100}%` }}/>
          <div className="finally-wrapper">
            <div className="icon"/>
            <div className="label-btn">惊喜奖品</div>
          </div>
          <div className="bar" style={{
            width: `${((detail.displayOrder || 0) / (detail.totalQuestionCount || 0)) * 100}%`
          }}/>
        </div>
        <div className="progress-tip">最多{detail.totalQuestionCount || '--'}题，预计耗时1分30秒</div>
      </div>
      <div className="content-wrapper">
        <div className="title">{detail.displayOrder || 1}. {detail.content}</div>
        {detail.questionType === 2 ? <div className="sub-t">「此题为多选，最多可选择个{detail.maxSelectCount}答案」</div> : null}
        <div className="list">
          {detail.questionaireOptionList && detail.questionaireOptionList.map(it => (
            <div className="item" key={it.optionId} onClick={() => selectItem(it)}>
              {detail.questionType === 1
                ? <RadioInput selected={selectedRadio.optionId === it.optionId} content={it.content}/>
                : <SelectInput selected={it.selected} content={it.content}/>
              }
            </div>
          ))}
        </div>
      </div>
      <div className="btn-wrapper">
        {commonQuestionList.length > 1 ? <div className="prev" onClick={() => props.history.goBack()}>上一题</div> : null}
        <div className="next"
             onClick={() => submitResult()}>{detail.displayOrder === detail.totalQuestionCount ? '提交，查看结果' : '下一题'}</div>
      </div>
    </div>
  )
}

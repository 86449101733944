import React, { useEffect } from 'react'

export default function WYMMYYZDS() {
  useEffect(() => {
    document.title = '35天+无忧妈妈科学养育指导师课程保密协议'
  }, [])

 return  <div className="agreement-wrapper">
   <div className="title">《35天+无忧妈妈科学养育指导师课程保密协议》</div>
   <div className="desc bold">
     杭州好奇谷信息科技有限公司提醒您认真阅读《35天+无忧妈妈科学养育指导师》课程保密协议。
   </div>
   <div className="desc bold">购买课程后用户需遵守以下内容</div>
   <div className="desc">1)不对课程进行任何形式的录音和录像以及未经授权的传播；对学员的分享需保密，未经授权不做讨论之外的其他用途。</div>
   <div className="desc">（2）所有课程内容版权归属简耕教育，未得到授权许可不得以任何形式转发扩散。如发现出现违反情况将依法追究法律责任。</div>
 </div>
}
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable } from 'mobx'
import { bridge } from '../../../lib/bridge'
import wx from 'weixin-js-sdk'
import serv from '../../../lib/api'
import './index.less'
import NoData from '../../../components/NoData'

@inject('store')
@observer
class EventSelectUser extends Component {
  @observable detail = {}
  @observable loaded = false
  @observable hasAuth = false

  componentDidMount() {
    document.title = '专属奖励'
    this.initData()
  }

  initData = () => {
    const { showPageLoading, hidePageLoading } = this.props.store
    showPageLoading()
    this.getDetail()
    .then(_ => {
      hidePageLoading()
    })
    .catch(err => {
      console.log(`getDetail failed: ${JSON.stringify(err)}`)
      hidePageLoading()
    })
  }

  /**
   * @description 获取活动详情
   * @returns {Promise<void>}
   */
  getDetail = async () => {
    this.loaded = false
    try {
      const res = await serv.getSelectActivityDetail()
      if (res) {
        const { data } = res
        this.detail = { ...(data || {}) }
        this.hasAuth = true
      }
    } catch (err) {
      console.log(err)
    }
    this.loaded = true
  }

  /**
   * @description 去买卡
   */
  toBuyCard = () => {
    const { env } = this.props.store
    if (env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/buy-card/index'
      })
    } else if (env === 'app') {
      bridge.appNavigate({ id: 3 })
    } else {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/buy-card/index',
        fail: () => {
          bridge.appNavigate({ id: 3 })
        }
      })
    }
  }

  /**
   * @description 跳转我的订阅
   */
  toMyCard = () => {
    const { env } = this.props.store
    if (env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/my-card/index'
      })
    } else if (env === 'app') {
      bridge.appNavigate({ id: 10 })
    } else {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/my-card/index',
        fail: () => {
          bridge.appNavigate({ id: 10 })
        }
      })
    }
  }

  /**
   * @description 领取活动优惠券
   * @returns {Promise<void>}
   */
  handleGet = async () => {
    const { showLoading, hideLoading, showToast } = this.props.store
    showLoading('领取中...')
    try {
      const res = await serv.getCouponInEventSelectUser()
      if (res) {
        const { data } = res
        if (data) {
          showToast('领取成功')
          await this.getDetail()
        } else {
          showToast('领取失败')
        }
      }
    } catch (err) {
      console.log(err)
    }
    hideLoading()
  }

  toCouponList = () => {
    const { env } = this.props.store
    if (env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/coupon-list/index'
      })
    } else if (env === 'app') {
      bridge.appNavigate({ id: 118 })
    } else {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/coupon-list/index',
        fail: () => {
          bridge.appNavigate({ id: 118 })
        }
      })
    }
  }

  render() {
    const { babyName, activityStatus } = this.detail
    return (
      <>
        {this.loaded && this.hasAuth
          ? <div className="e-select-user-wrapper">
            <img src="https://cdn.kangarooread.com/mp-assets/event_select_user_bg_v3.png" className="bg" alt=""/>
            <div className="info">
              感谢您长期以来对好奇说绘本的支持。<br/>
              经后台数据分析，您与{babyName || '宝宝'}<br/>
              <span className="important">爱惜借阅的绘本、使用周期健康、积极参与亲子伴读打卡与记录</span>，被选为
            </div>
            <div className="btn-wrapper">
              {activityStatus === 1
                ? <button className="btn" type="button" onClick={() => this.toBuyCard()}>马上续卡，领取赠送书袋</button>
                : null}
              {activityStatus === 2
                ? <button className="btn" type="button" onClick={() => this.toMyCard()}>你还未完成续卡，去完成</button>
                : null}
              {activityStatus === 3
                ? <button className="btn" type="button" onClick={() => this.handleGet()}>已续卡，领取赠送的书袋券</button>
                : null}
              {activityStatus === 4
                ? <button className="btn disabled" type="button" onClick={() => this.toCouponList()}>赠送的书袋券已领取</button>
                : null}
            </div>
            <div className="rule-wrapper">
              <div className="label">活动规则</div>
              <div className="rule">
                1. 活动时间：2021年6月1日至6月30日；<br/>
                2. 参与方式：点击下方续卡按钮，购买年卡并进入「我的订阅」页面完成激活续卡；<br/>
                3. 发放福利方式：完成续卡后，在此页面点击领取书袋券；<br/>
                4. 如何使用福利：进入「优惠券」中点击使用；<br/>
                5. 注：专属奖励每位获奖用户只能参与1次，例：续卡1年多送共6个月，续卡2年共多送9个月。
              </div>
            </div>
          </div>
          : <NoData emptyStr="专属奖励，您无法领取~"/>}
      </>
    )
  }
}

export default EventSelectUser

import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'
import wx from 'weixin-js-sdk'
import './index.less'
import { storage } from '../../lib/conf'
import { Util } from '../../lib/util'
import { bridge } from '../../lib/bridge'
import serv from '../../lib/api'

@inject('store')
@observer
class ExWeeklyCourse extends Component {
  @observable env = 'app'
  @observable userInfo = {}
  @observable babyAge = 0
  @observable detail = {}

  componentDidMount() {
    const { showLoading, hideLoading } = this.props.store
    this.babyAge = Util.getParams('babyAge')
    showLoading('加载中...')
    // 动态修改title
    document.title = '每周一节专业育儿课程'
    // 获取运行环境
    this.getEnv()
    setTimeout(() => {
      hideLoading()
    }, 1000)
  }

  @action
  getEnv = () => {
    if (bridge.isAppEnv()) {
      this.env = 'app'
      this.initAppData()
    } else {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            this.env = 'mp'
            this.initMpData()
          } else {
            this.env = 'h5'
            this.initH5Data()
          }
        })
      } else {
        this.env = 'h5'
        this.initH5Data()
      }
    }
  }

  initAppData = () => {
    bridge.getUserInfoFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getWeeklyCourse()
      } else {
        this.loginInApp()
      }
    })
  }

  /**
   * @desc APP 登录时间通知
   * @memberof ExClockIn
   */
  loginInApp = () => {
    bridge.loginFromApp().then((res) => {
      if (res) {
        this.userInfo = { ...res }
        this.getWeeklyCourse()
      } else {
        this.loginInApp()
      }
    })
  }

  initMpData = () => {
    try {
      const token = Util.getParams('token')
      if (token) {
        Util.setLocalStorage(
          storage.userInfo,
          JSON.stringify({ accountVo: { token: token || '' } })
        )
        this.fetchUser()
        setTimeout(() => {
          this.getWeeklyCourse()
        }, 300)
      } else {
        wx.miniProgram.navigateTo({
          url: '/pages/login/index',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  initH5Data = () => {
    try {
      const token = Util.getParams('token')
      if (token) {
        Util.setLocalStorage(
          storage.userInfo,
          JSON.stringify({ accountVo: { token: token || '' } })
        )
        this.fetchUser()
        setTimeout(() => {
          this.getWeeklyCourse()
        }, 300)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 获取每周课程信息
   * @memberof ExWeeklyCourse
   */
  getWeeklyCourse = async () => {
    const res = await serv.getWeeklyCourse(this.babyAge)
    if (res) {
      const { data } = res
      if (data) {
        this.detail = { ...(data || {}) }
      } else {
        Util.removeLocalStorage(storage.userInfo)
      }
    }
  }

  /**
   * @desc 获取用户信息（小程序及H5使用）
   * @memberof ExWeeklyCourse
   */
  fetchUser = async () => {
    const res = await serv.getUserInfo()
    try {
      if (res) {
        const { data } = res
        Util.setLocalStorage(storage.userInfo, JSON.stringify(data || {}))
        this.userInfo = { ...data }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @desc 跳转购卡页面
   * @memberof ExWeeklyCourse
   */
  toBuyCard = () => {
    if (this.env === 'app') {
      bridge.appNavigate({ id: 3 })
    } else if (this.env === 'mp') {
      wx.miniProgram.navigateTo({
        url: '/subPkg/extend/pages/buy-card/index?source=exWeejlyCourse',
      })
    }
  }

  render() {
    const { title, content, img, audio } = this.detail
    return (
      <div className="common-wrapper">
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: content || '',
          }}
        ></div>
        {img ? (
          <div className="img-wrapper">
            <img src={img || ''} alt="" className="img" />
          </div>
        ) : null}
        {audio ? (
          <div className="audio-wrapper">
            <audio className="audio" controls preload="auto">
              <source src={audio} type="audio/mpeg" />
            </audio>
          </div>
        ) : null}
        <div className="bottom-btn" onClick={() => this.toBuyCard()}>
          <p className="primary">成为会员收听精彩内容完整版</p>
        </div>
      </div>
    )
  }
}

export default ExWeeklyCourse
